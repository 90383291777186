import { has } from 'lodash'
import I18n from 'i18next'

const validateIndicatorMessagesAndNews = (indicator, indicatorMessageCountry, newsCountries) => {
  const indicatorValid = has(indicator, 'id') && has(indicator, 'name')
  const indicatorMessageValid = has(indicatorMessageCountry, 'id') && has(indicatorMessageCountry, 'name')
  const partiallyValid = indicatorValid ^ indicatorMessageValid
  const newsValid = newsCountries.length > 0
  const isValid = partiallyValid ? false : (indicatorValid && indicatorMessageValid) || newsValid
  const messages = {
    indicator: indicatorValid ? '' : I18n.t('common.messages.validation.required'),
    indicatorMessage: indicatorMessageValid ? '' : I18n.t('common.messages.validation.required'),
    news: newsValid ? '' : I18n.t('common.messages.validation.required'),
  }
  return { valid: isValid, errors: isValid ? {} : messages }
}

const validateNews = (newsCountries) => {
  const newsValid = newsCountries.length > 0
  const message = {
    news: newsValid ? '' : I18n.t('common.messages.validation.required'),
  }
  return {
    valid: newsValid,
    errors: message,
  }
}

export const validationCountry = (data, onlyNews) => {
  const { indicator, indicatorMessageCountry, newsCountries } = data
  return onlyNews
    ? validateNews(newsCountries)
    : validateIndicatorMessagesAndNews(indicator, indicatorMessageCountry, newsCountries)
}
