import { useMutation } from '@tanstack/react-query'

import { fetch } from '@services'

export type DeleteHitsData = {
  ids: number[]
}

const useDeleteHits = () => {
  const mutationFn = async ({ ids }: DeleteHitsData) => {
    await fetch('/api/hits/bulk_update', {
      method: 'POST',
      body: JSON.stringify({ ids, status: 'closed' }),
    })

    return { ids: [] } as DeleteHitsData
  }

  return useMutation<DeleteHitsData, DeleteHitsData, DeleteHitsData>({
    mutationKey: ['DELETE_HITS'],
    mutationFn,
  })
}

export default useDeleteHits
