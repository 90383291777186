import { UPDATE_COUNTRY_DATA, FORM_INVALID, FORM_VALID } from '../actions/country'
import initialState from './initial_state'

export default function country(state = initialState.country, { type, data }) {
  switch (type) {
    case UPDATE_COUNTRY_DATA:
      return {
        ...state,
        data: { ...state.data, ...data },
      }
    case FORM_INVALID:
      return {
        ...state,
        valid: false,
        errors: data,
      }
    case FORM_VALID:
      return {
        ...state,
        valid: true,
        errors: {},
      }
    default:
      return state
  }
}
