import { some, includes, isEmpty, pickBy } from 'lodash'
import I18n from 'i18next'

const t = (name) => I18n.t(`common.messages.validation.${name}`)

const validateOnlyNews = ({ newsForm }) => {
  const noNewsSuppliers = isEmpty(newsForm.suppliers)
  return {
    valid: !noNewsSuppliers,
    errors: pickBy({
      noNewsSuppliers: noNewsSuppliers && t('supplier.suppliers_empty'),
    }),
  }
}

const validateIMAndNews = ({ alertForm, newsForm }, noValidity) => {
  const noAlertSuppliers = isEmpty(alertForm.suppliers)
  const noIndicator = isEmpty(alertForm.selectedIndicator)
  const newsSuppliersIds = newsForm.suppliers.map(({ id, coreInstance }) => `${id}-${coreInstance}`)
  const sameSupplierExists = some(alertForm.suppliers, ({ id, coreInstance }) =>
    includes(newsSuppliersIds, `${id}-${coreInstance}`)
  )
  const bankruptcyWithValidityDate =
    !noIndicator && !noValidity && alertForm.selectedIndicator.eventType === 'bankruptcy'

  return {
    valid: !noAlertSuppliers && !sameSupplierExists && !noIndicator && !bankruptcyWithValidityDate,
    errors: pickBy({
      noAlertSuppliers: noAlertSuppliers && t('supplier.suppliers_empty'),
      sameSupplierExists: sameSupplierExists && t('supplier.same_supplier_exists'),
      noIndicator: noIndicator && t('required'),
      bankruptcyWithValidityDate: bankruptcyWithValidityDate && t('supplier.bankruptcy_with_validity_date'),
    }),
  }
}

const isAlertFormEmpty = ({ alertForm }) => {
  return isEmpty(alertForm.suppliers) && !alertForm.selectedIndicator
}

export const validationSupplier = (supplierData, onlyNews = false, noValidity = false) => {
  return onlyNews || isAlertFormEmpty(supplierData)
    ? validateOnlyNews(supplierData)
    : validateIMAndNews(supplierData, noValidity)
}
