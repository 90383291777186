import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import I18n from 'i18next'

import { useIMContext } from '@hooks'

import { ImForm } from '../im-form'
import { setImInitState } from '../../actions'
import { buildPreloadedState } from '../../utils/build_preloaded_state'

export const CreateIMForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { imFormValues, setIMFormValues } = useIMContext()

  useLayoutEffect(() => {
    if (!imFormValues) {
      const state = buildPreloadedState({})
      dispatch(setImInitState(state))
    }
  }, [])

  const handleAccept = (data) => {
    history.push(`${history.location.pathname}/${data.type}`)
  }

  const handleCancel = () => {
    setIMFormValues(null)
    history.push('/inbox')
  }

  return (
    <ImForm
      handleAccept={handleAccept}
      handleCancel={handleCancel}
      acceptBtnLabel={I18n.t('indicator_message.buttons.next')}
      submitOnAccept
    />
  )
}
