import { sortBy } from 'lodash'
import { industriesMasterData } from '@riskmethods/scrm-common-data-industries'

import { IndustriesMasterData } from '@types'

const industries: IndustriesMasterData = industriesMasterData

const useGetIndustries = () => {
  const industryMap = industries.reduce((acc, { id, name }) => {
    acc[id] = name

    return acc
  }, {})

  return {
    industries: sortBy(industries, ['name']),
    industryMap,
  }
}

export default useGetIndustries
