import { Field, Input } from '@riskmethods/rm-front-end'

type Props = {
  disabled?: boolean
}

export const SourceForContentInput = ({ disabled = false }: Props) => {
  return (
    <Field
      name="sourceForContent"
      label="Source for content"
      disabled={disabled}
      Component={(props) => (
        <Input
          testId="source-for-content-field"
          enableClearBtn
          enableTextCaseConverter
          textFieldProps={{ inputProps: { spellCheck: true } }}
          {...props}
        />
      )}
      validationRules={{
        required: true,
      }}
    />
  )
}
