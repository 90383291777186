import { FORM_MODE_CREATE } from '../utils/const'

const initialState = {
  formMode: FORM_MODE_CREATE,
  supplier: {
    data: {
      alertForm: {
        availableSuppliers: [],
        suppliers: [],
        suppliersLoading: false,
        searches: [],
      },
      newsForm: {
        availableSuppliers: [],
        suppliers: [],
        suppliersLoading: false,
        searches: [],
      },
      indicators: [],
      indicatorsLoading: true,
    },
    valid: true,
    errors: {},
  },
  country: {
    valid: false,
    data: {
      indicator: {},
      indicatorMessageCountry: {},
      newsCountries: [],
    },
    errors: {},
  },
  location: {
    valid: false,
    data: {
      indicators: [],
      indicatorsLoading: true,
      radius: null,
      backupRadius: null,
      isPolygon: false,
      polygonPoints: null,
      selectedLocations: [],
      findLocation: {
        loading: false,
      },
      editable: false,
      draggable: false,
    },
    externalServiceErrors: null,
    errors: {},
    updateMap: false,
    fitMapBounds: false,
    features: [],
    polygonToolsEnabled: false,
  },
  save: {
    inProgress: false,
    success: false,
    hasErrors: false,
  },
  update: {
    inProgress: false,
    success: false,
    hasErrors: false,
  },
  duplicateDetection: {
    errors: [],
    filters: {
      queryStrategy: 'subject_body',
    },
    table: {
      page: 1,
      data: { messages: [] },
    },
  },
}

export default initialState
