import { round, uniqueId } from 'lodash'

import { getJSON } from '@common/http'

export const UPDATE_LOCATION_DATA = 'UPDATE_LOCATION_DATA'
export const RESET_LOCATIONS = 'RESET_LOCATIONS'
export const APPEND_LOCATION = 'APPEND_LOCATION'
export const APPEND_LOCATION_FAILED = 'APPEND_LOCATION_FAILED'
export const TOGGLE_IS_POLYGON = 'TOGGLE_IS_POLYGON'

export const FORM_VALID = 'LOCATION_FORM_VALID'
export const FORM_INVALID = 'LOCATION_FORM_INVALID'
export const MAP_UPDATED = 'IM_MAP_UPDATED'
export const REFRESH_MAP = 'REFRESH_MAP'
export const MAP_BOUNDS_FITTED = 'MAP_BOUNDS_FITTED'
export const FIND_LOCATION_IN_PROGRESS = 'FIND_LOCATION_IN_PROGRESS'
export const FIND_LOCATION_FINISHED = 'FIND_LOCATION_FINISHED'
export const ENABLE_POLYGON_TOOLS = 'ENABLE_POLYGON_TOOLS'

export const updateLocationData = (data, refreshMap = false) => ({
  type: UPDATE_LOCATION_DATA,
  data,
  metaData: { refreshMap },
})

export const mapUpdated = () => ({
  type: MAP_UPDATED,
})

export const refreshMap = () => ({
  type: REFRESH_MAP,
})

export const mapBoundsFitted = () => ({
  type: MAP_BOUNDS_FITTED,
})

export const findLocationLoading = () => ({
  type: FIND_LOCATION_IN_PROGRESS,
})

export const findLocationFinished = () => ({
  type: FIND_LOCATION_FINISHED,
})

export const enablePolygonTools = (data) => ({
  type: ENABLE_POLYGON_TOOLS,
  data,
})

export const updateLocationDataAndRefresh = (locationData, { refreshMap: { data, bounds } } = {}) => {
  return (dispatch) => {
    dispatch(updateLocationData(locationData, { data, bounds }))
  }
}

export const toggleIsPolygon = (isPolygon) => ({
  type: TOGGLE_IS_POLYGON,
  data: isPolygon,
})

export const updateErrorInPolygon = () => (dispatch) => {
  dispatch({ type: FORM_INVALID, data: { polygonPointsText: ['Invalid format'] } })
}

export const updateManuallyEnteredPolygon = (polygonPoints) => (dispatch) => {
  const findPolygonArea = (polygonPoints) => {
    let area = 0,
      i,
      j,
      point1,
      point2

    for (i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i, i++) {
      point1 = polygonPoints[i]
      point2 = polygonPoints[j]
      area += point1.latitude * point2.longitude
      area -= point1.longitude * point2.latitude
    }
    area /= 2

    return area
  }

  const findCentroid = (polygonPoints, polygonArea) => {
    let x = 0,
      y = 0,
      i,
      j,
      f,
      point1,
      point2

    for (i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i, i++) {
      point1 = polygonPoints[i]
      point2 = polygonPoints[j]
      f = point1.latitude * point2.longitude - point2.latitude * point1.longitude
      x += (point1.latitude + point2.latitude) * f
      y += (point1.longitude + point2.longitude) * f
    }

    return { latitude: x / polygonArea, longitude: y / polygonArea }
  }

  const cleanAndRedraw = () => {
    dispatch({ type: RESET_LOCATIONS, data: [] })
    const polygonArea = findPolygonArea(polygonPoints) * 6
    const center = findCentroid(polygonPoints, polygonArea)
    dispatch(appendLocation(center.latitude, center.longitude))
    dispatch({ type: FORM_VALID })
    dispatch(
      updateLocationDataAndRefresh(
        {
          polygonPoints,
          polygonArea,
          editable: false,
          draggable: false,
        },
        { refreshMap: { data: true, bounds: false } }
      )
    )
  }

  cleanAndRedraw()
}

export const appendLocation = (latitude, longitude) => (dispatch) => {
  const updateData = (latitude, longitude, data = {}) => {
    const coordinates = `${round(latitude, 3)},${round(longitude, 3)}`
    const locationData = {
      ...{ id: uniqueId(), name: coordinates, fullName: coordinates },
      ...data,
      latitude,
      longitude,
    }
    dispatch({ type: APPEND_LOCATION, data: locationData })
    dispatch(findLocationFinished())
  }

  const updateDataFailed = () => {
    dispatch({ type: APPEND_LOCATION_FAILED })
    dispatch(findLocationFinished())
  }

  dispatch(findLocationLoading())
  return getJSON('/api/locations/find', { latitude, longitude })
    .then(({ data }) => {
      updateData(latitude, longitude, data)
    })
    .catch(() => {
      updateDataFailed()
    })
}
