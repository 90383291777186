import { useMemo, useState } from 'react'
import { Box, TableContainer, Table, TableBody, TableHead, Typography } from '@mui/material'
import I18n from 'i18next'

import type { HitQuality, HitsData, InitPageData } from '@types'
import type { UpdateHitsData } from '@pages/inbox/api/use-update-hits/use-update-hits'
import type { UseHitQualitiesReturn } from '@hooks/use-hit-qualities/use-hit-qualities'
import { useAppContext } from '@hooks'

import Header from './components/header/header'
import Row from './components/row/row'
import Footer from './components/footer/footer'

type Props = {
  initialData: InitPageData
  hitsData: HitsData
  updateHitsData: UpdateHitsData | undefined
  isUpdateHitsError: boolean
  isLoading: boolean
  disableSorting: boolean
  sort: string
  hitQualities: UseHitQualitiesReturn
  handleSortChanged: (sort: string) => void
  handlePageChanged: (e, page: number) => void
  handleQualityChange: (quality: HitQuality, hitId?: number) => Promise<unknown>
}

const HitsTable = ({
  initialData,
  hitsData,
  isLoading,
  disableSorting,
  sort,
  hitQualities,
  handleSortChanged,
  handlePageChanged,
  handleQualityChange,
}: Props) => {
  const {
    env: { REMOTE_ENV },
  } = useAppContext()

  const [disableHeaderUpdateQuality, setDisableHeaderUpdateQuality] = useState<boolean>(false)
  const [disableAllRowsUpdateQuality, setDisableAllRowsUpdateQuality] = useState<boolean>(false)

  const { qualities, onQualityCheckboxClick, areAllHitsGreat, areAllHitsIrrelevant, areAllHitsRated } = hitQualities
  const isLocalEnv = !REMOTE_ENV || REMOTE_ENV === 'false'

  const isArticleAnalyzerUser = initialData?.currentUser?.articleAnalyzerUser || false
  const showSavedTableHeader = isLocalEnv || isArticleAnalyzerUser

  const listItems = useMemo(() => {
    return hitsData.hits.map((hit) => {
      return (
        <Row
          key={hit.id}
          data={hit}
          initialData={initialData}
          noTranslationLanguages={hitsData.noTranslationLanguages}
          handleQualityChange={handleQualityChange}
          onQualityCheckboxClick={onQualityCheckboxClick}
          quality={qualities[hit.id]}
          disableAllRowsUpdateQuality={disableAllRowsUpdateQuality}
          setDisableHeaderUpdateQuality={setDisableHeaderUpdateQuality}
        />
      )
    })
  }, [hitsData, qualities, disableAllRowsUpdateQuality])

  if (!hitsData.hits.length) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <Typography variant="subtitle1">
          {isLoading ? 'Loading ...' : I18n.t('inbox.table.messages.empty_inbox')}
        </Typography>
      </Box>
    )
  }

  return (
    <Box mt={1}>
      <TableContainer sx={{ pb: '50px' }}>
        <Table>
          <TableHead>
            <Header
              sort={sort}
              handleSortChanged={handleSortChanged}
              handleQualityChange={handleQualityChange}
              areAllHitsGreat={areAllHitsGreat}
              areAllHitsIrrelevant={areAllHitsIrrelevant}
              areAllHitsRated={areAllHitsRated}
              showSavedTableHeader={showSavedTableHeader}
              disableSorting={disableSorting}
              disableHeaderUpdateQuality={disableHeaderUpdateQuality}
              setDisableHeaderUpdateQuality={setDisableHeaderUpdateQuality}
              setDisableAllRowsUpdateQuality={setDisableAllRowsUpdateQuality}
              onQualityCheckboxClick={onQualityCheckboxClick}
            />
          </TableHead>
          <TableBody>{listItems}</TableBody>
        </Table>
      </TableContainer>
      <Footer
        isLoading={isLoading}
        total={hitsData.totalResults}
        totalPages={hitsData.totalPages}
        page={hitsData.page}
        perPage={hitsData.perPage}
        handlePageChanged={handlePageChanged}
      />
    </Box>
  )
}

export default HitsTable
