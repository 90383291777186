import { useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import Inbox from '@mui/icons-material/MoveToInboxOutlined'
import InboxFilled from '@mui/icons-material/MoveToInbox'
import Review from '@mui/icons-material/RuleFolderOutlined'
import ReviewFilled from '@mui/icons-material/RuleFolder'
import Elist from '@mui/icons-material/FolderDeleteOutlined'
import ElistFilled from '@mui/icons-material/FolderDelete'
import Bp from '@mui/icons-material/LocalShippingOutlined'
import BpFilled from '@mui/icons-material/LocalShipping'
import World from '@mui/icons-material/MapOutlined'
import WorldFilled from '@mui/icons-material/Map'
import History from '@mui/icons-material/RestorePageOutlined'
import HistoryFilled from '@mui/icons-material/RestorePage'
import ShiftManagement from '@mui/icons-material/SchemaOutlined'
import ShiftManagementFilled from '@mui/icons-material/Schema'
import SavedHits from '@mui/icons-material/BookmarkBorder'
import SavedHitsFilled from '@mui/icons-material/Bookmark'

const defaultProps = {
  sx: { fontSize: 26 },
  inheritViewBox: true,
  color: 'inherit',
}
const HistoryIcon = ({ className }) => {
  return <SvgIcon component={History} className={className} {...defaultProps} />
}
const HistoryFilledIcon = ({ className }) => {
  return <SvgIcon component={HistoryFilled} className={className} {...defaultProps} />
}

const WorldIcon = ({ className }) => {
  return <SvgIcon component={World} className={className} {...defaultProps} />
}
const WorldFilledIcon = ({ className }) => {
  return <SvgIcon component={WorldFilled} className={className} {...defaultProps} />
}
const InboxIcon = ({ className }) => {
  return <SvgIcon component={Inbox} className={className} {...defaultProps} />
}
const InboxFilledIcon = ({ className }) => {
  return <SvgIcon component={InboxFilled} className={className} {...defaultProps} />
}
const ReviewIcon = ({ className }) => {
  return <Review className={className} {...defaultProps} />
}
const ReviewFilledIcon = ({ className }) => {
  return <SvgIcon component={ReviewFilled} className={className} {...defaultProps} />
}
const EListIcon = ({ className }) => {
  return <SvgIcon component={Elist} className={className} {...defaultProps} />
}
const EListFilledIcon = ({ className }) => {
  return <SvgIcon component={ElistFilled} className={className} {...defaultProps} />
}
const BpIcon = ({ className }) => {
  return <SvgIcon component={Bp} className={className} {...defaultProps} />
}
const BpFilledIcon = ({ className }) => {
  return <SvgIcon component={BpFilled} className={className} {...defaultProps} />
}
const ShiftManagementIcon = ({ className }) => {
  return <SvgIcon component={ShiftManagement} className={className} {...defaultProps} />
}
const ShiftManagementFilledIcon = ({ className }) => {
  return <SvgIcon component={ShiftManagementFilled} className={className} {...defaultProps} />
}
const SavedHitsIcon = ({ className }) => {
  return <SvgIcon component={SavedHits} className={className} {...defaultProps} />
}
const SavedHitsFilledIcon = ({ className }) => {
  return <SvgIcon component={SavedHitsFilled} className={className} {...defaultProps} />
}

const ICONS = {
  history: {
    normal: HistoryIcon,
    filled: HistoryFilledIcon,
  },
  inbox: {
    normal: InboxIcon,
    filled: InboxFilledIcon,
  },
  world: {
    normal: WorldIcon,
    filled: WorldFilledIcon,
  },
  review: {
    normal: ReviewIcon,
    filled: ReviewFilledIcon,
  },
  elist: {
    normal: EListIcon,
    filled: EListFilledIcon,
  },
  bp: {
    normal: BpIcon,
    filled: BpFilledIcon,
  },
  shiftManagement: {
    normal: ShiftManagementIcon,
    filled: ShiftManagementFilledIcon,
  },
  savedHits: {
    normal: SavedHitsIcon,
    filled: SavedHitsFilledIcon,
  },
}

const Icon = ({ icon, filled = false, className }) => {
  const Component = useMemo(() => ICONS[icon][filled ? 'filled' : 'normal'], [icon, filled])

  return <Component className={className} />
}

export default Icon
