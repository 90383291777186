import { get } from 'lodash'
import { getJSON, postJSON, patchJSON, deleteJSON } from '@common/http'
import { tableParams } from '@common/utils/table_params'

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const DATA_RECEIVED = 'MESSAGES_DATA_RECEIVED'
export const ERROR_RECEIVED = 'MESSAGES_ERROR_RECEIVED'
export const LOAD_DATA = 'MESSAGES_LOAD_DATA'
export const RECIPIENTS_DATA_REQUESTED = 'RECIPIENTS_DATA_REQUESTED'
export const RECIPIENTS_DATA_RECEIVED = 'RECIPIENTS_DATA_RECEIVED'
export const RECIPIENTS_DATA_REQUEST_FAILED = 'RECIPIENTS_DATA_REQUEST_FAILED'
export const DELETE_NEWS_REQUESTED = 'DELETE_NEWS_REQUESTED'
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED'

export const updateMessage = (id, attributes) => (dispatch) => {
  const buildAction = (payload, metaData) => ({
    type: UPDATE_MESSAGE,
    payload,
    metaData,
  })

  dispatch(buildAction({ id, attributes }, { status: 'pending ' }))

  return patchJSON(`/api/messages/${id}`, {
    message: {
      ...attributes,
      validUntil: attributes.validUntil || '11-11-2222',
    },
  })
    .then(() => {
      dispatch(buildAction({ id, attributes }, { status: 'success' }))
    })
    .catch(() => null) // Handle errors
}

export const loadData = () => ({ type: LOAD_DATA })

export const deleteNewsRequested = () => ({ type: DELETE_NEWS_REQUESTED })

export const dataReceived = (json) => ({
  type: DATA_RECEIVED,
  data: json,
})

export const errorReceived = (json) => ({
  type: ERROR_RECEIVED,
  data: json,
})

export const deleteNewsFailed = (json) => ({
  type: DELETE_NEWS_FAILED,
  data: json,
})

export const fetchData = (rootName) => (dispatch, getState) => {
  const {
    table: { page, sort },
    filters,
  } = rootName ? getState()[rootName] : getState()
  dispatch(loadData())
  return getJSON('/api/messages', tableParams({ page, sort, filters }))
    .then(({ data }) => {
      dispatch(dataReceived(data))
    })
    .catch((resp) => {
      dispatch(errorReceived({ errors: resp?.data?.errors }))
    })
}

export const recipientsDataRequested = () => ({
  type: RECIPIENTS_DATA_REQUESTED,
})

export const recipientsDataReceived = (data) => ({
  type: RECIPIENTS_DATA_RECEIVED,
  data,
})

export const recipientsDataRequestFailed = (json) => ({
  type: RECIPIENTS_DATA_REQUEST_FAILED,
  data: json,
})

export const fetchRecipients = (messageId) => (dispatch) => {
  dispatch(recipientsDataRequested())
  return postJSON('/api/messages/recipients', { messageId })
    .then(({ data }) => {
      dispatch(recipientsDataReceived(data))
    })
    .catch((err) => {
      dispatch(recipientsDataRequestFailed({ errors: get(err, 'data.errors', 'Internal error') }))
    })
}

export const deleteNews = (id) => (dispatch) => {
  dispatch(deleteNewsRequested())

  return deleteJSON(`/api/messages/${id}`)
    .then(() => {
      dispatch(fetchData())
    })
    .catch((resp) => {
      dispatch(deleteNewsFailed({ errors: resp?.data?.errors }))
    })
}
