import uniq from 'lodash/uniq'

import { allGreat, allIrrelevant, allRated } from '@pages/inbox/helpers'
import type { HitData, HitQuality } from '@types'

import getNewHitQuality from '../get-new-hit-quality/get-new-hit-quality'

const getUpdateHitsReqBody = (
  hits: HitData[],
  quality: HitQuality,
  hitId?: number
): { ids: number[]; quality: HitQuality } => {
  const body = {
    ids: [],
    quality: null,
  } as { ids: number[]; quality: HitQuality }

  if (hitId) {
    const hit = hits.find((h) => h.id === hitId)

    if (hit) {
      body.ids.push(hit.id)
      body.quality = getNewHitQuality([hit], quality)
    }
  } else {
    if (allRated(hits)) {
      const areAllGreat = allGreat(hits)
      const areAllIrrelevant = allIrrelevant(hits)
      if (areAllGreat || areAllIrrelevant) {
        body.ids = hits.map(({ id }) => id)
        body.quality =
          (areAllGreat && quality === 'great') || (areAllIrrelevant && quality === 'irrelevant') ? null : quality
      } else {
        body.ids = hits.filter((hit) => hit.quality !== quality).map(({ id }) => id)
        body.quality = quality
      }
    } else {
      body.ids = hits.filter((hit) => hit.quality === null).map(({ id }) => id)
      body.quality = quality
    }
  }

  body.ids = uniq(body.ids)

  return body
}

export default getUpdateHitsReqBody
