import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'
import { stringify } from 'qs'
import set from 'lodash/set'

import { fetch, displayError } from '@services'
import type { GetSavedHitsSchema, SavedHitsData, SavedHitsFormValues } from '@types'

export const GET_SAVED_HITS_DEFAULT_DATA = {
  hits: [],
  noTranslationLanguages: [],
} as SavedHitsData

const transformData = (data: GetSavedHitsSchema | null): SavedHitsData => {
  if (!data) return GET_SAVED_HITS_DEFAULT_DATA

  const transformedData = camelizeKeys<GetSavedHitsSchema>(data)
  if (!transformedData) return GET_SAVED_HITS_DEFAULT_DATA

  return transformedData
}

const transformReqParams = (params: SavedHitsFormValues): string => {
  const { saved_by_id, search_for, sort } = params

  const obj = {}

  if (saved_by_id && saved_by_id !== 'any') set(obj, 'saved_by_id', saved_by_id)
  if (search_for) set(obj, 'search_for', search_for)
  if (sort) set(obj, 'sort', sort)

  const transformedParams = stringify(obj, { arrayFormat: 'brackets' })
  return transformedParams ? `?${transformedParams}` : ''
}

const queryFn = async (params: SavedHitsFormValues): Promise<SavedHitsData> => {
  let data: GetSavedHitsSchema | null = null

  // this is to make sure getParams will return updated values
  await new Promise((resolve) => setTimeout(resolve))

  const queryString = transformReqParams(params)
  const path = `/api/v2/selected_hits${queryString}`

  const res = await fetch(path)

  if (res.ok) {
    data = await res.json()
  } else {
    displayError('Could not fetch hits this time.')
  }

  return transformData(data)
}

const useGetSavedHits = (params: SavedHitsFormValues) => {
  return useQuery<SavedHitsData>({
    queryKey: ['GET_SAVED_HITS'],
    queryFn: () => queryFn(params),
    refetchOnMount: false,
    retry: false,
    enabled: true,
    refetchInterval: (query) => {
      return query.state.data?.hits.some((hit) => !hit.template) ? 5000 : false
    },
  })
}

export default useGetSavedHits
