import { Box } from '@mui/material'
import I18n from 'i18next'

import Link from '@common/components/link'

import * as styles from '../../hits-content.scss'

type Props = {
  id: number
  isNativeLanguage: boolean
  isInsolvency: boolean
  noTranslationLanguage: boolean
  displayTranslatedText: boolean
  handleTranslationChange: (id: number) => void
}

const getText = (isNativeLanguage: boolean, displayTranslatedText: boolean) => {
  if ((isNativeLanguage && displayTranslatedText) || !displayTranslatedText) {
    return I18n.t('inbox.table.translation.translate')
  }

  return I18n.t('inbox.table.translation.show_original')
}

const HitTranslationBtn = ({
  id,
  isNativeLanguage,
  isInsolvency,
  noTranslationLanguage,
  displayTranslatedText,
  handleTranslationChange,
}: Props) => {
  if (!noTranslationLanguage && !isInsolvency) {
    const text = getText(isNativeLanguage, displayTranslatedText)

    const handleOnClick = (e) => {
      e.preventDefault()
      handleTranslationChange(id)
    }

    return (
      <Box>
        <Link href="#" id="toggle-translate" className={styles.translate} onClick={handleOnClick}>
          {text}
        </Link>
      </Box>
    )
  }

  return null
}

export default HitTranslationBtn
