import { pickBy } from 'lodash'
import { patchJSON } from '@common/http'

export const ERRORS_UPDATING = 'ERRORS_UPDATING'
export const REQUEST_UPDATE = 'REQUEST_UPDATE'
export const SUCCESSFULLY_UPDATED = 'SUCCESSFULLY_UPDATED'

const requestUpdate = () => ({
  type: REQUEST_UPDATE,
})

const successfullyUpdated = () => ({
  type: SUCCESSFULLY_UPDATED,
})

export const errorsUpdating = () => ({
  type: ERRORS_UPDATING,
})

const mapBusinessPartner = ({ id, name, address, coreInstance }) => ({
  id,
  name,
  coreInstance,
  city: address.city,
  countryCode: address.countryCode,
})

const mapSearch = ({ name, result }) => ({
  name,
  result: result.map(({ id }) => ({ id })),
})

export const updateMessage = (id, imFormValues) => (dispatch, getState) => {
  const { subject, sourceContent, sourceCopyright, body, hyperlink, type, onlyNews } = imFormValues

  const {
    country: { data: countryData },
    supplier: {
      data: {
        newsForm: { suppliers, searches: newsSearches },
      },
    },
  } = getState().indicatorMessage

  const country = type === 'country' &&
    onlyNews && { newsCountries: countryData.newsCountries.map(({ code }) => ({ code })) }
  const businessPartner = type === 'business_partner' &&
    onlyNews && { newsBusinessPartners: suppliers.map(mapBusinessPartner) }
  const searches = type === 'business_partner' && onlyNews && { newsSearches: newsSearches.map(mapSearch) }
  const hyperlinks = [hyperlink]

  dispatch(requestUpdate())

  return patchJSON(`/api/messages/${id}`, {
    message: pickBy({ subject, sourceContent, sourceCopyright, body, hyperlinks, country, businessPartner, searches }),
  })
    .then(() => {
      dispatch(successfullyUpdated())
    })
    .catch(() => {
      dispatch(errorsUpdating())
    })
}
