import PropTypes from 'prop-types'
import { Button, Stack, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import I18n from 'i18next'

const Buttons = ({ submit, back, cancel, reset, save, enabledPolygonTools }) => (
  <Stack direction={'row'} sx={{ py: 2 }} justifyContent={'space-between'}>
    <Box flex={1}>
      <Button color="secondary" onClick={back.onClick} disabled={back.disabled} data-testid="back">
        {I18n.t('indicator_message.buttons.back')}
      </Button>
    </Box>
    {enabledPolygonTools && (
      <Box flex={1} textAlign={'center'}>
        <Button color="secondary" onClick={reset.onClick} data-testid="reset" sx={{ mr: 1 }}>
          {I18n.t('indicator_message.buttons.reset')}
        </Button>
        <Button color="primary" onClick={save.onClick} data-testid="save">
          {I18n.t('indicator_message.buttons.save_polygon')}
        </Button>
      </Box>
    )}
    <Box flex={1} textAlign={'right'}>
      <Button color="secondary" sx={{ mr: 1 }} onClick={cancel.onClick} disabled={cancel.disabled} data-testid="cancel">
        {I18n.t('indicator_message.buttons.cancel')}
      </Button>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        onClick={submit.onClick}
        loading={submit.loading || submit.disabled}
        data-testid="submit"
      >
        {submit.label}
      </LoadingButton>
    </Box>
  </Stack>
)

Buttons.propTypes = {
  submit: PropTypes.shape({
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
  back: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  cancel: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  reset: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  save: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  enabledPolygonTools: PropTypes.bool,
}

export default Buttons
