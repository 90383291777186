import { useAppContext } from '@hooks'

import * as styles from './app-ribbon.module.scss'

const AppRibbon = () => {
  const { env } = useAppContext()

  if (!['FEATURE', 'DEVELOPMENT', 'STAGING'].includes(env.REMOTE_ENV)) {
    return null
  }

  return <div className={styles.root}>{env.REMOTE_ENV}</div>
}

export default AppRibbon
