import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import I18n from 'i18next'

import { setFilters, setPage } from '@common/actions/table'
import { businessPartner } from '@common/utils/shapes'
import { PageCont, TopCont, BottomCont } from '@common/layout'

import {
  fetchData,
  updateNameSearchQuery,
  updateLocationSearchQuery,
  updateSearchQueryFields,
  urlChanged,
} from './actions'

import {
  searchNamesFormatter,
  nativeSearchNamesFormatter,
  citySearchNamesFormatter,
  nameFormatter,
  addressFormatter,
  clusterFormatter,
  headerFormatter,
} from './components/formatters'

import Table from './components/table'
import Filters from './components/filters'

const buildColumns = ({ nameSearchQuery, locationSearchQuery, searchQueryFields = [] }) => {
  const separatedSearchQueryFields = searchQueryFields.join(',').split(',')

  return [
    {
      dataField: 'id',
      text: I18n.t('common.business_partners_table.columns.cluster'),
      sort: false,
      formatter: clusterFormatter,
      style: {
        width: '100px',
      },
    },
    {
      dataField: 'name',
      text: I18n.t('common.business_partners_table.columns.name'),
      sort: true,
      formatter: nameFormatter,
      headerFormatter: headerFormatter(nameSearchQuery),
    },
    {
      dataField: 'address',
      text: I18n.t('common.business_partners_table.columns.address'),
      formatter: addressFormatter,
      headerFormatter: headerFormatter(locationSearchQuery && separatedSearchQueryFields.includes('street')),
    },
    {
      dataField: 'address.city',
      text: I18n.t('common.business_partners_table.columns.city'),
      sort: true,
      headerFormatter: headerFormatter(locationSearchQuery && separatedSearchQueryFields.includes('city')),
    },
    {
      dataField: 'citySearchNames',
      text: I18n.t('common.business_partners_table.columns.city_search_name'),
      formatter: citySearchNamesFormatter,
      headerFormatter: headerFormatter(locationSearchQuery && separatedSearchQueryFields.includes('city_search_name')),
    },
    {
      dataField: 'address.countryName',
      text: I18n.t('common.business_partners_table.columns.country'),
      sort: true,
      headerFormatter: headerFormatter(locationSearchQuery && separatedSearchQueryFields.includes('country_name')),
    },
    {
      dataField: 'searchNames',
      text: I18n.t('common.business_partners_table.columns.search_name'),
      formatter: searchNamesFormatter,
      headerFormatter: headerFormatter(nameSearchQuery),
    },
    {
      dataField: 'nativeSearchNames',
      text: I18n.t('common.business_partners_table.columns.native_search_name'),
      formatter: nativeSearchNamesFormatter,
      headerFormatter: headerFormatter(nameSearchQuery),
    },
  ]
}
class BusinessPartnersPage extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(businessPartner),
    fetchData: PropTypes.func.isRequired,
    urlChanged: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number,
    perPage: PropTypes.number,
    totalResults: PropTypes.number,
    filters: PropTypes.object,
    nameSearchQuery: PropTypes.string,
    updateNameSearchQuery: PropTypes.func.isRequired,
    locationSearchQuery: PropTypes.string,
    updateLocationSearchQuery: PropTypes.func.isRequired,
    searchQueryFields: PropTypes.arrayOf(PropTypes.string),
    updateSearchQueryFields: PropTypes.func.isRequired,
    history: PropTypes.object,
  }

  componentDidMount() {
    this.props.fetchData()
    this.props.history.listen(({ search }) => {
      if (this.props.history.action === 'POP') {
        this.props.urlChanged(search)
        this.props.fetchData()
      }
    })
  }

  fetchData = () => {
    this.setState({
      ...this.state,
      columns: buildColumns({
        nameSearchQuery: this.props.nameSearchQuery,
        locationSearchQuery: this.props.locationSearchQuery,
        searchQueryFields: this.props.searchQueryFields,
      }),
    })
    this.props.fetchData()
    window.scrollTo(0, 0)
  }

  state = {
    columns: buildColumns({
      nameSearchQuery: this.props.nameSearchQuery,
      locationSearchQuery: this.props.locationSearchQuery,
      searchQueryFields: this.props.searchQueryFields,
    }),
  }

  render() {
    const {
      fetchData,
      setPage,
      perPage,
      nameSearchQuery,
      updateNameSearchQuery,
      locationSearchQuery,
      updateLocationSearchQuery,
      searchQueryFields,
      updateSearchQueryFields,
    } = this.props

    return (
      <PageCont>
        <TopCont>
          <Filters
            setPage={setPage}
            perPage={parseInt(perPage)}
            fetchData={this.fetchData}
            nameSearchQuery={nameSearchQuery}
            updateNameSearchQuery={updateNameSearchQuery}
            locationSearchQuery={locationSearchQuery}
            updateLocationSearchQuery={updateLocationSearchQuery}
            searchQueryFields={searchQueryFields}
            updateSearchQueryFields={updateSearchQueryFields}
          />
        </TopCont>
        <BottomCont>
          <Table
            columns={this.state.columns}
            fetchData={fetchData}
            nameSearchQuery={nameSearchQuery}
            locationSearchQuery={locationSearchQuery}
            searchQueryFields={searchQueryFields}
          />
        </BottomCont>
      </PageCont>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    data: { items },
    errors,
    table: { page, perPage, totalResults },
    filters: { nameSearchQuery, locationSearchQuery, searchQueryFields },
  } = rootState.bp

  return {
    items,
    errors,
    page: +page,
    perPage: +perPage,
    totalResults,
    nameSearchQuery,
    locationSearchQuery,
    searchQueryFields,
  }
}

const mapDispatchToProps = {
  fetchData,
  urlChanged,
  updateNameSearchQuery,
  updateLocationSearchQuery,
  updateSearchQueryFields,
  setFilters,
  setPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnersPage)
