import { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * A wrapper component to simplify writing forms using the {@link Checkbox}, {@link Input},
 * {@link ScoreSlider}, {@link Select}, {@link SliderWithInput}, {@link Textarea}, and {@link Toggle}
 * components. It takes the following props:
 *
 * - `data` (required): An object that contains the data to populate the form. The `name` prop of
 *    child components are used as keys into this object to retrieve the current value of a form
 *    element.
 * - `errors` (required): An object that contains the form errors. Here the `name` prop of the child
 *    elements is used to access this as well. The value should be an array of strings.
 * - `namespace` (required): The prefix/namespace to use to produce the label text. Here the `name`
 *    the `name` prop is used as well. With a namespace of `my.namespace` and a `name` prop of
 *    `the_name` the form generates the `my.name.the_name` I18n key when it tries to produce the
 *    label name.
 * - `onChange` (required): The callback that is executed whenever one of the child form elements
 *   changes. It takes two arguments: The value of the `name` prop of the child element and the new
 *   value.
 *
 * @example
 * <Form data={{name: "value"}} errors={{name: ['the error']}} namespace="my.namespace" onChange={(name, value) => { ... }}>
 *   <Input name="name" />
 * </Form>
 *
 * @extends {React.Component}
 */
export class Form extends Component {
  static propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object,
    namespace: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.any,
  }

  /**
   * @private
   */
  render() {
    return (
      <form className="form-horizontal" data-namespace={this.props.namespace} onSubmit={(e) => this.onSubmit(e)}>
        {this.props.children}
      </form>
    )
  }

  /**
   * @private
   */
  onSubmit(event) {
    event.preventDefault()
  }

  /**
   * @private
   */
  getChildContext() {
    return {
      data: this.props.data,
      disabled: this.props.disabled,
      errors: this.props.errors || {},
      namespace: this.props.namespace,
      onChange: this.props.onChange,
    }
  }
}
Form.childContextTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  namespace: PropTypes.string,
  onChange: PropTypes.func,
}
