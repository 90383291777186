import { Box, Chip, IconButton, Tooltip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { CopyToClipboard } from '@common/components/copy_to_clipboard'
import OpenInPodato from '@common/components/open_in_podato'

import * as styles from '../../bp-page.scss'

export const clusterFormatter = (cell, rowData) => {
  const clusterId = rowData.id

  return (
    <Box>
      <span>
        <OpenInPodato clusterId={clusterId} />
      </span>
      <span>
        <CopyToClipboard copyText={clusterId} />
      </span>
    </Box>
  )
}

export const nameFormatter = (cell, rowData) => {
  const {
    name,
    id,
    address: { streetAndNumber, zipcode, city, countryName },
  } = rowData

  const searchItems = [name, streetAndNumber, zipcode, city, countryName].filter((searchItem) => Boolean(searchItem))
  const searchPhrase = encodeURIComponent(searchItems.join(' '))

  return (
    <Box className={styles.nameCont}>
      <span className={styles.name}>{cell}</span>
      <span className={styles.btnAnchor}>
        <Tooltip title={'Search on Google'} placement="top" aria-label={'search business partner on google'}>
          <IconButton
            className={styles.googleButton}
            component={'a'}
            size={'small'}
            href={`https://www.google.com/search?q=${searchPhrase}`}
            target={'_blank'}
            rel="noreferrer"
            data-testid={`google_search_${id}`}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.secondary',
              },
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </span>
    </Box>
  )
}

export const addressFormatter = ({ streetAndNumber, zipcode }) => {
  return (
    <div className={styles.address}>
      {streetAndNumber}, {zipcode}
    </div>
  )
}

export const searchNamesFormatter = (cell, row) => {
  cell = row.searchNames || row.suggestedSearchNames
  return <div className={styles.searchName}>{cell?.join(', ')}</div>
}

export const nativeSearchNamesFormatter = (cell) => {
  return <div className={styles.nativeSearchName}>{cell?.join(', ')}</div>
}

export const citySearchNamesFormatter = (cell) => {
  return <div>{cell?.join(', ')}</div>
}

export const headerFormatter = (formatAsSelected) => (column, colIndex, components) => {
  return (
    <div key={colIndex}>
      {formatAsSelected ? <Chip label={column.text} color="secondary" /> : column.text}
      {components.sortElement && <span style={{ marginLeft: '5px' }}>{components.sortElement}</span>}
    </div>
  )
}
