import { datadogRum } from '@datadog/browser-rum'

import packageJson from '../../../../package.json'

export const initDataDog = (env: string) => {
  datadogRum.init({
    applicationId: '035ab4d9-eef1-433a-93bf-879fb7f0764d',
    clientToken: 'pub27bcbcfcd11a03b95af6465c92baeb08',
    site: 'datadoghq.eu',
    service: 'ric-fe',
    env,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
