import { ToastContainer } from 'react-toastify'

import { Authenticator, AppLoader, RootPage, AppRibbon } from '@components'
import { useTheme } from '@common/theme'

import '@src/client/i18n'

import 'react-toastify/dist/ReactToastify.css'
import '@src/client/styles/bootstrap.scss'
import '@src/client/styles/app.scss'

const App = () => {
  const { themeMode } = useTheme()

  return (
    <>
      <AppLoader />
      <Authenticator>
        <RootPage />
      </Authenticator>
      <ToastContainer
        theme={themeMode === 'light' ? 'dark' : 'light'}
        newestOnTop
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        bodyStyle={{ color: themeMode === 'light' ? 'white' : 'black' }}
      />
      <AppRibbon />
    </>
  )
}

export default App
