import { cloneDeep, merge, pickBy, snakeCase, each } from 'lodash'

export const tableParams = ({ page, sort, filters, filtersProcessors = [], perPage = 50 }) => {
  let params = { ...pickBy(cloneDeep(filters)), page, perPage }
  each(filtersProcessors, (processor) => {
    params = merge(params, processor(filters))
  })
  if (sort && sort.field && sort.order) {
    const field = snakeCase(sort.field)
    const order = sort.order === 'desc' ? `-${field}` : field
    params = merge(params, { sort: order })
  }
  return params
}
