import { Stack } from '@mui/material'

export const PageCont = ({ children, ...rest }) => {
  return (
    <Stack direction={'column'} gap={0} sx={{ minHeight: '100%' }} {...rest}>
      {children}
    </Stack>
  )
}

export const TopCont = ({ children, ...rest }) => {
  return (
    <Stack
      direction={'column'}
      sx={{
        px: 1,
        pt: 1,
        pb: 1,
        position: 'sticky',
        // top: 'var(--header-h)',
        top: 0,
        zIndex: 2,
        backgroundColor: 'background.default',
      }}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export const BottomCont = ({ children, ...rest }) => {
  return (
    <Stack sx={{ flex: 1, overflow: 'visible' }} {...rest}>
      {children}
    </Stack>
  )
}

export const FiltersCont = ({ children, ...rest }) => {
  return (
    <Stack rowGap={1} columnGap={'12px'} direction={'row'} flexWrap={'wrap'} alignItems={'center'} {...rest}>
      {children}
    </Stack>
  )
}
