// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.app-bar--eXNBJ{height:var(--header-h);min-height:auto}.tabs--RMIj0{flex:1;height:100%}.tabs--RMIj0 .MuiTabs-flexContainer{height:100%}.settings-item--fd9UJ:hover{color:inherit}`, "",{"version":3,"sources":["webpack://./src/client/components/app/navbar/navbar.scss"],"names":[],"mappings":"AAAA,mBACE,sBAAA,CACA,eAAA,CAGF,aACE,MAAA,CAEA,WAAA,CACA,oCACE,WAAA,CAKF,4BACE,aAAA","sourceRoot":""}]);
// Exports
var _1 = `app-bar--eXNBJ`;
export { _1 as "app-bar" };
export var appBar = `app-bar--eXNBJ`;
export var tabs = `tabs--RMIj0`;
var _2 = `settings-item--fd9UJ`;
export { _2 as "settings-item" };
export var settingsItem = `settings-item--fd9UJ`;
export default ___CSS_LOADER_EXPORT___;
