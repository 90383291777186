import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import I18n from 'i18next'

import { approveBundle, deleteBundle } from '../actions'

import {
  nameFormatter,
  alertTargetNameFormatter,
  newsTargetNameFormatter,
  geometryFormatter,
  actionsFormatter,
  editFormatter,
} from './formatters'

import { useGetIndustries } from '@api'
import { confirm } from '@common/components/alert'
import { bundle } from '@common/utils/shapes'
import dateComparator from '@common/utils/date_comparator'
import { setPage, setSort } from '@common/actions/table'
import CommonTable from '@common/components/table'
import { handleEmptyFormatter } from '@common/table_formatters/handle_empty_formatter'
import { readMoreFormatter } from '@common/table_formatters/read_more_formatter'
import { industriesFormatter } from '@common/table_formatters/industriesFormatter'

const buildColumns = (props) => [
  {
    dataField: 'id',
    text: I18n.t('common.messages_table.columns.id'),
  },
  {
    dataField: 'subject',
    text: I18n.t('common.messages_table.columns.subject'),
  },
  {
    dataField: 'sourceContent',
    text: I18n.t('common.messages_table.columns.source'),
  },
  {
    dataField: 'body',
    text: I18n.t('common.messages_table.columns.body'),
    formatter: readMoreFormatter('hyperlinks[0]'),
  },
  {
    dataField: 'name',
    text: I18n.t('common.messages_table.columns.name'),
    formatter: nameFormatter,
  },
  {
    dataField: 'alertTarget',
    text: I18n.t('common.messages_table.columns.alert_target'),
    formatter: alertTargetNameFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'newsTarget',
    text: I18n.t('common.messages_table.columns.news_target'),
    formatter: newsTargetNameFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },

  {
    dataField: 'targets.geometry',
    text: I18n.t('common.messages_table.columns.area'),
    formatter: geometryFormatter,
  },
  {
    dataField: 'industries',
    text: I18n.t('common.messages_table.columns.industries'),
    formatter: industriesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
      industryMap: props.industryMap,
    },
  },
  {
    dataField: 'createdAt',
    text: I18n.t('common.messages_table.columns.created_on'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'createdBy',
    text: I18n.t('common.messages_table.columns.created_by'),
  },
  {
    dataField: 'validUntil',
    text: I18n.t('common.messages_table.columns.valid_until'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'edit',
    text: I18n.t('common.messages_table.columns.edit'),
    formatter: editFormatter(props.history),
  },
  {
    dataField: 'actions',
    text: I18n.t('common.messages_table.columns.action'),
    formatter: actionsFormatter,
    formatExtraData: {
      onApprove: props.onApprove,
      onDelete: props.onDelete,
    },
  },
]

const Table = (props) => {
  const [state, setState] = useState({ expanded: {} })

  const history = useHistory()
  const { industryMap } = useGetIndustries()

  useEffect(() => {
    setState({
      ...state,
      expanded: {},
    })
  }, [props.page])

  const handleRowExpanded = (id) => {
    setState({
      expanded: {
        ...state.expanded,
        [id]: !state.expanded[id],
      },
    })
  }

  const pageChanged = (page) => {
    props.setPage('REVIEW', page)
    props.fetchData()
  }

  const sortChanged = (sortField, sortOrder) => {
    props.setSort('REVIEW', sortField, sortOrder)
    props.fetchData()
  }

  const getBundleType = (messages) => {
    if (messages.length === 1) {
      return messages[0].isNews ? 'news' : 'alert'
    } else {
      return 'alert & news'
    }
  }

  const onApproveClick = (bundle) => {
    confirm({
      title: I18n.t('reviews.alerts.approve.title'),
      body: I18n.t('reviews.alerts.approve.body', { messages: getBundleType(bundle.messages) }),
      isFullWidth: false,
    }).then(
      () => {
        props.approveBundle(bundle)
      },
      () => null
    )
  }

  const onDeleteClick = (bundle) => {
    confirm({
      title: I18n.t('reviews.alerts.delete.title'),
      body: I18n.t('reviews.alerts.delete.body', { messages: getBundleType(bundle.messages) }),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(
      () => {
        props.deleteBundle(bundle)
      },
      () => null
    )
  }

  const { loading, bundles, totalResults, page, errors } = props

  const columns = buildColumns({
    onApprove: onApproveClick,
    onDelete: onDeleteClick,
    handleRowExpanded: handleRowExpanded,
    expanded: state.expanded,
    history,
    industryMap,
  })
  return (
    <CommonTable
      data={bundles}
      loading={loading}
      columns={columns}
      pageChanged={pageChanged}
      sortChanged={sortChanged}
      emptyLabel={I18n.t('history.table.messages.empty')}
      perPage={50}
      totalSize={totalResults}
      page={page}
      errors={errors}
    />
  )
}

const mapStateToProps = (rootState) => {
  const {
    table: {
      page,
      loading,
      totalResults,
      errors,
      data: { bundles },
    },
  } = rootState.reviews

  return {
    errors,
    page,
    totalResults,
    bundles,
    loading,
  }
}

const mapDispatchToProps = { approveBundle, deleteBundle, setSort, setPage }

Table.propTypes = {
  totalResults: PropTypes.number,
  page: PropTypes.number.isRequired,
  bundles: PropTypes.arrayOf(bundle),
  errors: PropTypes.arrayOf(PropTypes.string),
  setPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  approveBundle: PropTypes.func.isRequired,
  deleteBundle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
