import { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { CustomControl } from '@riskmethods/world-map'
import Map from '@common/components/map'

import {
  hideControlUnits,
  fittedBounds,
  mapUpdated,
  init,
  boundsChanged,
  zoomChanged,
  requestData,
  updateMap,
} from './actions'

import { fetchObjectInfo, closeInfoWindow } from './actions/info'
import { selectSearch } from './actions/search'
import * as constants from './components/constants'
import LoadingBar from './components/loading-bar/index'
import LayerControl from './components/layer-control/index'
import { SearchBox } from './components/search-box'
import { LoadButton } from './components/load-button'
import LimitLoadingMessageModal from './components/limit_loading_message_modal/index'

import * as styles from './world-map-page.scss'

export const WorldMapPage = (props) => {
  const [showLimitLoadingMessage, setShowLimitLoadingMessage] = useState(false)

  useEffect(() => {
    const { layerFilter, bounds } = props
    props.init(layerFilter, bounds)
  }, [])

  const handleLimitLoadingMessageModalToggle = (show = false) => {
    setShowLimitLoadingMessage(show)
  }

  const reload = useCallback(() => {
    if (props.zoom > constants.LIMIT_LOADING_ZOOM) {
      const { layerFilter, layers, bounds } = props
      props.requestData(layerFilter, layers, bounds)
    } else {
      handleLimitLoadingMessageModalToggle(true)
    }
  }, [props])

  const handleSearchSelect = (element) => {
    props.onSearchSelect(element)
  }

  return (
    <div className={styles.mapContainer} onClick={props.onClick}>
      <LoadingBar loading={props.loading} />
      <Map
        features={props.features}
        zoom={props.zoom}
        updateMap={props.updateMap}
        loading={props.loading.isLoading}
        fitBounds={props.fitBounds}
        onMarkerClick={props.onMarkerClick}
        onInfoWindowClose={props.onInfoWindowClose}
        onFittedBounds={props.onFittedBounds}
        onUpdateData={props.onUpdateData}
        onBoundsChanged={props.onBoundsChanged}
        onZoomChanged={props.onZoomChanged}
        enableTracking={false}
        search={props.search}
        clustering="ALL"
        enableSpiderfier={false}
        enableClusteringColors={false}
        popup={props.popup}
        triggerMapUpdate={props.triggerMapUpdate}
      >
        <CustomControl position="TOP_RIGHT">
          <LayerControl
            isLoading={props.loading.isLoading}
            layers={props.layerFilter}
            layersControlUnit={props.layersControlUnit}
          />
        </CustomControl>
        <CustomControl position="TOP_RIGHT">
          <LoadButton reload={reload} />
        </CustomControl>
        <CustomControl position="TOP_RIGHT">
          <SearchBox search={props.search} onCommit={handleSearchSelect} />
        </CustomControl>
      </Map>
      <LimitLoadingMessageModal
        isOpen={showLimitLoadingMessage}
        onClose={() => handleLimitLoadingMessageModalToggle(false)}
      />
    </div>
  )
}

WorldMapPage.propTypes = {
  onClick: PropTypes.func,
  triggerMapUpdate: PropTypes.func,
  features: PropTypes.array,
  zoom: PropTypes.number,
  updateMap: PropTypes.bool,
  loading: PropTypes.object,
  onMarkerClick: PropTypes.func,
  onInfoWindowClose: PropTypes.func,
  onFittedBounds: PropTypes.func,
  onUpdateData: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  onZoomChanged: PropTypes.func,
  fitBounds: PropTypes.bool,
  init: PropTypes.func,
  search: PropTypes.object,
  onSearchSelect: PropTypes.func,
  requestData: PropTypes.func,
  layerFilter: PropTypes.object,
  layers: PropTypes.object,
  bounds: PropTypes.object,
  layersControlUnit: PropTypes.bool,
  popup: PropTypes.object,
  udmBaseUrl: PropTypes.string,
}

const mapStateToProps = (rootState) => {
  const state = rootState.worldMap

  return {
    features: state.features,
    zoom: state.zoom,
    updateMap: state.updateMap,
    loading: state.loading,
    fitBounds: state.fitBounds,
    search: state.search,
    layerFilter: state.filter.layers,
    layers: state.layers,
    bounds: state.bounds,
    layersControlUnit: state.visibility.layersControlUnit,
    popup: state.popup,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClick() {
    dispatch(hideControlUnits())
  },
  triggerMapUpdate() {
    dispatch(updateMap())
    dispatch(fittedBounds())
  },
  onMarkerClick(event, mapObject, feature) {
    if (mapObject.tooltip) {
      mapObject.tooltip.close()
    }
    if (!feature || feature.id !== mapObject.feature.id) {
      dispatch(fetchObjectInfo(event, mapObject))
    }
  },
  onInfoWindowClose() {
    dispatch(closeInfoWindow())
  },
  onFittedBounds() {
    dispatch(fittedBounds())
  },
  onUpdateData() {
    dispatch(mapUpdated())
  },
  onSearchSelect(element) {
    dispatch(selectSearch(element))
  },
  init(layerFilter, bounds) {
    dispatch(init(layerFilter, bounds))
  },
  onBoundsChanged(bounds) {
    dispatch(boundsChanged(bounds))
  },
  onZoomChanged(zoom) {
    dispatch(zoomChanged(zoom))
  },
  requestData(layerFilter, layers, bounds) {
    dispatch(requestData(layerFilter, layers, bounds))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(WorldMapPage)
