import { useLayoutEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Form } from '@riskmethods/rm-front-end'

import { IM_FORM_DEFAULT_VALUES } from '@components/form/im-form-fields'
import { useSavedHitsContext, useIMContext } from '@hooks'
import type { InitPageData, SavedHitsFormValues } from '@types'

import SavedHitsForm from './components/saved-hits-form/saved-hits-form'
import SavedHitsTable from './components/saved-hits-table/saved-hits-table'
import ArticleAnalyzer from './components/article-analyzer/article-analyzer'

import { clearFetchedData } from './helpers'

type Props = {
  initialData: InitPageData
}

const SavedHitsPage = ({ initialData }: Props) => {
  const queryClient = useQueryClient()

  const { imFormValues, setIMFormValues } = useIMContext()

  const { view, setSelectedHitIds, getSavedHitsQuery, getSavedByUsersQuery, useSearchParamsContext } =
    useSavedHitsContext()

  const { searchParams, syncSearchParams } = useSearchParamsContext

  useLayoutEffect(() => {
    return clearFetchedData(queryClient)
  }, [])

  useLayoutEffect(() => {
    if (!imFormValues) {
      getSavedByUsersQuery.refetch()
      getSavedHitsQuery.refetch()
    }
  }, [imFormValues])

  useLayoutEffect(() => {
    if (!searchParams.object.article_id) {
      setIMFormValues(null)
    }
  }, [searchParams.object.article_id])

  const handleSavedHitsFormSubmit = async (data: object) => {
    syncSearchParams({ ...data, article_id: '' } as SavedHitsFormValues)
    setSelectedHitIds([])
    setTimeout(async () => {
      await Promise.all([getSavedHitsQuery.refetch(), getSavedByUsersQuery.refetch()])
      window.scrollTo(0, 0)
    })
  }

  return (
    <>
      {view === 'SAVED_HITS' ? (
        <Form config={{ defaultValues: searchParams.object }} onSubmit={handleSavedHitsFormSubmit}>
          {(savedHitsFormContext) => {
            return (
              <>
                <SavedHitsForm />
                <SavedHitsTable initialData={initialData} savedHitsFormContext={savedHitsFormContext} />
              </>
            )
          }}
        </Form>
      ) : null}
      {view === 'ARTICLE_ANALYZER' ? (
        imFormValues ? (
          <ArticleAnalyzer defaultValues={imFormValues} />
        ) : (
          <ArticleAnalyzer defaultValues={IM_FORM_DEFAULT_VALUES} />
        )
      ) : null}
    </>
  )
}

export default SavedHitsPage
