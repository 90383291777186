import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UseFormReturn } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Divider, Stack, Typography, Grid } from '@mui/material'
import { NavigateNext as NavigateNextIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material'

import {
  ContentTextArea,
  IndustriesMultiSelect,
  NoValidityToggle,
  OnlyNewsToggle,
  SourceForContentInput,
  SourceForCopyrightInput,
  SourceUrlInput,
  SubjectInput,
  TypeSelect,
  ValidUntilDatePicker,
  IMFormFields,
} from '@components/form/im-form-fields'

import { showRemoveHitsWarnModal } from '@pages/saved-hits/helpers'
import { IM_FORM_DEFAULT_VALUES } from '@components/form/im-form-fields'
import { useSavedHitsContext, useIMContext } from '@hooks'
import type { SavedHitsData } from '@types'

import { buildPreloadedState } from '@pages/im/utils/build_preloaded_state'
import { setImInitState } from '@pages/im/actions'

type Props = {
  formContext: UseFormReturn
}

const ArticleForm = ({ formContext }: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const dispatch = useDispatch()

  const { watch, setValue, getValues, reset } = formContext

  const onlyNews = watch('onlyNews')
  const type = watch('type')
  const noValidity = watch('noValidity')

  const {
    selectedHitIds,
    setSelectedHitIds,
    activeArticleId,
    setActiveArticleId,
    getSavedHitsQuery,
    unsaveHitsQuery,
    getActiveArticleHit,
    navigateToArticle,
  } = useSavedHitsContext()

  const { setIMFormValues } = useIMContext()

  const handleFormAutoFill = () => {
    const hit = getActiveArticleHit()

    if (hit) {
      const currentValues = getValues()
      const newValues = {
        subject: currentValues.subject || hit.headline || '',
        sourceForContent: currentValues.sourceForContent || hit.siteName || '',
        sourceForCopyright: currentValues.sourceForCopyright || hit.copyright || '',
        sourceUrl: currentValues.sourceUrl || hit.link || '',
        content: currentValues.content,
      }
      Object.keys(newValues).forEach((key) => {
        setValue(key, newValues[key], { shouldValidate: ![undefined, null, ''].includes(newValues[key]) })
      })
    }
  }

  const handleIgnoreArticle = () => {
    showRemoveHitsWarnModal().then(
      () => {
        setIMFormValues(null)
        reset(IM_FORM_DEFAULT_VALUES)
        const state = buildPreloadedState({})
        dispatch(setImInitState(state))

        const ids = [String(activeArticleId)]
        const index = getSavedHitsQuery.data?.hits.findIndex((hit) => hit.id === activeArticleId)

        unsaveHitsQuery.mutateAsync(ids).then((unsavedHits) => {
          const unsavedHitIds = unsavedHits.map((hit) => hit.id)

          queryClient.setQueryData(['GET_SAVED_HITS'], (savedHitsData: SavedHitsData) => {
            return {
              ...savedHitsData,
              hits: savedHitsData.hits.filter((hit) => !unsavedHitIds.includes(hit.id)),
            }
          })

          setSelectedHitIds(selectedHitIds.filter((id) => !unsavedHitIds.includes(id)))

          if (index !== undefined && getSavedHitsQuery.data) {
            if (getSavedHitsQuery.data.hits.length === 1) {
              setActiveArticleId(null)
              history.replace('/saved_hits')
            } else if (getSavedHitsQuery.data.hits.length - 1 === index) {
              const hitId = getSavedHitsQuery.data.hits[index - 1].id
              const savedById = getSavedHitsQuery.data.hits[index - 1].selectedById
              setActiveArticleId(hitId)
              navigateToArticle(hitId, savedById || '', true)
            } else {
              const hitId = getSavedHitsQuery.data.hits[index + 1].id
              const savedById = getSavedHitsQuery.data.hits[index + 1].selectedById
              setActiveArticleId(hitId)
              navigateToArticle(hitId, savedById || '', true)
            }
          }
        })
      },
      () => null
    )
  }

  const onBackClick = () => {
    history.push('/saved_hits')
  }

  return (
    <IMFormFields formContext={formContext}>
      <Box position="relative" height="100%">
        <Typography variant="subtitle2">Create Alert/News with this article</Typography>
        <Divider sx={{ mt: 0.5 }} />
        <Stack direction="row" justifyContent="space-between" mt={3} mb={4}>
          <div>
            <Button onClick={handleFormAutoFill}>Fill automatically</Button>
          </div>
          <div>
            <Button color="secondary" onClick={onBackClick} startIcon={<ArrowBackIcon />}>
              Back to Saved Hits
            </Button>
          </div>
        </Stack>
        <Grid container direction="row" spacing={2.5} sx={{ height: 'calc(100vh - 290px)', overflowY: 'auto', pr: 1 }}>
          <Grid item xs={4}>
            <Stack rowGap={2.75}>
              <TypeSelect onlyNews={onlyNews} />
              <IndustriesMultiSelect />
              <SourceForContentInput />
              <SourceForCopyrightInput />
              <SourceUrlInput />
              <ValidUntilDatePicker noValidity={noValidity} />
              <NoValidityToggle onlyNews={onlyNews} type={type} />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack rowGap={2.75}>
              <SubjectInput />
              <ContentTextArea />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" mt={4} position="absolute" bottom="0" left="0" right="0">
          <Box flex={1}>
            <Button color="secondary" onClick={handleIgnoreArticle}>
              Ignore article
            </Button>
          </Box>
          <Stack direction="row" gap={1}>
            <OnlyNewsToggle type={type} />
            <Button type="submit" color="secondary" endIcon={<NavigateNextIcon />}>
              Next step
            </Button>
          </Stack>
        </Stack>
      </Box>
    </IMFormFields>
  )
}

export default ArticleForm
