import { pick, isNumber } from 'lodash'

const transformPolygonLocations = (selectedLocations, polygonPoints, requiredFields) => {
  if (!polygonPoints) {
    return {}
  }

  return {
    polygons: selectedLocations.map((location) => ({
      ...pick(location, requiredFields),
      coordinates: polygonPoints,
    })),
  }
}

const transformRadiusLocations = (selectedLocations, radius, requiredFields) =>
  isRadiusValid(radius)
    ? { areas: selectedLocations.map((location) => ({ ...pick(location, requiredFields), radius })) }
    : {}

const isRadiusValid = (radius) => {
  return isNumber(radius) && radius >= 0
}

export const transformLocations = (state, requiredFields) => {
  const {
    location: {
      data: { selectedLocations, radius, polygonPoints },
    },
  } = state

  return {
    ...transformPolygonLocations(selectedLocations, polygonPoints, requiredFields.polygons),
    ...transformRadiusLocations(selectedLocations, parseFloat(radius), requiredFields.areas),
  }
}
