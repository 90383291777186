/* eslint-disable camelcase */
// import mockedBuckets from "./mockedBuckets.json";
import moment from 'moment'
import { getJSON, putJSON } from '@common/http'

export const BUCKETS_RECEIVED = 'BUCKETS_RECEIVED'
export const TIME_FILTERED_BUCKETS_RECEIVED = 'TIME_FILTERED_BUCKETS_RECEIVED'
export const ASSIGNMENT_RECEIVED = 'ASSIGNMENT_RECEIVED'
export const USERS_RECEIVED = 'USERS_RECEIVED'
export const REASSIGN_HITS = 'REASSIGN_HITS'
export const ERROR_RECEIVED = 'SHIFT_MANAGEMENT_ERROR_RECEIVED'
export const LOAD_DATA = 'SHIFT_MANAGEMENT_LOAD_DATA'
export const LOAD_DATA_FINISHED = 'LOAD_DATA_FINISHED'
export const ADD_ASSIGNEE = 'ADD_ASSIGNEE'
export const REMOVE_ASSIGNEE = 'REMOVE_ASSIGNEE'
export const CHANGE_ASSIGNEE = 'CHANGE_ASSIGNEE'
export const RESET_ASSIGNEES = 'RESET_ASSIGNEES'
export const START_EDITING = 'START_EDITING'
export const STOP_EDITING = 'STOP_EDITING'
export const OPEN_MODAL = 'OPEN_REASSIGN_MODAL'
export const CLOSE_MODAL = 'CLOSE_REASSIGN_MODAL'
export const SET_HITS_UNTIL_TIME = 'SET_HITS_UNTIL_TIME'

export const loadData = () => ({ type: LOAD_DATA })

export const bucketsReceived = ({ buckets, totalUniqueHitsCount, totalHitsCount }) => ({
  type: BUCKETS_RECEIVED,
  data: {
    buckets,
    totalUniqueHitsCount,
    totalHitsCount,
  },
})

export const timeFilteredBucketsReceived = ({ buckets }) => ({
  type: TIME_FILTERED_BUCKETS_RECEIVED,
  data: {
    buckets,
  },
})

export const errorReceived = (json) => ({
  type: ERROR_RECEIVED,
  data: json,
})

export const openModal = ({ id }) => ({
  type: OPEN_MODAL,
  data: { id },
})

export const closeModal = () => ({
  type: CLOSE_MODAL,
})

export const addAssignee = () => ({
  type: ADD_ASSIGNEE,
})

export const resetAssignees = () => ({
  type: RESET_ASSIGNEES,
})

export const removeAssignee = ({ id }) => ({
  type: REMOVE_ASSIGNEE,
  data: {
    id,
  },
})

export const setHitsUntilTime = ({ time }) => ({
  type: SET_HITS_UNTIL_TIME,
  data: {
    time,
  },
})

export const changeAssignee = ({ id, previousId }) => ({
  type: CHANGE_ASSIGNEE,
  data: {
    id,
    previousId,
  },
})

export const currentAssignmentReceived = ({ assignees, buckets }) => ({
  type: ASSIGNMENT_RECEIVED,
  data: {
    assignees,
    buckets,
  },
})

export const startEditing = () => ({
  type: START_EDITING,
})

export const stopEditing = () => ({
  type: STOP_EDITING,
})

export const usersReceived = ({ users }) => ({
  type: USERS_RECEIVED,
  data: {
    users,
  },
})

export const reassignHits = ({ fromUserId, toUserIds, percentage }) => ({
  type: REASSIGN_HITS,
  data: {
    fromUserId,
    toUserIds,
    percentage,
  },
})

const handleAPIErrors = (err, dispatch) => {
  if (err.data) {
    const {
      data: { errors },
    } = err

    dispatch(errorReceived({ errors }))
  } else {
    throw err
  }
}

export const saveAssignment = () => {
  return (dispatch, getState) => {
    const { assignees } = getState().shiftManagement
    const allRanges = []

    assignees.forEach(({ id, ranges }) => {
      return ranges.forEach((range) => {
        allRanges.push({
          user_id: id,
          hits_from: range.start,
          hits_till: range.finish,
          total_hits_count_was: range.totalHitsCount,
          unique_hits_count_was: range.uniqueHitsCount,
          percent_of_hits_reassigned: range.percentOfHitsReassigned,
        })
      })
    })

    return putJSON('/api/v2/buckets/assignment', {
      data: {
        type: 'shift_assignments',
        attributes: {
          shift_assignments: allRanges,
        },
      },
    })
      .then(() => {
        dispatch(stopEditing())
      })
      .catch((err) => handleAPIErrors(err, dispatch))
  }
}

export const fetchCurrentAssignment = () => {
  return getJSON('/api/v2/buckets/assignment').then(({ data }) => {
    const assignments_data = data.data
    const userAssignments = {}

    assignments_data.forEach(({ attributes }) => {
      const { hitsFrom, hitsTill, userId } = attributes
      const range = { start: hitsFrom, finish: hitsTill }

      userAssignments[userId] ||= []
      userAssignments[userId].push(range)
    })

    return Object.entries(userAssignments).map((userAssignment) => {
      const [id, ranges] = userAssignment

      return { id, ranges }
    })
  })
}

function fetchBuckets({ hitsUntil } = {}) {
  return getJSON('/api/v2/buckets', { hitsUntil })
    .then(({ data }) => {
      return data
    })
    .then(({ data: buckets, meta }) => {
      const mappedBuckets = buckets.map((bucket) => {
        const { uniqCount, count, uniqOpenCount, openCount, firstLetters } = bucket.attributes

        return {
          uniqueCount: uniqCount,
          uniqueOpenCount: uniqOpenCount,
          openCount,
          count,
          firstLetters,
        }
      })

      return {
        buckets: mappedBuckets,
        totalUniqueHitsCount: meta[0].uniqCount,
        totalHitsCount: meta[0].totalCount,
      }
    })
}

export function getOpenHitsBuckets({ hitsUntilTime }) {
  return async (dispatch) => {
    const [hours, minutes] = (hitsUntilTime || moment().format('HH:mm')).split(':')
    const hitsUntil = moment().hour(hours).minutes(minutes).toISOString(true)
    const { buckets } = await fetchBuckets({ hitsUntil })
    dispatch(timeFilteredBucketsReceived({ buckets }))
  }
}

const fetchUsers = () => {
  return getJSON('/api/v2/users').then(({ data: { data: rawUsers } }) => {
    return rawUsers.map(({ attributes: { fullName }, id }) => {
      return {
        id,
        fullName,
      }
    })
  })
}

export const fetchData = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_DATA })
    return Promise.all([fetchCurrentAssignment(), fetchUsers(), fetchBuckets()])
      .then(([assignees, users, { buckets, totalHitsCount, totalUniqueHitsCount }]) => {
        dispatch({ type: LOAD_DATA_FINISHED })
        dispatch(usersReceived({ users }))
        dispatch(currentAssignmentReceived({ assignees, buckets }))
        dispatch(
          bucketsReceived({
            buckets,
            totalUniqueHitsCount,
            totalHitsCount,
          })
        )
      })
      .catch((err) => handleAPIErrors(err, dispatch))
  }
}

export const fetchOverviewData = () => null
