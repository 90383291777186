import { useMemo } from 'react'
import { TextField, Autocomplete } from '@mui/material'

const IndicatorSelect = ({ onChange, indicators, selectedIndicatorId }) => {
  const uniqueIndicators = useMemo(() => {
    return indicators.reduce((acc, indicator) => {
      if (!acc.find((i) => i.id === indicator.id)) {
        acc.push(indicator)
      }

      return acc
    }, [])
  }, [indicators])

  const selectedIndicator = useMemo(() => {
    return uniqueIndicators.find((indicator) => indicator.id === selectedIndicatorId)
  }, [uniqueIndicators, selectedIndicatorId])

  return (
    <Autocomplete
      value={selectedIndicator || null}
      onChange={(ev, newValue) => onChange(newValue)}
      sx={{ width: 200 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={({ name }) => name}
      options={uniqueIndicators}
      data-testid="indicator_select"
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Indicator'}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default IndicatorSelect
