import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch, displayError } from '@services'
import type { SavedByUsersData, GetSavedByUsersSchema } from '@types'

export const GET_SAVED_BY_USERS_DEFAULT_DATA = {
  users: [],
} as SavedByUsersData

const transformData = (data: GetSavedByUsersSchema | null): SavedByUsersData => {
  if (!data) return GET_SAVED_BY_USERS_DEFAULT_DATA

  const transformedData = camelizeKeys<GetSavedByUsersSchema>(data)
  if (!transformedData) return GET_SAVED_BY_USERS_DEFAULT_DATA

  return transformedData
}

const queryFn = async (): Promise<SavedByUsersData> => {
  let data: GetSavedByUsersSchema | null = null

  const res = await fetch('/api/v2/selected_users')

  if (res.ok) {
    data = await res.json()
  } else {
    displayError('Could not fetch users this time.')
  }

  return transformData(data)
}

const useGetSavedByUsers = () => {
  return useQuery<SavedByUsersData>({
    queryKey: ['GET_SAVED_BY_USERS'],
    queryFn,
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetSavedByUsers
