import { combineReducers } from 'redux'

import country from './country'
import supplier from './supplier'
import location from './location'
import save from './save'
import update from './update'

import initialState from './initial_state'

const reducer = combineReducers({
  country,
  supplier,
  location,
  save,
  update,
  currentUser: (state = {}) => state,
  formMode: (state = initialState.formMode) => state,
  udmBaseUrl: (state = '') => state,
  duplicateDetection: (state = initialState.duplicateDetection) => state, // hide redux errors
})

export default reducer
