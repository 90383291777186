import type { HitData, HitQuality } from '@types'

import { allGreat, allIrrelevant } from '../check-hits/check-hits'

const getNewHitQuality = (hits: HitData[], quality: HitQuality): HitQuality => {
  let newQuality: HitQuality

  if (allGreat(hits)) {
    newQuality = quality === 'great' ? null : quality
  } else if (allIrrelevant(hits)) {
    newQuality = quality === 'irrelevant' ? null : quality
  } else {
    newQuality = quality
  }

  return newQuality
}

export default getNewHitQuality
