import { useLayoutEffect, useState } from 'react'
import { Checkbox } from '@mui/material'

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'

type Props = {
  dataTestId?: string
  type: 'tick' | 'dash'
  checked: boolean
  forceShowAsUnchecked?: boolean
  disabled?: boolean
  setDisabled?: (disabled: boolean) => void
  insideRow?: boolean
  handleClick: () => void
  handleRequest?: (e) => Promise<unknown>
}

const HitCheckBox = ({
  dataTestId,
  type,
  checked,
  forceShowAsUnchecked = false,
  disabled = false,
  setDisabled,
  insideRow,
  handleClick,
  handleRequest,
}: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  useLayoutEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const onChange = (e) => {
    if (disabled) {
      return e.preventDefault()
    }
    !forceShowAsUnchecked && setIsChecked(!isChecked)
    setDisabled?.(true)
    setTimeout(async () => {
      handleClick()
      await handleRequest?.(e).catch(onError)
      setDisabled?.(false)
    })
  }

  const onError = () => {
    !forceShowAsUnchecked && setIsChecked(isChecked)
  }

  return (
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      icon={<CheckBoxOutlineBlankOutlinedIcon />}
      checkedIcon={type === 'tick' ? <CheckBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
      inputProps={
        {
          'data-testid': dataTestId,
        } as object
      }
      disableRipple
      sx={{ '& .MuiSvgIcon-root': { fontSize: 40 }, top: insideRow ? '-10px' : '0px' }}
    />
  )
}

export default HitCheckBox
