export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts[1].split(';').shift()
  }

  return undefined
}

export const setPermanentCookie = (name, value) => {
  document.cookie = `${name}=${value};path=/;max-age=31536000`
}
