import { REQUEST_UPDATE, SUCCESSFULLY_UPDATED, ERRORS_UPDATING } from '../actions/update'
import initialState from './initial_state'

export default function save(state = initialState.update, { type }) {
  switch (type) {
    case REQUEST_UPDATE:
      return {
        ...state,
        inProgress: true,
      }
    case SUCCESSFULLY_UPDATED:
      return {
        ...state,
        inProgress: false,
        success: true,
        hasErrors: false,
      }
    case ERRORS_UPDATING:
      return {
        ...state,
        inProgress: false,
        success: false,
        hasErrors: true,
      }
    default:
      return state
  }
}
