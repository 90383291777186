import { Typography } from '@mui/material'
import I18n from 'i18next'

import Link from '@common/components/link'
import type { HitData } from '@types'

import HighlightedText from '../hit-highlighted-text/hit-highlighted-text'

type Props = {
  hitData: HitData
  isNativeLanguage: boolean
  isInsolvency: boolean
  noTranslation: boolean
  showHitLink: boolean
}

const HitTitle = ({ hitData, isNativeLanguage, isInsolvency, noTranslation, showHitLink }: Props) => {
  const { subject, link, keywords, translatedSubject, translatedKeywords } = hitData

  const renderLink = (title) => (
    <Link href={link} target="_blank" rel="noreferrer">
      {title}
    </Link>
  )

  const renderTitle = (title, color) => (
    <Typography component="span" color={color}>
      {title}
    </Typography>
  )

  const OriginalLink = () => {
    const title = <HighlightedText text={subject} keywords={keywords} />

    if (showHitLink) {
      return renderLink(title)
    }

    return renderTitle(title, '#4fc3f7')
  }

  const TranslatedLink = () => {
    if (translatedSubject) {
      const highlightedText = <HighlightedText text={translatedSubject} keywords={translatedKeywords} />
      if (showHitLink) {
        return renderLink(highlightedText)
      } else {
        return renderTitle(highlightedText, '#4fc3f7')
      }
    }

    return renderTitle(I18n.t('inbox.table.translation.no_translation'), 'text.disabled')
  }

  if ((isNativeLanguage && isInsolvency) || (isNativeLanguage && !noTranslation) || noTranslation) {
    return <OriginalLink />
  }

  return <TranslatedLink />
}

export default HitTitle
