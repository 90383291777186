import { Component } from 'react'
import PropTypes from 'prop-types'

export class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true })
    // debugger;
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h2>Something went wrong.</h2>
    }
    return this.props.children
  }
}
