import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import AlertMessage from '@common/components/alert_message'
import { FIRST_PAGE, setFilters, setPage } from '@common/actions/table'
import { indicator } from '@common/utils/shapes'
import { PageCont, TopCont, BottomCont } from '@common/layout'

import { fetchIndicators, fetchData } from './actions'
import Filters from './components/filters'
import Table from './components/table'

class ReviewPage extends Component {
  static propTypes = {
    fetchIndicators: PropTypes.func.isRequired,
    bundles: PropTypes.array,
    indicators: PropTypes.arrayOf(indicator),
    fetchData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (!this.props.bundles?.length) {
      this.props.fetchData()
    }
    if (!this.props.indicators?.length) {
      this.props.fetchIndicators()
    }
  }

  fetchFilteredData = () => {
    this.props.setPage('REVIEW', FIRST_PAGE)
    this.props.fetchData()
  }

  render() {
    const { setFilters, errors, indicators, fetchData, filters } = this.props
    return (
      <PageCont>
        <TopCont>
          {map(errors, (error) => (
            <AlertMessage key={error} message={error} />
          ))}
          <Filters
            indicators={indicators}
            changeFilters={setFilters}
            fetchData={this.fetchFilteredData}
            filters={filters}
          />
        </TopCont>
        <BottomCont>
          <Table fetchData={fetchData} />
        </BottomCont>
      </PageCont>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    table,
    data: { indicators },
    filters,
    errors,
  } = rootState.reviews
  return {
    bundles: table.data.bundles,
    indicators,
    filters,
    errors,
  }
}

const mapDispatchToProps = { fetchIndicators, fetchData, setFilters, setPage }

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage)
