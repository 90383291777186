import { UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { TableCell, TableRow, IconButton, CircularProgress } from '@mui/material'
import { ContentPasteSearch as ArticleIcon } from '@mui/icons-material'
import moment from 'moment'

import { buildPreloadedState } from '@pages/im/utils/build_preloaded_state'
import { setImInitState } from '@pages/im/actions'

import { useTheme } from '@common/theme'
import { DATE_TIME_FORMAT } from '@common/utils/const'
import { HitCheckBox, HitContent } from '@components'
import { useSavedHitsContext } from '@hooks'
import type { HitData } from '@types'

type Props = {
  index: number
  hitData: HitData
  userSettings: object
  noTranslationLanguages: string[]
  isSelected: boolean
  savedHitsFormContext: UseFormReturn
}

const SavedHitsTableRow = ({
  index,
  hitData,
  userSettings,
  noTranslationLanguages,
  isSelected,
  savedHitsFormContext,
}: Props) => {
  const dispatch = useDispatch()
  const { themeMode, theme } = useTheme()

  const { selectedHitIds, setSelectedHitIds, navigateToArticle } = useSavedHitsContext()

  const { id, searchNames, newKeywords, createdAt, selectedAt, selectedBy, template } = hitData

  const evenRowBgColor = themeMode === 'light' ? '#EEF3EB' : 'rgba(255, 255, 255, 0.05)'
  const backgroundColor = index % 2 === 0 ? evenRowBgColor : theme.palette.background.default

  const fontSize = '1rem'

  const sx = {
    backgroundColor,
    fontSize,
    verticalAlign: 'top',
    borderBottom: themeMode === 'dark' ? '1px solid rgba(81, 81, 81, 0.5)' : '1px solid rgba(0, 0, 0, 0.12)',
  }

  const onArticleIconClick = (hitId: number) => (e) => {
    e.preventDefault()
    const state = buildPreloadedState({})
    dispatch(setImInitState(state))
    navigateToArticle(hitId, savedHitsFormContext.getValues().saved_by_id)
  }

  const handleHitSelectionToggle = (hitId: number) => {
    const index = selectedHitIds.findIndex((value) => value === hitId)
    if (index > -1) {
      setSelectedHitIds([...selectedHitIds.slice(0, index), ...selectedHitIds.slice(index + 1)])
    } else {
      setSelectedHitIds([...selectedHitIds, hitId])
    }
  }

  const articleHref = `/saved_hits?article_id=${id}&saved_by_id=${savedHitsFormContext.getValues().saved_by_id}`

  return (
    <TableRow>
      <TableCell key="searchNames" sx={{ ...sx, wordBreak: 'break-word' }}>
        {searchNames.join(', ')}
      </TableCell>
      <TableCell key="newKeywords" sx={{ ...sx, wordBreak: 'break-word' }}>
        {newKeywords.join(', ')}
      </TableCell>
      <TableCell key="createdAt" sx={sx}>
        {moment.parseZone(createdAt).format(DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell key="selectedAt" sx={sx}>
        {moment.parseZone(selectedAt).format(DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell key="text" sx={sx}>
        <HitContent
          hitData={hitData}
          userSettings={userSettings}
          noTranslationLanguages={noTranslationLanguages}
          showHitLink={true}
        />
      </TableCell>
      <TableCell key="article" sx={sx} align="center">
        {template ? (
          <IconButton href={articleHref} onClick={onArticleIconClick(id)}>
            <ArticleIcon fontSize="large" color="action" />
          </IconButton>
        ) : (
          <CircularProgress size="15px" sx={{ position: 'relative', top: '15px' }} />
        )}
      </TableCell>
      <TableCell key="selectedBy" sx={sx}>
        {selectedBy}
      </TableCell>
      <TableCell key="remove" sx={sx} align="center">
        <HitCheckBox
          dataTestId="mark_to_remove"
          type="tick"
          checked={isSelected}
          handleClick={() => handleHitSelectionToggle(id)}
        />
      </TableCell>
    </TableRow>
  )
}

export default SavedHitsTableRow
