import { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { pickBy, identity } from 'lodash'
import { Box, Button, Divider, FormControlLabel, Checkbox, Grid } from '@mui/material'

import { PageCont } from '@common/layout'
import { updateSettings } from './actions'

import * as styles from './individual-settings-page.scss'

const LANGUAGES = [
  { value: 'nl', label: 'Dutch' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'fr', label: 'French' },
  { value: 'zh', label: 'Mandarin' },
  { value: 'es', label: 'Spanish' },
  { value: 'de', label: 'German' },
  { value: 'pl', label: 'Polish' },
  { value: 'cz', label: 'Czech' },
  { value: 'se', label: 'Swedish' },
  { value: 'tr', label: 'Turkish' },
  { value: 'ja', label: 'Japanese' },
  { value: 'id', label: 'Indonesian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'dk', label: 'Danish' },
  { value: 'hr', label: 'Croatian' },
  { value: 'my', label: 'Malay' },
  { value: 'no', label: 'Norwegian' },
  { value: 'ph', label: 'Filipino' },
  { value: 'ro', label: 'Romanian' },
  { value: 'sk', label: 'Slovakian' },
  { value: 'vt', label: 'Vietnamese' },
  { value: 'af', label: 'Afrikaans' },
]

const IndividualSettingsPage = (props) => {
  const [checkboxSelected, setCheckboxSelected] = useState(props.userSettings)

  const onCheckboxBtnClick = (selected) => {
    checkboxSelected[selected] ? (checkboxSelected[selected] = false) : (checkboxSelected[selected] = true)
    setCheckboxSelected({ ...checkboxSelected })
  }

  const onButtonClick = () => {
    props.updateSettings(pickBy(checkboxSelected, identity))
  }

  return (
    <PageCont>
      <h3 className={styles.header}>Individual Settings</h3>
      <Box sx={{ borderColor: 'divider' }} className={styles.settingsBox}>
        <div className={styles.boxHeader}>Translation of hits in inbox</div>
        <Divider />
        <div>
          Select the language(s) that should <b>not</b> be translated by default:
        </div>
        <form className={styles.checkboxArea}>
          <Grid container columnSpacing={2}>
            {LANGUAGES.map(({ value, label }) => {
              return (
                <Grid key={value} item xs={6} md={3}>
                  <FormControlLabel
                    label={label}
                    control={<Checkbox checked={!!checkboxSelected[value]} onClick={() => onCheckboxBtnClick(value)} />}
                  />
                </Grid>
              )
            })}
          </Grid>
        </form>
      </Box>
      <div className={styles.buttonArea}>
        <Button onClick={onButtonClick} color="primary">
          Save
        </Button>
        {!!props.errors?.length && <Box sx={{ color: 'error.main' }}>{props.errors.join(', ')}</Box>}
        {props.settingsSaved && <Box sx={{ color: 'success.main' }}>{props.settingsSaved}</Box>}
      </div>
    </PageCont>
  )
}

IndividualSettingsPage.propTypes = {
  updateSettings: PropTypes.func.isRequired,
  userSettings: PropTypes.object.isRequired,
  settingsSaved: PropTypes.string.isRequired,
  errors: PropTypes.array,
}

const mapStateToProps = (rootState) => {
  const state = rootState.individualSettings
  return {
    settingsSaved: state.settingsSaved,
    errors: state.errors,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSettings(settings) {
    dispatch(updateSettings(settings))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(IndividualSettingsPage)
