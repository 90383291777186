import { TextField, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import I18n from 'i18next'

const t = (name) => I18n.t(`message_filters.selects.validity.${name}`)

const getValidityOptions = () => [
  { value: 'all', label: t('all') },
  { value: 'expire_today', label: t('expire_today') },
  { value: 'valid', label: t('valid_only') },
]

const ValiditySelect = ({ onChange }) => {
  const handleChange = (event) => {
    const { value } = event.target
    const mockedEvent = { target: { value: value === 'all' ? '' : value } }
    onChange(mockedEvent)
  }

  return (
    <TextField
      select
      name="validity"
      id="validity"
      data-testid="validity_select"
      sx={{ minWidth: '12rem' }}
      label="Validity"
      defaultValue={'all'}
      onChange={handleChange}
    >
      {getValidityOptions().map((option) => (
        <MenuItem key={option.value} value={option.value} data-testid={`validity_${option.value}`}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

ValiditySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default ValiditySelect
