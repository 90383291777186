import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'
import { toast } from 'react-toastify'

import { fetch } from '@services'
import { useAppContext } from '@hooks'
import type { UseAuthSchema, UseAuthData, AuthAction } from '@types'

const ONE_HUNDRED_MINUTES_IN_MS = 6_000_000

type Args = { code?: string; refreshToken?: string; accessToken?: string }

const useAuth = (action: AuthAction, args: Args) => {
  const { setActiveAuthAction } = useAppContext()

  const queryFn = async (
    action: AuthAction,
    { code, refreshToken, accessToken }: Args
  ): Promise<UseAuthData | null> => {
    let url = ''

    setActiveAuthAction(action)

    switch (action) {
      case 'signin':
        url = `/auth/signin?code=${code}`
        break
      case 'resign':
        url = `/auth/signin?refresh_token=${refreshToken}`
        break
      case 'signout':
        url = `/auth/signout?token=${accessToken}`
        break
    }

    const res = await fetch(url, { method: 'POST' }, 1000)

    if (res.ok) {
      const data = (await res.json()) as UseAuthSchema

      if (['signin', 'resign'].includes(action)) {
        const userData = localStorage.getItem('ric.user')

        if (!userData) {
          toast.success(
            `Successfully authenticated as ${data.user.first_name.toUpperCase()} ${data.user.last_name.toUpperCase()}.`
          )
        }

        localStorage.setItem(
          'ric.user',
          JSON.stringify({
            id: data.user.uid,
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
          })
        )
      } else if (action === 'signout') {
        localStorage.removeItem('ric.user')
      }

      setActiveAuthAction(null)
      return camelizeKeys<UseAuthSchema>(data)
    } else {
      setActiveAuthAction(null)
      toast.error('Something went wrong.')
      return null
    }
  }

  return useQuery<UseAuthData | null>({
    queryKey: ['USE_AUTH'],
    queryFn: () => queryFn(action, args),
    refetchOnMount: false,
    retry: false,
    enabled: action === 'resign' && Boolean(args.refreshToken),
    refetchInterval: action === 'resign' ? ONE_HUNDRED_MINUTES_IN_MS : false,
    refetchIntervalInBackground: action === 'resign',
  })
}

export default useAuth
