import { TextField, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import I18n from 'i18next'

const t = (name) => I18n.t(`message_filters.selects.message_type.${name}`)

const getMessageTypeOptions = () => [
  { value: 'all', label: t('all') },
  { value: 'indicator_message', label: t('indicator') },
  { value: 'news', label: t('news') },
]

const MessageTypeSelect = ({ onChange }) => {
  const handleChange = (event) => {
    const { value } = event.target
    const mockedEvent = { target: { value: value === 'all' ? '' : value } }
    onChange(mockedEvent)
  }

  return (
    <TextField
      select
      name="message_type"
      id="message_type"
      data-testid="message_type_select"
      sx={{ minWidth: '12rem' }}
      label="Message Type"
      defaultValue={'all'}
      onChange={handleChange}
    >
      {getMessageTypeOptions().map((option) => (
        <MenuItem key={option.value} value={option.value} data-testid={`message_type_${option.value}`}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

MessageTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default MessageTypeSelect
