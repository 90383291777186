import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Autocomplete, Box, Slider, Chip, DialogContentText } from '@mui/material'

import * as styles from './reassign_modal.scss'

import Modal from '@common/components/modal'
import { userShape, hitsAssigneeShape } from '@common/utils/shapes'

const isNewAssignee = (assignee) => !assignee.id || assignee.id.includes('new')

export default function ReassignModal(props) {
  const { onClose, onReassign, isOpen, assignees, users, editingId } = props
  const assignee = assignees.find(({ id }) => id === editingId)
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState([])
  const [percentageValue, setPercentageValue] = useState(30)

  useEffect(() => {
    setCurrentSelectedOptions([])
  }, [isOpen])

  if (!assignee?.id) {
    return null
  }

  const fromUser = users.find(({ id }) => {
    return Number(id) === Number(assignee.id)
  })

  const otherAssignees = assignees.filter((assignee) => {
    return !isNewAssignee(assignee) && assignee.id !== editingId
  })

  const otherAssignedUsers = otherAssignees.map((otherAssignee) => {
    return users.find(({ id }) => {
      return Number(id) === Number(otherAssignee.id)
    })
  })

  const options = otherAssignedUsers.map((user) => {
    const option = {
      label: user.fullName || user.id,
      value: user.id,
    }

    return option
  })

  const reassignHits = () => {
    onReassign({
      fromUserId: assignee.id,
      toUserIds: currentSelectedOptions.map(({ value }) => value),
      percentage: percentageValue,
    })
  }

  const MIN = 5
  const MAX = 100
  return (
    <Modal
      title={'Re-assign Unique hits of ' + fromUser.fullName}
      footer={
        <>
          <Button color="secondary" onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button color="primary" onClick={reassignHits} disabled={currentSelectedOptions.length === 0}>
            {'Re-Assign'}
          </Button>
        </>
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      <Box sx={{ pt: 2, px: 1 }}>
        <DialogContentText sx={{ mb: 4 }}>The percentage of hits you want to re-assign:</DialogContentText>
        <Box overflowX="hidden">
          <Slider
            step={5}
            min={MIN}
            max={MAX}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}%`}
            value={percentageValue}
            onChange={(event, value) => {
              setPercentageValue(value)
            }}
          />
        </Box>
        <div className={styles.sliderBottomLabels}>
          <span>{MIN}%</span>
          <span>{MAX}%</span>
        </div>
        <DialogContentText gutterBottom>The Assignees to reassign to:</DialogContentText>
        <Autocomplete
          multiple
          fullWidth
          autoHighlight
          filterSelectedOptions
          options={options}
          onChange={(event, values) => {
            setCurrentSelectedOptions(values)
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select assignees"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
            />
          )}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip variant="outlined" size="small" label={option.label} {...getTagProps({ index })} />
            ))
          }
        />
      </Box>
    </Modal>
  )
}

ReassignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(userShape),
  assignees: PropTypes.arrayOf(hitsAssigneeShape),
  editingId: PropTypes.string,
}
