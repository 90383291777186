import { useMemo } from 'react'
import { Field, Select } from '@riskmethods/rm-front-end'

type Props = {
  onlyNews: boolean
  disabled?: boolean
}

const DEFAULT_OPTIONS = [
  { value: 'business_partner', label: 'Business Partner' },
  { value: 'location', label: 'Location' },
  { value: 'country', label: 'Country' },
]

export const TypeSelect = ({ onlyNews, disabled = false }: Props) => {
  const OPTIONS = useMemo(() => {
    return !onlyNews ? DEFAULT_OPTIONS : [DEFAULT_OPTIONS[0], DEFAULT_OPTIONS[2]]
  }, [onlyNews])

  return (
    <Field
      name="type"
      label="Type"
      disabled={disabled}
      Component={(props) => <Select testId="type-field" {...props} options={OPTIONS} />}
      validationRules={{
        required: true,
      }}
    />
  )
}
