import { Stack, Tooltip, Chip, Box } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import PropTypes from 'prop-types'

import { businessPartner } from '@common/utils/shapes'

import * as styles from '../../bp-im-form.scss'

export default function SearchLabels({ searches, removeSearch, formType, suppliers }) {
  return (
    <Stack direction={'row'} flexWrap={'wrap'} gap={1} overflow={'hidden'}>
      {searches.map(({ id, name, result, additional }) => {
        const searchResultsLength = result.length
        const resultIds = result.map((res) => res.id)
        const targetsFromSearch = suppliers.filter((supplier) => resultIds.includes(supplier.id)).length
        const validDomId = `search-${id}` // it has to start with character

        return (
          <Box overflow={'hidden'} key={validDomId}>
            <Tooltip
              title={name}
              PopperProps={{
                sx: { '.MuiTooltip-tooltip': { maxWidth: '38vw' } },
              }}
            >
              <Chip
                size="small"
                color={additional ? 'warning' : undefined}
                variant={additional ? 'outlined' : 'filled'}
                label={
                  <>
                    <ClearIcon
                      fontSize="small"
                      data-testid={`${formType}_remove_search`}
                      onClick={() => removeSearch(id)}
                    />{' '}
                    | {targetsFromSearch} ({searchResultsLength})| {name}
                  </>
                }
                sx={{
                  backgroundColor: additional ? 'background.default' : undefined,
                }}
                className={styles.searchQuery}
              />
            </Tooltip>
          </Box>
        )
      })}
    </Stack>
  )
}

SearchLabels.propTypes = {
  searches: PropTypes.arrayOf(PropTypes.object),
  removeSearch: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  suppliers: PropTypes.arrayOf(businessPartner).isRequired,
}
