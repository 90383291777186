import { Field, Input } from '@riskmethods/rm-front-end'

type Props = {
  disabled?: boolean
}

export const SourceForCopyrightInput = ({ disabled = false }: Props) => {
  return (
    <Field
      name="sourceForCopyright"
      label="Source for copyright ©"
      disabled={disabled}
      Component={(props) => (
        <Input
          testId="source-for-copyright-field"
          enableClearBtn
          enableTextCaseConverter
          textFieldProps={{ inputProps: { spellCheck: true } }}
          {...props}
        />
      )}
      validationRules={{
        required: true,
      }}
    />
  )
}
