import { Box, Pagination, Stack, Typography } from '@mui/material'

type Props = {
  isLoading: boolean
  total: number
  totalPages: number
  page: number
  perPage: number
  handlePageChanged: (e, page: number) => void
}

const Footer = ({ isLoading, total, totalPages, page, perPage, handlePageChanged }: Props) => {
  const info = `Showing ${(page - 1) * perPage + 1} to ${totalPages > 1 ? page * perPage : total} of ${total} result(s)`

  return (
    <Stack
      direction="row"
      alignItems="center"
      position="fixed"
      width="100%"
      zIndex={2}
      bottom={0}
      px={2}
      py={1}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Box flex="1">
        <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
          {info}
        </Typography>
      </Box>
      {totalPages > 1 ? (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChanged}
          showFirstButton
          showLastButton
          siblingCount={2}
          boundaryCount={0}
          shape="rounded"
          color="primary"
          disabled={isLoading}
        />
      ) : null}
    </Stack>
  )
}

export default Footer
