import {
  UPDATE_FEATURES,
  FIT_BOUNDS,
  FITTED_BOUNDS,
  UPDATE_MAP,
  MAP_UPDATED,
  BOUNDS_CHANGED,
  ZOOM_CHANGED,
} from '../actions'
import { SHOW_INFO_WINDOW, CLOSE_INFO_WINDOW, OBJECT_INFO_RECEIVED } from '../actions/info'
import filter from './filter'
import layers from './layers'
import visibility from './visibility'
import loading from './loading'
import search from './search'

export const defaultState = {
  // Processed data that is sent to Google Maps
  features: [],
  zoom: 2,
  fitBounds: false,
  updateMap: false,
  popup: {
    visible: false,
    type: null,
    anchor: null,
    feature: null,
    featureId: null,
    featureIndex: null,
  },
}

const updateFeatures = (newState) => {
  return Object.keys(newState.layers)
    .filter((k) => newState.filter.layers[k])
    .map((k) => newState.layers[k])
    .reduce((acc, v) => acc.concat(v), [])
}

const transformFeature = (feature, targets) => {
  if (targets[0].radius) {
    return { ...feature, radius: targets[0].radius }
  } else if (targets[0].area) {
    return { ...feature, area: targets[0].area }
  } else if (targets[0].error) {
    return { ...feature, error: targets[0].error }
  } else {
    return feature
  }
}

export function reducer(state = defaultState, action) {
  const newState = {
    ...state,
    filter: filter(state.filter, action),
    layers: layers(state.layers, action),
    visibility: visibility(state.visibility, action),
    loading: loading(state.loading, action),
    search: search(state.search, action),
  }
  switch (action.type) {
    case UPDATE_FEATURES:
      return {
        ...newState,
        features: updateFeatures(newState),
      }
    case FIT_BOUNDS:
      return {
        ...newState,
        fitBounds: true,
      }
    case FITTED_BOUNDS:
      return {
        ...newState,
        fitBounds: false,
      }
    case UPDATE_MAP:
      return {
        ...newState,
        updateMap: true,
      }
    case MAP_UPDATED:
      return {
        ...newState,
        updateMap: false,
      }
    case BOUNDS_CHANGED:
      return {
        ...newState,
        bounds: action.bounds,
        updateMap: false,
      }
    case ZOOM_CHANGED:
      return {
        ...newState,
        zoom: action.zoom,
        updateMap: false,
      }
    case SHOW_INFO_WINDOW:
      return {
        ...newState,
        popup: {
          visible: true,
          anchor: action.anchor,
          feature: null,
          type: 'RicEvent',
          featureId: action.featureId,
          featureIndex: action.featureIndex,
        },
      }
    case CLOSE_INFO_WINDOW:
      return {
        ...newState,
        popup: {
          visible: false,
          anchor: null,
          feature: null,
          type: null,
          featureId: null,
          featureIndex: null,
        },
      }
    case OBJECT_INFO_RECEIVED:
      return {
        ...newState,
        popup: {
          ...newState.popup,
          visible: true,
          type: 'RicEvent',
          feature: transformFeature(action.feature, action.targets),
          featureId: action.featureId,
          featureIndex: null,
        },
      }
    default:
      return newState
  }
}
