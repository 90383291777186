import { Field, Input } from '@riskmethods/rm-front-end'

type Props = {
  disabled?: boolean
}

export const SourceUrlInput = ({ disabled = false }: Props) => {
  return (
    <Field
      name="sourceUrl"
      label="Source URL"
      disabled={disabled}
      Component={(props) => (
        <Input
          testId="source-url-field"
          enableClearBtn
          textFieldProps={{ inputProps: { spellCheck: true } }}
          {...props}
        />
      )}
      validationRules={{
        required: true,
        url: true,
      }}
    />
  )
}
