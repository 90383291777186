import { Component } from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles.scss'
import LocationSelect from '@common/components/location_select'

export class SearchBox extends Component {
  static propTypes = {
    search: PropTypes.object,
    onCommit: PropTypes.func,
  }

  render() {
    return (
      <div className={styles.search}>
        <LocationSelect
          sx={{
            '.MuiInputBase-root': {
              backgroundColor: 'background.default',
            },
          }}
          placeholder="Search map location"
          value={this.props.search.selection}
          onChange={this.props.onCommit}
        />
      </div>
    )
  }
}
