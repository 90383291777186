import { pickBy } from 'lodash'

import type { IndicatorMessageFormValues } from '@types'

import { transformSupplier } from '../transform-supplier'

const mapSearch = ({ name, result }) => ({
  name,
  result: result.map(({ id }) => ({ id })),
})

export const getMsgReqBody = (state, imFormValues: IndicatorMessageFormValues) => {
  const { subject, sourceForContent, sourceForCopyright, content, sourceUrl, type, onlyNews, industries } = imFormValues

  const {
    country: { data: countryData },
    supplier: {
      data: {
        alertForm: { selectedIndicator },
        newsForm: { searches: newsSearches },
      },
      priorityForm,
    },
  } = state

  const country = type === 'country' &&
    onlyNews && { newsCountries: countryData.newsCountries.map(({ code }) => ({ code })) }
  const businessPartner = type === 'business_partner' && transformSupplier(state, onlyNews)
  const businessPartnerSearches = { indicatorMessageSearches: newsSearches }
  const indicatorId = onlyNews ? null : selectedIndicator && selectedIndicator.id
  const formPriorityReverse = priorityForm === 'newsForm'
  const searches = type === 'business_partner' && onlyNews && { newsSearches: newsSearches.map(mapSearch) }
  const hyperlinks = [sourceUrl]

  return {
    message: pickBy({
      subject,
      sourceContent: sourceForContent,
      sourceCopyright: sourceForCopyright,
      body: content,
      hyperlinks,
      country,
      indicatorId,
      industries,
      businessPartner,
      businessPartnerSearches,
      searches,
      score: 100,
      formPriorityReverse,
    }),
  }
}
