// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header--f8L16{font-size:24px;font-weight:600;margin:28px 0px 18px 48px}.settings-box--QlnPd{border-radius:3px;border-style:solid;border-width:1px;padding:27px 20px 16px;margin-left:48px;margin-right:20px}.settings-box--QlnPd .box-header--KLDDp{font-size:20px;font-weight:600;margin-bottom:.3em}.settings-box--QlnPd .checkbox-area--fKc_S{max-width:70rem;font-size:18px;margin-top:1rem}.button-area--H_Z2j{display:flex;align-items:center;gap:2rem;margin-top:1.5rem;margin-left:48px}`, "",{"version":3,"sources":["webpack://./src/client/pages/individual-settings/individual-settings-page.scss"],"names":[],"mappings":"AAAA,eACE,cAAA,CACA,eAAA,CACA,yBAAA,CAGF,qBACE,iBAAA,CACA,kBAAA,CACA,gBAAA,CACA,sBAAA,CACA,gBAAA,CACA,iBAAA,CAEA,wCACE,cAAA,CACA,eAAA,CAEA,kBAAA,CAGF,2CACE,eAAA,CAEA,cAAA,CAEA,eAAA,CAIJ,oBACE,YAAA,CACA,kBAAA,CAEA,QAAA,CACA,iBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export var header = `header--f8L16`;
var _1 = `settings-box--QlnPd`;
export { _1 as "settings-box" };
export var settingsBox = `settings-box--QlnPd`;
var _2 = `box-header--KLDDp`;
export { _2 as "box-header" };
export var boxHeader = `box-header--KLDDp`;
var _3 = `checkbox-area--fKc_S`;
export { _3 as "checkbox-area" };
export var checkboxArea = `checkbox-area--fKc_S`;
var _4 = `button-area--H_Z2j`;
export { _4 as "button-area" };
export var buttonArea = `button-area--H_Z2j`;
export default ___CSS_LOADER_EXPORT___;
