// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container--csNbG{position:fixed;top:calc(var(--header-h) + 2px);bottom:0px;left:0px;right:0px}`, "",{"version":3,"sources":["webpack://./src/client/pages/world-map/world-map-page.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,+BAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
var _1 = `map-container--csNbG`;
export { _1 as "map-container" };
export var mapContainer = `map-container--csNbG`;
export default ___CSS_LOADER_EXPORT___;
