import { flatMap } from 'lodash'

export const joinRecipients = (messages, messagesRecipients) => {
  return messages.map((message) => {
    if (message.coreMessageId) {
      const foundRecipientsEntry = messagesRecipients.find((item) => item.coreMessageId === message.coreMessageId)
      if (foundRecipientsEntry) {
        return {
          ...message,
          recipients: flatMap(messagesRecipients, (message) => message.recipients),
        }
      }
    }
    return { ...message, recipients: [] }
  })
}

const exactSearchRegexp = /".+?"/g

const getExactSearchKeywords = (keywordsSearch) => {
  return Array.from(keywordsSearch.matchAll(exactSearchRegexp), (match) => match[0])
}

const getRegularKeywords = (keywordsSearch) => {
  const regularKeywordsSearch = keywordsSearch.replace(exactSearchRegexp, ' ')
  const regularKeywords = regularKeywordsSearch.split(' ')

  return regularKeywords
}

const getOrderedKeywords = (keywordsSearch, keywords) =>
  !keywordsSearch ? keywords : [...keywords].sort((a, b) => keywordsSearch.indexOf(a) - keywordsSearch.indexOf(b))

export const processKeywordsSearch = (keywordsSearch = '') => {
  if (!keywordsSearch) {
    return []
  }

  const exactSearchKeywords = getExactSearchKeywords(keywordsSearch)
  const regularKeywords = getRegularKeywords(keywordsSearch)

  const keywords = [...exactSearchKeywords, ...regularKeywords]

  const filteredKeywords = keywords.filter((keyword) => Boolean(keyword)) // Remove empty keywords;

  return getOrderedKeywords(keywordsSearch, filteredKeywords)
}
