import { memo } from 'react'
import PropTypes from 'prop-types'
import { TextField, Autocomplete } from '@mui/material'

const isNotAssigned = (assignees, user, value) => {
  if (String(value) === String(user.id)) {
    return true
  }

  const isAssigned = Boolean(assignees.find((assignee) => String(user.id) === String(assignee.id)))

  return !isAssigned
}

const UsersDropdown = memo(({ users, assignees, value, onUserSelected, isEditing }) => {
  let selectedOption = null

  const options = users
    .filter((user) => {
      return isNotAssigned(assignees, user, value)
    })
    .map((user) => {
      const option = {
        label: user.fullName || user.id,
        value: user.id,
      }

      if (String(option.value) === String(value)) {
        selectedOption = option
      }

      return option
    })

  return isEditing ? (
    <Autocomplete
      fullWidth
      disableClearable
      value={selectedOption || null}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      getOptionLabel={({ label }) => label}
      onChange={(ev, newAssignee) => {
        if (!newAssignee) {
          return
        }
        onUserSelected({
          id: newAssignee.value,
          previousId: value,
        })
      }}
      options={options}
      data-testid="assignee_select"
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Select assignee'}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  ) : (
    selectedOption?.label
  )
})

UsersDropdown.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  onUserSelected: PropTypes.func,
  index: PropTypes.number,
  value: PropTypes.any,
  isEditing: PropTypes.bool.isRequired,
}

export default function dropdownFormatter(assignee, _hit, rowIndex, formatExtraData) {
  return <UsersDropdown {...formatExtraData} value={assignee.id} index={rowIndex} />
}
