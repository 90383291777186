import { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18next'
import { Button } from '@mui/material'

import Modal from '@common/components/modal'

export default class LimitLoadingMessageModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  handleClose = () => this.props.onClose()

  render = () => (
    <Modal
      title={I18n.t('worldmap.limit_loading_message.title')}
      footer={
        <Button color="secondary" onClick={this.handleClose}>
          {I18n.t('common.ok')}
        </Button>
      }
      onClose={this.handleClose}
      isOpen={this.props.isOpen}
    >
      {I18n.t('worldmap.limit_loading_message.body')}
    </Modal>
  )
}
