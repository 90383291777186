import { merge, pick } from 'lodash'

const mapSuppliers = (suppliers) => {
  const attributes = ['id', 'name', 'city', 'countryCode', 'streetAndNumber', 'zipcode', 'memberIds']

  return suppliers.map((supplier) => pick(merge({}, supplier, supplier.address), attributes))
}

export const transformSupplier = (state, onlyNews) => {
  const {
    supplier: {
      data: {
        alertForm: { suppliers: indicatorMessageBusinessPartners },
        newsForm: { suppliers: newsBusinessPartners },
      },
    },
  } = state

  return {
    indicatorMessageBusinessPartners: onlyNews ? [] : mapSuppliers(indicatorMessageBusinessPartners),
    newsBusinessPartners: mapSuppliers(newsBusinessPartners),
  }
}
