import { getJSON } from '../http'

export const COUNTRIES_FETCHED = 'COUNTRIES_FETCHED'

export const fetchCountries = () => (dispatch) => {
  return getJSON('/api/countries').then(({ data: { countries } }) => {
    dispatch({
      type: COUNTRIES_FETCHED,
      data: countries,
    })
  })
}
