import { Tooltip, Button } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const minimumReassignPeopleNumber = 2

export default function actionFormatter(_value, rowData, rowIndex, formatExtraData) {
  const { removeUser, openReassignModal, assignees, isEditing } = formatExtraData
  if (!isEditing) {
    return ''
  }

  const assigneesWithSelectedUser = assignees.filter(({ id }) => id !== null && !id.includes('new'))
  const hasNotEnoughAssignees = assigneesWithSelectedUser.length < minimumReassignPeopleNumber
  const canBeReassigned = rowData.assignee.ranges.length < 2
  const isReassignDisabled = hasNotEnoughAssignees || !canBeReassigned
  return (
    <>
      <Tooltip title="Remove assignee">
        <Button
          size="medium"
          variant="outlined"
          sx={{ px: 1, minWidth: '40px', fontSize: '1.4rem', mr: 1 }}
          color="warning"
          data-testid="remove_assignee"
          onClick={() => {
            removeUser(rowData.id)
          }}
        >
          <DeleteForeverIcon fontSize="inherit" />
        </Button>
      </Tooltip>
      <Tooltip title="Open reassing modal" disableHoverListener={isReassignDisabled}>
        <span>
          <Button
            size="medium"
            variant="outlined"
            sx={{ px: 1, minWidth: '40px', fontSize: '1.4rem' }}
            color="info"
            disabled={isReassignDisabled}
            data-testid="open_reassign_modal"
            onClick={() => {
              openReassignModal({ id: rowData.id })
            }}
          >
            <TuneIcon fontSize="inherit" />
          </Button>
        </span>
      </Tooltip>
    </>
  )
}
