export const START_LOADING = 'START_LOADING'
export const UPDATE_LOADING = 'UPDATE_LOADING'
export const END_LOADING = 'END_LOADING'
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
export const INCREMENT_PAGE = 'INCREMENT_PAGE'

export const startLoading = (layer, showLoadingBar) => ({
  type: START_LOADING,
  layer,
  showLoadingBar,
})

export const updateLoading = (progress) => ({
  type: UPDATE_LOADING,
  progress,
})

export const endLoading = () => ({
  type: END_LOADING,
})

export const setLoadingTotalPages = (totalPages) => ({
  type: SET_TOTAL_PAGES,
  totalPages,
})

export const incrementLoadingPage = () => ({
  type: INCREMENT_PAGE,
})
