import { getJSON, patchJSON, deleteJSON } from '@common/http'
import { tableParams } from '@common/utils/table_params'

export const DATA_RECEIVED = 'MESSAGE_BUNDLES_DATA_RECEIVED'
export const RESET_DATA = 'RESET_REVIEW_PAGE_DATA'
export const INDEX_ERROR_RECEIVED = 'MESSAGE_BUNDLES_INDEX_ERROR_RECEIVED'
export const SUBMIT_ERROR_RECEIVED = 'MESSAGE_BUNDLES_SUBMIT_ERROR_RECEIVED'
export const LOAD_DATA = 'MESSAGE_BUNDLES_LOAD_DATA'
export const FREEZE_BUNDLE = 'FREEZE_BUNDLE'
export const CLEAR_SUBMIT_ERRORS = 'MESSAGE_BUNDLES_CLEAR_SUBMIT_ERRORS'
export const INDICATORS_FETCHED = 'REVIEW_PAGE_INDICATORS_FETCHED'

export const indicatorsFetched = (data) => ({
  type: INDICATORS_FETCHED,
  data,
})

export const fetchIndicators = () => (dispatch) => {
  return getJSON('api/indicators').then(({ data: { indicators } }) => {
    dispatch(indicatorsFetched(indicators))
  })
}

export const loadData = () => ({ type: LOAD_DATA })

export const dataReceived = (json) => ({
  type: DATA_RECEIVED,
  data: json,
})

export const resetData = () => ({
  type: RESET_DATA,
})

export const indexErrorReceived = (json) => ({
  type: INDEX_ERROR_RECEIVED,
  data: json,
})

export const submitErrorReceived = (json) => ({
  type: SUBMIT_ERROR_RECEIVED,
  data: json,
})

export const freezeBundle = (id) => ({
  type: FREEZE_BUNDLE,
  data: { id },
})

export const clearSubmitErrors = () => ({
  type: CLEAR_SUBMIT_ERRORS,
})

export const fetchData = () => (dispatch, getState) => {
  const {
    table: { page, sort },
    filters,
  } = getState().reviews
  dispatch(loadData())
  return getJSON('/api/message_bundles', tableParams({ page, sort, filters }))
    .then(({ data }) => {
      dispatch(dataReceived(data))
    })
    .catch(({ data }) => {
      dispatch(indexErrorReceived({ errors: data?.errors }))
    })
}

export const approveBundle = ({ id }) => {
  return (dispatch) => {
    dispatch(freezeBundle(id))

    return patchJSON(`/api/message_bundles/${id}/approve`)
      .then(() => {
        dispatch(fetchData())
        dispatch(clearSubmitErrors())
      })
      .catch(({ data }) => {
        dispatch(submitErrorReceived({ errors: data?.errors }))
      })
  }
}

export const deleteBundle = ({ id }) => {
  return (dispatch) => {
    dispatch(freezeBundle(id))

    return deleteJSON(`/api/message_bundles/${id}`)
      .then(() => {
        dispatch(fetchData())
        dispatch(clearSubmitErrors())
      })
      .catch(({ data: { errors } }) => {
        dispatch(submitErrorReceived({ errors }))
      })
  }
}
