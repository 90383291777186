import { SET_LAYER } from '../actions'
import { END_LOADING } from '../actions/loading'
import { cacheKeyForAllLayers } from '../actions/cache'

export const initialState = {
  layers: {
    suppliers: true,
    debitors: true,
    locations: true,
    manufactures: true,
    events: true,
  },
  query: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case END_LOADING:
      return {
        ...state,
        persistedCacheKey: cacheKeyForAllLayers(state),
      }
    case SET_LAYER:
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layer]: action.value,
        },
      }
    default:
      return state
  }
}
