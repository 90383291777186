import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

export function CopyToClipboard({ copyText, customClassName = '' }) {
  const [hasBeenCopied, setHasBeenCopied] = useState(false)

  function copyTextToClipboard() {
    // This check is needed, as JS has access to Clipboard
    // only in secure connections (https) - does not work on dev.
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(copyText)
    }
    setHasBeenCopied(true)

    setTimeout(() => {
      setHasBeenCopied(false)
    }, 3000)
  }

  const tooltipTitle = hasBeenCopied ? 'Copied!' : copyText

  return (
    <Tooltip title={tooltipTitle} placement="top" aria-label={'copy cluster id to clipboard'}>
      <IconButton component="button" size={'small'} className={customClassName} onClick={copyTextToClipboard}>
        {hasBeenCopied ? <CheckRoundedIcon /> : <ContentCopyIcon />}
      </IconButton>
    </Tooltip>
  )
}

CopyToClipboard.propTypes = {
  copyText: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
}
