import { Component } from 'react'
import PropTypes from 'prop-types'
import { Stack, TextField, Typography, Box, Button } from '@mui/material'
import I18n from 'i18next'

import { FIRST_PAGE } from '@common/actions/table'
import MultipleSelect from '@common/components/multiple_select'

export default class Filters extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    perPage: PropTypes.number,
    nameSearchQuery: PropTypes.string,
    updateNameSearchQuery: PropTypes.func.isRequired,
    locationSearchQuery: PropTypes.string,
    updateLocationSearchQuery: PropTypes.func.isRequired,
    searchQueryFields: PropTypes.arrayOf(PropTypes.string),
    updateSearchQueryFields: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.nameSearchInput.focus()
  }

  handleNameSearchChange = ({ target: { value } }) => {
    this.props.updateNameSearchQuery(value)
  }

  handleLocationSearchChange = ({ target: { value } }) => {
    this.props.updateLocationSearchQuery(value)
  }

  handleSearchFieldsChange = ({ target: { value } }) => {
    this.props.updateSearchQueryFields(value)
  }

  handleSearchClick = () => {
    const { setPage, fetchData, perPage } = this.props

    setPage('BP', FIRST_PAGE, perPage)
    fetchData()
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSearchClick()
      e.preventDefault()
    }
  }

  options = [
    { value: 'city,city_search_name', label: 'City & City Search Name' },
    { value: 'street,zipcode', label: 'Address & Zip Code' },
    { value: 'country_name', label: 'Country' },
  ]

  render() {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={2} sx={{ pt: '1rem', pb: '10px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" component="h2">
            Business Partners
          </Typography>
        </Box>
        <Stack direction="row" alignItems="flex-end" columnGap="10px">
          <MultipleSelect
            dataTestId="quality-select"
            label="Search in columns"
            options={this.options}
            values={this.props.searchQueryFields}
            onChange={this.handleSearchFieldsChange}
          />
          <Stack rowGap="10px">
            <TextField
              inputRef={(input) => (this.nameSearchInput = input)}
              label="Name search"
              data-testid="name-search-query"
              sx={{ width: '24rem' }}
              value={this.props.nameSearchQuery ?? ''}
              onChange={this.handleNameSearchChange}
              onKeyDown={this.handleKeyDown}
              autoComplete="off"
              spellCheck="false"
            />
            <TextField
              label="Location search"
              data-testid="location-search-query"
              sx={{ width: '24rem' }}
              value={this.props.locationSearchQuery ?? ''}
              onChange={this.handleLocationSearchChange}
              onKeyDown={this.handleKeyDown}
              autoComplete="off"
              spellCheck="false"
            />
          </Stack>
        </Stack>
        <Button color="secondary" data-testid="search-btn" onClick={this.handleSearchClick}>
          {I18n.t('history.filter.buttons.search')}
        </Button>
      </Stack>
    )
  }
}
