import { createTheme } from '@mui/material/styles'

const themeDefaults = {
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'Roboto',
      'sans-serif',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        sx: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          '& .MuiFormControlLabel-label': {
            lineHeight: 1.7,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '100%',
          left: '0',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        SelectProps: {
          MenuProps: {
            disableScrollLock: true,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: 1.3,
          '&.MuiInputLabel-shrink': {
            lineHeight: 1.6,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: '600px',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
  },
}
export const lightTheme = createTheme({
  ...themeDefaults,
  palette: {
    primary: { main: 'hsl(211, 100%, 50%)' },
    secondary: { main: '#6c757d', light: '#6c757d' },
    background: {
      default: '#ffffff',
      accent: '#EEF3EB',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.32)',
    },
    text: {
      primary: '#212529',
    },
  },
})

export const darkTheme = createTheme({
  ...themeDefaults,
  components: {
    ...themeDefaults.components,
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: { main: '#4DA133', dark: '#007200', light: '#80D362' },
    secondary: { main: '#6e7289', dark: '#4E5264', light: '#7a7e92' },
    background: {
      default: '#1f1f1f',
      paper: '#373738',
      accent: '#1f2128',
    },
    action: {
      active: '#FFFFFF',
      hover: '#444446',
      selected: '#616062',
      disabled: 'rgba(255, 255, 255, 0.35)',
    },
    text: {
      primary: '#D1D1D3',
      secondary: '#D1D1D3',
      disabled: '#9E9E9F',
    },
  },
})
