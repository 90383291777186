// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hit-text__cell--bPUW1{word-break:break-word}.hit-text__sentence--rgwuP{margin-top:20px}.translate--hQZwY{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/client/components/hit/hit-content/hits-content.scss"],"names":[],"mappings":"AACE,uBACE,qBAAA,CAGF,2BACE,eAAA,CAIJ,kBACE,kBAAA","sourceRoot":""}]);
// Exports
var _1 = `hit-text__cell--bPUW1`;
export { _1 as "hit-text__cell" };
export var hitTextCell = `hit-text__cell--bPUW1`;
var _2 = `hit-text__sentence--rgwuP`;
export { _2 as "hit-text__sentence" };
export var hitTextSentence = `hit-text__sentence--rgwuP`;
export var translate = `translate--hQZwY`;
export default ___CSS_LOADER_EXPORT___;
