import Validator from 'validatorjs'
import I18n from 'i18next'

export const validationLocation = ({ radius, indicator, selectedLocations, polygonPoints, isPolygon }) => {
  const indicatorId = indicator && indicator.id
  const rules = {
    radius: isPolygon ? [] : 'required|min:0|max:500',
    indicator: 'numeric|required',
    selectedLocations: 'required',
    polygonPoints: isPolygon ? 'required|min:3' : [],
  }
  const messages = {
    required: I18n.t('common.messages.validation.required'),
    numeric: I18n.t('common.messages.validation.numeric'),
    min: I18n.t('common.messages.validation.location.min_max_radius'),
    max: I18n.t('common.messages.validation.location.min_max_radius'),
    'min.polygonPoints': I18n.t('common.messages.validation.location.min_polygon_points'),
  }

  const validator = new Validator(
    { radius, indicator: indicatorId, selectedLocations, polygonPoints, isPolygon },
    rules,
    messages
  )
  return validator.fails() ? { valid: false, errors: validator.errors.all() } : { valid: true, errors: {} }
}
