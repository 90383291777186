import { useEffect } from 'react'
import { Chip, Stack, CircularProgress, Typography, Divider, Box } from '@mui/material'
import I18n from 'i18next'
import isEmpty from 'lodash/isEmpty'

import { useGetStats } from '@pages/inbox/api'
import Modal from '@common/components/modal'
import { useAppContext } from '@hooks'

import * as globalStyles from '@src/client/styles/global.scss'

type Props = {
  hitIds: number[]
}

const StatsModal = ({ hitIds }: Props) => {
  const { showInboxStats, toggleInboxStats } = useAppContext()

  const {
    data: statsData,
    refetch: fetchStats,
    isLoading: isLoadingStats,
    isFetching: isFetchingStats,
  } = useGetStats(hitIds)

  useEffect(() => {
    if (showInboxStats) {
      fetchStats()
    }
  }, [showInboxStats])

  const handleClose = () => {
    if (!isLoading) {
      toggleInboxStats(false)
    }
  }

  const isLoading = isLoadingStats || isFetchingStats

  return (
    <Modal
      title={<Typography variant="h5">{I18n.t('inbox.filter.buttons.statistics')}</Typography>}
      isOpen={showInboxStats}
      onClose={handleClose}
    >
      {!isLoading ? (
        <>
          <Typography variant="subtitle1">{I18n.t('inbox.overview.general_statistics.title')}</Typography>
          <Box mt={0.25} mb={2}>
            <Divider />
          </Box>
          <Typography variant="subtitle2">
            {`- ${statsData?.statistics.new} ${I18n.t('inbox.overview.general_statistics.new').toLowerCase()}`}
          </Typography>
          <Box mt={5} mb={3} />
          <Typography variant="subtitle1">{I18n.t('inbox.overview.most_popular_keywords.title')}</Typography>
          <Box mt={0.25} mb={2}>
            <Divider />
          </Box>
          {isEmpty(statsData?.topKeywordCounts) ? (
            <Typography variant="subtitle2">{I18n.t('inbox.overview.most_popular_keywords.no_keywords')}</Typography>
          ) : (
            <Stack direction="row" flexWrap="wrap" gap={0.75}>
              {statsData?.topKeywordCounts.map(({ keyword, count }) => (
                <Chip key={keyword} color="secondary" label={`${keyword} (${count})`} size="medium" />
              ))}
            </Stack>
          )}
        </>
      ) : (
        <div className={globalStyles.absCentered}>
          <CircularProgress color="info" disableShrink size={25} thickness={3} />
        </div>
      )}
    </Modal>
  )
}

export default StatsModal
