import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import I18n from 'i18next'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import values from 'lodash/values'
import { useSearchParams, Form } from '@riskmethods/rm-front-end'

import { DEFAULT_DATA as GET_HITS_DEFAULT_DATA } from '@src/client/pages/inbox/api/use-get-hits/use-get-hits'
import { useGetHits, useUpdateHits, useDeleteHits } from '@pages/inbox/api'
import { LANGUAGES, QUALITY_OPTIONS } from '@pages/inbox/constants'
import { getUpdateHitsReqBody, getAllMarkedHits } from '@pages/inbox/helpers'
import { useAppContext, useHitQualities } from '@hooks'
import { useUnsaveHits } from '@api'
import type { InboxFormValues, HitQuality, InitPageData } from '@types'

import FiltersForm from './components/filters-form/filters-form'
import HitsTable from './components/hits-table/hits-table'
import StatsModal from './components/stats-modal/stats-modal'

type Props = {
  initialData: InitPageData
}

const InboxPage = ({ initialData }: Props) => {
  const queryClient = useQueryClient()

  const USE_SYNCED_STATE_CONFIG = {
    storageKey: 'ric.inbox',
    searchParams: [
      { key: 'keywords', defaultValue: '' },
      {
        key: 'source_id',
        defaultValue: 'all',
        whitelist: ['all', ...initialData.researchSources.map(({ id }) => String(id))],
      },
      {
        key: 'category',
        defaultValue: 'all',
        whitelist: ['all', ...initialData.categories],
      },
      {
        key: 'languages',
        defaultValue: [],
        whitelist: LANGUAGES.map(({ value }) => value),
      },
      { key: 'quality', defaultValue: 'all', whitelist: QUALITY_OPTIONS },
      { key: 'date', defaultValue: null },
      { key: 'time', defaultValue: null, whitelist: Array.from({ length: 24 }, (v, k) => k + 1) },
      { key: 'personalised_inbox', defaultValue: true, initialValue: true, whitelist: [true, false] },
      { key: 'sort', defaultValue: 'created_at' },
      { key: 'page', defaultValue: 1, initialValue: 1 },
    ],
  }

  const { searchParams, syncSearchParams } = useSearchParams(USE_SYNCED_STATE_CONFIG)

  const { setIsLoading } = useAppContext()

  const getHits = useGetHits(searchParams.object as InboxFormValues)
  const updateHits = useUpdateHits()
  const deleteHits = useDeleteHits()
  const unsaveHits = useUnsaveHits()

  const isFetchingHits = getHits.isLoading || getHits.isFetching
  const isDeletingHits = deleteHits.isPending
  const isUpdatingHits = updateHits.isPending

  useEffect(() => {
    getHits.refetch()

    return () => {
      queryClient.cancelQueries({
        queryKey: ['GET_HITS'],
      })
      queryClient.setQueryData(['GET_HITS'], () => {
        return GET_HITS_DEFAULT_DATA
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(async () => {
      await getHits.refetch()
      window.scrollTo(0, 0)
    })
  }, [(searchParams.object as InboxFormValues).page])

  const handleFiltersFormSubmit = async (data: object) => {
    syncSearchParams({ ...data, page: 1 }, false)
    setTimeout(async () => {
      await getHits.refetch()
      window.scrollTo(0, 0)
    })
  }

  const handlePageChanged = (e, page: number) => {
    syncSearchParams({ ...searchParams.object, page }, false)
  }

  const handleQualityChange = async (quality: HitQuality, hitId?: number) => {
    if (getHits.data) {
      const body = getUpdateHitsReqBody(getHits.data.hits, quality, hitId)
      await updateHits.mutateAsync(body)
      if (['irrelevant', null].includes(quality)) {
        const savedHitsIds = body.ids.filter((id) => getHits.data.hits.find((hit) => hit.id === id)?.selectedAt)
        if (savedHitsIds.length) {
          await unsaveHits.mutateAsync(savedHitsIds.map((id) => id.toString()))
        }
      }
    }
  }

  const handleDeleteMarkedHits = async () => {
    if (getHits.data) {
      setIsLoading(true)
      const ids = uniq(getAllMarkedHits(getHits.data.hits, true).map(({ id }) => id))
      await deleteHits.mutateAsync({ ids })
      setTimeout(async () => {
        setIsLoading(false)
        document.getElementById('inbox-filters-form-submit-btn')?.click()
        window.scrollTo(0, 0)
      })
    }
  }

  const handleDeleteAllHits = async () => {
    if (getHits.data) {
      setIsLoading(true)
      await deleteHits.mutateAsync({ ids: getHits.data.allHitIds })
      setIsLoading(false)
      document.getElementById('inbox-filters-form-submit-btn')?.click()
      window.scrollTo(0, 0)
    }
  }

  const hitQualities = useHitQualities(getHits.data, isFetchingHits, updateHits.isError)

  let submitBtnLabel = I18n.t('inbox.filter.buttons.search')
  if (getHits.data) {
    submitBtnLabel += ` (${getHits.data.totalResults})`
  }

  const disableDeleteAllBtn = isEmpty(getHits.data?.allHitIds || [])
  const selectedHitsCount = values(hitQualities.qualities).filter((q) => q).length

  return (
    <>
      <Form config={{ defaultValues: searchParams.object }} onSubmit={handleFiltersFormSubmit}>
        {({ setValue }) => {
          const handleSortChanged = (sort: string) => {
            syncSearchParams({ ...searchParams.object, sort, page: 1 }, false)
            setValue('sort', sort)
            setTimeout(getHits.refetch)
          }

          return (
            <>
              <FiltersForm
                initialData={initialData}
                submitBtnLabel={submitBtnLabel}
                isSubmitting={isFetchingHits}
                isDeleting={isDeletingHits}
                handleDeleteMarkedHits={handleDeleteMarkedHits}
                handleDeleteAllHits={handleDeleteAllHits}
                disableDeleteAllBtn={disableDeleteAllBtn}
                selectedHitsCount={selectedHitsCount}
              />
              {getHits.isSuccess ? (
                <HitsTable
                  initialData={initialData}
                  hitsData={getHits.data}
                  updateHitsData={updateHits.data}
                  isUpdateHitsError={updateHits.isError}
                  isLoading={isFetchingHits}
                  disableSorting={isFetchingHits || isDeletingHits || isUpdatingHits}
                  sort={(searchParams.object as InboxFormValues).sort}
                  hitQualities={hitQualities}
                  handleSortChanged={handleSortChanged}
                  handlePageChanged={handlePageChanged}
                  handleQualityChange={handleQualityChange}
                />
              ) : null}
            </>
          )
        }}
      </Form>
      <StatsModal hitIds={getHits.data?.allHitIds || []} />
    </>
  )
}

export default InboxPage
