import { Box, Stack, Typography } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

type Props = {
  title: string
}

const PageTitle = ({ title }: Props) => {
  return (
    <Box mb={5}>
      <Stack direction={'row'} gap={1} sx={{ my: 2, alignItems: 'center' }}>
        <WarningAmberIcon color="error" />
        <Typography variant="subtitle1" component="h6" color="error">
          This page is for shift leader only
        </Typography>
      </Stack>
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>
    </Box>
  )
}

export default PageTitle
