import { shape, string, number, arrayOf, bool, oneOfType } from 'prop-types'

export const researchSource = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const hit = shape({
  id: number.isRequired,
  time: string,
  keywords: arrayOf(string),
  subject: string.isRequired,
  body: string.isRequired,
  link: string.isRequired,
  score: number,
  language: string.isRequired,
  quality: string,
  hitCount: number,
})

export const recipient = shape({
  firstName: string,
  lastName: string,
  email: string,
  companyName: string,
})

export const message = shape({
  id: number.isRequired,
  subject: string.isRequired,
  sourceContent: string.isRequired,
  body: string.isRequired,
  hyperlinks: arrayOf(string).isRequired,
  targets: arrayOf(shape({ name: string.isRequired })).isRequired,
  radii: arrayOf(string),
  score: number,
  createdAt: string.isRequired,
  createdBy: string.isRequired,
  validUntil: string,
  name: string,
  recipients: arrayOf(recipient),
})

export const bundle = shape({
  id: number.isRequired,
  subject: string.isRequired,
  sourceContent: string.isRequired,
  body: string.isRequired,
  hyperlinks: arrayOf(string).isRequired,
  score: number,
  createdAt: string.isRequired,
  createdBy: string.isRequired,
  validUntil: string,
  messages: arrayOf(
    shape({
      isNews: bool.isRequired,
      name: string,
      targets: arrayOf(shape({ name: string.isRequired })).isRequired,
    })
  ).isRequired,
})

export const indicator = shape({
  id: oneOfType([number, string]).isRequired,
  type: string,
  name: string.isRequired,
  eventType: string,
})

export const location = shape({
  fullName: string,
  name: string.isRequired,
  latitude: number.isRequired,
  longitude: number.isRequired,
})

export const position = shape({
  latitude: number.isRequired,
  longitude: number.isRequired,
})

export const businessPartner = shape({
  id: oneOfType([string, number]).isRequired,
  addtributes: shape({
    name: string.isRequired,
    address: shape({
      city: string.isRequired,
      country: string.isRequired,
    }),
  }),
})

export const exclusionItem = shape({
  id: number,
  name: string,
  alternatives: Array,
  description: string,
  isNotString: string,
  isNotName: Array,
})

export const hitsAssigneeShape = shape({
  id: oneOfType([string, number]),
  uniqueHitsCount: number,
  totalHitsCount: number,
  rangeStart: string,
  rangeEnd: string,
})

export const userShape = shape({
  fullName: string,
  id: oneOfType([number, string]).isRequired,
})
