import PropTypes from 'prop-types'
import I18n from 'i18next'

import * as styles from './styles.scss'

export const LoadingText = (props) => {
  if (props.isLoading) {
    return <div className={styles.loadingText + ' ' + props.customStyle}>{I18n.t('worldmap.loading')}</div>
  }
  return <div />
}

LoadingText.propTypes = {
  isLoading: PropTypes.bool,
  customStyle: PropTypes.string,
}
