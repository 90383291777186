import { isBucketInRange } from './is_bucket_in_range'

export function countHitTypesInRange({ buckets, range }) {
  let uniqueHitsCount = 0
  let totalHitsCount = 0
  let openTotalHitsCount = 0
  let openUniqueHitsCount = 0

  for (const bucket of buckets) {
    const { uniqueCount, uniqueOpenCount = 0, count, openCount = 0 } = bucket
    const isInRange = isBucketInRange({ bucket, range })

    let wasInRange = false

    if (isInRange) {
      wasInRange = true
      uniqueHitsCount += uniqueCount
      openTotalHitsCount += openCount
      openUniqueHitsCount += uniqueOpenCount
      totalHitsCount += count
    } else if (wasInRange) {
      break
    }
  }

  return {
    uniqueHitsCount,
    totalHitsCount,
    openTotalHitsCount,
    openUniqueHitsCount,
  }
}
