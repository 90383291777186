import { putJSON } from '@common/http'

export const ERROR_RECEIVED = 'INDIVIDUAL_SETTINGS_ERROR_RECEIVED'
export const SAVE_SETTINGS = 'SAVE_SETTINGS'

export const errorReceived = (data) => ({
  type: ERROR_RECEIVED,
  data,
})

export const saveSettings = () => ({
  type: SAVE_SETTINGS,
})

export const updateSettings = (settings) => (dispatch) => {
  putJSON('/api/individual_settings', { settings })
    .then(() => {
      dispatch(saveSettings())
    })
    .catch(({ data }) => {
      dispatch(errorReceived(data))
    })
}
