import { useLocation, Link } from 'react-router-dom'
import { AppBar, Toolbar, Tabs } from '@mui/material'

import { ROUTES } from '@common/utils/const'

import Item from './components/item'
import UserMenu from './components/user_menu'

import logo from '@src/client/assets/logo.png'
import * as styles from './navbar.scss'

type Props = {
  routesArr: { path: string; labelKey: string; iconKey: string }[]
  udmBaseUrl: string
}

const Navbar = ({ routesArr, udmBaseUrl }: Props) => {
  const { pathname } = useLocation()

  const onLogoClick = (e) => {
    if (pathname === '/inbox') {
      e.preventDefault()
    }
  }

  const value = `/${pathname.split('/')[1]}`

  if (value === '/') {
    return null
  }

  return (
    <>
      <AppBar
        data-testid="header"
        elevation={0}
        position="relative"
        sx={{ backgroundColor: (theme) => (theme.palette.mode === 'dark' ? undefined : '#e9eaef') }}
      >
        <Toolbar className={styles.appBar}>
          <Link to="/inbox" onClick={onLogoClick}>
            <img height="30px" src={logo} />
          </Link>
          <Tabs
            value={value}
            className={styles.tabs}
            sx={{ pl: 1.75 }}
            TabIndicatorProps={{ sx: { height: '2px' } }}
            indicatorColor="secondary"
          >
            {routesArr.map((routeData) => {
              return (
                <Item
                  key={routeData.path}
                  value={routeData.path}
                  isActive={value === routeData.path}
                  isDistributionItem={routeData.path === ROUTES.SHIFT_MANAGEMENT}
                  {...routeData}
                />
              )
            })}
          </Tabs>
          <UserMenu udmBaseUrl={udmBaseUrl} />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navbar
