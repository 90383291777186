import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import I18n from 'i18next'
import { Stack, Box, Button, Paper, Typography } from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PlaceIcon from '@mui/icons-material/Place'
import FactoryIcon from '@mui/icons-material/Factory'
import WarningIcon from '@mui/icons-material/Warning'
import LayersIcon from '@mui/icons-material/Layers'

import { LoadingText } from '../loading-text/index'

import * as styles from './styles.scss'
import { Form } from '@common/components/form'
import { Toggle } from '@common/components/toggle'
import { ErrorBoundary } from '@common/error_boundary'
import { toggleLayersControlUnitVisibility, updateLayer } from '@pages/world-map/actions'

export class LayerControl extends Component {
  static propTypes = {
    onButtonClick: PropTypes.func,
    layers: PropTypes.object,
    showControlUnit: PropTypes.bool,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  render() {
    return (
      <ErrorBoundary>
        <div className={styles.component}>
          <Button color={this.buttonColor()} sx={{ minWidth: '45px' }} onClick={this.props.onButtonClick}>
            <LayersIcon />
          </Button>
          {this.controlUnit()}
        </div>
      </ErrorBoundary>
    )
  }

  buttonColor() {
    if (Object.keys(this.props.layers).every((k) => this.props.layers[k])) {
      return 'secondary'
    } else {
      return 'primary'
    }
  }

  controlUnit() {
    if (this.props.showControlUnit) {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Paper elevation={8} sx={{ py: 2, px: 3, position: 'absolute', right: 0, top: '50px', zIndex: 1100 }}>
            {this.controlUnitLoadingOverlay()}
            <Typography variant="h6" sx={{ textAlign: 'center' }} gutterBottom>
              {I18n.t('worldmap.layers')}
            </Typography>
            {this.loadingText()}
            <Form
              onChange={this.props.onChange}
              data={this.props.layers}
              namespace="map.layers"
              disabled={this.props.isLoading}
            >
              {this.toggle('supplier')}
              {this.toggle('debitor')}
              {this.toggle('location')}
              {this.toggle('manufacture')}
            </Form>
          </Paper>
        </div>
      )
    }
  }

  icon(name) {
    switch (name) {
      case 'supplier': {
        return <LocalShippingIcon />
      }
      case 'debitor': {
        return <ShoppingCartIcon />
      }
      case 'location': {
        return <PlaceIcon />
      }
      case 'manufacture': {
        return <FactoryIcon />
      }
      default: {
        return <WarningIcon />
      }
    }
  }

  toggle(name) {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Box sx={{ minWidth: '8rem' }}>
          <Toggle name={`${name}s`} />
        </Box>
        <Box sx={{ color: 'text.secondary' }}>{this.icon(name)}</Box>
      </Stack>
    )
  }

  arrowLoadingOverlay() {
    if (this.props.isLoading) {
      return <div></div>
    }
    return <div />
  }

  controlUnitLoadingOverlay() {
    if (this.props.isLoading) {
      return <div className={styles.darkOverlay}></div>
    }
    return <div />
  }

  loadingText() {
    return <LoadingText isLoading={this.props.isLoading} customStyle={styles.loadingText} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: ownProps.isLoading,
    layers: ownProps.layers,
    showControlUnit: ownProps.layersControlUnit,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange(field, value) {
    dispatch(updateLayer(field, value))
  },
  onButtonClick(e) {
    e.stopPropagation()
    dispatch(toggleLayersControlUnitVisibility())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LayerControl)
