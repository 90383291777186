import { useLayoutEffect } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import I18n from 'i18next'

import { useEditIndicatorMessage, useGetIndicatorMessage } from '@pages/im/api'
import { resetData as resetHistoryPageData } from '@pages/history/actions'
import { useIMContext } from '@hooks'
import { ImAction, IndicatorMessageFormValues } from '@types'

import { ImForm } from '../im-form'
import { parseImDataToFormValues } from '../../helpers'

// TODO: remove
import { setImInitState } from '../../actions'
import { errorsUpdating } from '../../actions/update'
import { buildPreloadedState } from '../../utils/build_preloaded_state'

type Props = {
  action: ImAction
  imId: number | null
  defaultValues: IndicatorMessageFormValues | null
}

export const DataIMForm = ({ action, imId }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const store = useStore()

  const { imFormValues, setIMFormValues } = useIMContext()

  const getIMQuery = useGetIndicatorMessage(action, imId)
  const editIMQuery = useEditIndicatorMessage()

  useLayoutEffect(() => {
    if (!getIMQuery?.data || !imFormValues) {
      setIMFormValues(null)
      getIMQuery?.refetch().then((res) => {
        const state = buildPreloadedState(res.data)
        dispatch(setImInitState(state))
        if (res.data) {
          setIMFormValues(parseImDataToFormValues(res.data))
        }
      })
    } else if (imId !== imFormValues.id) {
      setIMFormValues(parseImDataToFormValues(getIMQuery.data))
    }
  }, [])

  const handleAccept = async (data: object) => {
    if (imFormValues && imId) {
      if (action === 'edit') {
        const state = store.getState().indicatorMessage
        const ok = await editIMQuery
          .mutateAsync({ state, imFormValues: data as IndicatorMessageFormValues, id: imId })
          .catch(() => {
            dispatch(errorsUpdating())
          })
        if (ok) {
          setIMFormValues(null)
          dispatch(resetHistoryPageData())
          history.push('/history')
        }
      } else {
        history.push(`${history.location.pathname}/${imFormValues.type}?id=${imId}`)
      }
    }
  }

  const handleCancel = () => {
    setIMFormValues(null)
    switch (action) {
      case 'review':
        return history.push('/review')
      case 'edit':
      case 'copy':
        return history.push('/history')
    }
  }

  const disableValidUntilDatePicker = action === 'edit'
  const disableNoValidityToggle = action === 'edit'
  const disableOnlyNewsToggle = action === 'edit'

  const acceptBtnLabel =
    action === 'edit'
      ? imFormValues?.onlyNews
        ? I18n.t('indicator_message.buttons.update_news')
        : I18n.t('indicator_message.buttons.update_im')
      : I18n.t('indicator_message.buttons.next')

  return (
    <ImForm
      handleAccept={handleAccept}
      handleCancel={handleCancel}
      acceptBtnLabel={acceptBtnLabel}
      submitOnAccept={action === 'edit'}
      isLoading={!getIMQuery?.data || getIMQuery?.isLoading || getIMQuery?.isFetching}
      disableTypeSelect
      disableValidUntilDatePicker={disableValidUntilDatePicker}
      disableNoValidityToggle={disableNoValidityToggle}
      disableOnlyNewsToggle={disableOnlyNewsToggle}
    />
  )
}
