import { camelCase, values } from 'lodash'

import { startLoading, endLoading, setLoadingTotalPages, incrementLoadingPage } from './loading'
import { getJSON } from '@common/http'

export * from './filters'
export * from './search'

export const CLEAR_LAYER = 'CLEAR_LAYER'
export const DEBITORS_RECEIVED = 'DEBITORS_RECEIVED'
export const EVENTS_RECEIVED = 'EVENTS_RECEIVED'
export const FITTED_BOUNDS = 'FITTED_BOUNDS'
export const FIT_BOUNDS = 'FIT_BOUNDS'
export const HIDE_CONTROL_UNITS = 'HIDE_CONTROL_UNITS'
export const LOCATIONS_RECEIVED = 'LOCATIONS_RECEIVED'
export const MANUFACTURES_RECEIVED = 'MANUFACTURES_RECEIVED'
export const MAP_UPDATED = 'WORLD_MAP_UPDATED'
export const SUPPLIERS_RECEIVED = 'SUPPLIERS_RECEIVED'
export const SUPPLY_PATHS_RECEIVED = 'SUPPLY_PATHS_RECEIVED'
export const TOGGLE_LAYERS_CONTROL_UNIT_VISIBILITY = 'TOGGLE_LAYERS_CONTROL_UNIT_VISIBILITY'
export const UPDATE_FEATURES = 'UPDATE_FEATURES'
export const UPDATE_MAP = 'UPDATE_MAP'
export const BOUNDS_CHANGED = 'BOUNDS_CHANGED'
export const ZOOM_CHANGED = 'ZOOM_CHANGED'

export const clearLayer = (data) => ({
  type: CLEAR_LAYER,
  data,
})

export const hideControlUnits = () => ({
  type: HIDE_CONTROL_UNITS,
})

export const toggleLayersControlUnitVisibility = () => ({
  type: TOGGLE_LAYERS_CONTROL_UNIT_VISIBILITY,
})

export const debitorsReceived = (debitors) => ({
  type: DEBITORS_RECEIVED,
  debitors,
})

export const boundsChanged = (bounds) => ({
  type: BOUNDS_CHANGED,
  bounds,
})

export const zoomChanged = (zoom) => ({
  type: ZOOM_CHANGED,
  zoom,
})

export const eventsReceived = (events) => ({
  type: EVENTS_RECEIVED,
  events,
})

export const locationsReceived = (locations) => ({
  type: LOCATIONS_RECEIVED,
  locations,
})

export const manufacturesReceived = (manufactures) => ({
  type: MANUFACTURES_RECEIVED,
  manufactures,
})

export const suppliersReceived = (suppliers) => ({
  type: SUPPLIERS_RECEIVED,
  suppliers,
})

export const updateFeatures = () => ({
  type: UPDATE_FEATURES,
})

export const fitBounds = () => ({
  type: FIT_BOUNDS,
})

export const fittedBounds = () => ({
  type: FITTED_BOUNDS,
})

export const updateMap = () => ({
  type: UPDATE_MAP,
})

export const mapUpdated = () => ({
  type: MAP_UPDATED,
})

export const init = (layerFilter, bounds) => (dispatch) => {
  return requestAll('events', dispatch, layerFilter, bounds).then(() => {
    dispatch(updateMap())
    dispatch(endLoading())
  })
}

export const filterData = () => (dispatch) => {
  dispatch(requestData()).then(() => {
    dispatch(fitBounds())
  })
}

export const requestData = (layerFilter, layers, bounds) => (dispatch) => {
  if (checkAllFiltersDisabled(layers)) {
    dispatch(updateFeatures())
    dispatch(updateMap())
    return Promise.resolve()
  }
  return requestAll('suppliers', dispatch, layerFilter, bounds)
    .then(() => requestAll('debitors', dispatch, layerFilter, bounds))
    .then(() => requestAll('manufactures', dispatch, layerFilter, bounds))
    .then(() => requestAll('locations', dispatch, layerFilter, bounds))
    .then(() => {
      dispatch(updateMap())
      dispatch(endLoading())
    })
}

const checkAllFiltersDisabled = (layers) => {
  return values(layers).every((item) => {
    return item === false
  })
}

const requestAll = (field, dispatch, layerFilter, bounds) => {
  const layer = camelCase(field)
  if (layerFilter[layer]) {
    const url = `/api/worldmap/${field}`
    const params = {
      per_page: 1000, // eslint-disable-line camelcase
      page: 1,
      boundingBox: bounds,
    }
    const method = eval(`${camelCase(field)}Received`)
    dispatch(clearLayer(camelCase(field)))
    const jsonDataHandler = (json) => {
      dispatch(method(json.data))
      dispatch(updateFeatures())
    }
    dispatch(startLoading(field, true))
    return new Promise((resolve) => requestAllRecords(url, params, dispatch, jsonDataHandler, resolve))
  } else {
    return Promise.resolve()
  }
}

const requestAllRecords = (url, params, dispatch, jsonDataHandler, resolve) => {
  requestPage(url, params, dispatch, jsonDataHandler, resolve, handleFirstPage)
}

const requestPage = (url, params, dispatch, jsonDataHandler, resolve, pageHandler) => {
  return getJSON(url, params)
    .then((response) => {
      return response.data
    })
    .then((json) => {
      pageHandler(url, params, dispatch, jsonDataHandler, resolve, json)
    })
}

const handleFirstPage = (url, params, dispatch, jsonDataHandler, resolve, json) => {
  dispatch(setLoadingTotalPages(json.meta.totalPages))
  const requestPagePromises = []
  for (let i = json.meta.page; i < json.meta.totalPages; i++) {
    const newParams = { ...params, page: i + 1 }
    requestPagePromises.push(requestPage(url, newParams, dispatch, jsonDataHandler, resolve, handleSubsequentPages))
  }
  handleResponse(dispatch, jsonDataHandler, json)
  Promise.all(requestPagePromises).then(() => {
    resolve()
  })
}

const handleSubsequentPages = (url, params, dispatch, jsonDataHandler, resolve, json) => {
  handleResponse(dispatch, jsonDataHandler, json)
}

const handleResponse = (dispatch, jsonDataHandler, json) => {
  dispatch(incrementLoadingPage())
  jsonDataHandler(json)
}
