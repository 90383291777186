import { map, omit, flatMap } from 'lodash'
import I18n from 'i18next'

import {
  LOAD_DATA,
  DATA_RECEIVED,
  INDEX_ERROR_RECEIVED,
  SUBMIT_ERROR_RECEIVED,
  CLEAR_SUBMIT_ERRORS,
  EXCLUSION_ITEMS_FETCHED,
  OPEN_ADD_COMPANY_MODAL,
  CLOSE_ADD_COMPANY_MODAL,
  UPDATE_FORM,
  CLEAR_FORM,
  SET_EXPANDED,
} from '../actions'

import { SET_PAGE, SET_SORT, SET_FILTERS } from '@common/actions/table'

export const initialState = {
  data: { items: [] },
  filters: {},
  table: {
    loading: false,
    page: 1,
    perPage: 100,
    totalResults: 0,
    sort: {
      field: 'name',
      order: 'asc',
    },
    data: {
      items: [],
    },
    expanded: {},
  },
  modals: {
    addCompany: {
      opened: false,
    },
  },
  form: {
    formControls: {
      name: {
        value: '',
      },
      alternatives: {
        value: [],
      },
      description: {
        value: '',
      },
      isNotString: {
        value: '',
      },
      isNotName: {
        value: [],
      },
    },
    formErrors: {},
  },
  errors: [],
}

export function reducer(state = initialState, { type, data }) {
  switch (type) {
    case EXCLUSION_ITEMS_FETCHED: {
      return { ...state, data: { ...state.data, ...{ items: data } } }
    }
    case LOAD_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      }
    }
    case DATA_RECEIVED: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: data.totalResults,
          data: {
            items: data.shitItems,
          },
          keywords: state.filters.keywords,
        },
        errors: [],
      }
    }
    case INDEX_ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: 0,
          data: {
            items: [],
          },
          errors: [I18n.t('common.messages.unable_to_retrieve_data')],
        },
      }

    case SUBMIT_ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            items: map(state.table.data.bundles, (item) => omit(item, ['freezed'])),
          },
        },
        errors: flatMap(data.errors, (errors) => errors),
      }
    case CLEAR_SUBMIT_ERRORS:
      return {
        ...state,
        errors: [],
      }

    case `${SET_PAGE}_ELIST`:
      return {
        ...state,
        table: {
          ...state.table,
          page: data.page,
        },
      }
    case `${SET_SORT}_ELIST`:
      return {
        ...state,
        table: {
          ...state.table,
          sort: { field: data.sortField, order: data.sortOrder },
        },
      }
    case `${SET_FILTERS}_ELIST`:
      return {
        ...state,
        filters: { ...state.filters, ...data },
      }
    case OPEN_ADD_COMPANY_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, addCompany: { opened: true } },
      }
    }
    case CLOSE_ADD_COMPANY_MODAL: {
      return {
        ...state,
        modals: { ...state.modals, addCompany: { opened: false } },
        form: initialState.form,
      }
    }
    case UPDATE_FORM: {
      return {
        ...state,
        form: {
          formControls: data.name
            ? { ...state.form.formControls, [data.name]: { value: data.value } }
            : state.form.formControls,
          formErrors: data.formErrors,
        },
      }
    }
    case CLEAR_FORM: {
      return {
        ...state,
        form: initialState.form,
      }
    }
    case SET_EXPANDED:
      return {
        ...state,
        table: {
          ...state.table,
          expanded: data,
        },
      }
    default:
      return state
  }
}
