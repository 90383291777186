import I18n from 'i18next'

import { INDICATORS_FETCHED, RESET_DATA } from '../actions'

import {
  UPDATE_MESSAGE,
  LOAD_DATA,
  DATA_RECEIVED,
  ERROR_RECEIVED,
  RECIPIENTS_DATA_RECEIVED,
  DELETE_NEWS_REQUESTED,
  DELETE_NEWS_FAILED,
} from '@common/entities/messages/actions'

import { SET_PAGE, SET_FILTERS, SET_SORT } from '@common/actions/table'
import { joinRecipients } from '@common/entities/messages/utils'

export const initialState = {
  data: { indicators: [] },
  filters: {
    queryStrategy: 'subject_body',
  },
  table: {
    loading: false,
    page: 1,
    totalResults: 0,
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
    data: {
      messages: [],
    },
  },
  errors: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case INDICATORS_FETCHED: {
      return { ...state, data: { ...state.data, ...{ indicators: action.data } } }
    }
    case LOAD_DATA:
    case DELETE_NEWS_REQUESTED: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      }
    }
    case DATA_RECEIVED: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: action.data.totalResults,
          data: {
            messages: action.data.messages,
          },
          keywords: state.filters.keywords,
          queryStrategy: state.filters.queryStrategy,
        },
        errors: [],
      }
    }
    case ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: 0,
          data: {
            messages: [],
          },
        },
        errors: [I18n.t('common.messages.unable_to_retrieve_data')],
      }
    case `${SET_PAGE}_HISTORY`:
      return {
        ...state,
        table: {
          ...state.table,
          page: action.data.page,
        },
      }
    case `${SET_SORT}_HISTORY`:
      return {
        ...state,
        table: {
          ...state.table,
          sort: {
            field: action.data.sortField,
            order: action.data.sortOrder,
          },
        },
      }
    case `${SET_FILTERS}_HISTORY`:
      return {
        ...state,
        filters: { ...state.filters, ...action.data },
      }
    case UPDATE_MESSAGE: {
      if (action.metaData.status === 'success') {
        return updateMessage(state, action)
      } else {
        return state
      }
    }
    case RECIPIENTS_DATA_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            messages: joinRecipients(state.table.data.messages, action.data.messagesRecipients),
          },
        },
      }
    case DELETE_NEWS_FAILED:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
        },
        errors: [I18n.t('history.table.news.delete.fail')],
      }
    case RESET_DATA:
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            messages: [],
          },
        },
      }
    default:
      return state
  }
}

const updateMessage = (state, action) => {
  const { id, attributes } = action.payload

  return {
    ...state,
    table: {
      ...state.table,
      data: {
        ...state.table.data,
        messages: state.table.data.messages.map((message) => {
          return message.id === id ? { ...message, ...attributes } : message
        }),
      },
    },
  }
}
