import { useQuery } from '@tanstack/react-query'

import { fetch } from '@services'

type AAStaticData = {
  styles: {
    light: string
    dark: string
  }
}

const queryFn = async (): Promise<AAStaticData> => {
  const res = await fetch('/static-data')
  return await res.json()
}

const useGetAAStaticData = () => {
  return useQuery<AAStaticData>({
    queryKey: ['GET_AA_STATIC_DATA'],
    queryFn,
    retry: false,
    enabled: true,
    staleTime: Infinity,
  })
}

export default useGetAAStaticData
