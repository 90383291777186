import I18n from 'i18next'
import { Button, Tooltip, IconButton } from '@mui/material'
import TextareaAutosize from 'react-autosize-textarea'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { showMoreFormatter } from '@common/table_formatters/show_more_formatter/show_more_formatter'

import * as styles from '../../e-list-page.scss'

export const nameFormatter = (cell, item, rowIndex, formatExtraData) => {
  return formatExtraData.editMode[rowIndex] ? (
    editNameFormatter(cell, item, rowIndex, formatExtraData)
  ) : (
    <div className={styles.name}>{cell}</div>
  )
}

export const alternativesFormatter = (cell, item, rowIndex, formatExtraData) => {
  return formatExtraData.editMode[rowIndex] ? (
    editAlternativesFormatter(cell, item, rowIndex, formatExtraData)
  ) : (
    <div className={styles.alternatives}>
      <ul>
        {cell.some((e) => e.length > 0) &&
          cell.map((name) => (
            <li key={name}>
              {name}
              <br />{' '}
            </li>
          ))}
      </ul>
    </div>
  )
}

export const descriptionsFormatter = (cell, item, rowIndex, formatExtraData) => {
  return formatExtraData.editMode[rowIndex] ? (
    editDescriptionsFormatter(cell, item, rowIndex, formatExtraData)
  ) : (
    <div className={styles.description}>{cell}</div>
  )
}

export const isNotStringFormatter = (cell, item, rowIndex, formatExtraData) => {
  return formatExtraData.editMode[rowIndex] ? (
    editIsNotStringFormatter(cell, item, rowIndex, formatExtraData)
  ) : (
    <div className={styles.isNotString}>{cell}</div>
  )
}

export const isNotNameFormatter = (cell, item, rowIndex, formatExtraData) => {
  const notEmptyCell = cell.some((e) => e.length > 0)
  return formatExtraData.editMode[rowIndex] ? (
    editIsNotNameFormatter(cell, item, rowIndex, formatExtraData)
  ) : (
    <div className={styles.isNotName}>{notEmptyCell && showMoreFormatter(cell, rowIndex, formatExtraData)}</div>
  )
}

export const editNameFormatter = (cell, item, rowIndex, formatExtraData) => (
  <TextareaAutosize
    className={styles.name}
    data-testid={`edit_name_${rowIndex}`}
    defaultValue={cell}
    onChange={(e) => formatExtraData.handleInputChange('name', e.target.value)}
    rows={formatExtraData.rows}
  />
)

export const editAlternativesFormatter = (cell, item, rowIndex, formatExtraData) => (
  <TextareaAutosize
    className={styles.alternatives}
    data-testid={`edit_alternatives_${rowIndex}`}
    defaultValue={cell.join('\n')}
    onChange={(e) => formatExtraData.handleInputChange('alternatives', e.target.value.split('\n'))}
    rows={formatExtraData.rows}
  />
)

export const editDescriptionsFormatter = (cell, item, rowIndex, formatExtraData) => (
  <TextareaAutosize
    className={styles.description}
    data-testid={`edit_description_${rowIndex}`}
    defaultValue={cell}
    onChange={(e) => formatExtraData.handleInputChange('description', e.target.value)}
    rows={formatExtraData.rows}
  />
)

export const editIsNotStringFormatter = (cell, item, rowIndex, formatExtraData) => (
  <TextareaAutosize
    className={styles.isNotString}
    data-testid={`edit_isnotstring_${rowIndex}`}
    defaultValue={cell}
    onChange={(e) => formatExtraData.handleInputChange('isNotString', e.target.value)}
    rows={formatExtraData.rows}
  />
)

export const editIsNotNameFormatter = (cell, item, rowIndex, formatExtraData) => (
  <TextareaAutosize
    className={styles.isNotName}
    data-testid={`edit_isnotname_${rowIndex}`}
    defaultValue={cell.join('\n')}
    onChange={(e) => formatExtraData.handleInputChange('isNotName', e.target.value.split('\n'))}
    rows={formatExtraData.rows}
  />
)

const actionsEditModeFormatter = (_cell, item, rowIndex, formatExtraData) => (
  <div>
    <Button
      color="success"
      variant="text"
      onClick={(e) => formatExtraData.onSaveClick(e, rowIndex)}
      data-testid="update_exclusion_item"
    >
      Save
    </Button>
    <br />
    <Button
      color="warning"
      variant="text"
      onClick={(e) => formatExtraData.onCancelClick(e, rowIndex)}
      data-testid="cancel_edit"
    >
      Cancel
    </Button>
  </div>
)

const actionsNonEditModeFormatter = (_cell, item, rowIndex, formatExtraData) => (
  <div className="inline btn-group">
    <IconButton edge="start" color="info" onClick={(e) => formatExtraData.onEditClick(e, item, rowIndex)}>
      <Tooltip title={I18n.t('common.exclusion_items_table.buttons.edit')}>
        <EditIcon fontSize="small" id={`row_edit_${rowIndex}`} />
      </Tooltip>
    </IconButton>
    <IconButton color="error" onClick={(e) => formatExtraData.onDelete(e, item)} data-testid="delete_news">
      <Tooltip title={I18n.t('common.exclusion_items_table.buttons.delete')}>
        <DeleteIcon fontSize="small" id={`row_${rowIndex}`} />
      </Tooltip>
    </IconButton>
  </div>
)

export const actionsFormatter = (_cell, item, rowIndex, formatExtraData) =>
  formatExtraData.editMode[rowIndex]
    ? actionsEditModeFormatter(_cell, item, rowIndex, formatExtraData)
    : actionsNonEditModeFormatter(_cell, item, rowIndex, formatExtraData)
