import { useLayoutEffect, useState } from 'react'
import values from 'lodash/values'

import { getUpdateHitsReqBody } from '@pages/inbox/helpers'
import type { HitQuality, HitsData } from '@types'

export type UseHitQualitiesReturn = {
  qualities: Record<number, HitQuality>
  areAllHitsGreat: boolean
  areAllHitsIrrelevant: boolean
  areAllHitsRated: boolean
  onQualityCheckboxClick: (quality: HitQuality, hitId?: number) => void
  resetQualities: () => void
}

const useHitQualities = (
  hitsData: HitsData | undefined,
  isFetchingHits: boolean,
  isUpdateHitsError: boolean
): UseHitQualitiesReturn => {
  const [qualities, setQualities] = useState<Record<number, HitQuality>>({})

  const areAllHitsGreat = !values(qualities).some((q) => q !== 'great')
  const areAllHitsIrrelevant = !values(qualities).some((q) => q !== 'irrelevant')
  const areAllHitsRated = !values(qualities).some((q) => !q)

  const resetQualities = () => {
    const newQualities = {}
    for (const hit of hitsData?.hits || []) {
      newQualities[hit.id] = hit.quality
    }
    setQualities(newQualities)
  }

  useLayoutEffect(() => {
    if (isUpdateHitsError || !isFetchingHits) {
      resetQualities()
    }
  }, [isUpdateHitsError, isFetchingHits])

  const onQualityCheckboxClick = (quality: HitQuality, hitId?: number) => {
    if (hitId) {
      setQualities({ ...qualities, [hitId]: qualities[hitId] === quality ? null : quality })
    } else if (hitsData?.hits) {
      const newQualities = { ...qualities }
      const body = getUpdateHitsReqBody(hitsData?.hits, quality)

      for (const id of body.ids) {
        newQualities[id] = body.quality
      }

      setQualities(newQualities)
    }
  }

  return {
    qualities,
    areAllHitsGreat,
    areAllHitsIrrelevant,
    areAllHitsRated,
    onQualityCheckboxClick,
    resetQualities,
  }
}

export default useHitQualities
