import { Typography } from '@mui/material'

import { findHitKeywords } from '@pages/inbox/helpers'

type Props = {
  text: string
  keywords: string[]
}

const HitHighlightedText = ({ text, keywords }: Props) => {
  const chunks = findHitKeywords(text, keywords)

  return chunks.map(({ content, keyword }, index: number) => {
    if (keyword) {
      return (
        <Typography
          key={index}
          component="span"
          sx={{
            fontWeight: 'bold',
            color: (theme) => (theme.palette.mode === 'light' ? 'inherit' : 'warning.dark'),
          }}
          data-testid="keyword"
        >
          {content}
        </Typography>
      )
    }

    return content
  })
}

export default HitHighlightedText
