import { HitData } from '@types'

import HighlightedText from '../hit-highlighted-text/hit-highlighted-text'

import * as styles from '../../hits-content.scss'

type HitBodyProps = {
  hitData: HitData
  isNativeLanguage: boolean
  isInsolvency: boolean
  noTranslation: boolean
}

type RenderTextProps = {
  text: string
  hitSentence: string
  keywords: string[]
}

const RenderText = ({ text, hitSentence, keywords }: RenderTextProps) => {
  return (
    <div>
      {text && <div>{<HighlightedText text={text} keywords={keywords} />}</div>}
      {hitSentence && (
        <div className={styles['hit-text__sentence']}>{<HighlightedText text={hitSentence} keywords={keywords} />}</div>
      )}
    </div>
  )
}

const HitBody = ({ hitData, isNativeLanguage, isInsolvency, noTranslation }: HitBodyProps) => {
  const { body, translatedBody, searchNames, newKeywords, translatedKeywords } = hitData

  const [openingText, ...hitSentence] = (body || '').replace('\n\n', ' ').split('\\n\\n')
  const [translatedOpeningText, ...translatedHitSentence] = (translatedBody || '').replace('\n\n', ' ').split('\\n\\n')

  const renderOriginalText = (isNativeLanguage && isInsolvency) || (isNativeLanguage && !noTranslation) || noTranslation

  if (renderOriginalText) {
    return (
      <RenderText text={openingText} hitSentence={hitSentence.join(' ')} keywords={searchNames.concat(newKeywords)} />
    )
  }

  return (
    <RenderText
      text={translatedOpeningText}
      hitSentence={translatedHitSentence.join(' ')}
      keywords={searchNames.concat(translatedKeywords)}
    />
  )
}

export default HitBody
