import { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import I18n from 'i18next'
import { Field, Input, Select, MultiSelect, Toggle, DatePicker, NumberInput } from '@riskmethods/rm-front-end'

import { confirm } from '@common/components/alert'
import { LANGUAGES, QUALITY_OPTIONS } from '@pages/inbox/constants'
import type { InitPageData } from '@types'

import { ActionBtns } from './components'

type Props = {
  initialData: InitPageData
  submitBtnLabel: string
  isSubmitting: boolean
  isDeleting: boolean
  handleDeleteMarkedHits: () => void
  handleDeleteAllHits: () => void
  disableDeleteAllBtn: boolean
  selectedHitsCount: number
}

const confirmPersonilizedInboxChange = () => {
  return confirm({
    title: 'Personal inbox',
    body: 'Do you really want to turn on/off personalised inbox?',
    proceedLabel: 'Yes',
    isDeleteDialog: true,
    isFullWidth: false,
  })
}

export const FiltersForm = (props: Props) => {
  const {
    initialData,
    submitBtnLabel,
    isSubmitting,
    isDeleting,
    handleDeleteAllHits,
    handleDeleteMarkedHits,
    disableDeleteAllBtn,
    selectedHitsCount,
  } = props

  if (!initialData) {
    return null
  }

  const QUALITY_SELECT_OPTIONS = useMemo(() => {
    return [
      { value: QUALITY_OPTIONS[0], label: I18n.t('inbox.filter.selects.quality.all') },
      { value: QUALITY_OPTIONS[1], label: I18n.t('inbox.filter.selects.quality.great') },
      { value: QUALITY_OPTIONS[2], label: I18n.t('inbox.filter.selects.quality.unrated') },
    ]
  }, [])

  return (
    <Box
      data-testid="inbox_filters"
      position="sticky"
      top={0}
      zIndex={2}
      mt={1}
      px={2}
      py={1.25}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Stack direction="row" flexWrap="wrap" alignItems="flex-end" gap={1.25}>
        <Box width="225px">
          <Field
            name="keywords"
            label="Search"
            Component={(props) => (
              <Input testId="keywords" enableClearBtn textFieldProps={{ placeholder: '...' }} {...props} />
            )}
          />
        </Box>
        <Box width="180px">
          <Field
            name="source_id"
            label="Search in"
            Component={(props) => (
              <Select
                testId="query_source_select"
                options={[
                  { value: 'all', label: I18n.t('inbox.filter.selects.source.all') },
                  ...(initialData.researchSources.map(({ id, name }) => ({ value: String(id), label: name })) || []),
                ]}
                {...props}
              />
            )}
          />
        </Box>
        <Box width="250px">
          <Field
            name="category"
            label="Category"
            Component={(props) => (
              <Select
                testId="query_category_select"
                options={[
                  { value: 'all', label: I18n.t('inbox.filter.selects.category.all') },
                  ...(initialData.categories.map((category) => ({ value: category, label: category })) || []),
                ]}
                {...props}
              />
            )}
          />
        </Box>
        <Box width="150px">
          <Field
            name="languages"
            label="Languages"
            Component={(props) => (
              <MultiSelect testId="query_languages_select" options={LANGUAGES} noSelectionText="All" {...props} />
            )}
          />
        </Box>
        <Box width="135px">
          <Field
            name="quality"
            label="Quality"
            Component={(props) => <Select testId="query_quality_select" options={QUALITY_SELECT_OPTIONS} {...props} />}
          />
        </Box>
        <Box width="250px">
          <Stack direction="row">
            <Field
              name="date"
              label="Date"
              Component={(props) => (
                <DatePicker
                  testId="query_date_picker"
                  desktopDatePickerProps={{
                    minDate: new Date('2000-01-01'),
                    disableFuture: true,
                  }}
                  enableClearBtn
                  {...props}
                />
              )}
              validationRules={{
                date: true,
              }}
            />
            <Field
              name="time"
              label="Until"
              Component={(props) => (
                <NumberInput
                  testId="query_time_input"
                  min={1}
                  max={24}
                  textFieldProps={{ placeholder: 'hh' }}
                  {...props}
                />
              )}
            />
          </Stack>
        </Box>
        <Box>
          <LoadingButton
            id="inbox-filters-form-submit-btn"
            type="submit"
            variant="contained"
            color="secondary"
            data-testid="search"
            loading={isSubmitting}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {submitBtnLabel}
          </LoadingButton>
        </Box>
        <Box width="70px">
          <Field
            name="personalised_inbox"
            Component={(props) => (
              <Toggle
                testId="personalised_inbox"
                label="Personalized Inbox"
                asSwitch
                {...props}
                onChange={(value) => {
                  confirmPersonilizedInboxChange().then(
                    () => {
                      props.onChange(value)
                      document.getElementById('inbox-filters-form-submit-btn')?.click()
                    },
                    () => null
                  )
                }}
              />
            )}
          />
        </Box>
        <Box flex="1">
          <ActionBtns
            selectedHitsCount={selectedHitsCount}
            disableDeleteBtn={isSubmitting}
            disableDeleteAllBtn={disableDeleteAllBtn}
            isDeleting={isDeleting}
            handleDeleteAllHits={handleDeleteAllHits}
            handleDeleteMarkedHits={handleDeleteMarkedHits}
          />
        </Box>
      </Stack>
    </Box>
  )
}

export default FiltersForm
