import { toast } from 'react-toastify'

import { REQ_TIMEOUT_MS } from '@services'

const options = { style: { color: 'black' } }

export const displayError = (p1: string, p2 = '') => {
  toast.error(
    <>
      <p>{p1}</p>
      {p2 ? <p>{p2}</p> : null}
    </>,
    options
  )
}

const errorHandler = async (err: Error) => {
  if (err) {
    if (err.message === 'Unauthorized') {
      localStorage.removeItem('ric.user')
      location.href = '/'
    } else if (err.name === 'AbortError') {
      displayError(`Request timeout (${REQ_TIMEOUT_MS} ms)`)
    } else if (err.name && err.message) {
      displayError(err.name, err.message)
    }
  } else {
    displayError('Something went wrong')
  }
}

export default errorHandler
