// Map initialization constants
export const LAT = 48.1351
export const LNG = 11.582
export const MIN_ZOOM = 2
export const LIMIT_LOADING_ZOOM = 6

// InfoWindows offsets
export const DEFAULT_IW_OFFSET = -2
export const SUPPLY_PATH_IW_OFFSET = -3
export const EVENT_IW_OFFSET = -20
