import { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18next'

import {
  Box,
  Stack,
  Paper,
  Button,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@mui/material'

import Tags from '@common/components/tags'
import { businessPartner, indicator } from '@common/utils/shapes'

import FormItem from '../../../shared/form-item/form-item'
import SearchLabels from '../../components/search-labels/search-labels'
import SupplierSearchResult from '../../components/supplier-search-result/supplier-search-result'
import CitiesSelect from '../../components/cities-select/cities-select'
import CountriesSelect from '../../components/countries-select/countries-select'
import { processSearchTerm } from '../../helpers'

import * as styles from '../../bp-im-form.scss'

const t = (name) => I18n.t(`indicator_message.form.supplier.${name}`)
const placeholdersT = (name) => I18n.t(`indicator_message.placeholders.${name}`)

export default class SupplierAlertForm extends Component {
  static propTypes = {
    formChanged: PropTypes.func.isRequired,
    formSearch: PropTypes.func.isRequired,
    removeSupplier: PropTypes.func.isRequired,
    indicators: PropTypes.arrayOf(PropTypes.object),
    indicatorsLoading: PropTypes.bool.isRequired,
    indicatorSelected: PropTypes.func.isRequired,
    selectedIndicator: indicator,
    countries: PropTypes.arrayOf(PropTypes.object),
    clearForm: PropTypes.func.isRequired,
    formData: PropTypes.shape({
      includeNames: PropTypes.arrayOf(PropTypes.object),
      excludeNames: PropTypes.arrayOf(PropTypes.object),
      countries: PropTypes.arrayOf(PropTypes.object),
      cities: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    suppliers: PropTypes.arrayOf(businessPartner),
    suppliersLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    searches: PropTypes.arrayOf(PropTypes.object),
    removeSearch: PropTypes.func.isRequired,
    industries: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    errors: {},
  }

  onChange = (name) => {
    return ({ target: { value } }) => {
      this.props.formChanged(name, value)
    }
  }

  onSelectChange = (name) => (value) => {
    this.props.formChanged(name, value)
  }

  onTagsChange = (name) => (value) => {
    const processedValue = processSearchTerm(value)
    this.props.formChanged(name, processedValue)
  }

  onIndicatorsChange(event) {
    const { value } = event.target
    const selectedIndicator = this.props.indicators.find((indicator) => indicator.id === value)

    this.props.indicatorSelected(selectedIndicator)
  }

  onIndustryChange({ target: { value } }) {
    const selectedIndustryValue = this.props.industries.find((industry) => industry.id === value)

    this.props.industrySelected(selectedIndustryValue)
  }

  render() {
    const {
      indicators,
      indicatorsLoading,
      selectedIndicator,
      suppliers,
      suppliersLoading,
      searches,
      removeSearch,
      countries,
      formData,
      clearForm,
      removeSupplier,
      formSearch,
      errors,
    } = this.props
    return (
      <Stack
        flex={1}
        noValidate
        component="form"
        autoComplete="off" // disable countries auto fill
        onSubmit={(ev) => ev.preventDefault()}
        sx={{ maxWidth: '800px' }}
        data-testid="alert_supplier_form"
      >
        <Typography variant="h5" component="h3" sx={{ mb: 2 }} textAlign={'center'}>
          {t('titles.alert')}
        </Typography>
        <Stack
          component={Paper}
          id="alert_form"
          elevation={4}
          sx={{
            overflow: 'hidden',
            width: '100%',
            flexShrink: 0,
            flexGrow: 1,
            position: 'relative',
            backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#e8ebf0' : '#222222'),
          }}
          p={3}
          gap={2}
        >
          <FormItem label={t('labels.indicator')}>
            <TextField
              select
              required
              fullWidth
              sx={{ backgroundColor: 'background.default' }}
              value={selectedIndicator?.id ?? ''}
              label={placeholdersT('indicator')}
              onChange={(ev) => this.onIndicatorsChange(ev)}
              InputProps={
                indicatorsLoading
                  ? {
                      endAdornment: (
                        <InputAdornment position="start" sx={{ pr: 1 }}>
                          <CircularProgress color="secondary" size={25} />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              error={!!errors.noIndicator}
              helperText={errors.noIndicator}
              data-testid="alert_indicator_select"
            >
              {(indicators || []).map((option) => (
                <MenuItem key={option.id} value={option.id} data-testid={`alert_indicator_${option.id}`}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </FormItem>
          <FormItem label={t('labels.search')}>
            <Tags
              type="text"
              onChange={this.onTagsChange('includeNames')}
              placeholder={t('placeholders.search')}
              value={formData.includeNames || []}
              isClearable={false}
              data-testid="alert_include_names"
              onEnterPressed={formSearch}
              height={37}
              maxHeight={150}
            />
          </FormItem>
          <FormItem label={t('labels.is_not')}>
            <Tags
              type="text"
              onChange={this.onTagsChange('excludeNames')}
              placeholder={t('placeholders.is_not')}
              value={formData.excludeNames || []}
              isClearable={false}
              data-testid="alert_exclude_names"
              onEnterPressed={formSearch}
              height={37}
              maxHeight={150}
            />
          </FormItem>
          <FormItem label={t('labels.country')}>
            <CountriesSelect
              countries={countries}
              value={formData.countries || []}
              onChange={this.onSelectChange('countries')}
              label={placeholdersT('countries')}
              formType="alert"
            />
          </FormItem>
          <FormItem label={t('labels.city')}>
            <CitiesSelect formType="alert" value={formData.cities || []} onChange={this.onSelectChange('cities')} />
          </FormItem>
          <Box textAlign={'right'}>
            <Button
              onClick={formSearch}
              type="button"
              color="secondary"
              disabled={suppliersLoading}
              data-testid="alert_search"
            >
              {t(suppliersLoading ? 'info.loading' : 'buttons.search')}
            </Button>
          </Box>
          <Stack flex={1} justifyContent={'end'} data-testid="alert_suppliers_result_cont">
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 1 }} gap={1} textAlign={'right'}>
              <SearchLabels formType="alert" searches={searches} removeSearch={removeSearch} suppliers={suppliers} />
              <Box alignSelf={'end'}>
                <Button
                  color="error"
                  variant="text"
                  type="button"
                  onClick={clearForm}
                  className={styles.removeAll}
                  data-testid="alert_remove_all"
                >
                  {t('buttons.remove_all')}
                </Button>
              </Box>
            </Stack>
            <SupplierSearchResult
              formType="alert"
              suppliers={suppliers}
              suppliersLoading={suppliersLoading}
              removeSupplier={removeSupplier}
            />
          </Stack>
        </Stack>
      </Stack>
    )
  }
}
