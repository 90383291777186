import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch } from '@services'
import type { GetInitPageDataSchema, InitPageData } from '@types'

const DEFAULT_DATA: InitPageData = {
  categories: [],
  researchSources: [],
  userSettings: {},
  udmBaseUrl: '',
  currentUser: { admin: false, inboxLinksDisabled: true, articleAnalyzerUser: false, id: '' },
}

const transformData = (data: GetInitPageDataSchema | null): InitPageData => {
  if (!data) {
    return DEFAULT_DATA
  }

  return camelizeKeys<GetInitPageDataSchema>(data)
}

const queryFn = async (): Promise<InitPageData> => {
  let data: GetInitPageDataSchema | null = null

  const res = await fetch('/api/v2/main')

  if (res.ok) {
    data = await res.json()
  }

  return transformData(data)
}

const useGetInitPageData = () => {
  return useQuery<InitPageData>({
    queryKey: ['GET_INIT_PAGE_DATA'],
    queryFn,
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetInitPageData
