// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supplier--aFoZc{overflow:hidden;flex:0 1 auto}.remove-all--hlUH4{white-space:nowrap}div.search-query--A_Wvd{text-overflow:ellipsis;max-width:630px;overflow:hidden;cursor:pointer}.result--center--xHsGT{justify-content:center;align-items:center;padding-left:0}.result--Uo_JK{padding-left:20px;height:300px;display:flex;flex-direction:column;border-radius:4px;overflow:scroll}`, "",{"version":3,"sources":["webpack://./src/client/pages/im/components/im-type-specific-form/components/bp-im-form/bp-im-form.scss"],"names":[],"mappings":"AAAA,iBACE,eAAA,CACA,aAAA,CAGF,mBACE,kBAAA,CAGF,wBACE,sBAAA,CACA,eAAA,CACA,eAAA,CAEA,cAAA,CAGF,uBACE,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,eACE,iBAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var supplier = `supplier--aFoZc`;
var _1 = `remove-all--hlUH4`;
export { _1 as "remove-all" };
export var removeAll = `remove-all--hlUH4`;
var _2 = `search-query--A_Wvd`;
export { _2 as "search-query" };
export var searchQuery = `search-query--A_Wvd`;
var _3 = `result--center--xHsGT`;
export { _3 as "result--center" };
export var resultCenter = `result--center--xHsGT`;
export var result = `result--Uo_JK`;
export default ___CSS_LOADER_EXPORT___;
