import { Field, MultiSelect } from '@riskmethods/rm-front-end'

import { useGetIndustries } from '@api'
import { useMemo } from 'react'

type Props = {
  disabled?: boolean
}

export const IndustriesMultiSelect = ({ disabled = false }: Props) => {
  const { industries } = useGetIndustries()

  const OPTIONS = useMemo(() => {
    return industries.map(({ id, name }) => ({ label: name, value: id }))
  }, [industries])

  return (
    <Field
      name="industries"
      label="Industries"
      disabled={disabled}
      Component={(props) => (
        <MultiSelect testId="industries-field" {...props} options={OPTIONS} noSelectionText="" delimiter=";" />
      )}
    />
  )
}
