import { bucketsToRanges, groupRanges, replaceRanges } from './methods'
import type { Bucket, Range } from './types'

export const distributeHits = (buckets: Bucket[], divideForNum: number): Range[] => {
  const ranges = bucketsToRanges(buckets)
  const grouped = groupRanges(ranges, divideForNum)

  const replaced = replaceRanges(grouped)

  const distributed: Range[] = []

  replaced.forEach((ranges) => {
    const start = ranges[0].start
    const finish = ranges[ranges.length - 1].finish
    const uniqueCount = ranges.reduce((acc, current) => acc + current.uniqueCount, 0)

    distributed.push({ start, finish, uniqueCount })
  })

  return distributed
}
