import { useMutation, useQueryClient } from '@tanstack/react-query'

import { fetch, displayError } from '@services'
import type { HitQuality, HitsData } from '@types'

export type UpdateHitsData = {
  ids: number[]
  quality: HitQuality
}

const useUpdateHits = () => {
  const queryClient = useQueryClient()

  const mutationFn = async ({ ids, quality }: UpdateHitsData) => {
    const res = await fetch(
      '/api/hits/bulk_update',
      {
        method: 'POST',
        body: JSON.stringify({ ids, quality }),
      },
      0
    )

    if (!res.ok) {
      setTimeout(() => {
        displayError('Could not update hit(s) this time.')
      })
      return Promise.reject()
    }

    await queryClient.setQueryData(['GET_HITS'], (data: HitsData): HitsData => {
      return {
        ...data,
        hits: data.hits.map((hit) => ({
          ...hit,
          quality: ids.includes(hit.id) ? quality : hit.quality,
          children:
            hit.children &&
            hit.children.map((childHit) => ({
              ...childHit,
              quality: ids.includes(childHit.id) ? quality : childHit.quality,
            })),
        })),
      }
    })

    return { ids, quality }
  }

  return useMutation<UpdateHitsData, UpdateHitsData, UpdateHitsData>({
    mutationKey: ['UPDATE_HITS'],
    mutationFn,
  })
}

export default useUpdateHits
