import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Grid, Paper, Typography, Stack, IconButton } from '@mui/material'
import { OpenInNew as OpenInNewIcon, CropOriginal as CropOriginalIcon } from '@mui/icons-material'
import { Form } from '@riskmethods/rm-front-end'
import qs from 'qs'

import { IM_FORM_DEFAULT_VALUES } from '@components/form/im-form-fields'
import { useTheme } from '@common/theme'

import useGetHit from './api/use-get-hit/use-get-hit'
import ArticleForm from './components/article-form/article-form'
import { ArticleContent } from '../saved-hits/components/article-analyzer/components'

const AAReadOnlyPage = () => {
  const history = useHistory()
  const { themeMode } = useTheme()

  const hitId = Number(qs.parse(history.location.search, { ignoreQueryPrefix: true }).id)

  const getHitQuery = useGetHit(hitId)

  const hit = getHitQuery?.data || null

  useEffect(() => {
    if (hitId) {
      getHitQuery?.refetch()
    }
  }, [hitId])

  const onArticleUrlClick = useCallback(() => {
    window.open(hit?.link, '_blank')
  }, [hit?.link])

  const onScreenshotUrlClick = useCallback(() => {
    const { accessToken = '' } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')
    window.open(`/screenshot?id=${hit?.id}&access_token=${accessToken}`, '_blank')
  }, [hit?.id])

  const elevation = themeMode === 'light' ? 3 : 1

  return (
    <Form config={{ defaultValues: IM_FORM_DEFAULT_VALUES, mode: 'onChange' }} onSubmit={() => null}>
      {(articleFormContext) => {
        const { reset } = articleFormContext

        useEffect(() => {
          if (hit) {
            reset({
              subject: hit.headline,
              sourceForContent: hit.siteName,
              sourceForCopyright: hit.copyright,
              sourceUrl: hit.link,
            })
          }
        }, [hit])

        return (
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} textAlign="right"></Grid>
            <Grid item container xs={12} lg={5.5}>
              <Paper
                elevation={elevation}
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 'calc(100vh - 100px)',
                  padding: 2,
                  bgcolor: 'transparent',
                }}
              >
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography variant="subtitle2" mr={1}>
                    {`Article details ${hit ? `(${hit.id})` : ''}`}
                  </Typography>
                  <IconButton onClick={onArticleUrlClick} size="small" disabled={!hit}>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={onScreenshotUrlClick} size="small" disabled={!hit}>
                    <CropOriginalIcon fontSize="small" />
                  </IconButton>
                </Stack>
                <Divider sx={{ mt: 0.5, mb: 2 }} />
                <Grid item xs={12}>
                  <ArticleContent hit={hit} iframeHeight="calc(100vh - 182px)" />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6.5}>
              <Paper elevation={elevation} sx={{ padding: 2, bgcolor: 'transparent', height: 'calc(100vh - 100px)' }}>
                <ArticleForm formContext={articleFormContext} />
              </Paper>
            </Grid>
          </Grid>
        )
      }}
    </Form>
  )
}

export default AAReadOnlyPage
