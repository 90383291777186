import { pick, uniqueId } from 'lodash'

import { FORM_MODE_CREATE, FORM_MODE_EDIT, FORM_MODE_COPY, FORM_MODE_REVIEW } from './const'
import { getAvailableSuppliersFromSearchResults } from './getAvailableSuppliers'

export const buildPreloadedState = (data) => {
  const { message, messageBundle, copyMessage, currentUser, alertMessage } = data

  let formMode = FORM_MODE_CREATE
  let newsCountries = []
  let indicatorMessageCountry = {}
  let newsBusinessPartners = []
  let newsBusinessPartnersSearches = []
  let newsAvailableSuppliers = []
  let alertBusinessPartners = []
  let alertBusinessPartnersSearches = []
  let alertAvailableSuppliers = []
  let locationData = {}
  let indicator = null
  let priorityForm = null

  if (message) {
    formMode = FORM_MODE_EDIT
    if (message.isNews && message.type === 'country') {
      newsCountries = message.targets
    }
    if (message.isNews && message.type === 'business_partner') {
      newsBusinessPartners = message.targets
      newsBusinessPartnersSearches = message.searches
    }
  }

  if (messageBundle) {
    formMode = FORM_MODE_REVIEW
    const alertMessage = messageBundle.messages.find((message) => message.isNews === false)
    const newsMessage = messageBundle.messages.find((message) => message.isNews === true)
    if (newsMessage) {
      newsCountries = newsMessage.targets
      newsBusinessPartners = newsMessage.targets
      newsBusinessPartnersSearches = newsMessage.searches

      // it should have the same value in alertMessage.Since by default
      // alertForm is priorityForm, it only makes sense to check it,
      // if there is newsMessage.
      priorityForm = newsMessage.formPriorityReverse ? 'newsForm' : 'alertForm'
    }
    if (alertMessage) {
      const { targets } = alertMessage
      ;[indicatorMessageCountry] = targets
      alertBusinessPartners = targets
      alertBusinessPartnersSearches = alertMessage.searches
      locationData = mapTargetsToLocationData(targets)
      indicator = pick(alertMessage.indicator, ['id', 'name', 'type', 'eventType'])
    }

    alertAvailableSuppliers = getAvailableSuppliersFromSearchResults(alertBusinessPartnersSearches)
    newsAvailableSuppliers = getAvailableSuppliersFromSearchResults(newsBusinessPartnersSearches)
  }

  if (copyMessage) {
    const { targets } = copyMessage
    formMode = FORM_MODE_COPY
    if (copyMessage.isNews) {
      newsCountries = targets
      newsBusinessPartners = targets
      newsBusinessPartnersSearches = copyMessage.searches
    } else {
      ;[indicatorMessageCountry] = targets
      alertBusinessPartners = targets
      alertBusinessPartnersSearches = copyMessage.searches
      locationData = mapTargetsToLocationData(targets)
      indicator = pick(copyMessage.indicator, ['id', 'name', 'type', 'eventType'])
    }
  }

  // read the casing for subject and body here.

  return {
    formMode,
    country: {
      data: {
        indicator,
        indicatorMessageCountry,
        newsCountries,
      },
    },
    location: {
      data: { ...locationData, indicator },
      updateMap: locationData && locationData.selectedLocations && locationData.selectedLocations.length > 0,
      fitMapBounds: locationData && locationData.selectedLocations && locationData.selectedLocations.length > 0,
    },
    supplier: {
      data: {
        alertForm: {
          selectedIndicator: indicator,
          suppliers: alertBusinessPartners,
          availableSuppliers: alertAvailableSuppliers,
          searches: alertBusinessPartnersSearches,
        },
        newsForm: {
          suppliers: newsBusinessPartners,
          availableSuppliers: newsAvailableSuppliers,
          searches: newsBusinessPartnersSearches,
        },
      },
      priorityForm,
    },
    currentUser,
    duplicateDetection: {
      filters: {
        keywords: [],
      },
      alertMessage,
    },
  }
}

const mapTargetsToLocationData = (targets) => {
  let radius = null
  let selectedLocations = []
  let isPolygon = false
  let polygonPoints = null
  let polygonArea = null
  if (targets.length > 0) {
    if (targets[0].coordinates) {
      polygonPoints = targets[0].coordinates[0].map((point) => ({ longitude: point[0], latitude: point[1] }))
      selectedLocations = [{ name: targets[0].name, fullName: targets[0].name, latitude: 0, longitude: 0 }]
      isPolygon = true
      polygonArea = targets[0].area * 1000000
    } else {
      selectedLocations = targets.map((target) => ({
        id: uniqueId(),
        ...pick(target, ['name', 'fullName', 'latitude', 'longitude']),
      }))
      ;[{ radius }] = targets
    }
  }
  return { radius, backupRadius: radius, selectedLocations, isPolygon, polygonPoints, polygonArea }
}
