import { get } from 'lodash'
import I18n from 'i18next'

import Link from '@common/components/link'

export const readMoreFormatter = (linkPath) => (cell, row) => (
  <span>
    <span style={{ wordBreak: 'break-word' }}>{cell}</span>{' '}
    <Link href={get(row, linkPath)} target="_blank">
      {I18n.t('common.table.read_more')}
    </Link>
  </span>
)
