import { HIDE_CONTROL_UNITS, TOGGLE_LAYERS_CONTROL_UNIT_VISIBILITY } from '../actions'

export const initialState = {
  layersControlUnit: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_CONTROL_UNITS:
      return hideAll(state)
    case TOGGLE_LAYERS_CONTROL_UNIT_VISIBILITY:
      return {
        ...hideAll(state),
        layersControlUnit: !state.layersControlUnit,
      }
    default:
      return state
  }
}

const hideAll = (state) =>
  Object.keys(state).reduce((acc, k) => {
    acc[k] = false
    return acc
  }, {})
