import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import I18n from 'i18next'

import * as styles from './styles.scss'

export const LoadingBar = (props) => {
  if (!props.visible) {
    return <></>
  }

  return (
    <div className={styles.loadingBar}>
      <span className={styles.loadingBarTitle}>{I18n.t(`worldmap.loading_features.${props.layer}`)}</span>
      <div className="progress progress-striped active">
        <div
          className="progress-bar progress-bar-striped active"
          role="progressbar"
          aria-valuenow={props.progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${props.progress}%` }}
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    visible: ownProps.loading.visible,
    layer: ownProps.loading.layer,
    progress: ownProps.loading.progress,
  }
}

LoadingBar.propTypes = {
  visible: PropTypes.bool,
  layer: PropTypes.string,
  progress: PropTypes.number,
}

export default connect(mapStateToProps)(LoadingBar)
