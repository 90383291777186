import { UseFormReturn } from 'react-hook-form'
import { Box, Divider, Stack, Typography, Grid } from '@mui/material'

import {
  ContentTextArea,
  IndustriesMultiSelect,
  NoValidityToggle,
  OnlyNewsToggle,
  SourceForContentInput,
  SourceForCopyrightInput,
  SourceUrlInput,
  SubjectInput,
  TypeSelect,
  ValidUntilDatePicker,
  IMFormFields,
} from '@components/form/im-form-fields'

type Props = {
  formContext: UseFormReturn
  headingText?: string
}

const ArticleForm = ({ formContext }: Props) => {
  const { onlyNews, noValidity, type } = formContext.getValues()

  return (
    <IMFormFields formContext={formContext}>
      <Box position="relative" height="100%">
        <Typography variant="subtitle2">Create Alert/News with this article</Typography>
        <Divider sx={{ mt: 0.5, mb: 3 }} />
        <Grid container direction="row" spacing={2.5} sx={{ height: 'calc(100vh - 160px)', overflowY: 'auto', pr: 1 }}>
          <Grid item xs={4}>
            <Stack rowGap={2.75}>
              <TypeSelect onlyNews={onlyNews} disabled />
              <IndustriesMultiSelect disabled />
              <SourceForContentInput disabled />
              <SourceForCopyrightInput disabled />
              <SourceUrlInput disabled />
              <ValidUntilDatePicker noValidity={noValidity} disabled />
              <Stack>
                <NoValidityToggle onlyNews={onlyNews} type={type} disabled />
                <OnlyNewsToggle type={type} disabled />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Stack rowGap={2.75}>
              <SubjectInput disabled />
              <ContentTextArea disabled />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </IMFormFields>
  )
}

export default ArticleForm
