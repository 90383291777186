import get from 'lodash/get'
import I18n from 'i18next'

import { getJSON } from '@common/http'
import { tableParams } from '@common/utils/table_params'
import { FIRST_PAGE } from '@common/actions/table'

export const DATA_RECEIVED = 'BUSINESS_PARTNERS_DATA_RECEIVED'
export const UPDATE_NAME_SEARCH_QUERY = 'UPDATE_NAME_SEARCH_QUERY'
export const UPDATE_LOCATION_SEARCH_QUERY = 'UPDATE_LOCATION_SEARCH_QUERY'
export const INDEX_ERROR_RECEIVED = 'BUSINESS_PARTNERS_INDEX_ERROR_RECEIVED'
export const LOAD_DATA = 'BUSINESS_PARTNERS_LOAD_DATA'
export const BUSINESS_PARTNERS_FETCHED = 'BUSINESS_PARTNERS_FETCHED'
export const UPDATE_SEARCH_QUERY_FIELDS = 'UPDATE_SEARCH_QUERY_FIELDS'
export const URL_CHANGED = 'URL_CHANGED'

export const loadData = () => ({ type: LOAD_DATA })

export const dataReceived = (json) => ({
  type: DATA_RECEIVED,
  data: json,
})

export const indexErrorReceived = (json) => ({
  type: INDEX_ERROR_RECEIVED,
  data: json,
})

export const fetchData = () => (dispatch, getState) => {
  const {
    table: { page, perPage, sort },
    filters,
  } = getState().bp

  dispatch(loadData())

  return getJSON(
    '/api/clusters_page',
    tableParams({
      page: convertPageToAPIIndexing(page),
      perPage,
      sort,
      filters: {
        ...filters,
        searchQueryFields: filters.searchQueryFields.join(','),
      },
    })
  )
    .then(({ data }) => {
      dispatch(dataReceived(data))
    })
    .catch((response) => {
      let error = get(response, 'data.error') || get(response, 'data.errors[0]')
      if (!error) {
        if ([503, 504].includes(status)) {
          error = I18n.t('common.req_err_msgs.request_timeout')
        } else if (status >= 500) {
          error = I18n.t('common.req_err_msgs.internal_server_error')
        } else {
          error = I18n.t('common.req_err_msgs.something_went_wrong')
        }
      }
      dispatch(indexErrorReceived({ error }))
    })
}

export const updateNameSearchQuery = (data) => ({
  type: UPDATE_NAME_SEARCH_QUERY,
  data,
})

export const updateLocationSearchQuery = (data) => ({
  type: UPDATE_LOCATION_SEARCH_QUERY,
  data,
})

export const updateSearchQueryFields = (data) => ({
  type: UPDATE_SEARCH_QUERY_FIELDS,
  data,
})

export const urlChanged = (data) => ({
  type: URL_CHANGED,
  data,
})

export const convertPageToAPIIndexing = (page) => (page === 0 ? FIRST_PAGE : page)
