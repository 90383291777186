import omit from 'lodash/omit'

import type { HitData } from '@types'

import { parseHits } from '../transform-hits/transform-hits'
import { hasChildren, isGreat, isIrrelevant, isNotRated } from '../check-hits/check-hits'

export const getGreatHits = (hits: HitData[], includeChildren?: boolean): HitData[] =>
  parseHits(hits, includeChildren).filter(isGreat)

export const getIrrelevantHits = (hits: HitData[], includeChildren?: boolean): HitData[] =>
  parseHits(hits, includeChildren).filter(isIrrelevant)

export const getAllMarkedHits = (hits: HitData[], includeChildren?: boolean): HitData[] => {
  const greatHits = getGreatHits(hits, includeChildren)
  const irrelevantHits = getIrrelevantHits(hits, includeChildren)
  return greatHits.concat(irrelevantHits)
}

export const getNotRatedHits = (hits: HitData[]): HitData[] => hits.filter(isNotRated)

export const withChildren = (hit: HitData): HitData[] =>
  hasChildren(hit) ? [omit(hit, 'children'), ...(hit.children || [])] : [omit(hit, 'children')]
