import { ERROR_RECEIVED, SAVE_SETTINGS } from '../actions'

export const initialState = {
  userSettings: {},
  errors: [],
  settingsSaved: '',
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_RECEIVED:
      return {
        ...state,
        errors: action.data?.errors[0],
      }
    case SAVE_SETTINGS:
      return {
        ...state,
        settingsSaved: 'Settings saved!',
      }
    default:
      return state
  }
}
