import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'
import I18n from 'i18next'

import { useGetIndustries } from '@api'
import CommonTable from '@common/components/table'
import { setSort, setPage } from '@common/actions/table'
import { confirm } from '@common/components/alert'
import { fetchData, fetchRecipients, deleteNews } from '@common/entities/messages/actions'
import { message } from '@common/utils/shapes'
import dateComparator from '@common/utils/date_comparator'
import { geometryFormatter } from '@common/table_formatters/geometry_formatter'
import { handleEmptyFormatter } from '@common/table_formatters/handle_empty_formatter'
import { readMoreFormatter } from '@common/table_formatters/read_more_formatter'
import { showMoreFormatter } from '@common/table_formatters/show_more_formatter/show_more_formatter'
import { industriesFormatter } from '@common/table_formatters/industriesFormatter'

import ShowRecipientsModal from './show_recipients_modal'
import EditValidityDateModal from './edit_validity_date_modal'
import { actionsFormatter } from './formatters/actions_formatter'

const buildColumns = (props) => [
  {
    dataField: 'coreMessageId',
    text: I18n.t('common.messages_table.columns.id_in_admin'),
    attrs: (id) => ({
      'data-testid': id,
    }),
  },
  {
    dataField: 'subject',
    text: I18n.t('common.messages_table.columns.subject'),
  },
  {
    dataField: 'sourceContent',
    text: I18n.t('common.messages_table.columns.source'),
  },
  {
    dataField: 'body',
    text: I18n.t('common.messages_table.columns.body'),
    formatter: readMoreFormatter('hyperlinks[0]'),
  },
  {
    dataField: 'name',
    text: I18n.t('common.messages_table.columns.name'),
  },
  {
    dataField: 'targets.names',
    text: I18n.t('common.messages_table.columns.target'),
    formatter: (_, items, rowIndex, formatExtraData) =>
      showMoreFormatter(
        items.targets.map((t) => t.name),
        rowIndex,
        formatExtraData
      ),
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'targets.geometry',
    text: I18n.t('common.messages_table.columns.area'),
    formatter: geometryFormatter,
  },
  {
    dataField: 'industries',
    text: I18n.t('common.messages_table.columns.industries'),
    formatter: industriesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
      industryMap: props.industryMap,
    },
  },
  {
    dataField: 'reviewedAt',
    text: I18n.t('common.messages_table.columns.approved_and_created_on'),
    sort: true,
    sortFunc: dateComparator,
    formatter: (_, row) => `${row.reviewedAt} / ${row.createdAt}`,
  },
  {
    dataField: 'approvedAndCreatedBy',
    text: I18n.t('common.messages_table.columns.approved_and_created_by'),
    formatter: (_, row) => `${row.reviewedBy} / ${row.createdBy}`,
  },
  {
    dataField: 'validUntil',
    text: I18n.t('common.messages_table.columns.valid_until'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'actions',
    text: I18n.t('common.messages_table.columns.action'),
    formatter: actionsFormatter,
    formatExtraData: {
      rowEvents: props.rowEvents,
      history: props.history,
    },
  },
]

const Table = (props) => {
  const [state, setState] = useState({
    editValidityDateModal: null,
    expanded: {},
    showRecipientsModal: false,
    coreMessageId: null,
  })

  const history = useHistory()
  const { industryMap } = useGetIndustries()

  useEffect(() => {
    if (!props.messages?.length) {
      props.fetchData('history')
    }
  }, [])

  useEffect(() => {
    setState({
      ...state,
      expanded: {},
    })
  }, [props.page])

  const handleRowExpanded = (id) => {
    setState({
      ...state,
      expanded: {
        ...state.expanded,
        [id]: !state.expanded[id],
      },
    })
  }

  const handleEditValidityDateModalToggle = (id = null) => {
    setState({ ...state, editValidityDateModal: id })
  }

  const handleShowRecipientsModalToggle = (coreMessageId, messageId) => {
    if (!coreMessageId) {
      return setState({ ...state, showRecipientsModal: false })
    }
    props.fetchRecipients(messageId)
    setState({ ...state, showRecipientsModal: true, coreMessageId })
  }

  const recipientsList = () => {
    return find(props.messages, { coreMessageId: state.coreMessageId })?.recipients
  }

  const showNewsDeletionConfirmationModal = (newsId) => {
    confirm({
      title: I18n.t('history.table.news.delete.title'),
      body: I18n.t('history.table.news.delete.body'),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(
      () => {
        props.deleteNews(newsId)
      },
      () => null
    )
  }

  const pageChanged = (page) => {
    props.setPage('HISTORY', page)
    props.fetchData('history')
  }

  const sortChanged = (sortField, sortOrder) => {
    props.setSort('HISTORY', sortField, sortOrder)
    props.fetchData('history')
  }

  const columns = buildColumns({
    rowEvents: {
      openEditValidityDateModal: handleEditValidityDateModalToggle,
      openShowRecipientsModal: handleShowRecipientsModalToggle,
      showNewsDeletionConfirmationModal: showNewsDeletionConfirmationModal,
    },
    handleRowExpanded: handleRowExpanded,
    expanded: state.expanded,
    history,
    industryMap,
  })

  const { loading, messages, totalResults, page, errors } = props

  return (
    <>
      <CommonTable
        data={messages}
        loading={loading}
        columns={columns}
        pageChanged={pageChanged}
        sortChanged={sortChanged}
        emptyLabel={I18n.t('history.table.messages.empty')}
        perPage={50}
        totalSize={totalResults}
        page={page}
        errors={errors}
      />
      <EditValidityDateModal
        id={state.editValidityDateModal}
        isOpen={!!state.editValidityDateModal}
        onClose={handleEditValidityDateModalToggle}
      />
      <ShowRecipientsModal
        recipients={recipientsList()}
        isOpen={state.showRecipientsModal}
        onClose={handleShowRecipientsModalToggle}
      />
    </>
  )
}

const mapStateToProps = (rootState) => {
  const {
    errors,
    table: {
      page,
      loading,
      totalResults,
      data: { messages },
    },
  } = rootState.history

  return {
    errors,
    page,
    totalResults,
    messages,
    loading,
  }
}

const mapDispatchToProps = { fetchData, fetchRecipients, setSort, setPage, deleteNews }

Table.propTypes = {
  totalResults: PropTypes.number,
  page: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(message),
  errors: PropTypes.arrayOf(PropTypes.string),
  setPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchRecipients: PropTypes.func.isRequired,
  deleteNews: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
