import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import PropTypes from 'prop-types'

export const MOMENT_DATE_FORMAT = 'DD.MM.YYYY'
export const PICKER_DATE_FORMAT = 'dd.MM.yyyy'

const DatePicker = (props) => {
  const {
    id,
    disabled,
    label = 'Date',
    value,
    'data-testid': testId = 'datepicker',
    onlyFuture,
    error,
    onChange,
    required,
    className,
  } = props

  const parseDate = (date) => {
    return date ? moment(date, MOMENT_DATE_FORMAT).toDate() : null
  }

  const formatDate = (date) => {
    return date ? moment(date).format(MOMENT_DATE_FORMAT) : null
  }

  const handleChange = (newDate) => {
    onChange(formatDate(newDate))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        id={id}
        className={className}
        inputFormat={PICKER_DATE_FORMAT}
        mask="__.__.____"
        disabled={disabled}
        fullWidth
        label={label}
        value={parseDate(value)}
        minDate={onlyFuture ? Date.now() : undefined}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            data-testid={testId}
            required={!!required}
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            {...props.textFieldProps}
          />
        )}
      />
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onlyFuture: PropTypes.bool,
  'data-testid': PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  textFieldProps: PropTypes.object,
}

export default DatePicker
