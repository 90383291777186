import { createContext, useContext, useState, ReactNode, useMemo, useLayoutEffect, useEffect } from 'react'
import { UseMutationResult, UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { useSearchParams } from '@riskmethods/rm-front-end'
import qs from 'qs'

import { useUnsaveHits } from '@api'
import { useGetSavedByUsers, useGetSavedHits } from '@pages/saved-hits/api'
import { getUseSearchParamsConfig } from '@pages/saved-hits/helpers'

import type {
  IndicatorMessageFormValues,
  HitData,
  SavedByUsersData,
  SavedHitsData,
  SavedHitsFormValues,
  SelectOption,
} from '@types'

type View = 'SAVED_HITS' | 'ARTICLE_ANALYZER'

type UseSearchParamsReturn = {
  searchParams: {
    object: SavedHitsFormValues
    string: string
  }
  syncSearchParams: (searchValues: SavedHitsFormValues) => void
}

type TSavedHitsContext = {
  view: View
  setView: (view: View) => void
  selectedHitIds: number[]
  setSelectedHitIds: (ids: number[]) => void
  activeArticleId: number | null
  setActiveArticleId: (id: number | null) => void
  articleFormContext: UseFormReturn<IndicatorMessageFormValues> | null
  setArticleFormContext: (context: UseFormReturn<IndicatorMessageFormValues> | null) => void
  getSavedHitsQuery: UseQueryResult<SavedHitsData>
  getSavedByUsersQuery: UseQueryResult<SavedByUsersData>
  unsaveHitsQuery: UseMutationResult<HitData[], unknown, string[], unknown>
  savedBySelectOptions: SelectOption[]
  useSearchParamsContext: UseSearchParamsReturn
  getActiveArticleHit: () => HitData | null
  getActiveArticleHitIndex: () => number
  getArticleNavHitIds: () => number[]
  navigateToArticle: (articleId: number, savedById: string, replace?: boolean) => void
  areAllHitsSelected: boolean
}

const SavedHitsContext = createContext<TSavedHitsContext>({} as TSavedHitsContext)

type Props = {
  children: ReactNode
}

export const SavedHitsContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const [view, setView] = useState<View>('SAVED_HITS')
  const [selectedHitIds, setSelectedHitIds] = useState<number[]>([])
  const [activeArticleId, setActiveArticleId] = useState<number | null>(null)
  const [articleFormContext, setArticleFormContext] = useState<UseFormReturn<IndicatorMessageFormValues> | null>(null)

  const articleId = Number(qs.parse(history.location.search, { ignoreQueryPrefix: true }).article_id)

  const savedById = qs.parse(history.location.search, { ignoreQueryPrefix: true }).saved_by_id as string

  const { id: userId } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  const { searchParams, syncSearchParams } = useSearchParams(
    getUseSearchParamsConfig(userId, articleId || null, savedById || '')
  )

  useLayoutEffect(() => {
    articleFormContext?.reset()
    syncSearchParams({ ...searchParams.object, article_id: articleId || null })
    setActiveArticleId(articleId || null)
    setView(!isNaN(articleId) ? 'ARTICLE_ANALYZER' : 'SAVED_HITS')
  }, [articleId])

  useEffect(() => {
    if (view === 'SAVED_HITS') {
      queryClient.cancelQueries({ queryKey: ['GET_ARTICLE', activeArticleId] })
      queryClient.cancelQueries({ queryKey: ['GET_ARTICLE', 0] })
      queryClient.setQueryData(['GET_ARTICLE', 0], () => null)
    }
  }, [view])

  const getSavedHitsQuery = useGetSavedHits(searchParams.object as SavedHitsFormValues)
  const getSavedByUsersQuery = useGetSavedByUsers()
  const unsaveHitsQuery = useUnsaveHits()

  const savedBySelectOptions = useMemo(() => {
    return [
      { value: 'any', label: 'Any' },
      ...(getSavedByUsersQuery.data?.users.map(({ userId, fullName }) => ({
        value: userId,
        label: fullName || '',
      })) || []),
    ]
  }, [getSavedByUsersQuery.data])

  const getActiveArticleHit = () => {
    return getSavedHitsQuery.data?.hits?.find((hit) => hit.id === activeArticleId) || null
  }

  const getActiveArticleHitIndex = () => {
    const index = getSavedHitsQuery.data?.hits.findIndex((hit) => hit.id === activeArticleId)
    return index === undefined ? -1 : index
  }

  const getArticleNavHitIds = () => {
    return getSavedHitsQuery.data?.hits?.map((hit) => hit.id) || []
  }

  const navigateToArticle = (articleId: number, savedById: string, replace = false) => {
    history[replace ? 'replace' : 'push'](`/saved_hits?article_id=${articleId}&saved_by_id=${savedById}`)
  }

  const areAllHitsSelected = selectedHitIds.length === getSavedHitsQuery.data?.hits.length

  return (
    <SavedHitsContext.Provider
      value={{
        view,
        setView,
        selectedHitIds,
        setSelectedHitIds,
        activeArticleId,
        setActiveArticleId,
        articleFormContext,
        setArticleFormContext,
        getSavedHitsQuery,
        getSavedByUsersQuery,
        unsaveHitsQuery,
        savedBySelectOptions,
        useSearchParamsContext: {
          searchParams,
          syncSearchParams,
        } as UseSearchParamsReturn,
        getActiveArticleHit,
        getActiveArticleHitIndex,
        getArticleNavHitIds,
        navigateToArticle,
        areAllHitsSelected,
      }}
    >
      {children}
    </SavedHitsContext.Provider>
  )
}

export const useSavedHitsContext = () => {
  return useContext(SavedHitsContext)
}
