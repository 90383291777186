export function getAvailableSuppliersFromSearchResults(searches) {
  const availableSuppliers = []

  for (const search in searches) {
    availableSuppliers.push(...searches[search].result)
  }

  return availableSuppliers
}

function combineAvailableSuppliers(priorityFormSearchResults, otherFormSuppliers) {
  const priorityFormAvailableSuppliers = getAvailableSuppliersFromSearchResults(priorityFormSearchResults)

  const combinedAvailableSuppliers = [...priorityFormAvailableSuppliers]

  otherFormSuppliers.forEach((otherFormSupplier) => {
    const isSupplierAlreadyInArray = combinedAvailableSuppliers.some((supplier) => supplier.id === otherFormSupplier.id)
    // we only add the otherFormSupplier if it is NOT the duplicate of priorityFormAvailableSupplier
    if (!isSupplierAlreadyInArray) {
      combinedAvailableSuppliers.push(otherFormSupplier)
    }
  })

  return combinedAvailableSuppliers
}

export function getAvailableSuppliers(messageForms, priorityForm) {
  const alertFormAvailableSuppliers =
    priorityForm === 'alertForm'
      ? getAvailableSuppliersFromSearchResults(messageForms.alertForm.searches)
      : combineAvailableSuppliers(messageForms.newsForm.searches, messageForms.alertForm.suppliers)

  const newsFormAvailableSuppliers =
    priorityForm === 'newsForm'
      ? getAvailableSuppliersFromSearchResults(messageForms.newsForm.searches)
      : combineAvailableSuppliers(messageForms.alertForm.searches, messageForms.newsForm.suppliers)

  return [alertFormAvailableSuppliers, newsFormAvailableSuppliers]
}
