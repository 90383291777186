import { getJSON } from '@common/http'

export const INDICATORS_FETCHED = 'HISTORY_PAGE_INDICATORS_FETCHED'

export const RESET_DATA = 'RESET_HISTORY_PAGE_DATA'

export const indicatorsFetched = (data) => ({
  type: INDICATORS_FETCHED,
  data,
})

export const fetchIndicators = () => (dispatch) => {
  return getJSON('/api/indicators').then(({ data: { indicators } }) => {
    dispatch(indicatorsFetched(indicators))
  })
}

export const resetData = () => ({
  type: RESET_DATA,
})
