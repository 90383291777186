import { useEffect, useState, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { Stack, Typography, Button, TextField } from '@mui/material'

import { confirm } from '@common/components/alert'
import { TopCont, BottomCont } from '@common/layout'

import PageTitle from '../common/page_title/page_title'
import Table from './table/table'
import ReassignModal from './reassign_modal/reassign_modal'

import {
  addAssignee,
  removeAssignee,
  resetAssignees,
  saveAssignment,
  fetchData,
  startEditing,
  openModal,
  closeModal,
  reassignHits,
  getOpenHitsBuckets,
} from '../../actions'

type User = {
  id: string
  fullName: string
}

type HitsAssignee = {
  id: string
  uniqueHitsCount: number
  totalHitsCount: number
  rangeStart: string
  rangeEnd: string
}

type Props = {
  assignees: HitsAssignee[]
  users: User[]
  isEditing: boolean
  isModalOpen: boolean
  totalHitsCount: number
  totalUniqueHitsCount: number
  hitsUntilTime: string
  editingId: string
  addAssignee: () => void
  removeAssignee: () => void
  resetAssignees: () => void
  saveAssignment: () => void
  fetchData: () => void
  startEditing: () => void
  openModal: () => void
  closeModal: () => void
  reassignHits: (args: ReassignArgs) => void
  getOpenHitsBuckets: ({ hitsUntilTime }: { hitsUntilTime: string }) => void
}

type ReassignArgs = { fromUserId: string; percentage: number; toUserIds: string[] }

const HitsDistribution = (props: Props) => {
  const {
    assignees,
    users,
    isEditing,
    isModalOpen,
    totalHitsCount,
    totalUniqueHitsCount,
    editingId,
    addAssignee,
    resetAssignees,
    saveAssignment,
    fetchData,
    startEditing,
    closeModal,
    reassignHits,
    getOpenHitsBuckets,
  } = props

  const [hitsUntilInputState, setHitsUntilInputState] = useState<string>('')

  useEffect(() => {
    fetchData()
  }, [])

  const showAssignConfirmation = () => {
    const userNumber = assignees.length

    confirm({
      title: 'Confirm the assignment',
      body: `Please confirm to assign Unique hits to ${userNumber} users in this shift`,
      proceedLabel: 'Assign',
      isFullWidth: false,
    })
      .then(() => {
        saveAssignment()
      })
      .catch(() => null)
  }

  const onReassign = (args: ReassignArgs) => {
    reassignHits(args)
    closeModal()
  }

  const onTimeFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHitsUntilInputState(e.target.value)
  }

  const refreshHits = () => {
    getOpenHitsBuckets({ hitsUntilTime: hitsUntilInputState })
  }

  const hasIncompleteAssignees = assignees.some((a) => a.id == null || a.id.includes('new') || assignees.length === 0)

  return (
    <>
      <TopCont>
        <PageTitle title="Hits distribution panel" />
        <Typography variant="subtitle1">
          Unique / Total hits: {totalUniqueHitsCount} / {totalHitsCount} (from 24 h)
        </Typography>
        <Stack direction="row" alignItems="center" gap={1} mt={3} mb={5}>
          <TextField
            type="time"
            id="hits_until"
            name="hits_until"
            label="Hits until"
            data-testid="hits_until"
            value={hitsUntilInputState || ''}
            onChange={onTimeFilterChange}
            sx={{ minWidth: '8rem' }}
            InputLabelProps={{ shrink: true }}
          />
          <Button data-testid="recalculate" color="primary" onClick={refreshHits}>
            Refresh open hits
          </Button>
          {isEditing ? (
            <>
              <Button data-testid="add_assignee" color="secondary" onClick={addAssignee}>
                Add new assignee
              </Button>
              <Button data-testid="reset" color="secondary" disabled={assignees.length === 0} onClick={resetAssignees}>
                Reset
              </Button>
              <Button
                data-testid="assign"
                color="primary"
                disabled={hasIncompleteAssignees}
                onClick={showAssignConfirmation}
              >
                Assign hits
              </Button>
            </>
          ) : (
            <Button data-testid="edit" color="primary" onClick={startEditing}>
              Edit hits distribution
            </Button>
          )}
        </Stack>
      </TopCont>
      <BottomCont>
        <Table />
      </BottomCont>
      <ReassignModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onReassign={onReassign}
        users={users}
        assignees={assignees}
        editingId={editingId}
      />
    </>
  )
}

const mapStateToProps = (rootState) => {
  const state = rootState.shiftManagement

  return {
    assignees: state.assignees,
    totalUniqueHitsCount: state.totalUniqueHitsCount,
    totalHitsCount: state.totalHitsCount,
    isEditing: state.isEditing,
    errors: state.errors,
    isModalOpen: state.isModalOpen,
    editingId: state.editingId,
    users: state.users,
    hitsUntilTime: state.hitsUntilTime,
  }
}

const mapDispatchToProps = {
  addAssignee,
  removeAssignee,
  resetAssignees,
  saveAssignment,
  fetchData,
  startEditing,
  openModal,
  closeModal,
  reassignHits,
  getOpenHitsBuckets,
}

export default connect(mapStateToProps, mapDispatchToProps)(HitsDistribution)
