import { useEffect, useState, useMemo, useRef } from 'react'

import WorldMap from '@riskmethods/world-map'
import { Box } from '@mui/material'

import '@riskmethods/world-map/dist/world-map.css'
import { useTheme } from '@common/theme'
import { MAP_BACKGROUND_COLOR, MAP_STYLE } from '@common/utils/const'

const Map = ({ children, ...rest }) => {
  const { themeMode } = useTheme()
  const [showMap, setShowMap] = useState(true)

  // in order to update the styles of the map we need to unmount and mount the map
  const isInitializedRef = useRef(false)
  useEffect(() => {
    if (!isInitializedRef.current) {
      isInitializedRef.current = true
      return
    }
    setShowMap(false)
  }, [themeMode])

  useEffect(() => {
    if (showMap) {
      return
    }

    // make sure it has time to unmount
    requestAnimationFrame(() => {
      setShowMap(true)
      if (rest.triggerMapUpdate) rest.triggerMapUpdate(null) // draw features that already have been fetched
    })
  }, [showMap])

  const mapStyle = useMemo(() => MAP_STYLE[themeMode] || MAP_STYLE.light, [themeMode])
  const mapBg = useMemo(() => MAP_BACKGROUND_COLOR[themeMode] || MAP_BACKGROUND_COLOR.light, [themeMode])

  return (
    <Box
      sx={{
        colorScheme: 'light',
        color: '#212121',
        width: '100%',
        height: '100%',
      }}
    >
      {showMap && (
        <WorldMap {...rest} styles={mapStyle} backgroundColor={mapBg}>
          {children}
        </WorldMap>
      )}
    </Box>
  )
}

export default Map
