import { Component } from 'react'
import { Stack, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import I18n from 'i18next'

export class ModalForm extends Component {
  static propTypes = {
    updateForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    formErrors: PropTypes.object,
  }

  arrayValue = (value) => {
    const regex = /\s*(?:,|$)\s*/
    return value.split(regex)
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const inputValue = ['alternatives', 'isNotName'].includes(name) ? this.arrayValue(value) : value
    this.props.updateForm({ name, value: inputValue, formErrors: {} })
  }

  render = () => (
    <Stack component="form" gap={2} sx={{ mt: 2 }} onSubmit={this.props.handleSubmit}>
      <TextField
        name="name"
        required
        onChange={this.handleChange}
        label={I18n.t('exclusion_list.form.labels.company_name')}
        error={!!this.props.formErrors.name}
        helperText={this.props.formErrors.name}
        sx={{
          '.MuiFormHelperText-root': {
            position: 'initial',
          },
        }}
      />
      <TextField
        multiline
        rows={3}
        name="alternatives"
        onChange={this.handleChange}
        label={I18n.t('exclusion_list.form.labels.alternatives')}
      />
      <TextField
        multiline
        rows={3}
        name="description"
        onChange={this.handleChange}
        label={I18n.t('exclusion_list.form.labels.description')}
      />
      <TextField
        multiline
        rows={3}
        name="isNotString"
        onChange={this.handleChange}
        label={I18n.t('exclusion_list.form.labels.is_not_string')}
      />
      <TextField
        multiline
        rows={3}
        name="isNotName"
        onChange={this.handleChange}
        label={I18n.t('exclusion_list.form.labels.is_not_name')}
      />
    </Stack>
  )
}

export default ModalForm
