import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch, displayError } from '@services'
import type { HitData } from '@types'

const queryFn = async (id: number | null): Promise<HitData | null> => {
  let data: HitData | null = null

  const res = await fetch(`/api/hits/${id}`)

  if (res.ok) {
    data = await res.json()
  } else {
    displayError('Could not fetch hit this time.')
  }

  return camelizeKeys(data)
}

const useGetHit = (id: number | null) => {
  if (id === null) {
    return null
  }

  return useQuery<HitData | null>({
    queryKey: ['GET_HIT', id],
    queryFn: () => queryFn(id),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetHit
