import { useEffect, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { Divider, Grid, Paper, Typography, Stack, IconButton } from '@mui/material'
import { OpenInNew as OpenInNewIcon, CropOriginal as CropOriginalIcon } from '@mui/icons-material'
import { Form } from '@riskmethods/rm-front-end'

import { useTheme } from '@common/theme'
import { useSavedHitsContext, useIMContext } from '@hooks'
import type { IndicatorMessageFormValues } from '@types'

import { ArticleContent, ArticleForm, ArticleNav } from './components'

type Props = {
  defaultValues: IndicatorMessageFormValues
}

const ArticleAnalyzer = ({ defaultValues }: Props) => {
  const history = useHistory()
  const { themeMode } = useTheme()

  const { setIMFormValues, setIsValid } = useIMContext()

  const { activeArticleId, setArticleFormContext, getActiveArticleHit } = useSavedHitsContext()

  const onArticleUrlClick = () => {
    const url = getActiveArticleHit()?.link
    window.open(url, '_blank')
  }

  const onScreenshotUrlClick = () => {
    const id = getActiveArticleHit()?.id
    const { accessToken = '' } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')
    window.open(`/screenshot?id=${id}&access_token=${accessToken}`, '_blank')
  }

  const handleSubmit = (data: object) => {
    setIMFormValues(data as IndicatorMessageFormValues)
    const { type } = data as IndicatorMessageFormValues
    history.push(`/im/create/${type}?article_id=${activeArticleId}`)
  }

  const elevation = themeMode === 'light' ? 3 : 1

  if (activeArticleId === null) {
    return null
  }

  const hit = getActiveArticleHit()

  return (
    <Form config={{ defaultValues, mode: 'onChange' }} onSubmit={handleSubmit}>
      {(articleFormContext) => {
        const { getValues, formState } = articleFormContext

        useLayoutEffect(() => {
          setArticleFormContext(articleFormContext as unknown as UseFormReturn<IndicatorMessageFormValues>)
          return () => {
            setIMFormValues(getValues() as IndicatorMessageFormValues)
          }
        }, [])

        useEffect(() => {
          setIsValid(formState.isValid)
        }, [formState.isValid])

        return (
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} textAlign="right"></Grid>
            <Grid item container xs={12} lg={5.5}>
              <Paper
                elevation={elevation}
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 'calc(100vh - 100px)',
                  padding: 2,
                  bgcolor: 'transparent',
                }}
              >
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography variant="subtitle2" mr={1}>
                    Article details ({activeArticleId})
                  </Typography>
                  <IconButton onClick={onArticleUrlClick} size="small">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={onScreenshotUrlClick} size="small">
                    <CropOriginalIcon fontSize="small" />
                  </IconButton>
                </Stack>
                <Divider sx={{ mt: 0.5, mb: 2 }} />
                <Grid item xs={12}>
                  <ArticleContent hit={hit} />
                </Grid>
                <Grid item xs={12} position="absolute" bottom="0" left="0" right="0">
                  <Divider />
                  <ArticleNav articleFormContext={articleFormContext} />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6.5}>
              <Paper elevation={elevation} sx={{ padding: 2, bgcolor: 'transparent', height: 'calc(100vh - 100px)' }}>
                <ArticleForm formContext={articleFormContext} />
              </Paper>
            </Grid>
          </Grid>
        )
      }}
    </Form>
  )
}

export default ArticleAnalyzer
