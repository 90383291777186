import { useRef } from 'react'
import { TextField, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import I18n from 'i18next'

const t = (name) => I18n.t(`message_filters.selects.query_strategy.${name}`)

const getQueryStrategyOptions = (strategies) =>
  [
    { value: 'all', label: t('all') },
    strategies.includes('subject_body') ? { value: 'subject_body', label: t('subject_body') } : null,
    strategies.includes('id_in_admin') ? { value: 'id_in_admin', label: t('id_in_admin') } : null,
  ].filter((option) => Boolean(option))

const QueryStrategySelect = ({ onChange, strategies, defaultValue }) => {
  const { current: defaultValueRef } = useRef(defaultValue)
  const handleChange = (event) => {
    const { value } = event.target
    const mockedEvent = { target: { value: value === 'all' ? '' : value } }
    onChange(mockedEvent)
  }

  return (
    <TextField
      select
      name="queryStrategy"
      id="query_strategy"
      data-testid="query_strategy_select"
      sx={{ minWidth: '12rem' }}
      label="Search strategy"
      defaultValue={defaultValueRef ?? 'all'}
      onChange={handleChange}
    >
      {getQueryStrategyOptions(strategies).map((option) => (
        <MenuItem key={option.value} value={option.value} data-testid={`query_strategy_${option.value}`}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

QueryStrategySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  strategies: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.oneOf(['subject_body', 'id_in_admin', '']),
}

QueryStrategySelect.defaultProps = {
  strategies: ['subject_body', 'id_in_admin'],
}

export default QueryStrategySelect
