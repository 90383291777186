import { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { TextField } from '@mui/material'

class PolygonInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.delayedCallback = debounce((event) => {
      this.props.onChange(event)
    }, 500)
  }

  handleChange = (event) => {
    event.persist()
    this.delayedCallback(event)
  }

  render() {
    const polygonPointsText = this.props.value || ''
    return (
      <TextField
        id={this.props.id}
        name="polygon_points"
        label="Polygon Points"
        data-testid="polygon_points"
        className={this.props.className}
        sx={{ width: '14rem' }}
        error={!!this.props.error}
        helperText={this.props.error}
        placeholder="e.g. [[N, N], [N, N], [N, N]]"
        defaultValue={polygonPointsText}
        onChange={this.handleChange}
        disabled={this.props.disabled}
      />
    )
  }
}

export default PolygonInput
