import { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import { filter, isEmpty, compact, uniq, uniqBy, map, sortBy } from 'lodash'
import I18n from 'i18next'

import { recipient } from '@common/utils/shapes'
import Modal from '@common/components/modal'

export default class ShowRecipientsModal extends Component {
  static propTypes = {
    recipients: PropTypes.arrayOf(recipient),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  handleClose = () => this.props.onClose()

  notEmptyRecipients = (recipients) => {
    const uniqRecipients = uniqBy(recipients, 'email')
    const recipientsList = filter(uniqRecipients, (recipient) => {
      return !isEmpty(compact(Object.values(recipient)))
    })
    return sortBy(recipientsList, ['fullName', 'companyName'])
  }

  notifiedCustomers = () => {
    return compact(uniq(map(this.props.recipients, 'companyName'))).sort()
  }

  render = () => (
    <Modal
      title={I18n.t('indicator_message.modals.show_recipients.title')}
      footer={
        <Button color="secondary" onClick={this.handleClose}>
          {I18n.t('common.close')}
        </Button>
      }
      onClose={this.handleClose}
      isOpen={!!this.props.isOpen}
    >
      <span>Notified customers</span>
      {this.props.recipients === null || this.props.recipients === undefined ? (
        <ol>
          <CircularProgress size={30} sx={{ mt: 1 }} />
        </ol>
      ) : (
        <ol>
          {!isEmpty(this.props.recipients) ? (
            this.notifiedCustomers().map((companyName, index) => <li key={index}>{companyName}</li>)
          ) : (
            <span className="text-secondary">
              <i>No notified customers</i>
            </span>
          )}
        </ol>
      )}
      <span>List of recipients</span>
      {this.props.recipients === null || this.props.recipients === undefined ? (
        <ol>
          <CircularProgress size={30} sx={{ mt: 1 }} />
        </ol>
      ) : (
        <ul>
          {!isEmpty(this.props.recipients) ? (
            this.notEmptyRecipients(this.props.recipients).map(({ firstName, lastName, email, companyName }, index) => (
              <li key={index}>
                {firstName} {lastName} {`<${email}>`} - {companyName}
              </li>
            ))
          ) : (
            <span className="text-secondary">
              <i>No recipients</i>
            </span>
          )}
        </ul>
      )}
    </Modal>
  )
}
