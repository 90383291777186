export const PAGE_TITLE = {
  '/inbox': 'Inbox - RIC',
  '/im/create': 'Create Message - RIC',
  '/review/edit': 'Review Message - RIC',
  '/history/edit': 'Edit Message - RIC',
  '/history/copy': 'Copy Message - RIC',
  '/saved_hits': 'Saved Hits - RIC',
  '/business_partners': 'Business Partners - RIC',
  '/worldmap': 'World Map - RIC',
  '/review': 'Review - RIC',
  '/history': 'History - RIC',
  '/exclusion_list': 'E-List - RIC',
  '/shift_management': 'Shift Management - RIC',
  '/individual_settings': 'Individual Settings - RIC',
  '/': 'RIC',
}
