import { Component } from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, TextField } from '@mui/material'
import I18n from 'i18next'

class RadiusInput extends Component {
  static propTypes = {
    value: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  handleChange = (event) => {
    this.props.onChange(event)
  }

  render() {
    const radius = this.props.value === null ? '' : this.props.value
    return (
      <TextField
        id={this.props.id}
        type="number"
        name="radius"
        label="Radius"
        data-testid="radius"
        className={this.props.className}
        sx={{ width: '8rem' }}
        error={!!this.props.error}
        helperText={this.props.error}
        InputProps={{
          inputProps: { min: 0, max: 500, 'aria-label': 'radius' },
          endAdornment: (
            <InputAdornment position="end">{I18n.t('indicator_message.location.inputs.labels.km')}</InputAdornment>
          ),
        }}
        defaultValue={radius}
        onChange={this.handleChange}
        disabled={this.props.disabled}
      />
    )
  }
}

export default RadiusInput
