import { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, FormControlLabel, Checkbox, TextField } from '@mui/material'
import I18n from 'i18next'

import { indicator } from '@common/utils/shapes'

import IndicatorSelect from '@common/components/indicator_select'
import MessageTypeSelect from '@common/components/message_filters/message_type_select'
import MessageSubtypeSelect from '@common/components/message_filters/message_subtype_select'
import QueryStrategySelect from '@common/components/message_filters/query_strategy_select'
import ValiditySelect from '@common/components/message_filters/validity_select'
import { processKeywordsSearch } from '@common/entities/messages/utils'
import { FiltersCont } from '@common/layout'

export default class Filters extends Component {
  static propTypes = {
    changeFilters: PropTypes.func.isRequired,
    indicators: PropTypes.arrayOf(indicator).isRequired,
    fetchData: PropTypes.func.isRequired,
    filters: PropTypes.object,
  }

  state = {}

  handleFilterChange = (filterName) => {
    return ({ target: { value } }) => {
      this.props.changeFilters('HISTORY', { [filterName]: value })
    }
  }

  handleLastTwoYearsFilterChange = (e) => {
    const filterName = 'fromLastTwoYears'
    const value = e.target.checked
    this.props.changeFilters('HISTORY', { [filterName]: value })
  }

  handleIndicatorChange = (indicator) => {
    const indicatorId = indicator ? indicator.id : null
    this.props.changeFilters('HISTORY', { indicatorId })
  }

  handleKeywordsSearchChange = ({ target: { value } }) => {
    this.props.changeFilters('HISTORY', { keywords: processKeywordsSearch(value) })
  }

  handleSearchClick = () => {
    this.props.fetchData()
  }

  indicatorSelectStyles = () => ({
    control: (provided) => ({
      ...provided,
      width: 190,
      height: 36,
    }),
  })

  render() {
    const {
      indicators,
      filters: { indicatorId, queryStrategy },
    } = this.props

    return (
      <FiltersCont component="form" sx={{ pt: '1rem', mb: 1 }}>
        <TextField
          label="Search"
          data-testid="query"
          sx={{ width: 350 }}
          onChange={this.handleKeywordsSearchChange}
          onKeyDown={(e) => e.key === 'Enter' && this.handleSearchClick()}
          inputProps={{
            autoComplete: 'off',
          }}
        />
        <QueryStrategySelect onChange={this.handleFilterChange('queryStrategy')} defaultValue={queryStrategy} />
        <MessageSubtypeSelect onChange={this.handleFilterChange('messageSubtype')} />
        <ValiditySelect onChange={this.handleFilterChange('validity')} />
        <MessageTypeSelect onChange={this.handleFilterChange('messageType')} />
        <IndicatorSelect
          onChange={this.handleIndicatorChange}
          indicators={indicators}
          selectedIndicatorId={indicatorId}
        />
        <FormControlLabel
          label={'Last two years only'}
          control={<Checkbox sx={{ p: '7px' }} onChange={(e) => this.handleLastTwoYearsFilterChange(e)} />}
        />
        <Button color="secondary" data-testid="search" onClick={this.handleSearchClick}>
          {I18n.t('history.filter.buttons.search')}
        </Button>
      </FiltersCont>
    )
  }
}
