import { useState } from 'react'

import { FormControl, InputLabel, Select, ListItemText, Checkbox, Tooltip, MenuItem } from '@mui/material'

const MultipleSelect = ({ dataTestId = '', label = '', options = [], values = [], onChange = null }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const onOpen = () => {
    setIsMenuOpen(true)
    setShowTooltip(false)
  }

  const onClose = () => {
    setIsMenuOpen(false)
  }

  const onMouseEnter = () => {
    !isMenuOpen && setShowTooltip(true)
  }

  const onMouseLeave = () => {
    setShowTooltip(false)
  }

  const tooltipTitle = values.map((value) => options.find((option) => option.value === value)?.label).join(', ')

  const renderValue = () => tooltipTitle

  return (
    <Tooltip arrow title={tooltipTitle} open={showTooltip}>
      <FormControl variant="outlined" size="small">
        <InputLabel id={dataTestId || 'label'}>{label}</InputLabel>
        <Select
          data-testid={dataTestId}
          labelId={dataTestId || 'label'}
          label={label}
          value={values}
          onChange={onChange}
          renderValue={renderValue}
          MenuProps={{
            disableScrollLock: true,
          }}
          displayEmpty
          multiple
          onOpen={onOpen}
          onClose={onClose}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={{ width: '300px' }}
        >
          {options.map((option) => {
            const isSelected = values.includes(option.value)
            const isOnlyValue = isSelected && values.length === 1

            return (
              <MenuItem
                data-testid={`${dataTestId}_option_${option.value}`}
                key={option.label}
                value={option.value}
                disabled={isOnlyValue}
                style={{ height: 40 }}
              >
                <Checkbox checked={isSelected} size="small" />
                <ListItemText primary={option.label} disableTypography />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Tooltip>
  )
}

export default MultipleSelect
