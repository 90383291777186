import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      components: {
        formatableInput: {
          formatOptions: {
            none: 'none',
            title_case: 'Title Case',
            sentence_case: 'Sentence case.',
            upper_case: 'UPPERCASE',
            lower_case: 'lowercase',
          },
        },
      },
      active_interaction: {
        errors: {
          models: {
            'message_bundles/approve': {
              attributes: {
                bundle: {
                  valid_until_incorrect:
                    'Message cannot be approved because validity date of IM has expired. Please edit the validity date.',
                  wrong_status: 'Message must have pending status to be approved.',
                },
              },
            },
            'message_bundles/reject': {
              attributes: {
                bundle: {
                  wrong_status: 'Message must have pending status to be rejected.',
                },
              },
            },
            'bucket_assignments/create': {
              attributes: {
                assigned_until: {
                  in_past: 'The value of assigned_until attribute must be in the future.',
                },
                bucket_ids: {
                  not_valid: 'Invalid bucket_ids passed.',
                },
                original_user: {
                  not_found:
                    'The value of original_user_id attributeś passed does not correspond to any database record.',
                },
                replacement_user: {
                  not_found:
                    'The value of replacement_user_id attribute passed does not correspond to any database record.',
                },
              },
            },
          },
        },
      },
      activemodel: {
        errors: {
          messages: {
            not_in_future: 'is not in the future',
          },
        },
      },
      terms: {
        n_a: 'N/A',
      },
      common: {
        yes: 'Yes',
        no: 'No',
        delete: 'Delete',
        ok: 'Ok',
        cancel: 'Cancel',
        close: 'Close',
        add: 'Add',
        loading: 'Loading...',
        forms: {
          hints: {
            required: 'required',
          },
        },
        table: {
          read_more: 'read more',
          pagination: 'Showing {{from}} to {{to}} of {{size}} results',
        },
        reviews_table: {
          buttons: {
            edit: 'Edit',
          },
        },
        messages_table: {
          columns: {
            action: 'Action',
            edit: 'Edit',
            area: 'Radius / Area',
            body: 'Text',
            created_by: 'Created by',
            created_on: 'Created on',
            approved_and_created_by: 'Approved / created by',
            approved_and_created_on: 'Approved / created on',
            id: '#',
            id_in_admin: 'ID',
            industries: 'Industry',
            notified_customer: 'Notified Customer',
            recipients: 'Recipients',
            source: 'Source',
            subject: 'Subject',
            target: 'Suppliers / Locations / Countries',
            alert_target: 'IM - Suppliers / Locations / Countries',
            news_target: 'News - Suppliers / Countries',
            name: 'Indicator Type / News',
            valid_until: 'Valid until',
          },
        },
        exclusion_items_table: {
          table: {
            exclusion_items: {
              empty: 'No results found in the list',
            },
          },
          columns: {
            name: 'Company Name',
            alternatives: 'Alternatives',
            description: 'Description',
            is_not_string: 'Is not String',
            is_not_name: 'Is not Name',
            action: 'Action',
          },
          buttons: {
            delete: 'Delete',
            edit: 'Edit',
          },
          alerts: {
            delete: {
              title: 'Delete Company',
              body: 'Are you sure you want to delete this company from the E-List?',
            },
          },
        },
        business_partners_table: {
          table: {
            business_partners: {
              empty: 'No results found in the list',
            },
          },
          columns: {
            duns: 'DUNS',
            cluster: 'Cluster',
            name: 'Name',
            address: 'Address & Zip Code',
            city: 'City',
            country: 'Country',
            customers: 'Customers',
            search_name: 'Search Name',
            native_search_name: 'Native Search Name',
            city_search_name: 'City Search Name',
            created_on: 'Created On',
            last_update: 'Last Update',
          },
        },
        messages: {
          validation: {
            required: "Can't be blank",
            numeric: 'is not a valid number',
            url: 'is invalid',
            max_length: 'is too long (maximum is {{count}} characters)',
            invalid_date: 'Invalid date',
            supplier: {
              suppliers_empty: 'No Business partners',
              same_supplier_exists: "Business Partners for IM & News can't be same",
              bankruptcy_with_validity_date:
                'Bankruptcy IMs should not have a validity date. Please go back and select "No validity date".',
            },
            location: {
              min_max_radius: 'Enter any whole number from 0 to 500 only',
              min_polygon_points: 'Coordinates must have more than 2 points',
            },
          },
          unable_to_create: 'Unable to create Indicator Message',
          unable_to_create_im: 'Unable to create Indicator Message or News',
          unable_to_create_news: 'Unable to create News',
          unable_to_retrieve_data: 'Unable to retrieve data from the server. Please, try again.',
          unable_to_update: 'Unable to update Indicator Message or News',
          unable_to_update_news: 'Unable to update News',
          unable_to_update_im: 'Unable to update Indicator Message',
          supplier_search_form_invalid: 'Please add at least one keyword to Search field to complete search',
          unable_to_retrieve_location_name: 'Unable to retrieve location name. Please, try again.',
        },
        staging: 'Staging',
        req_err_msgs: {
          request_timeout: 'Request timeout',
          internal_server_error: 'Internal server error',
          something_went_wrong: 'Something went wrong',
        },
      },
      business_objects: {
        score_cards: {
          total_score: 'Total Risk Score',
        },
      },
      reviews: {
        buttons: {
          approve: 'Approve',
          delete: 'Delete',
        },
        alerts: {
          approve: {
            title: 'Approve',
            body: 'Are you sure you want to approve selected {{messages}}?',
          },
          delete: {
            title: 'Delete',
            body: 'Are you sure you want to delete selected {{messages}}?',
          },
        },
      },
      inbox: {
        filter: {
          buttons: {
            all_delete: 'Delete all',
            delete: 'Delete',
            create: 'Create IM/News',
            statistics: 'Statistics',
            search: 'Search',
          },
          checkboxes: {
            na: 'N/A',
          },
          inputs: {
            score: {
              labels: {
                to: 'to',
              },
            },
          },
          selects: {
            category: {
              all: 'All',
            },
            quality: {
              all: 'All hits',
              great: 'Great hits',
              unrated: 'Unrated hits',
            },
            source: {
              all: 'All source',
              in: 'in',
            },
            strategy: {
              all: 'All fields',
              keywords: 'Keywords only',
              titles: 'Title only',
            },
            date: {
              hits_until: 'Date',
            },
            score: {
              from: 'Score from',
              to: 'to',
            },
          },
        },
        table: {
          columns: {
            create: 'Create IM/News',
            great: 'Great',
            hit_count: 'Hit count',
            id: 'ID',
            irrelevant: 'Irrelevant',
            keywords: 'Keywords',
            language: 'Language',
            score: 'Score',
            search: 'Google',
            search_names: 'Search names',
            text: 'Text',
            time: 'Date',
          },
          links: {
            create: 'Create',
            search: 'Google search',
          },
          messages: {
            empty_inbox: 'No research hits found',
          },
          translation: {
            show_original: 'show original',
            translate: 'translate',
            no_translation:
              'No translation available. Please click "show original" to display the hit in its original language.',
          },
        },
        overview: {
          most_popular_keywords: {
            title: 'Most popular keywords (for selected hits)',
            no_keywords: 'No keywords found',
          },
          general_statistics: {
            title: 'General statistics (on all hits)',
            new: 'New in last 24h',
          },
          error_message: 'Failed to fetch overview data',
        },
        alerts: {
          delete_all_hits: {
            title: 'Delete All',
            body: 'Are you sure you want to delete all hits?',
          },
          delete_marked_hits: {
            title: 'Delete hits',
            body: 'Are you sure you want to delete all marked hits from inbox?',
          },
        },
      },
      indicator_message: {
        hit_unavailable: 'This hit has already been deleted or somebody else is working on it',
        buttons: {
          apply_formatting: 'Apply',
          back: 'Back',
          cancel: 'Cancel',
          reset: 'Reset',
          save_polygon: 'Save Polygon',
          create_im: 'Create IM / News',
          create_only_im: 'Create IM',
          create_news: 'Create News',
          update_news: 'Update News',
          next: 'Next',
          update_im: 'Update IM',
          update_im_news: 'Update IM / News',
          search: 'Search',
        },
        placeholders: {
          indicator: 'Select indicator',
          locations: 'Select locations',
          countries: 'Select countries',
          cities: 'Select cities',
        },
        form: {
          general: {
            hints: {
              hyperlink: "'read more (…)' gets appended to the body.",
              source_content: "'… reports that:' gets prepended to the body.",
              source_copyright: '© … - Event researched by Sphera SCRM',
            },
            labels: {
              content: 'Content',
              hyperlink: 'Hyperlink',
              no_validity: 'No validity date',
              only_news: 'Only news',
              risk_score: 'Risk Score',
              source_content: 'Source for content',
              source_copyright: 'Source for copyright',
              subject: 'Subject',
              subject_formatting: 'Subject formatting',
              type: 'Type',
              valid_until: 'Valid Until',
              indicator: 'Indicator',
              indicator_message: 'Indicator Message',
              news: 'News',
              industries: 'Industries',
            },
            placeholders: {
              select_type: 'Select Type',
              select_industries: 'Select Industries',
              subject_formatting: 'Change subject formatting',
              body_formatting: 'Change body formatting',
            },
            types: {
              business_partner: 'Business partners',
              country: 'Countries',
              location: 'Locations',
            },
          },
          supplier: {
            titles: {
              alert: 'Business partners Alert',
              news: 'Business partners News',
            },
            labels: {
              indicator: 'Indicator',
              search: 'Search',
              is_not: 'Is not',
              country: 'Country',
              city: 'City',
            },
            placeholders: {
              indicator: 'Select Indicator',
              search: 'Search',
              is_not: 'Select Business Partners',
              country: 'Select Country',
              city: 'Select City',
            },
            buttons: {
              search: 'Search',
              remove_all: 'remove all',
            },
            info: {
              no_results: 'No results',
              loading: 'Loading...',
            },
          },
        },
        location: {
          affected_polygon_area: 'Affected Area:',
          inputs: {
            labels: {
              indicator: 'Indicator',
              locations: 'Locations',
              radius: 'Radius',
              points: 'Points',
              km: 'km',
              is_polygon: 'Is Polygon?',
            },
          },
        },
        duplicate_detection: {
          possible_duplicates: '{{count}} possible duplicates',
          indicator: {
            placeholder: 'Indicator',
          },
          table: {
            empty: 'No duplicate results',
          },
        },
        headers: {
          country_im: 'Create Indicator Message / News for Countries',
          country_news: 'Create News for Countries',
          country_edit_news: 'Edit News for Countries',
          country_copy_news: 'Copy News for Countries',
          country_edit_im: 'Edit Indicator Message / News for Countries',
          country_copy_im: 'Copy Indicator Message / News for Countries',
          general: 'Create Indicator Message / News',
          general_copy: 'Copy Indicator Message / News',
          general_tooltip_copy: 'Copy IM / News',
          general_tooltip_time: 'Overwrite validity date',
          general_tooltip_users: 'Recipients',
          general_tooltip_edit: 'Edit',
          general_tooltip_delete: 'Delete',
          general_edit_news: 'Edit News',
          general_edit_im: 'Edit Indicator Message',
          general_review: 'Edit Indicator Message / News',
          location: 'Create Indicator Message for Locations',
          location_copy: 'Copy Indicator Message for Locations',
          location_edit: 'Edit Indicator Message for Locations',
          supplier: 'Create Indicator Message / News for business partners',
          supplier_news: 'Create News for business partners',
          supplier_edit: 'Edit Indicator Message / News for business partners',
          supplier_copy: 'Copy Indicator Message / News for business partners',
          supplier_edit_news: 'Edit News for business partners',
          supplier_copy_news: 'Copy News for business partners',
        },
        modals: {
          edit_validity_date: {
            title: 'Overwrite validity date',
            body: {
              line_1: 'This is only for correcting mistakes.',
              line_2: 'If you don\'t know the implications here, press "Cancel".',
            },
          },
          show_recipients: {
            title: 'Recipients',
          },
          delete_duplicate: {
            title: 'Delete Duplicate?',
            message: 'Are you sure it is a duplicate?',
          },
        },
      },
      message_filters: {
        selects: {
          query_strategy: {
            all: 'All fields',
            subject_body: 'Subject & body',
            id_in_admin: '# in admin',
          },
          type: {
            all: 'All types',
            business_partners: 'Business partners',
            locations: 'Locations',
            countries: 'Countries',
          },
          message_type: {
            all: 'Indicator & News',
            indicator: 'Indicator',
            news: 'News',
          },
          validity: {
            all: 'All validity',
            expire_today: 'Expire today',
            valid_only: 'Valid only',
          },
        },
      },
      history: {
        filter: {
          selects: {
            indicator: {
              placeholder: 'Indicator',
            },
          },
          buttons: {
            search: 'Search',
          },
        },
        table: {
          messages: {
            empty: 'No news/messages',
          },
          news: {
            delete: {
              title: 'Delete news',
              body: 'Are you sure you want to delete selected news?',
              fail: 'Deleting news failed',
            },
          },
        },
      },
      risk_levels: {
        event: 'Event',
        high: 'High',
        low: 'Low',
        medium: 'Medium',
        na: 'N/A',
        no_risk: 'No Risk',
        very_low: 'Very low',
      },
      test_mailer: {
        welcome_email: {
          subject: 'Welcome to the Risk Intelligence Cockpit',
        },
      },
      worldmap: {
        total_score: 'Total risk score',
        layers: 'Layers',
        loading: 'Loading...',
        loading_features: {
          debitors: 'Loading customers...',
          events: 'Loading events...',
          locations: 'Loading locations...',
          manufactures: 'Loading own sites...',
          suppliers: 'Loading suppliers...',
        },
        limit_loading_message: {
          title: "Can't load Risk Objects",
          body: 'It is not possible to load Risk Objects with the current zoom level. Please zoom in more.',
        },
        error: 'Event does not exist in RIC',
      },
      navbar: {
        inbox: 'Inbox',
        saved_hits: 'Saved Hits',
        shift_management: 'Shift Management',
        bp: 'Business Partners',
        worldmap: 'World Map',
        history: 'History',
        review: 'Review',
        elist: 'E-List',
        manage_users: 'Manage users',
        sidekiq: 'Sidekiq',
        individual_settings: 'Individual Settings',
        user_data: 'User Data',
        logout: 'Sign out',
      },
      exclusion_list: {
        buttons: {
          add_company: 'Add New Company',
        },
        modal: {
          title: 'Add New Company',
        },
        form: {
          labels: {
            company_name: 'Company Name',
            alternatives: 'Alternatives',
            description: 'Description',
            is_not_string: 'Is Not String',
            is_not_name: 'Is Not Name',
          },
        },
      },
      search_queries: {
        message: {
          success: 'Successful update',
          failure: 'Update failed',
          invalid_params: 'Update failed: {{errors}}',
          deletion_failed: 'Deletion failed: {{errors}}',
        },
        header: 'Update {{type}} search queries',
        modals: {
          delete_query: {
            title: 'Delete query',
            message: 'Are you sure you want to delete the whole query?',
          },
        },
      },
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
