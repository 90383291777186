export const SET_PAGE = 'SET_PAGE'
export const SET_SORT = 'SET_SORT'
export const SET_FILTERS = 'SET_FILTERS'
export const FIRST_PAGE = 1

export const setPage = (name, page, perPage) => ({
  type: `${SET_PAGE}_${name}`,
  data: { name, page, perPage },
})

export const setSort = (name, sortField, sortOrder) => ({
  type: `${SET_SORT}_${name}`,
  data: { sortField, sortOrder },
})

export const setFilters = (name, filters) => (dispatch) => {
  dispatch({ type: `${SET_FILTERS}_${name}`, data: filters })
}
