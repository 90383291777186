import { Link, styled } from '@mui/material'

const StyledLink = styled(({ children, ...rest }) => {
  return (
    <Link
      sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '#007bff' : 'info.light'),
        textDecoration: 'none',
        '&:hover': {
          color: (theme) => (theme.palette.mode === 'light' ? '#007bff' : 'info.light'),
        },
        ...rest.sx,
      }}
      {...rest}
    >
      {children}
    </Link>
  )
})({})

export default StyledLink
