import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Spinner } from '@riskmethods/rm-front-end'

import { useTheme } from '@common/theme'
import { useGetAAStaticData } from '@pages/saved-hits/api'
import { HitData } from '@types'

import * as styles from './article-content.module.scss'
import './article-content.scss'

type Props = {
  hit: HitData | null
  iframeHeight?: string
}

const ArticleContent = ({ hit, iframeHeight = 'calc(100vh - 230px)' }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { themeMode } = useTheme()
  const aaStaticData = useGetAAStaticData()

  useEffect(() => {
    setIsLoading(true)
  }, [hit?.id])

  const onLoad = () => {
    setTimeout(() => {
      setIsLoading(false)
    }, 150)
  }

  const isLoadingStaticData = aaStaticData.isLoading || aaStaticData.isFetching || !aaStaticData.data

  if (isLoadingStaticData) {
    return <Spinner active centered label="Loading styles ..." />
  }

  if (!hit) {
    return null
  }

  const srcDoc = hit.template ? hit.template + aaStaticData.data.styles[themeMode] : ''

  return (
    <div className={styles.root} translate="yes">
      {srcDoc ? (
        <>
          <iframe
            srcDoc={srcDoc}
            onLoad={onLoad}
            style={{ height: iframeHeight, visibility: !isLoading ? 'visible' : 'hidden' }}
          />
          <Spinner active={isLoading} centered label="Loading template ..." />
        </>
      ) : (
        <Typography
          variant="subtitle2"
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          No template
        </Typography>
      )}
    </div>
  )
}

export default ArticleContent
