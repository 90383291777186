import { useState, SyntheticEvent } from 'react'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { PageCont } from '@common/layout'

import HitsDistribution from './components/hits_distribution/hits_distribution'
import DelayHits from './components/delay_hits/delay_hits'

const ShiftManagementPage = () => {
  const [value, setValue] = useState<string>('1')

  const handleChange = (e: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <PageCont>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Hits Distribution" value="1" />
            <Tab label="Delay Hits" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <HitsDistribution />
        </TabPanel>
        <TabPanel value="2">
          <DelayHits />
        </TabPanel>
      </TabContext>
    </PageCont>
  )
}

export default ShiftManagementPage
