import I18n from 'i18next'

import { parseEventId } from '@riskmethods/world-map'
import * as constants from '../components/constants'
import { getJSON } from '@common/http'

export const SHOW_INFO_WINDOW = 'SHOW_INFO_WINDOW'
export const CLOSE_INFO_WINDOW = 'CLOSE_INFO_WINDOW'
export const OBJECT_INFO_RECEIVED = 'OBJECT_INFO_RECEIVED'

export const showInfoWindow = (anchor, featureId, featureIndex = 0) => ({
  type: SHOW_INFO_WINDOW,
  anchor,
  featureId,
  featureIndex,
})

export const closeInfoWindow = () => ({
  type: CLOSE_INFO_WINDOW,
})

export const objectInfoReceived = (targets, feature, featureId) => ({
  type: OBJECT_INFO_RECEIVED,
  targets,
  feature,
  featureId,
})

export const fetchObjectInfo = (event, mapObject) => (dispatch) => {
  const { feature } = mapObject
  const anchor = getAnchor(event, mapObject)
  const { type } = feature.properties
  let featureIndex = 0
  if (type === 'Event') {
    featureIndex = parseInt(parseEventId(event.target.parentNode.id).index)
  }

  const id = extractId(feature.id)

  dispatch(showInfoWindow(anchor, id, featureIndex))

  const url = `/api/worldmap/locations/${id}/find`
  return getJSON(url)
    .then((data) => {
      const {
        data: { targets },
      } = data
      dispatch(objectInfoReceived(targets, feature, id))
    })
    .catch(() => {
      dispatch(objectInfoReceived([{ error: I18n.t('worldmap.error') }], feature, id))
    })
}

const extractId = (featureId) => {
  return featureId.substr(featureId.lastIndexOf('-') + 1)
}

const getAnchor = (event, mapObject) => {
  const anchor = {
    coordinates: {},
    pixelOffset: constants.DEFAULT_IW_OFFSET,
  }
  if (mapObject.feature.properties.type === 'SupplyPath') {
    anchor.coordinates = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    }
    anchor.pixelOffset = constants.SUPPLY_PATH_IW_OFFSET
  } else if (mapObject.feature.properties.type === 'Event') {
    const eventIndex = parseEventId(event.target.parentNode.id).index
    anchor.coordinates = {
      lat: mapObject.latLngs[eventIndex].lat(),
      lng: mapObject.latLngs[eventIndex].lng(),
    }
    anchor.pixelOffset = constants.EVENT_IW_OFFSET
  } else {
    anchor.coordinates = {
      lat: mapObject.marker.position.lat(),
      lng: mapObject.marker.position.lng(),
    }
  }
  return anchor
}
