import PropTypes from 'prop-types'
import { Alert } from '@mui/material'

const AlertMessage = (props) => {
  const { message, type, 'data-testid': testId = 'alert' } = props

  return (
    <Alert sx={{ mt: 1 }} severity={type} data-testid={testId}>
      {message}
    </Alert>
  )
}

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warning']),
  'data-testid': PropTypes.string,
}

AlertMessage.defaultProps = {
  type: 'warning',
}

export default AlertMessage
