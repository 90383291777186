import { TextField, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import I18n from 'i18next'

const t = (name) => I18n.t(`message_filters.selects.type.${name}`)

const getMessageSubtypeOptions = () => [
  { value: 'all', label: t('all') },
  { value: 'business_partner', label: t('business_partners') },
  { value: 'location', label: t('locations') },
  { value: 'country', label: t('countries') },
]

const MessageSubtypeSelect = ({ onChange }) => {
  const handleChange = (event) => {
    const { value } = event.target
    const mockedEvent = { target: { value: value === 'all' ? '' : value } }
    onChange(mockedEvent)
  }

  return (
    <TextField
      select
      name="message_subtype"
      id="message_subtype"
      data-testid="message_subtype_select"
      sx={{ minWidth: '12rem' }}
      label="Message Subtype"
      defaultValue={'all'}
      onChange={handleChange}
    >
      {getMessageSubtypeOptions().map((option) => (
        <MenuItem key={option.value} value={option.value} data-testid={`message_subtype_${option.value}`}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

MessageSubtypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default MessageSubtypeSelect
