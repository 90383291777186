import Validator from 'validatorjs'
import I18n from 'i18next'

export const validate = (data) => {
  const rules = {
    name: 'required',
  }
  const messages = {
    required: I18n.t('common.messages.validation.required'),
  }

  const validator = new Validator(data, rules, messages)

  return validator.fails() ? validator.errors.all() : null
}
