import { Component } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import I18n from 'i18next'

import { businessPartner } from '@common/utils/shapes'
import { setPage, setSort } from '@common/actions/table'
import CommonTable from '@common/components/table'

class Table extends Component {
  static propTypes = {
    columns: PropTypes.array,
    totalResults: PropTypes.number,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(businessPartner),
    errors: PropTypes.arrayOf(PropTypes.string),
    setPage: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    nameSearchQuery: PropTypes.string,
    locationSearchQuery: PropTypes.string,
    searchQueryFields: PropTypes.arrayOf(PropTypes.string),
  }

  pageChanged = (page, perPage) => {
    this.props.setPage('BP', page, perPage)
    this.props.fetchData()
  }

  sortChanged = (sortField, sortOrder) => {
    this.props.setSort('BP', sortField, sortOrder)
    this.props.fetchData()
  }

  state = {
    textareaRows: 0,
  }

  render() {
    const { loading, items, totalResults, page, perPage, error } = this.props

    return (
      <CommonTable
        data={items.map((item) => ({ ...item.attributes, id: item.id }))}
        loading={loading}
        columns={this.props.columns}
        pageChanged={this.pageChanged}
        sortChanged={this.sortChanged}
        emptyLabel={I18n.t('common.exclusion_items_table.table.exclusion_items.empty')}
        showSizePerPage={true}
        perPage={perPage || 100}
        totalSize={totalResults}
        page={page}
        errors={error ? [error] : undefined}
      />
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    table: {
      page,
      perPage,
      loading,
      totalResults,
      error,
      data: { items },
    },
  } = rootState.bp

  return {
    error,
    page: parseInt(page),
    perPage: parseInt(perPage),
    totalResults,
    items,
    loading,
  }
}

const mapDispatchToProps = { setSort, setPage }

export default connect(mapStateToProps, mapDispatchToProps)(Table)
