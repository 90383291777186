import { omit, isEmpty } from 'lodash'

export const isGreat = (hit) => hit.quality === 'great'

export const allGreat = (hits) => !isEmpty(hits) && hits.every(isGreat)
export const someGreat = (hits) => hits.some(isGreat)

export const isIrrelevant = (hit) => hit.quality === 'irrelevant'

export const allIrrelevant = (hits) => !isEmpty(hits) && hits.every(isIrrelevant)
export const someIrrelevant = (hits) => hits.some(isIrrelevant)

export const allRated = (hits) => hits.every((hit) => isGreat(hit) || isIrrelevant(hit))

export const isNotRated = (hit) => hit.quality === null

export const hasChildren = (hit) => !!(hit.children && hit.children.length)

export const withChildren = (hit) =>
  hasChildren(hit) ? [omit(hit, 'children'), ...hit.children] : [omit(hit, 'children')]

export const getNewQuality = (hits, quality) => {
  let newQuality

  if (allGreat(hits)) {
    newQuality = quality === 'great' ? null : quality
  } else if (allIrrelevant(hits)) {
    newQuality = quality === 'irrelevant' ? null : quality
  } else {
    newQuality = quality
  }

  return newQuality
}

export function rangesToString(ranges) {
  const hasNoRange = !ranges.length || !ranges[0].start || !ranges[0].finish

  if (hasNoRange) {
    return '-'
  }

  return ranges
    .map((range) => {
      return range.start !== range.finish ? `${range.start} - ${range.finish}` : range.start
    })
    .join(', ')
}
