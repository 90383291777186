// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-separator--nip19{flex:1}a.tab-link--Ii_vH{min-height:auto;font-weight:600;font-size:.925rem;text-transform:none}a.tab-link--Ii_vH.tab-link--active--ocp8g,a.tab-link--Ii_vH:hover{text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/client/components/app/navbar/components/item.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CAGF,kBACE,eAAA,CAEA,eAAA,CACA,iBAAA,CAEA,mBAAA,CAEA,kEAEE,oBAAA","sourceRoot":""}]);
// Exports
var _1 = `tab-separator--nip19`;
export { _1 as "tab-separator" };
export var tabSeparator = `tab-separator--nip19`;
var _2 = `tab-link--Ii_vH`;
export { _2 as "tab-link" };
export var tabLink = `tab-link--Ii_vH`;
var _3 = `tab-link--active--ocp8g`;
export { _3 as "tab-link--active" };
export var tabLinkActive = `tab-link--active--ocp8g`;
export default ___CSS_LOADER_EXPORT___;
