import moment from 'moment'

import { ImData, IndicatorMessageFormValues } from '@types'

export const parseImDataToFormValues = (imData: ImData): IndicatorMessageFormValues | null => {
  if (!imData.messageBundle) {
    const im = imData.message || imData.copyMessage

    if (im) {
      return {
        id: im.id,
        onlyNews: im.isNews,
        type: im.type,
        industries: im.industries || [],
        subject: im.subject,
        sourceForContent: im.sourceContent,
        sourceForCopyright: im.sourceCopyright,
        sourceUrl: im.hyperlink1,
        content: im.body,
        validUntil: im.validUntil ? new Date(moment(im.validUntil, 'DD.MM.YYYY').format('MM.DD.YYYY')) : null,
        noValidity: !im.validUntil,
      }
    } else {
      return null
    }
  } else {
    const [{ subject, body, type, industries, hyperlink1, sourceContent, sourceCopyright }] =
      imData.messageBundle.messages

    const alertMessage = imData.messageBundle.messages.find((message) => message.isNews === false)
    const validUntil = alertMessage && alertMessage.validUntil
    const noValidity = alertMessage && !validUntil
    const onlyNews = !alertMessage

    return {
      id: imData.messageBundle.id,
      subject,
      content: body,
      type,
      industries,
      sourceUrl: hyperlink1,
      sourceForContent: sourceContent,
      sourceForCopyright: sourceCopyright,
      validUntil: validUntil ? new Date(moment(validUntil, 'DD.MM.YYYY').format('MM.DD.YYYY')) : null,
      noValidity: noValidity || false,
      onlyNews,
    }
  }
}
