import { useLayoutEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Bookmark as BookmarkIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material'

import { useUnsaveHits } from '@api'
import { useSaveHit } from '@pages/inbox/api'
import type { HitData } from '@types'

type Props = {
  hit: HitData
}

const sx = { '& .MuiSvgIcon-root': { fontSize: 42 }, top: '-9px' }

const SavedHitIcon = ({ hit }: Props) => {
  const [isChecked, setChecked] = useState<boolean>(Boolean(hit.selectedAt))
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const { mutateAsync: saveHit } = useSaveHit()
  const { mutateAsync: unsaveHits } = useUnsaveHits()

  useLayoutEffect(() => {
    setChecked(Boolean(hit.selectedAt))
  }, [hit])

  const handleClick = async (e) => {
    if (isDisabled) {
      return e.preventDefault()
    }

    setIsDisabled(true)
    setChecked(!isChecked)

    const onError = () => {
      setIsDisabled(false)
      setChecked(isChecked)
    }

    if (isChecked) {
      await unsaveHits([hit.id.toString()]).catch(onError)
    } else {
      await saveHit(hit).catch(onError)
    }

    setIsDisabled(false)
  }

  return (
    <IconButton
      data-testid="hit_save"
      onClick={handleClick}
      sx={sx}
      color={isChecked ? 'primary' : 'inherit'}
      disableRipple
    >
      {isChecked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
    </IconButton>
  )
}

export default SavedHitIcon
