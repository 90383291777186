import { pickBy } from 'lodash'
import { getJSON } from '../http'

export const INDICATORS_FETCHED = 'INDICATORS_FETCHED'

export const fetchIndicators =
  ({ type } = {}) =>
  (dispatch) => {
    return getJSON('/api/indicators', pickBy({ type })).then(({ data: { indicators } }) => {
      dispatch({
        type: INDICATORS_FETCHED,
        data: indicators,
        metaData: {
          indicatorType: type,
        },
      })
    })
  }
