import type { Range } from '../../types'

export const groupRanges = (ranges: Range[], divideForNum: number): Range[][] => {
  const totalCount = ranges.reduce((acc, current) => acc + current.uniqueCount, 0)
  const numOfHitsPerRange = Math.round(totalCount / divideForNum)

  const groupedRanges: Range[][] = []

  let startIndex: number | null = null
  let countSum = 0

  ranges.forEach((range, index) => {
    if (startIndex === null) {
      startIndex = index
    }

    countSum += range.uniqueCount

    if (countSum >= numOfHitsPerRange || index === ranges.length - 1) {
      let rewind = false

      if (countSum > numOfHitsPerRange) {
        const upOffset = countSum - numOfHitsPerRange
        const downOffset = numOfHitsPerRange - (countSum - range.uniqueCount)

        if (downOffset < upOffset) {
          rewind = true
        }
      }

      if (!rewind) {
        if (groupedRanges.length < divideForNum) {
          groupedRanges.push(ranges.slice(startIndex, index + 1))
        } else {
          groupedRanges[groupedRanges.length - 1].push(...ranges.slice(startIndex, index + 1))
        }
        startIndex = null
        countSum = 0
      } else {
        if (groupedRanges.length < divideForNum) {
          groupedRanges.push(ranges.slice(startIndex, index))
        } else {
          groupedRanges[groupedRanges.length - 1].push(...ranges.slice(startIndex, index))
        }
        startIndex = index
        countSum = range.uniqueCount
      }
    }
  })

  return groupedRanges
}
