import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { fetch, displayError } from '@services'
import type { HitData, HitsData } from '@types'

const useUnsaveHits = () => {
  const queryClient = useQueryClient()
  const { pathname } = useLocation()

  const mutationFn = async (ids: string[]) => {
    const res = await fetch(
      '/api/v2/inbox_hits/remove_mark_many',
      {
        method: 'PUT',
        body: JSON.stringify({ unselect_hits: ids }),
      },
      150
    )

    if (res.ok) {
      if (pathname === '/inbox') {
        await queryClient.setQueryData(['GET_HITS'], (data: HitsData): HitsData => {
          return {
            ...data,
            hits: data.hits.map((hit) => {
              if (!ids.includes(hit.id.toString())) {
                return hit
              }
              return {
                ...hit,
                selectedAt: null,
                children:
                  hit.children &&
                  hit.children.map((childHit) => ({
                    ...childHit,
                    selectedAt: null,
                  })),
              }
            }),
          }
        })
      }

      return res.json()
    } else {
      displayError('Could not unsave hit(s) this time.')
      return Promise.reject()
    }
  }

  return useMutation<HitData[], unknown, string[]>({
    mutationKey: ['UNSAVE_HITS'],
    mutationFn,
  })
}

export default useUnsaveHits
