import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Box, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material'
import I18n from 'i18next'

import { useSavedHitsContext } from '@hooks'
import type { InitPageData } from '@types'

import SavedHitsTableHeader from './components/saved-hits-table-header/saved-hits-table-header'
import SavedHitsTableRow from './components/saved-hits-table-row/saved-hits-table-row'

type Props = {
  initialData: InitPageData
  savedHitsFormContext: UseFormReturn
}

const SavedHitsTable = ({ initialData, savedHitsFormContext }: Props) => {
  const { selectedHitIds, getSavedHitsQuery } = useSavedHitsContext()

  const data = getSavedHitsQuery.data

  const isLoading = getSavedHitsQuery.isLoading || getSavedHitsQuery.isFetching

  const listItems = useMemo(() => {
    return data?.hits.map((hit, index) => (
      <SavedHitsTableRow
        key={hit.id}
        index={index}
        hitData={hit}
        userSettings={initialData.userSettings}
        noTranslationLanguages={data.noTranslationLanguages}
        isSelected={selectedHitIds.includes(hit.id)}
        savedHitsFormContext={savedHitsFormContext}
      />
    ))
  }, [data?.hits, selectedHitIds])

  if (!data?.hits.length) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <Typography variant="subtitle1">
          {isLoading ? 'Loading ...' : I18n.t('inbox.table.messages.empty_inbox')}
        </Typography>
      </Box>
    )
  }

  return (
    <Box mt={1}>
      <TableContainer sx={{ pb: '50px' }}>
        <Table>
          <TableHead>
            <SavedHitsTableHeader />
          </TableHead>
          <TableBody>{listItems}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SavedHitsTable
