import { isEmpty } from 'lodash'

const transformCountryObjects = (countries) => {
  return countries.map((country) => ({ code: country.code }))
}

export const transformCountry = (state, onlyNews: boolean) => {
  const {
    country: {
      data: { indicatorMessageCountry, newsCountries },
    },
  } = state
  return {
    indicatorMessageCountries:
      onlyNews || isEmpty(indicatorMessageCountry) ? [] : transformCountryObjects([indicatorMessageCountry]),
    newsCountries: transformCountryObjects(newsCountries),
  }
}
