import flatten from 'lodash/flatten'
import flatMap from 'lodash/flatMap'

import type { HitData } from '@types'

import { withChildren } from '../get-specific-hits/get-specific-hits'

export const expandHit = (hit: HitData, index: number) => {
  const children = (hit.children || []).map((child) => ({ ...child, parentId: hit.id, index }))
  return [{ ...hit, index }].concat(children)
}

export const expandAll = (hits: HitData[]) => flatten(hits.map(expandHit))

export const parseHits = (hits: HitData[], includeChildren?: boolean): HitData[] => {
  return includeChildren ? flatMap(hits, (hit) => withChildren(hit)) : hits
}
