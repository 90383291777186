import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch } from '@services'
import type { GetIndicatorsSchema, IndicatorsData } from '@types'

const transformData = (data: GetIndicatorsSchema | null): IndicatorsData => {
  if (!data) {
    return []
  }
  return camelizeKeys<GetIndicatorsSchema>(data).indicators
}

const queryFn = async (type: string): Promise<IndicatorsData> => {
  let data: GetIndicatorsSchema | null = null
  const res = await fetch(`/api/indicators?type=${type}`)
  if (res.ok) {
    data = await res.json()
  }
  return transformData(data)
}

const useGetIndicators = (type: string) => {
  return useQuery<IndicatorsData>({
    queryKey: ['GET_INDICATORS', type],
    queryFn: () => queryFn(type),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetIndicators
