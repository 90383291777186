import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { FIRST_PAGE, setFilters, setPage } from '@common/actions/table'
import { fetchData } from '@common/entities/messages/actions'
import { indicator } from '@common/utils/shapes'
import { PageCont, TopCont, BottomCont } from '@common/layout'

import { fetchIndicators } from './actions'
import Table from './components/table'
import Filters from './components/filters'

class HistoryPage extends Component {
  static propTypes = {
    fetchIndicators: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    indicators: PropTypes.arrayOf(indicator),
    fetchData: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (!this.props.indicators?.length) {
      this.props.fetchIndicators()
    }
  }

  fetchHistoryData = () => {
    this.props.setPage('HISTORY', FIRST_PAGE)
    this.props.fetchData('history')
    window.scrollTo(0, 0)
  }

  render() {
    const { indicators, setFilters, filters } = this.props
    return (
      <PageCont>
        <TopCont>
          <Filters
            changeFilters={setFilters}
            indicators={indicators}
            fetchData={this.fetchHistoryData}
            filters={filters}
          />
        </TopCont>
        <BottomCont>
          <Table />
        </BottomCont>
      </PageCont>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    data: { indicators },
    filters,
  } = rootState.history

  return {
    indicators,
    filters,
  }
}

const mapDispatchToProps = { fetchIndicators, setFilters, setPage, fetchData }

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage)
