import type { Bucket, Range } from '../../types'

export const bucketsToRanges = (buckets: Bucket[]): Range[] => {
  const numOfBuckets = buckets.length

  let start = ''
  let uniqueCount = 0
  const ranges: Range[] = []

  buckets.forEach((bucket, index) => {
    const numOfRanges = ranges.length

    if (!start) {
      start = bucket.firstLetters
    }

    if (bucket.uniqueCount > 0) {
      uniqueCount += bucket.uniqueCount
      ranges.push({ start, finish: start, uniqueCount })
    } else if (numOfRanges > 0) {
      ranges[numOfRanges - 1].finish = bucket.firstLetters
      ranges[numOfRanges - 1].uniqueCount = uniqueCount
    }

    if (index < numOfBuckets - 1 && buckets[index + 1].uniqueCount) {
      uniqueCount = 0
      start = ''
    }
  })

  ranges[0].start = buckets[0].firstLetters

  return ranges
}
