import { useMemo, useState } from 'react'
import { Stack, Box } from '@mui/material'
import includes from 'lodash/includes'

import type { HitData } from '@types'

import HitTitle from './components/hit-title/hit-title'
import HitTranslationBtn from './components/hit-translation-btn/hit-translation-btn'
import HitBody from './components/hit-body/hit-body'

import * as styles from './hits-content.scss'

type Props = {
  hitData: HitData
  userSettings: object
  noTranslationLanguages: string[]
  showHitLink: boolean
}

const HitContent = ({ hitData, userSettings, noTranslationLanguages, showHitLink }: Props) => {
  const [displayTranslatedText, setDisplayTranslatedText] = useState<boolean>(true)

  if (!hitData) {
    return null
  }

  const isNativeLanguage = userSettings[hitData.language]

  const noTranslationLanguage = includes(noTranslationLanguages, hitData.language)
  const isInsolvency = hitData.category === 'Insolvenzbekanntmachungen'
  const noTranslation = !displayTranslatedText || noTranslationLanguage || isInsolvency

  const handleTranslationChange = () => {
    setDisplayTranslatedText(!displayTranslatedText)
  }

  return useMemo(() => {
    return (
      <div className={styles.hitTextCell}>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Box>
            <HitTitle
              hitData={hitData}
              isNativeLanguage={isNativeLanguage}
              isInsolvency={isInsolvency}
              noTranslation={noTranslation}
              showHitLink={showHitLink}
            />
          </Box>
          <HitTranslationBtn
            id={hitData.id}
            isNativeLanguage={isNativeLanguage}
            isInsolvency={isInsolvency}
            noTranslationLanguage={noTranslationLanguage}
            displayTranslatedText={displayTranslatedText}
            handleTranslationChange={handleTranslationChange}
          />
        </Stack>
        <HitBody
          hitData={hitData}
          isNativeLanguage={isNativeLanguage}
          isInsolvency={isInsolvency}
          noTranslation={noTranslation}
        />
      </div>
    )
  }, [displayTranslatedText])
}

export default HitContent
