import { useMutation } from '@tanstack/react-query'
import { decamelizeKeys } from 'humps'

import { fetch } from '@services'
import type { IndicatorMessageFormValues } from '@types'

import { getMsgReqBody } from '../helpers'

type TVariables = {
  state: object
  imFormValues: IndicatorMessageFormValues
  id: number
}

const useEditIndicatorMessage = () => {
  const mutationFn = async ({ state, imFormValues, id }: TVariables): Promise<boolean> => {
    const body = JSON.stringify(decamelizeKeys(getMsgReqBody(state, imFormValues)))

    const res = await fetch(`/api/messages/${id}`, {
      method: 'PATCH',
      body,
    })

    if (!res.ok) {
      return Promise.reject()
    }

    return true
  }

  return useMutation<boolean, boolean, TVariables>({
    mutationKey: ['EDIT_INDICATOR_MESSAGE'],
    mutationFn,
  })
}

export default useEditIndicatorMessage
