import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Stack, Typography, DialogContentText } from '@mui/material'
import Validator from 'validatorjs'
import I18n from 'i18next'

import { getMessage } from '@common/entities/messages/selectors'
import { updateMessage } from '@common/entities/messages/actions'
import Modal from '@common/components/modal'
import DatePicker from '@common/components/date_picker'

const validate = (data) => {
  const validator = new Validator(
    data,
    { validUntil: 'date' },
    { date: I18n.t('common.messages.validation.invalid_date') }
  )

  return validator.fails() ? validator.errors.all() : null
}

const EditValidityDateModal = ({ id, message, isOpen, onClose, updateMessage }) => {
  const [formState, setFormState] = useState({
    validUntil: message?.validUntil || null,
    errors: {},
  })

  useEffect(() => {
    setFormState({ validUntil: message.validUntil, errors: {} })
  }, [message?.validUntil])

  const handleClose = () => {
    onClose()
  }

  const handleChange = (validUntil) => {
    setFormState({ validUntil, errors: {} })
  }

  const handleFormSubmit = (ev) => {
    ev.preventDefault()
    const { validUntil } = formState
    const errors = validUntil ? validate({ validUntil: String(validUntil).split('.').reverse().join('-') }) : null
    if (errors) {
      setFormState({ ...formState, errors })
    } else {
      updateMessage(id, { validUntil }).then(onClose)
    }
  }

  const Footer = (
    <>
      <Button color="primary" onClick={handleFormSubmit}>
        {I18n.t('common.ok')}
      </Button>
      <Button color="secondary" onClick={handleClose}>
        {I18n.t('common.cancel')}
      </Button>
    </>
  )

  return (
    <Modal
      title={I18n.t('indicator_message.modals.edit_validity_date.title')}
      footer={Footer}
      onClose={handleClose}
      isOpen={isOpen}
      bodyStyle={{ overflow: 'initial' }}
      isEditingDialog={true}
    >
      <DialogContentText>{I18n.t('indicator_message.modals.edit_validity_date.body.line_1')}</DialogContentText>
      <DialogContentText>{I18n.t('indicator_message.modals.edit_validity_date.body.line_2')}</DialogContentText>
      <br />
      <Stack
        component="form"
        direction={'row'}
        alignItems={'center'}
        sx={{ mt: 1 }}
        gap={1}
        onSubmit={handleFormSubmit}
      >
        <Typography variant="subtitle11">{I18n.t('indicator_message.form.general.labels.valid_until')}</Typography>
        <DatePicker required error={formState.errors.validUntil} value={formState.validUntil} onChange={handleChange} />
      </Stack>
    </Modal>
  )
}

const mapStateToProps = (state, props) => ({
  message: getMessage(state.history, props.id) || {},
})

const mapDispatchToProps = {
  updateMessage,
}

EditValidityDateModal.propTypes = {
  id: PropTypes.number,
  message: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditValidityDateModal)
