import { QueryClient } from '@tanstack/react-query'

import { confirm } from '@common/components/alert'

export const clearFetchedData = (queryClient: QueryClient) => () => {
  queryClient.cancelQueries({
    queryKey: ['GET_SAVED_BY_USERS'],
  })
}

export const getUseSearchParamsConfig = (userId: string, articleId: number | null, savedById: string) => ({
  storageKey: 'ric.saved_hits',
  searchParams: [
    { key: 'saved_by_id', defaultValue: userId, initialValue: savedById || userId },
    { key: 'search_for', defaultValue: '' },
    {
      key: 'sort',
      defaultValue: 'created_at',
      whitelist: ['search_names', 'created_at', 'selected_by', '-search_names', '-created_at', '-selected_by'],
    },
    {
      key: 'article_id',
      defaultValue: articleId,
      initialValue: articleId,
    },
  ],
})

export const showRemoveHitsWarnModal = () => {
  return confirm({
    title: 'Removing saved hit(s)',
    body: 'Are you sure you want to proceed with this action ?',
    proceedLabel: 'Yes',
    isDeleteDialog: true,
    isFullWidth: false,
  })
}
