import { useState, useEffect, useRef, useMemo } from 'react'
import { isEmpty, debounce, map } from 'lodash'
import { camelizeKeys } from 'humps'
import { Autocomplete, Chip, TextField, CircularProgress } from '@mui/material'

import { getJSON } from '@common/http'

const isLocationEqual = (first, second) =>
  first.fullName === second.fullName && first.latitude === second.latitude && first.longitude === second.longitude

const getUniqueLocations = (locations) =>
  locations.reduce((acc, curr) => {
    if (acc.every((location) => !isLocationEqual(location, curr))) {
      acc.push(curr)
    }

    return acc
  }, [])

const fetchLocations = (types) =>
  debounce((query, callback) => {
    if (isEmpty(query)) {
      return callback([])
    }

    getJSON('/api/locations', { query, types })
      .then((response) => callback(map(response.data.locations, (locataion) => camelizeKeys(locataion))))
      .catch((error) => callback({ error }))
  }, 500)

const LocationSelect = ({
  types,
  isLoading: externalIsLoading,
  isDisabled,
  isMulti,
  onChange,
  label,
  placeholder,
  error: externalError,
  value,
  ...rest
} = {}) => {
  const [locationValue, setLocationValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [error, setError] = useState()

  const loading = isLoading || externalIsLoading

  const loadLocationsRef = useRef(fetchLocations(types))
  useEffect(() => {
    loadLocationsRef.current = fetchLocations(types)
  }, [types])

  const handleInputChange = (value) => {
    setLocationValue(value)
  }

  useEffect(() => {
    if (!locationValue || !open) {
      setIsLoading(false)
      setOptions([])
      return
    }

    let active = true

    ;(async () => {
      setIsLoading(true)
      setError()
      loadLocationsRef.current(locationValue, (locations) => {
        if (!active) {
          return
        }
        setIsLoading(false)

        if (locations.error) {
          setError(locations.error.message || 'Invalid locations data')
          setOpen(false)
          setOptions([])
          return
        }

        setOptions(locations)
      })
    })()

    return () => {
      active = false
    }
  }, [open, locationValue, loadLocationsRef])

  const processedOptions = useMemo(() => {
    const currentValueList = (!isMulti ? [value] : value) ?? []
    const processedValueList = currentValueList.filter(Boolean).map((val) => ({ ...val, isSelected: true }))
    return getUniqueLocations([...options, ...processedValueList].filter(Boolean))
  }, [options, value, isMulti])

  const errorMessage = error || externalError

  const memoizedValue = useMemo(() => {
    if (value) {
      return value
    }

    return isMulti ? [] : null
  }, [value, isMulti])

  return (
    <Autocomplete
      multiple={!!isMulti}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      // if errors with option keys being not unique ...
      // ... consider reducing filtered options to have unique fullName
      filterOptions={(options) => options.filter(({ isSelected }) => !isSelected)}
      isOptionEqualToValue={(option, value) => isLocationEqual(option, value)}
      getOptionLabel={({ fullName }) => fullName}
      options={processedOptions}
      loading={loading}
      disabled={isDisabled}
      onChange={(ev, value) => onChange(value)}
      value={memoizedValue}
      data-testid={'location_select'}
      {...rest}
      clearIcon={loading ? <></> : undefined}
      onInputChange={(event, newInputValue) => {
        handleInputChange(newInputValue.trim())
      }}
      renderTags={
        isMulti
          ? (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  sx={{ '&.MuiChip-sizeSmall': { my: '1px' } }}
                  label={option.fullName}
                  {...getTagProps({ index })}
                />
              ))
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} sx={{ position: 'absolute', right: '37px' }} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

LocationSelect.defaultProps = {
  types: ['cities', 'hot_spots'],
}

export default LocationSelect
