import { useMutation } from '@tanstack/react-query'
import { decamelizeKeys } from 'humps'

import { fetch } from '@services'
import type { IndicatorMessageFormValues } from '@types'

import { getMsgBundleReqBody } from '../helpers'

type TVariables = {
  state: object
  imFormValues: IndicatorMessageFormValues
  articleId: number | null
}

const useCreateIndicatorMessage = () => {
  const mutationFn = async ({ state, imFormValues, articleId }: TVariables): Promise<boolean> => {
    const body = JSON.stringify(decamelizeKeys(getMsgBundleReqBody(state, imFormValues, articleId)))

    const res = await fetch('/api/message_bundles', {
      method: 'POST',
      body,
    })

    if (!res.ok) {
      return Promise.reject()
    }

    return true
  }

  return useMutation<boolean, boolean, TVariables>({
    mutationKey: ['CREATE_INDICATOR_MESSAGE'],
    mutationFn,
  })
}

export default useCreateIndicatorMessage
