import { Stack, TableCell, TableRow, TableSortLabel, Typography } from '@mui/material'

import { HitCheckBox } from '@components'
import type { HitQuality } from '@types'

type Props = {
  sort: string
  handleSortChanged: (sort: string) => void
  handleQualityChange: (quality: HitQuality) => Promise<unknown>
  areAllHitsGreat: boolean
  areAllHitsIrrelevant: boolean
  areAllHitsRated: boolean
  showSavedTableHeader: boolean
  disableSorting: boolean
  disableHeaderUpdateQuality: boolean
  setDisableHeaderUpdateQuality: (disabled: boolean) => void
  setDisableAllRowsUpdateQuality: (disabled: boolean) => void
  onQualityCheckboxClick: (quality: HitQuality) => void
}

const cellSx = {
  paddingTop: 0,
  paddingBottom: '10px',
}

const fontSx = {
  lineHeight: '15px',
  fontWeight: 'bold',
}

const Header = ({
  sort,
  handleSortChanged,
  handleQualityChange,
  areAllHitsGreat,
  areAllHitsIrrelevant,
  areAllHitsRated,
  showSavedTableHeader,
  disableSorting,
  disableHeaderUpdateQuality,
  setDisableHeaderUpdateQuality,
  setDisableAllRowsUpdateQuality,
  onQualityCheckboxClick,
}: Props) => {
  const setDisabled = (disabled: boolean) => {
    setDisableHeaderUpdateQuality(disabled)
    setDisableAllRowsUpdateQuality(disabled)
  }

  const createSortHandler = (fieldName: string) => () => {
    let newSort
    if (sort.includes(fieldName)) {
      newSort = sort.includes('-') ? fieldName : '-' + fieldName
    } else {
      newSort = sort.includes('-') ? '-' + fieldName : fieldName
    }
    handleSortChanged(newSort)
  }

  const direction = sort.includes('-') ? 'desc' : 'asc'

  return (
    <TableRow sx={{ backgroundColor: 'background.default', verticalAlign: 'bottom' }}>
      <TableCell key="searchNames" variant="head" sx={{ ...cellSx, width: '7%' }}>
        <TableSortLabel
          active={sort.includes('search_names')}
          disabled={disableSorting}
          direction={direction}
          onClick={createSortHandler('search_names')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Search names
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="newKeywords" variant="head" sx={{ ...cellSx, width: '10%' }}>
        <TableSortLabel
          active={sort.includes('new_keywords')}
          disabled={disableSorting}
          direction={direction}
          onClick={createSortHandler('new_keywords')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Keywords
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="createdAt" variant="head" sx={{ ...cellSx, width: '5%' }}>
        <TableSortLabel
          active={sort.includes('created_at')}
          disabled={disableSorting}
          direction={direction}
          onClick={createSortHandler('created_at')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Date
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="text" variant="head" sx={cellSx}>
        <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
          Text
        </Typography>
      </TableCell>
      {showSavedTableHeader && (
        <TableCell key="saved" variant="head" align="center" sx={{ ...cellSx, width: '4%' }}>
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Saved
          </Typography>
        </TableCell>
      )}
      <TableCell key="great" variant="head" sx={{ ...cellSx, width: '4%' }}>
        <Stack direction="column" alignItems="center">
          <HitCheckBox
            dataTestId="hit_mark_great"
            type="tick"
            checked={areAllHitsGreat}
            forceShowAsUnchecked={!areAllHitsRated}
            disabled={disableHeaderUpdateQuality}
            setDisabled={setDisabled}
            handleClick={() => onQualityCheckboxClick('great')}
            handleRequest={() => handleQualityChange('great')}
          />
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Great
          </Typography>
        </Stack>
      </TableCell>
      <TableCell key="irrelevant" variant="head" sx={{ ...cellSx, width: '4%' }}>
        <Stack direction="column" alignItems="center">
          <HitCheckBox
            dataTestId="hit_mark_irrelevant"
            type="dash"
            checked={areAllHitsIrrelevant}
            forceShowAsUnchecked={!areAllHitsRated}
            disabled={disableHeaderUpdateQuality}
            setDisabled={setDisabled}
            handleClick={() => onQualityCheckboxClick('irrelevant')}
            handleRequest={() => handleQualityChange('irrelevant')}
          />
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Irrelevant
          </Typography>
        </Stack>
      </TableCell>
      <TableCell key="hitCount" variant="head" sx={{ ...cellSx, width: '5%', paddingLeft: 5 }}>
        <TableSortLabel
          active={sort.includes('hit_count')}
          disabled={disableSorting}
          direction={direction}
          onClick={createSortHandler('hit_count')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Hit count
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="language" variant="head" sx={{ ...cellSx, width: '5%' }}>
        <TableSortLabel
          active={sort.includes('language')}
          disabled={disableSorting}
          direction={direction}
          onClick={createSortHandler('language')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Lang
          </Typography>
        </TableSortLabel>
      </TableCell>
    </TableRow>
  )
}

export default Header
