import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch } from '@services'
import type { GetCountriesSchema, CountriesData } from '@types'

const transformData = (data: GetCountriesSchema | null): CountriesData => {
  if (!data) {
    return []
  }
  return camelizeKeys<GetCountriesSchema>(data).countries
}

const queryFn = async (): Promise<CountriesData> => {
  let data: GetCountriesSchema | null = null
  const res = await fetch('/api/countries.json')
  if (res.ok) {
    data = await res.json()
  }
  return transformData(data)
}

const useGetCountries = () => {
  return useQuery<CountriesData>({
    queryKey: ['GET_COUNTRIES'],
    queryFn,
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetCountries
