// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component--X20k8{margin-right:12px;margin-top:12px;position:relative}.loading-text--u5dmD{width:100%;text-align:center;top:48%;font-size:18px;z-index:1202}.dark-overlay--SDrRn{top:-1px;left:-1px;z-index:1200;background:rgba(0,0,0,.6)}`, "",{"version":3,"sources":["webpack://./src/client/pages/world-map/components/layer-control/styles.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,eAAA,CACA,iBAAA,CAGF,qBACE,UAAA,CACA,iBAAA,CACA,OAAA,CACA,cAAA,CACA,YAAA,CAGF,qBACE,QAAA,CACA,SAAA,CACA,YAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
export var component = `component--X20k8`;
var _1 = `loading-text--u5dmD`;
export { _1 as "loading-text" };
export var loadingText = `loading-text--u5dmD`;
var _2 = `dark-overlay--SDrRn`;
export { _2 as "dark-overlay" };
export var darkOverlay = `dark-overlay--SDrRn`;
export default ___CSS_LOADER_EXPORT___;
