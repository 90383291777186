import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

import { changeAssignee, removeAssignee, openModal } from '../../../actions'
import dropdownFormatter from './dropdown_formatter'
import actionFormatter from './action_formatter'

import CommonTable from '@common/components/table'
import { userShape, hitsAssigneeShape } from '@common/utils/shapes'

export class Table extends Component {
  state = { columns: [] }

  updateColumns() {
    const { users, assignees, isEditing, openModal, hitsUntilTime } = this.props

    this.setState({
      columns: [
        {
          dataField: 'assignee',
          text: 'Assignee',
          headerStyle: { width: '20%' },
          formatter: dropdownFormatter,
          formatExtraData: {
            users,
            assignees,
            isEditing,
            onUserSelected: this.onUserSelected,
          },
        },
        {
          dataField: 'hitsCount',
          text: 'Unique / Total hits (from 24 h)',
          headerStyle: { width: '20%' },
        },
        {
          dataField: 'searchLetter',
          text: 'Search Letter',
          headerStyle: { width: '20%' },
        },
        {
          dataField: 'actionButtons',
          text: 'Action',
          headerStyle: { width: '20%' },
          formatter: actionFormatter,
          formatExtraData: {
            users,
            assignees,
            removeUser: this.removeUser,
            openReassignModal: openModal,
            isEditing: !!isEditing,
          },
        },
        {
          dataField: 'remainingOpenHits',
          text: `Remaining open Unique / Total hits ${hitsUntilTime ? '(until ' + hitsUntilTime + ')' : ''}`,
          headerStyle: { width: '20%' },
        },
      ],
    })
  }

  componentMount() {
    this.updateColumns()
  }

  componentDidUpdate(prevProps) {
    const ignoreStateAndLookAtPropsOnly = this.props !== prevProps

    if (ignoreStateAndLookAtPropsOnly) {
      this.updateColumns()
    }
  }

  onUserSelected = (changeAssigneeArg) => {
    this.props.changeAssignee(changeAssigneeArg)
  }

  removeUser = (id) => {
    this.props.removeAssignee({ id })
  }

  render() {
    const { loading, assignees } = this.props
    const rows = assignees.map((assignee, i) => {
      return {
        assignee,
        searchLetter: assignee.searchLetter,
        id: assignee.id || `index-${i}`,
        hitsCount: `${assignee.uniqueHitsCount || 0} / ${assignee.totalHitsCount || 0}`,
        actionButtons: '',
        remainingOpenHits: `${assignee.openUniqueHitsCount || 0} / ${assignee.openTotalHitsCount || 0}`,
      }
    })

    return this.state.columns.length > 0 ? (
      <CommonTable
        data={rows}
        loading={loading}
        columns={this.state.columns}
        emptyLabel={'No assignees'}
        perPage={50}
        totalSize={0}
        page={0}
        errors={[]}
        hidePagination={true}
        striped={false}
        customClasses={['d-md-table']}
      />
    ) : null
  }

  static propTypes = {
    assignees: PropTypes.arrayOf(hitsAssigneeShape),
    users: PropTypes.arrayOf(userShape),
    errors: PropTypes.arrayOf(PropTypes.string),
    changeAssignee: PropTypes.func.isRequired,
    removeAssignee: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isEditing: PropTypes.bool.isRequired,
    keywords: PropTypes.arrayOf(PropTypes.string),
    hitsUntilTime: PropTypes.string,
  }
}

const mapStateToProps = (rootState) => {
  const { errors, assignees, users, loading, totalUniqueHitsCount, totalHitsCount, isEditing, hitsUntilTime } =
    rootState.shiftManagement

  return {
    errors,
    users,
    assignees,
    loading,
    totalUniqueHitsCount,
    totalHitsCount,
    isEditing,
    hitsUntilTime,
  }
}

const mapDispatchToProps = { changeAssignee, removeAssignee, openModal }

export default connect(mapStateToProps, mapDispatchToProps)(Table)
