import { Stack, TableCell, TableRow, TableSortLabel, Typography } from '@mui/material'

import { HitCheckBox } from '@components'
import { useSavedHitsContext } from '@hooks'

const cellSx = {
  paddingTop: 0,
  paddingBottom: '10px',
}

const fontSx = {
  lineHeight: '15px',
  fontWeight: 'bold',
}

const SavedHitsTableHeader = () => {
  const { selectedHitIds, setSelectedHitIds, getSavedHitsQuery, useSearchParamsContext, areAllHitsSelected } =
    useSavedHitsContext()

  const { searchParams, syncSearchParams } = useSearchParamsContext

  const handleSortChanged = (sort: string) => {
    syncSearchParams({ ...searchParams.object, sort })
    setSelectedHitIds([])
    setTimeout(getSavedHitsQuery.refetch)
  }

  const handleAllHitsSelectionToggle = () => {
    if (selectedHitIds.length === getSavedHitsQuery.data?.hits.length) {
      setSelectedHitIds([])
    } else {
      setSelectedHitIds(getSavedHitsQuery.data?.hits.map((hit) => hit.id) || [])
    }
  }

  const sort = searchParams.object.sort

  const createSortHandler = (fieldName: string) => () => {
    let newSort
    if (sort.includes(fieldName)) {
      newSort = sort.includes('-') ? fieldName : '-' + fieldName
    } else {
      newSort = sort.includes('-') ? '-' + fieldName : fieldName
    }
    handleSortChanged(newSort)
  }

  const direction = sort.includes('-') ? 'desc' : 'asc'

  const isLoading = getSavedHitsQuery.isLoading || getSavedHitsQuery.isFetching

  return (
    <TableRow sx={{ backgroundColor: 'background.default', verticalAlign: 'bottom' }}>
      <TableCell key="searchNames" variant="head" sx={{ ...cellSx, width: '7%' }}>
        <TableSortLabel
          active={sort.includes('search_names')}
          disabled={isLoading}
          direction={direction}
          onClick={createSortHandler('search_names')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Search names
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="newKeywords" variant="head" sx={{ ...cellSx, width: '10%' }}>
        <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
          Keywords
        </Typography>
      </TableCell>
      <TableCell key="createdAt" variant="head" sx={{ ...cellSx, width: '5%' }}>
        <TableSortLabel
          active={sort.includes('created_at')}
          disabled={isLoading}
          direction={direction}
          onClick={createSortHandler('created_at')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Creation Date
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="selectedAt" variant="head" sx={{ ...cellSx, width: '5%' }}>
        <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
          Save date
        </Typography>
      </TableCell>
      <TableCell key="text" variant="head" sx={cellSx}>
        <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
          Text
        </Typography>
      </TableCell>
      <TableCell key="article" variant="head" sx={{ ...cellSx, width: '6%' }}>
        <Typography variant="subtitle2" color="text.secondary" sx={fontSx} />
      </TableCell>
      <TableCell key="selectedBy" variant="head" sx={{ ...cellSx, width: '10%' }}>
        <TableSortLabel
          active={sort.includes('selected_by')}
          disabled={isLoading}
          direction={direction}
          onClick={createSortHandler('selected_by')}
        >
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            RR Analyst
          </Typography>
        </TableSortLabel>
      </TableCell>
      <TableCell key="remove" variant="head" sx={{ ...cellSx, width: '7%' }}>
        <Stack direction="column" alignItems="center">
          <HitCheckBox
            dataTestId="mark_to_remove"
            type="tick"
            checked={areAllHitsSelected}
            handleClick={handleAllHitsSelectionToggle}
          />
          <Typography variant="subtitle2" color="text.secondary" sx={fontSx}>
            Remove
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default SavedHitsTableHeader
