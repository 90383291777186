import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import ThemeProvider from '@common/theme'
import { App } from '@components'
import { initStore } from '@src/client/store/initialize'
import { errorHandler, initDataDog } from '@services'
import { AppContextProvider } from '@hooks'

import packageJson from '../../package.json'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({ onError: errorHandler }),
  mutationCache: new MutationCache({ onError: errorHandler }),
})

const rootElement = document.getElementById('root') as HTMLElement

if (rootElement) {
  const store = initStore()
  const root = createRoot(rootElement)

  const env = JSON.parse(document.getElementById('env')?.dataset.env || '{}')

  if (process.env.NODE_ENV === 'development') {
    env.VERSION = packageJson.version
    env.AUTH_URL = process.env.AUTH_URL
    env.AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID
    env.PODATO_APP_URL = process.env.PODATO_APP_URL
    env.REMOTE_ENV = process.env.REMOTE_ENV
  }

  root.render(
    <ThemeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider env={env}>
              <App />
            </AppContextProvider>
            <ReactQueryDevtools buttonPosition="top-left" />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  )

  if (['DEVELOPMENT', 'STAGING', 'PRODUCTION'].includes(env.REMOTE_ENV)) {
    initDataDog(env.REMOTE_ENV)
  }
}
