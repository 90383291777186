import { REQUEST_SAVE, SUCCESSFULLY_SAVED, ERRORS_SAVING } from '../actions/save'
import initialState from './initial_state'

export default function save(state = initialState.save, { type }) {
  switch (type) {
    case REQUEST_SAVE:
      return {
        ...state,
        inProgress: true,
      }
    case SUCCESSFULLY_SAVED:
      return {
        ...state,
        inProgress: false,
        success: true,
        hasErrors: false,
      }
    case ERRORS_SAVING:
      return {
        ...state,
        inProgress: false,
        success: false,
        hasErrors: true,
      }
    default:
      return state
  }
}
