import { Box, Stack, Typography } from '@mui/material'

const FormItem = ({ label, children }) => {
  return (
    <Stack direction={'row'}>
      <Box flexBasis={'25%'} maxWidth={'25%'}>
        <Typography sx={{ pt: '6px' }}>{label}</Typography>
      </Box>
      <Box flex={'0 0 75%'} maxWidth={'75%'}>
        {children}
      </Box>
    </Stack>
  )
}

export default FormItem
