import { END_LOADING, INCREMENT_PAGE, SET_TOTAL_PAGES, START_LOADING, UPDATE_LOADING } from '../actions/loading'

export const initialState = {
  isLoading: false,
  visible: false,
  layer: null,
  progress: 0,
  page: 0,
  totalPages: 0,
}

export default function reducer(state = initialState, action) {
  let progress
  switch (action.type) {
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
        visible: false,
        layer: null,
        progress: 0,
        page: 0,
        totalPages: null,
      }
    case INCREMENT_PAGE: {
      const newPage = state.page + 1
      progress = (100 * newPage) / state.totalPages
      if (progress === Infinity || isNaN(progress)) {
        progress = 100
      }
      return {
        ...state,
        progress,
        page: newPage,
      }
    }
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.totalPages,
      }
    case START_LOADING:
      if (action.showLoadingBar) {
        return {
          ...state,
          isLoading: true,
          visible: true,
          layer: action.layer,
          progress: 0,
          page: 0,
          totalPages: null,
        }
      } else {
        return {
          ...state,
          isLoading: true,
        }
      }
    case UPDATE_LOADING:
      return {
        ...state,
        progress: action.progress,
      }
    default:
      return state
  }
}
