import { flatten } from 'lodash'

export const processSearchTerm = (fieldValue) => {
  return flatten(
    fieldValue.map(({ label, value, phraseSearch }) => {
      if (value.match(/\|/)) {
        return processSearchTerm(value.split('|').map((value) => ({ label: value, value })))
      } else if (value.match(/^\s*\*./)) {
        const newValue = value.replace(/^\*/, '')
        return { label: value, value: newValue, phraseSearch: false }
      } else {
        return { label, value, phraseSearch: phraseSearch === false ? false : true }
      }
    })
  )
}
