import { uniqWith, isEqual } from 'lodash'

const areaUnit = (
  <span>
    km<sup>2</sup>
  </span>
)
const radiusUnit = <span>km</span>

export const geometryFormatter = (_, message) => {
  const uniqGeometries = uniqWith(
    message.targets.map((obj) => {
      if (obj.areaFormatted) return { geometry: obj.areaFormatted, unit: areaUnit }
      if (obj.radiusFormatted) return { geometry: obj.radiusFormatted, unit: radiusUnit }
      return { geometry: '-' }
    }),
    isEqual
  )

  return uniqGeometries.map((obj, index) => {
    return (
      <span key={index}>
        {' '}
        {index ? ',' : ''} {obj.geometry} {obj.unit}{' '}
      </span>
    )
  })
}
