import {
  CLEAR_LAYER,
  DEBITORS_RECEIVED,
  EVENTS_RECEIVED,
  LOCATIONS_RECEIVED,
  MANUFACTURES_RECEIVED,
  SUPPLIERS_RECEIVED,
} from '../actions'
import { debitorIcon, eventIcon, locationIcon, manufactureIcon, supplierIcon } from './icons'

export const initialState = {
  suppliers: [],
  debitors: [],
  locations: [],
  manufactures: [],
  events: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_LAYER: {
      return {
        ...state,
        ...{ [action.data]: [] },
      }
    }
    case DEBITORS_RECEIVED:
      return {
        ...state,
        debitors: [...state.debitors, ...transformDebitors(action.debitors)],
      }
    case EVENTS_RECEIVED:
      return {
        ...state,
        events: [...transformEvents(state.events.concat(action.events))],
      }
    case LOCATIONS_RECEIVED:
      return {
        ...state,
        locations: [...state.locations, ...transformLocations(action.locations)],
      }
    case MANUFACTURES_RECEIVED:
      return {
        ...state,
        manufactures: [...state.manufactures, ...transformManufactures(action.manufactures)],
      }
    case SUPPLIERS_RECEIVED:
      return {
        ...state,
        suppliers: [...state.suppliers, ...transformSuppliers(action.suppliers)],
      }
    default:
      return state
  }
}

export const transformDebitors = (debitors) =>
  debitors.map(({ id, attributes }) =>
    randomizeOnOrigin({
      id,
      geometry: attributes.geometry,
      properties: {
        name: attributes.name,
        type: attributes.partnerType,
        icon: debitorIcon(attributes.score),
      },
    })
  )

export const transformLocations = (locations) =>
  locations.map(({ id, attributes }) =>
    randomizeOnOrigin({
      id,
      geometry: attributes.geometry,
      properties: {
        name: attributes.name,
        type: attributes.locationType,
        icon: locationIcon(attributes.score),
      },
    })
  )

export const transformManufactures = (manufactures) =>
  manufactures.map(({ id, attributes }) =>
    randomizeOnOrigin({
      id,
      geometry: attributes.geometry,
      properties: {
        name: attributes.name,
        type: attributes.locationType,
        icon: manufactureIcon(attributes.score),
      },
    })
  )

export const transformSuppliers = (suppliers) =>
  suppliers.map(({ id, attributes }) =>
    randomizeOnOrigin({
      id,
      geometry: attributes.geometry,
      properties: {
        name: attributes.name,
        type: attributes.partnerType,
        icon: supplierIcon(attributes.score),
      },
    })
  )

export const transformEvents = (events) =>
  events.map((event) => ({
    ...event,
    properties: {
      ...event.properties,
      noTooltip: true,
      icon: eventIcon(event.properties.eventType),
    },
  }))

const randomizeOnOrigin = (feature) => {
  if (feature.geometry.coordinates.every((c) => c === 0)) {
    const coordinates = feature.geometry.coordinates.map(() => Math.random() * 2 - 1)
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates,
      },
    }
  }
  return feature
}
