export const LANGUAGES = [
  { value: 'de', label: 'DE' },
  { value: 'en', label: 'EN' },
  { value: 'es', label: 'ES' },
  { value: 'fr', label: 'FR' },
  { value: 'it', label: 'IT' },
  { value: 'nl', label: 'NL' },
  { value: 'pl', label: 'PL' },
  { value: 'pt', label: 'PT' },
  { value: 'tr', label: 'TR' },
  { value: 'zh', label: 'ZH' },
  { value: 'cz', label: 'CZ' },
  { value: 'se', label: 'SE' },
  { value: 'ja', label: 'JA' },
  { value: 'id', label: 'ID' },
  { value: 'hu', label: 'HU' },
  { value: 'dk', label: 'DK' },
  { value: 'hr', label: 'HR' },
  { value: 'my', label: 'MY' },
  { value: 'no', label: 'NO' },
  { value: 'ph', label: 'PH' },
  { value: 'ro', label: 'RO' },
  { value: 'sk', label: 'SK' },
  { value: 'vt', label: 'VT' },
  { value: 'af', label: 'AF' },
]

export const QUALITY_OPTIONS = ['all', 'great', 'unrated']
