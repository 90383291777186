import { ReactNode, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Spinner } from '@riskmethods/rm-front-end'
import { parse } from 'qs'

import { useAppContext } from '@hooks'
import { useAuth } from '@auth'

type Props = {
  children: ReactNode
}

export const useAuthenticator = () => {
  const {
    env: { AUTH_URL, AUTH_CLIENT_ID },
  } = useAppContext()

  const { accessToken } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  const { refetch: signOut } = useAuth('signout', { accessToken })

  const login = () => {
    location.replace(
      encodeURI(
        `${AUTH_URL}/oauth/authorize?client_id=${AUTH_CLIENT_ID}&response_type=code&redirect_uri=${location.origin}/auth/riskmethods/callback`
      )
    )
  }

  const logout = () => {
    signOut().then((res) => {
      location.replace(encodeURI(`${AUTH_URL}/signout?jwt=${res.data?.signOutToken}`))
    })
  }

  return {
    login,
    logout,
  }
}

const Authenticator = ({ children }: Props) => {
  const { replace } = useHistory()
  const { pathname, search } = useLocation()
  const { login } = useAuthenticator()
  const { activeAuthAction } = useAppContext()

  const code = parse(search.slice(1)).code as string

  const { refreshToken = '' } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  const { refetch: signIn, data: authData } = useAuth('signin', { code })
  useAuth('resign', { refreshToken })

  const { accessToken = '' } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  useEffect(() => {
    if (!accessToken) {
      if (pathname === '/auth/riskmethods/callback') {
        signIn().then(() => {
          replace('/')
        })
      } else {
        login()
      }
    }
  }, [])

  switch (activeAuthAction) {
    case 'signin':
      return <Spinner active centered label="Signing in ..." />
    case 'signout':
      return <Spinner active centered label="Signing out ..." />
    default:
      return !authData?.accessToken ? <Spinner active centered /> : children
  }
}

export default Authenticator
