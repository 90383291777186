import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import * as styles from './styles.scss'

export const LoadButton = (props) => {
  return (
    <div className={styles.loadButton}>
      <Button color="primary" onClick={props.reload} disabled={props.disabled}>
        Load Risk Objects
      </Button>
    </div>
  )
}

LoadButton.propTypes = {
  reload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
