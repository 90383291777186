import { useMutation, useQueryClient } from '@tanstack/react-query'

import { fetch, displayError } from '@services'
import type { Hit, HitData, HitsData } from '@types'
import { camelizeKeys } from 'humps'

const useSaveHit = () => {
  const queryClient = useQueryClient()

  const mutationFn = async ({ id }: HitData) => {
    const res = await fetch(`/api/v2/inbox_hits/${id}/mark`, { method: 'PUT' }, 150)

    if (res.ok) {
      const savedHit = camelizeKeys<Hit>(await res.json())

      await queryClient.setQueryData(['GET_HITS'], (data: HitsData): HitsData => {
        return {
          ...data,
          hits: data.hits.map((hit) => {
            if (hit.id === savedHit.id) {
              return {
                ...hit,
                selectedAt: savedHit.selectedAt,
                children:
                  hit.children &&
                  hit.children.map((childHit) => ({
                    ...childHit,
                    selectedAt: savedHit.selectedAt,
                  })),
              }
            }
            return hit
          }),
        }
      })

      return savedHit
    } else {
      displayError('Could not save hit this time.')
      return Promise.reject()
    }
  }

  return useMutation<HitData, unknown, HitData>({
    mutationKey: ['SAVE_HIT'],
    mutationFn,
  })
}

export default useSaveHit
