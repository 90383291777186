import { Component } from 'react'
import { Box, Stack, Paper, Button, Typography, Skeleton } from '@mui/material'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import { businessPartner } from '@common/utils/shapes'
import Tags from '@common/components/tags'

import FormItem from '../../../shared/form-item/form-item'
import SearchLabels from '../../components/search-labels/search-labels'
import SupplierSearchResult from '../../components/supplier-search-result/supplier-search-result'
import CitiesSelect from '../../components/cities-select/cities-select'
import CountriesSelect from '../../components/countries-select/countries-select'

import { processSearchTerm } from '../../helpers'

import * as styles from '../../bp-im-form.scss'

const t = (name) => I18n.t(`indicator_message.form.supplier.${name}`)
const placeholdersT = (name) => I18n.t(`indicator_message.placeholders.${name}`)

export default class SupplierNewsForm extends Component {
  static propTypes = {
    formChanged: PropTypes.func.isRequired,
    formSearch: PropTypes.func.isRequired,
    removeSupplier: PropTypes.func.isRequired,
    suppliers: PropTypes.arrayOf(businessPartner),
    suppliersLoading: PropTypes.bool.isRequired,
    countries: PropTypes.array,
    clearForm: PropTypes.func.isRequired,
    formData: PropTypes.shape({
      search: PropTypes.string,
      includeNames: PropTypes.arrayOf(PropTypes.object),
      excludeNames: PropTypes.arrayOf(PropTypes.object),
      countries: PropTypes.array,
      cities: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    searches: PropTypes.arrayOf(PropTypes.object),
    removeSearch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    suppliers: [],
  }

  onChange = (name) => {
    return ({ target: { value } }) => {
      this.props.formChanged(name, value)
    }
  }

  onSelectChange = (name) => (value) => {
    this.props.formChanged(name, value)
  }

  onTagsChange = (name) => (value) => {
    const processedValue = processSearchTerm(value)
    this.props.formChanged(name, processedValue)
  }

  render() {
    const {
      formSearch,
      suppliers,
      suppliersLoading,
      clearForm,
      formData,
      countries,
      removeSupplier,
      searches,
      removeSearch,
    } = this.props
    return (
      <Stack
        flex={1}
        noValidate
        component="form"
        autoComplete="off" // disable countries auto fill
        onSubmit={(ev) => ev.preventDefault()}
        sx={{ maxWidth: '800px' }}
        data-testid="news_supplier_form"
      >
        <Typography variant="h5" component="h3" sx={{ mb: 2 }} textAlign={'center'}>
          {t('titles.news')}
        </Typography>
        <Stack
          component={Paper}
          id="news_form"
          elevation={4}
          sx={{
            overflow: 'hidden',
            width: '100%',
            flexShrink: 0,
            flexGrow: 1,
            position: 'relative',
            backgroundColor: 'background.accent',
          }}
          p={3}
          gap={2}
        >
          <Skeleton variant="rectangle" animation={false} sx={{ height: '37px', borderRadius: '4px' }} />
          <FormItem label={t('labels.search')}>
            <Tags
              type="text"
              onChange={this.onTagsChange('includeNames')}
              placeholder={t('placeholders.search')}
              value={formData.includeNames || []}
              isClearable={false}
              data-testid="news_include_names"
              onEnterPressed={formSearch}
              height={37}
              maxHeight={150}
            />
          </FormItem>
          <FormItem label={t('labels.is_not')}>
            <Tags
              type="text"
              onChange={this.onTagsChange('excludeNames')}
              placeholder={t('placeholders.is_not')}
              value={formData.excludeNames || []}
              isClearable={false}
              data-testid="news_exclude_names"
              onEnterPressed={formSearch}
              height={37}
              maxHeight={150}
            />
          </FormItem>
          <FormItem label={t('labels.country')}>
            <CountriesSelect
              countries={countries}
              value={formData.countries || []}
              onChange={this.onSelectChange('countries')}
              label={placeholdersT('countries')}
              formType="news"
            />
          </FormItem>
          <FormItem label={t('labels.city')}>
            <CitiesSelect formType="news" value={formData.cities || []} onChange={this.onSelectChange('cities')} />
          </FormItem>
          <Box textAlign={'right'}>
            <Button
              onClick={formSearch}
              type="button"
              color="secondary"
              disabled={suppliersLoading}
              data-testid="news_search"
            >
              {t(suppliersLoading ? 'info.loading' : 'buttons.search')}
            </Button>
          </Box>
          <Stack flex={1} justifyContent={'end'} data-testid={'news_suppliers_result_cont'}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 1 }} gap={1} textAlign={'right'}>
              <SearchLabels formType="news" searches={searches} removeSearch={removeSearch} suppliers={suppliers} />
              <Box alignSelf={'end'}>
                <Button
                  color="error"
                  variant="text"
                  type="button"
                  onClick={clearForm}
                  className={styles.removeAll}
                  data-testid="news_remove_all"
                >
                  {t('buttons.remove_all')}
                </Button>
              </Box>
            </Stack>
            <SupplierSearchResult
              formType="news"
              suppliers={suppliers}
              suppliersLoading={suppliersLoading}
              removeSupplier={removeSupplier}
            />
          </Stack>
        </Stack>
      </Stack>
    )
  }
}
