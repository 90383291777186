import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'
import { stringify } from 'qs'
import set from 'lodash/set'
import { parseISO } from 'date-fns'
import format from 'date-fns/format'

import { expandAll } from '@pages/inbox/helpers'
import { fetch, displayError } from '@services'
import type { InboxFormValues, GetHitsSchema, HitsData } from '@types'

export const DEFAULT_DATA = {
  page: 1,
  perPage: 50,
  totalPages: 0,
  totalResults: 0,
  allHitIds: [],
  hits: [],
  totalParentHits: 0,
  noTranslationLanguages: [],
} as HitsData

const transformData = (data: GetHitsSchema | null): HitsData => {
  if (!data) return DEFAULT_DATA

  const transformedData = camelizeKeys<GetHitsSchema>(data)
  if (!transformedData) return DEFAULT_DATA

  return {
    ...transformedData,
    hits: expandAll(transformedData.hits),
  }
}

const transformReqParams = (params: InboxFormValues): string => {
  const { keywords, source_id, category, languages, quality, personalised_inbox, page, sort, date, time } = params

  const obj = {}

  if (source_id !== 'all') set(obj, 'source_id', source_id)
  if (category !== 'all') set(obj, 'category', category)
  if (languages[0] !== 'all') set(obj, 'languages', languages)
  if (personalised_inbox) set(obj, 'personalised_inbox', 'on')
  if (keywords) set(obj, 'keywords', (keywords as string).split(' '))
  if (date) set(obj, 'date', format(parseISO(date.toString()), 'dd.MM.yyyy'))
  if (time) set(obj, 'time', time)

  set(obj, 'quality', quality)
  set(obj, 'page', page)
  set(obj, 'sort', sort)

  set(obj, 'query_strategy', 'all')

  return stringify(obj, { arrayFormat: 'brackets' })
}

const queryFn = async (params: InboxFormValues): Promise<HitsData> => {
  let data: GetHitsSchema | null = null

  // this is to make sure params will be up to date
  await new Promise((resolve) => setTimeout(resolve))

  const queryString = transformReqParams(params)
  const path = `/api/hits?${queryString}`

  const res = await fetch(path)

  if (res.ok) {
    data = await res.json()
  } else {
    displayError('Could not fetch hits this time.')
  }

  return transformData(data)
}

const useGetHits = (params: InboxFormValues) => {
  return useQuery<HitsData>({
    queryKey: ['GET_HITS'],
    queryFn: () => queryFn(params),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetHits
