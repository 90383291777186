import { Component } from 'react'
import I18n from 'i18next'
import { Button, DialogContentText } from '@mui/material'

import PropTypes from 'prop-types'
import { validate } from './form/validator'
import { ModalForm } from './form'

import Modal from '@common/components/modal'

export class AddCompanyModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    formControls: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { name, alternatives, description, isNotString, isNotName } = this.props.formControls
    const errors = validate({ name: name.value })
    if (errors) {
      this.props.updateForm({ formErrors: errors })
    } else {
      this.props
        .create({
          name: name.value,
          alternatives: alternatives.value,
          description: description.value,
          isNotString: isNotString.value,
          isNotName: isNotName.value,
        })
        .then(this.props.onClose)
    }
  }

  handleClose = () => this.props.onClose()

  render = () => (
    <Modal
      title={I18n.t('exclusion_list.modal.title')}
      footer={
        <>
          <Button color="secondary" onClick={this.handleClose}>
            {I18n.t('common.cancel')}
          </Button>
          <Button type="submit" color="primary" onClick={this.handleSubmit}>
            {I18n.t('common.add')}
          </Button>
        </>
      }
      onClose={this.handleClose}
      isOpen={this.props.isOpen}
    >
      <DialogContentText>Fill the form to add a company to the e-list</DialogContentText>
      <ModalForm
        create={this.props.create}
        handleSubmit={this.handleSubmit}
        updateForm={this.props.updateForm}
        formErrors={this.props.formErrors}
      />
    </Modal>
  )
}

export default AddCompanyModal
