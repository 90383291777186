import { updateFeatures, updateMap } from '../actions'

export const SET_LAYER = 'SET_LAYER'

export const updateLayer = (layer, value) => (dispatch) => {
  dispatch(setLayer(layer, value))
  dispatch(updateFeatures())
  dispatch(updateMap())
}

export const setLayer = (layer, value) => ({
  type: SET_LAYER,
  layer,
  value,
})
