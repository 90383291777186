import { useState } from 'react'
import { Switch, Box, FormControlLabel } from '@mui/material'

import CommonTable from '@common/components/table'
import { confirm } from '@common/components/alert'

import type { Indicator, IndicatorToggleAction, IndicatorsCollectionKey } from '@pages/shift-management/types'
import { type GetIndicatorsData } from '../api'

type Props = {
  data: GetIndicatorsData
  isLoading: boolean
  handleUpdateIndicator: (args: {
    collectionKey: IndicatorsCollectionKey
    indicator: string
    action: IndicatorToggleAction
  }) => void
}

const Table = ({ data, isLoading, handleUpdateIndicator }: Props) => {
  const [showLocationIndicators, setShowLocationIndicators] = useState<boolean>(false)

  const onVisibilityToggle = (
    collectionKey: IndicatorsCollectionKey,
    indicator: string,
    action: IndicatorToggleAction
  ) => {
    const actionText1 = action === 'show' ? 'visible' : 'hidden'
    const actionText2 = action === 'show' ? 'to users' : 'from users'

    confirm({
      title: 'Are you sure ?',
      body: (
        <>
          <span>Hits with predicted indicator </span>
          <b>{indicator}</b>
          <span> will be </span>
          <b>{actionText1}</b>
          <span> {actionText2}.</span>
        </>
      ),
      proceedLabel: 'Save',
      cancelLabel: 'Cancel',
    }).then(
      () => {
        handleUpdateIndicator({ collectionKey, indicator, action })
      },
      () => null
    )
  }

  const onShowLocationIndicatorsToggle = () => {
    setShowLocationIndicators(!showLocationIndicators)
  }

  const getColumns = (collectionKey: IndicatorsCollectionKey, indicatorColumnText: string) => [
    {
      dataField: 'indicator',
      text: indicatorColumnText,
      headerStyle: { width: '40%' },
    },
    {
      dataField: 'inbox_count',
      text: 'Hits in Inbox',
      headerStyle: { width: '20%' },
      formatter: (value: number) => {
        return value.toLocaleString()
      },
    },
    {
      dataField: 'delayed_count',
      text: 'Delayed Hits',
      headerStyle: { width: '20%' },
      formatter: (value: number) => {
        return value.toLocaleString()
      },
    },
    {
      dataField: 'hidden_after',
      text: 'Show / Hide',
      headerStyle: { width: '20%' },
      formatter: (value: string | null, data: Indicator) => {
        const onChange = () => {
          onVisibilityToggle(collectionKey, data.indicator, data.hidden_after ? 'show' : 'hide')
        }
        return <Switch size="small" checked={!!value} onChange={onChange} />
      },
    },
  ]

  if (!data) {
    return null
  }

  return (
    <>
      <CommonTable
        columns={getColumns('bp_indicators', 'Business Partner Indicator')}
        data={data?.bp_indicators || []}
        hidePagination
        striped={false}
        keyField="indicator"
      />
      <Box ml={2} mb={2}>
        <FormControlLabel
          control={<Switch size="small" checked={showLocationIndicators} onChange={onShowLocationIndicatorsToggle} />}
          label="Collapse / Expand"
          disabled={isLoading}
        />
      </Box>
      <CommonTable
        columns={getColumns('location_indicators', 'Location Indicator')}
        data={(showLocationIndicators && data?.location_indicators) || []}
        hidePagination
        striped={false}
        keyField="indicator"
      />
    </>
  )
}

export default Table
