import { SEARCH_SELECT } from '../actions'

const defaultState = {
  selection: null,
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SEARCH_SELECT:
      return {
        ...state,
        selection: action.payload,
      }
    default:
      return state
  }
}
