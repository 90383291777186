import type { Range } from '../../types'

export const replaceRanges = (groupedRanges: Range[][]): Range[][] => {
  groupedRanges.forEach((group, index) => {
    if (index < groupedRanges.length - 1) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const currentCount = group.reduce((acc, current) => acc + current.uniqueCount, 0)
        const nextCount = groupedRanges[index + 1].reduce((acc, current) => acc + current.uniqueCount, 0)

        if (currentCount !== nextCount) {
          const offset = Math.abs(currentCount - nextCount)

          let newCurrentCount = 0
          let newNextCount = 0

          if (currentCount < nextCount) {
            newCurrentCount = currentCount + groupedRanges[index + 1][0].uniqueCount
            newNextCount = groupedRanges[index + 1].slice(1).reduce((acc, current) => acc + current.uniqueCount, 0)
          } else if (currentCount > nextCount) {
            newCurrentCount = group.slice(0, group.length - 1).reduce((acc, current) => acc + current.uniqueCount, 0)
            newNextCount = nextCount + group[group.length - 1].uniqueCount
          }

          const newOffset = Math.abs(newCurrentCount - newNextCount)

          if (newOffset < offset) {
            if (currentCount < nextCount) {
              group.push(groupedRanges[index + 1][0])
              groupedRanges[index + 1].splice(0, 1)
            } else if (currentCount > nextCount) {
              groupedRanges[index + 1] = [group[group.length - 1], ...groupedRanges[index + 1]]
              group.pop()
            }
          } else {
            break
          }
        } else {
          break
        }
      }
    }
  })

  return groupedRanges
}
