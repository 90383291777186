import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox } from '@mui/material'

export class Toggle extends Component {
  static propTypes = {
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    notice: PropTypes.object,
  }

  namesMapper = {
    supplier: 'Suppliers',
    debitor: 'Customers',
    location: 'Locations',
    manufacture: 'Own sites',
  }

  /**
   * @private
   */
  render() {
    return (
      <FormControlLabel
        label={this.namesMapper[this.props.name.slice(0, -1)]}
        control={
          <Checkbox
            onClick={(event) => this.onClick(event)}
            checked={this.currentState()}
            required={this.props.required}
          />
        }
      ></FormControlLabel>
    )
  }

  /**
   * @private
   */
  currentState() {
    return this.context.data[this.props.name]
  }

  /**
   * @private
   */
  onClick() {
    if (!this.props.readOnly && !this.context.disabled) {
      this.context.onChange(this.props.name, !this.currentState())
    }
  }
}

Toggle.contextTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
