import { DatePicker, Field } from '@riskmethods/rm-front-end'

type Props = {
  noValidity: boolean
  disabled?: boolean
}

export const ValidUntilDatePicker = ({ noValidity, disabled = false }: Props) => {
  const isDisabled = disabled || noValidity
  const isRequired = !noValidity

  return (
    <Field
      name="validUntil"
      label="Valid until"
      disabled={isDisabled}
      Component={(props) => (
        <DatePicker
          testId="valid-until-field"
          enableClearBtn
          desktopDatePickerProps={
            !isDisabled
              ? {
                  minDate: new Date(),
                  maxDate: new Date('2030-01-01'),
                }
              : {}
          }
          {...props}
        />
      )}
      validationRules={{
        required: isRequired,
        date: true,
        minDate: !isDisabled ? new Date() : undefined,
        maxDate: !isDisabled ? new Date('2030-01-01') : undefined,
      }}
    />
  )
}
