import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import I18n from 'i18next'

import { updateExclusionItem, deleteExclusionItem, updateForm, clearForm, setExpanded } from '../actions'

import {
  nameFormatter,
  alternativesFormatter,
  descriptionsFormatter,
  isNotStringFormatter,
  isNotNameFormatter,
  actionsFormatter,
} from './formatters'

import * as styles from './table.scss'

import { confirm } from '@common/components/alert'
import { exclusionItem } from '@common/utils/shapes'
import { setPage, setSort } from '@common/actions/table'
import CommonTable from '@common/components/table'

const buildColumns = (props) => [
  {
    dataField: 'name',
    text: I18n.t('common.exclusion_items_table.columns.name'),
    sort: true,
    formatter: nameFormatter,
    formatExtraData: {
      editMode: props.editMode,
      rows: props.rows,
      handleInputChange: props.handleInputChange,
    },
  },
  {
    dataField: 'alternatives',
    text: I18n.t('common.exclusion_items_table.columns.alternatives'),
    formatter: alternativesFormatter,
    formatExtraData: {
      editMode: props.editMode,
      rows: props.rows,
      handleInputChange: props.handleInputChange,
    },
  },
  {
    dataField: 'description',
    text: I18n.t('common.exclusion_items_table.columns.description'),
    formatter: descriptionsFormatter,
    formatExtraData: {
      editMode: props.editMode,
      rows: props.rows,
      handleInputChange: props.handleInputChange,
    },
  },
  {
    dataField: 'isNotString',
    text: I18n.t('common.exclusion_items_table.columns.is_not_string'),
    formatter: isNotStringFormatter,
    formatExtraData: {
      editMode: props.editMode,
      rows: props.rows,
      handleInputChange: props.handleInputChange,
    },
  },
  {
    dataField: 'isNotName',
    text: I18n.t('common.exclusion_items_table.columns.is_not_name'),
    formatter: isNotNameFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      onDelete: props.onDelete,
      expanded: props.expanded,
      handleInputChange: props.handleInputChange,
      editMode: props.editMode,
      rows: props.rows,
      keywords: props.keywords,
    },
  },
  {
    dataField: 'actions',
    text: I18n.t('common.exclusion_items_table.columns.action'),
    formatter: actionsFormatter,
    formatExtraData: {
      onApprove: props.onApprove,
      onDelete: props.onDelete,
      onSaveClick: props.onSaveClick,
      onEditClick: props.onEditClick,
      onCancelClick: props.onCancelClick,
      editMode: props.editMode,
    },
  },
]

class Table extends Component {
  static propTypes = {
    totalResults: PropTypes.number,
    page: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(exclusionItem),
    keywords: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
    setPage: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    updateExclusionItem: PropTypes.func.isRequired,
    deleteExclusionItem: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    setExpanded: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    formControls: PropTypes.object,
    expanded: PropTypes.object,
  }

  pageChanged = (page) => {
    this.props.setPage('ELIST', page)
    this.props.fetchData()
  }

  sortChanged = (sortField, sortOrder) => {
    this.props.setSort('ELIST', sortField, sortOrder)
    this.props.fetchData()
  }

  state = {
    editMode: {},
    textareaRows: 0,
  }

  handleRowExpanded = (index) => {
    this.props.setExpanded({ [index]: !this.props.expanded[index] })
  }

  handleInputChange = (name, value) => {
    this.props.updateForm({ name, value })
  }

  onDeleteClick = (e, item) => {
    e.preventDefault()
    confirm({
      title: I18n.t('common.exclusion_items_table.alerts.delete.title'),
      body: I18n.t('common.exclusion_items_table.alerts.delete.body'),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(
      () => {
        this.props.deleteExclusionItem(item)
      },
      () => null
    )
  }

  onSaveClick = (e, index) => {
    e.preventDefault()
    this.setState({
      editMode: {
        [index]: !this.state.editMode[index],
      },
    })
    this.props.updateExclusionItem().then(this.props.clearForm())
  }

  onEditClick = (e, item, index) => {
    e.preventDefault()
    this.setState({
      editMode: {
        [index]: !this.state.editMode[index],
      },
      textareaRows: this.minRowsCount(item),
    })
    for (const name in item) {
      this.props.updateForm({ name, value: item[name] })
    }
  }

  minRowsCount = ({ name, description, alternatives, isNotString, isNotName }) => {
    return (
      Math.max(
        isEmpty(name) ? 0 : Math.ceil(name.length / 40),
        isEmpty(description) ? 0 : Math.ceil(description.length / 40),
        isEmpty(alternatives) ? 0 : alternatives.length,
        isEmpty(isNotString) ? 0 : Math.ceil(isNotString.length / 100),
        isEmpty(isNotName) ? 0 : isNotName.length
      ) + 1
    )
  }

  onCancelClick = (e, index) => {
    e.preventDefault()
    this.setState({
      editMode: {
        [index]: !this.state.editMode[index],
      },
    })
    this.props.clearForm()
  }

  render() {
    const { loading, items, keywords, totalResults, page, errors } = this.props
    const columns = buildColumns({
      handleRowExpanded: this.handleRowExpanded,
      onDelete: this.onDeleteClick,
      expanded: this.props.expanded,
      editMode: this.state.editMode,
      rows: this.state.textareaRows,
      handleInputChange: this.handleInputChange,
      onSaveClick: this.onSaveClick,
      onEditClick: this.onEditClick,
      onCancelClick: this.onCancelClick,
      formData: this.state.form,
      keywords,
    })
    return (
      <div className={styles.table}>
        <CommonTable
          data={items}
          loading={loading}
          columns={columns}
          pageChanged={this.pageChanged}
          sortChanged={this.sortChanged}
          emptyLabel={I18n.t('common.exclusion_items_table.table.exclusion_items.empty')}
          perPage={100}
          totalSize={totalResults}
          page={page}
          errors={errors}
        />
      </div>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    table: {
      page,
      loading,
      totalResults,
      errors,
      data: { items },
      keywords,
      expanded,
    },
  } = rootState.elist

  return {
    errors,
    page,
    totalResults,
    items,
    loading,
    keywords,
    expanded,
  }
}

const mapDispatchToProps = {
  updateExclusionItem,
  deleteExclusionItem,
  updateForm,
  clearForm,
  setSort,
  setPage,
  setExpanded,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
