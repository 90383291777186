import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { useAppContext } from '@hooks'

const OpenInPodato = ({ clusterId, customClassName = '' }) => {
  const { env } = useAppContext()

  const podatoURL = `${env.PODATO_APP_URL}/partners-matching?selectedCluster=${clusterId}&clusterId=${clusterId}`

  return (
    <Tooltip title={'Open in PODATO'} placement="top" aria-label={'open cluster details in podato'}>
      <IconButton component={'a'} className={customClassName} href={podatoURL} target={'_blank'} rel="noreferrer">
        <OpenInNewIcon color="primary" />
      </IconButton>
    </Tooltip>
  )
}

OpenInPodato.propTypes = {
  clusterId: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  podatoUrl: PropTypes.string,
}

export default OpenInPodato
