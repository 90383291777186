import { createContext, useContext, useState, ReactNode } from 'react'

import type { AuthAction } from '@types'

type Env = {
  VERSION: string
  AUTH_URL: string
  AUTH_CLIENT_ID: string
  PODATO_APP_URL: string
  REMOTE_ENV: string
}

type TAppContext = {
  env: Env
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  activeAuthAction: AuthAction | null
  setActiveAuthAction: (action: AuthAction | null) => void
  showInboxStats: boolean
  toggleInboxStats: (showInboxStats: boolean) => void
}

const AppContext = createContext<TAppContext>({} as TAppContext)

type Props = {
  children: ReactNode
  env: Env
}

export const AppContextProvider = ({ children, env }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeAuthAction, setActiveAuthAction] = useState<AuthAction | null>(null)
  const [showInboxStats, toggleInboxStats] = useState<boolean>(false)

  return (
    <AppContext.Provider
      value={{
        env,
        isLoading,
        setIsLoading,
        activeAuthAction,
        setActiveAuthAction,
        showInboxStats,
        toggleInboxStats,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
