/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'

import {
  LOAD_DATA,
  LOAD_DATA_FINISHED,
  BUCKETS_RECEIVED,
  ERROR_RECEIVED,
  USERS_RECEIVED,
  ASSIGNMENT_RECEIVED,
  ADD_ASSIGNEE,
  CHANGE_ASSIGNEE,
  REMOVE_ASSIGNEE,
  RESET_ASSIGNEES,
  START_EDITING,
  STOP_EDITING,
  OPEN_MODAL,
  CLOSE_MODAL,
  REASSIGN_HITS,
  SET_HITS_UNTIL_TIME,
  TIME_FILTERED_BUCKETS_RECEIVED,
} from '../actions'

import { rangesToString } from '../utils/hits'
import { countHitTypesInRange } from './helpers/count_hit_types_in_range'
import { getBucketsFromRange } from './helpers/get_buckets_from_range'
import { extractAssigneeRangeByPercentage } from './helpers/extract_assignee_range_by_percentage'

import { distributeHits } from '../components/hits_distribution/distribution_algorithm'

export const createNewAssignee = (id = null) => {
  const assigneeId = id || 'new-' + uuidv4()

  return {
    id: assigneeId,
    totalHitsCount: 0,
    uniqueHitsCount: 0,
    openTotalHitsCount: 0,
    openUniqueHitsCount: 0,
    ranges: [],
  }
}

export const initialState = {
  loading: false,
  isEditing: false,
  isModalOpen: false,
  totalHitsCount: 0,
  totalUniqueHitsCount: 0,
  editingId: null,
  hitsUntilTime: null,
  buckets: [],
  filteredBuckets: [],
  users: [],
  assignees: [],
  errors: [],
  table: [],
}

const compareAssignees = (a, b) => a.searchLetter.localeCompare(b.searchLetter)

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA:
      return {
        ...state,
        loading: true,
      }
    case LOAD_DATA_FINISHED:
      return {
        ...state,
        loading: false,
      }
    case BUCKETS_RECEIVED: {
      const { buckets, totalHitsCount, totalUniqueHitsCount } = action.data
      return {
        ...state,
        buckets,
        filteredBuckets: buckets,
        totalUniqueHitsCount,
        totalHitsCount,
        errors: [],
      }
    }
    case TIME_FILTERED_BUCKETS_RECEIVED:
      return {
        ...state,
        filteredBuckets: action.data.buckets,
        assignees: countHitTypesInAssigneeRanges({
          assignees: state.assignees,
          buckets: state.buckets,
          filteredBuckets: action.data.buckets,
        }),
      }
    case ERROR_RECEIVED:
      return {
        ...state,
        loading: false,
        errors: action.data.errors,
      }
    case ADD_ASSIGNEE: {
      const newAssignees = [...state.assignees, createNewAssignee()]

      return {
        ...state,
        assignees: recalculateRanges({
          buckets: state.buckets,
          assignees: newAssignees,
          totalUniqueHitsCount: state.totalUniqueHitsCount,
        }),
      }
    }
    case CHANGE_ASSIGNEE:
      return {
        ...state,
        assignees: state.assignees.map((assignee) => {
          if (action.data.previousId === assignee.id) {
            return { ...assignee, id: action.data.id }
          }

          return assignee
        }),
      }
    case REMOVE_ASSIGNEE: {
      const assigneesAfterRemoval = state.assignees.filter((assignee) => {
        return assignee.id !== action.data.id
      })
      return {
        ...state,
        assignees: recalculateRanges({
          buckets: state.buckets,
          assignees: assigneesAfterRemoval,
          totalUniqueHitsCount: state.totalUniqueHitsCount,
        }),
      }
    }
    case RESET_ASSIGNEES:
      return {
        ...state,
        assignees: [],
      }
    case USERS_RECEIVED:
      return {
        ...state,
        users: action.data.users,
      }
    case ASSIGNMENT_RECEIVED:
      return {
        ...state,
        assignees: countHitTypesInAssigneeRanges({
          assignees: action.data.assignees,
          buckets: action.data.buckets,
          filteredBuckets: action.data.buckets,
        }),
      }
    case START_EDITING:
      return {
        ...state,
        isEditing: true,
      }
    case STOP_EDITING:
      return {
        ...state,
        isEditing: false,
      }
    case OPEN_MODAL:
      return {
        ...state,
        editingId: action.data.id,
        isModalOpen: true,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      }
    case SET_HITS_UNTIL_TIME:
      return {
        ...state,
        hitsUntilTime: action.data.time,
      }
    case REASSIGN_HITS: {
      const { fromUserId, toUserIds, percentage } = action.data
      const { assignees } = state

      if (percentage === 0) {
        return state
      }

      if (percentage === 100) {
        return {
          ...state,
          assignees: countHitTypesInAssigneeRanges({
            assignees: reassignWholeRange({ assignees, fromUserId, toUserIds, buckets: state.buckets }),
            buckets: state.buckets,
            filteredBuckets: state.filteredBuckets,
          }),
        }
      }
      return {
        ...state,
        assignees: countHitTypesInAssigneeRanges({
          assignees: reassignPartialRanges({
            assignees,
            fromUserId,
            toUserIds,
            percentage,
            buckets: state.buckets,
          }),
          buckets: state.buckets,
          filteredBuckets: state.filteredBuckets,
        }),
      }
    }
    default:
      return state
  }
}

const countHitTypesInAssigneeRanges = ({ assignees, buckets, filteredBuckets }) => {
  return assignees
    .map((assignee) => {
      const { id, ranges } = assignee

      let [totalHitsCount, uniqueHitsCount, openTotalHitsCount, openUniqueHitsCount] = [0, 0, 0, 0]
      ranges.forEach((range) => {
        const rangeTotals = countHitTypesInRange({ buckets, range })
        const rangeFilteredTotals = countHitTypesInRange({ buckets: filteredBuckets, range })

        ;(range.uniqueHitsCount = rangeTotals.uniqueHitsCount), (range.totalHitsCount = rangeTotals.totalHitsCount)

        totalHitsCount += rangeTotals.totalHitsCount
        uniqueHitsCount += rangeTotals.uniqueHitsCount
        openTotalHitsCount += rangeFilteredTotals.openTotalHitsCount
        openUniqueHitsCount += rangeFilteredTotals.openUniqueHitsCount
      })

      return {
        id,
        ranges,
        totalHitsCount,
        uniqueHitsCount,
        openTotalHitsCount,
        openUniqueHitsCount,
        searchLetter: rangesToString(ranges),
      }
    })
    .sort(compareAssignees)
}

function mapRangesToAssignees({ toAssignees, buckets, ranges, totalPercentReassigned }) {
  // if totalPercentReassigned is passed it means the ranges are being reassigned to new assignees
  const splittedPercentReassigned =
    totalPercentReassigned && toAssignees.length ? Math.floor(totalPercentReassigned / toAssignees.length) : null

  return toAssignees
    .map((assignee, i) => {
      const { uniqueHitsCount, totalHitsCount, openTotalHitsCount, openUniqueHitsCount } = countHitTypesInRange({
        buckets,
        range: ranges[i],
      })

      ranges[i].uniqueHitsCount = uniqueHitsCount
      ranges[i].totalHitsCount = totalHitsCount
      ranges[i].percentOfHitsReassigned = splittedPercentReassigned

      const newRanges = [...assignee.ranges, ranges[i]]

      return {
        ...assignee,
        ranges: newRanges,
        searchLetter: rangesToString(newRanges),
        totalHitsCount: assignee.totalHitsCount + totalHitsCount,
        uniqueHitsCount: assignee.uniqueHitsCount + uniqueHitsCount,
        openTotalHitsCount: assignee.openTotalHitsCount + openTotalHitsCount,
        openUniqueHitsCount: assignee.openUniqueHitsCount + openUniqueHitsCount,
      }
    })
    .sort(compareAssignees)
}

function reassignPartialRanges({ assignees, fromUserId, toUserIds, percentage, buckets }) {
  const [fromAssignee] = assignees.filter((assignee) => String(assignee.id) === String(fromUserId))
  const toAssignees = assignees.filter((assignee) => toUserIds.map(String).includes(String(assignee.id)))

  const { extractedRange, leftOverRange } = extractAssigneeRangeByPercentage({
    percentage,
    buckets,
    assignee: fromAssignee,
  })

  const splittedExtractedRanges = distributeHits(
    getBucketsFromRange({ buckets, range: extractedRange }),
    toUserIds.length
  )

  const fromAssigneeAfterReassignment = {
    ...fromAssignee,
    ranges: [leftOverRange],
  }

  const toAssigneesAfterReassignment = mapRangesToAssignees({
    toAssignees,
    buckets,
    ranges: splittedExtractedRanges,
    totalPercentReassigned: percentage,
  })

  return assignees
    .map((assignee) => {
      if (String(assignee.id) === String(fromAssigneeAfterReassignment.id)) {
        return fromAssigneeAfterReassignment
      }
      const foundToAssignee = toAssigneesAfterReassignment.find((toAssignee) => {
        return String(toAssignee.id) === String(assignee.id)
      })

      if (foundToAssignee) {
        return foundToAssignee
      }

      return assignee
    })
    .sort(compareAssignees)
}

function reassignWholeRange({ assignees, fromUserId, toUserIds, buckets }) {
  const [fromAssignee] = assignees.filter((assignee) => String(assignee.id) === String(fromUserId))
  const toAssignees = assignees.filter((assignee) => toUserIds.map(String).includes(String(assignee.id)))

  const splittedExtractedRanges = distributeHits(
    getBucketsFromRange({ buckets, range: fromAssignee.ranges[0] }),
    toUserIds.length
  )

  const toAssigneesAfterReassignment = mapRangesToAssignees({ toAssignees, buckets, ranges: splittedExtractedRanges })

  return assignees
    .filter((assignee) => {
      return String(fromAssignee.id) !== String(assignee.id)
    })
    .map((assignee) => {
      const foundToAssignee = toAssigneesAfterReassignment.find((toAssignee) => {
        return String(toAssignee.id) === String(assignee.id)
      })

      if (foundToAssignee) {
        return foundToAssignee
      }

      return assignee
    })
}

function recalculateRanges({ buckets, assignees, totalUniqueHitsCount }) {
  if (assignees.length === 0) {
    return []
  }

  if (totalUniqueHitsCount === 0) {
    return assignees
  }

  const splittedRanges = distributeHits(buckets, assignees.length)

  if (assignees.length > splittedRanges.length) {
    assignees.pop()
    toast.warn('Not able to add anymore assignees', {
      hideProgressBar: true,
      autoClose: 2000,
    })
  }

  const toAssignees = assignees.map((assignee) => createNewAssignee(assignee.id))

  return mapRangesToAssignees({ toAssignees, buckets, ranges: splittedRanges })
}
