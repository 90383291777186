import { reject, map, isEmpty, uniqBy, uniqWith, flatMap, intersectionBy } from 'lodash'

import {
  CLEAR_FORM,
  INDICATOR_SELECTED,
  REMOVE_SUPPLIER,
  SUPPLIERS_FETCHED,
  SUPPLIERS_LOADING,
  FORM_INVALID,
  FORM_VALID,
  FORM_CHANGED,
  REMOVE_SEARCH,
  REMOVE_FORM,
} from '../actions/supplier'

import initialState from './initial_state'
import { INDICATORS_FETCHED } from '@common/actions/fetch_indicators'
import { COUNTRIES_FETCHED } from '@common/actions/fetch_countries'

const filterBusinessPartnersWithPriority = (priorityBusinessPartners, businessPartners) => {
  const ids = map(priorityBusinessPartners, ({ id }) => id)

  return businessPartners.filter(({ id }) => !ids.includes(id))
}

const uniqBusinessPartners = (businessPartners, newBusinessPartners) => {
  const comparator = ({ id }, { id: otherId }) => id === otherId
  return uniqWith(businessPartners.concat(newBusinessPartners || []), comparator)
}

const prepareSuppliers = (hasPriority, newSuppliers, otherFormSuppliers, otherFormAvailableSuppliers) => {
  if (hasPriority) {
    return {
      suppliers: newSuppliers,
      otherFormSuppliers: filterBusinessPartnersWithPriority(newSuppliers, otherFormAvailableSuppliers),
    }
  } else {
    return {
      suppliers: filterBusinessPartnersWithPriority(otherFormSuppliers, newSuppliers),
      otherFormSuppliers,
    }
  }
}

const setPriorityAfterClear = (currentPriority, formName, otherFormName, otherFormSuppliers) => {
  if (currentPriority === formName) {
    return isEmpty(otherFormSuppliers) ? null : otherFormName
  } else {
    return currentPriority
  }
}

// We check all the existing ids in the searches array,
// and we add + 1 to the highest one. This way we should never
// receive duplicated id.
const getSearchId = (currentSearches) => {
  if (currentSearches.length === 0) {
    return 1
  }

  const currentSearchIds = currentSearches.filter((search) => !search.additional).map((search) => Number(search.id))
  return Math.max(...currentSearchIds) + 1
}

const otherFormMap = {
  alert: 'news',
  news: 'alert',
}

export default function general(state = initialState.supplier, { type, data, metaData }) {
  switch (type) {
    case INDICATORS_FETCHED:
      if (metaData.indicatorType !== 'business_partner') {
        return state
      }

      return {
        ...state,
        data: { ...state.data, ...{ indicators: data, indicatorsLoading: false } },
      }
    case COUNTRIES_FETCHED:
      return {
        ...state,
        data: { ...state.data, ...{ countries: data } },
      }
    case SUPPLIERS_LOADING: {
      const formName = `${metaData.type}Form`
      return {
        ...state,
        data: { ...state.data, ...{ [formName]: { ...state.data[formName], suppliersLoading: true } } },
      }
    }
    case SUPPLIERS_FETCHED: {
      const formName = `${metaData.type}Form`
      const otherForm = `${otherFormMap[metaData.type]}Form`
      const { suppliers, otherFormSuppliers } = prepareSuppliers(
        state.priorityForm === formName,
        uniqBusinessPartners(state.data[formName].suppliers, data),
        state.data[otherForm].suppliers,
        state.data[otherForm].availableSuppliers
      )
      const mainSearch = {
        id: getSearchId(state.data[formName].searches).toString(),
        name: metaData.searchName,
        result: data,
        additional: false,
      }

      return {
        ...state,
        data: {
          ...state.data,
          ...{
            [formName]: {
              ...state.data[formName],
              suppliers,
              searches: state.data[formName].searches.concat([mainSearch]),
              availableSuppliers: uniqBusinessPartners(state.data[formName].availableSuppliers, data),
              suppliersLoading: false,
            },
          },
          ...{ [otherForm]: { ...state.data[otherForm], suppliers: otherFormSuppliers, suppliersLoading: false } },
        },
      }
    }

    case CLEAR_FORM: {
      const formName = `${metaData.type}Form`
      const otherForm = `${otherFormMap[metaData.type]}Form`
      const priorityForm = setPriorityAfterClear(
        state.priorityForm,
        formName,
        otherForm,
        state.data[otherForm].availableSuppliers
      )
      return {
        ...state,
        priorityForm,
        data: {
          ...state.data,
          ...{ [formName]: { suppliers: [], availableSuppliers: [], suppliersLoading: false, searches: [] } },
          ...{ [otherForm]: { ...state.data[otherForm], suppliers: state.data[otherForm].availableSuppliers } },
        },
      }
    }

    case REMOVE_FORM: {
      const formName = `${metaData.type}Form`
      const otherForm = `${otherFormMap[metaData.type]}Form`
      const priorityForm = setPriorityAfterClear(
        state.priorityForm,
        formName,
        otherForm,
        state.data[otherForm].availableSuppliers
      )
      return {
        ...state,
        priorityForm,
        data: {
          ...state.data,
          ...{ [formName]: { suppliers: [], availableSuppliers: [], suppliersLoading: false, searches: [] } },
        },
      }
    }

    case FORM_CHANGED: {
      const formName = `${metaData.type}Form`
      const otherFormName = `${otherFormMap[metaData.type]}Form`
      const otherFormSuppliers = state.data[otherFormName].availableSuppliers
      return { ...state, priorityForm: isEmpty(otherFormSuppliers) ? formName : otherFormName }
    }

    case REMOVE_SUPPLIER: {
      const formName = `${metaData.type}Form`
      const otherForm = `${otherFormMap[metaData.type]}Form`
      const suppliers = filterBusinessPartnersWithPriority([data], state.data[formName].suppliers)
      const otherSuppliers =
        state.priorityForm === formName
          ? filterBusinessPartnersWithPriority(suppliers, state.data[otherForm].availableSuppliers)
          : state.data[otherForm].suppliers

      return {
        ...state,
        data: {
          ...state.data,
          ...{
            [formName]: {
              ...state.data[formName],
              suppliers,
              availableSuppliers: filterBusinessPartnersWithPriority([data], state.data[formName].availableSuppliers),
            },
          },
          ...{ [otherForm]: { ...state.data[otherForm], suppliers: otherSuppliers } },
        },
      }
    }

    case REMOVE_SEARCH: {
      const formName = `${metaData.type}Form`
      const otherForm = `${otherFormMap[metaData.type]}Form`
      const searches = reject(state.data[formName].searches, { id: data })
      const currentSuppliers = state.data[formName].suppliers
      const searchesSuppliers = uniqBy(flatMap(searches, 'result'), 'id')

      const { suppliers, otherFormSuppliers } = prepareSuppliers(
        state.priorityForm === formName,
        intersectionBy(currentSuppliers, searchesSuppliers, 'id'),
        state.data[otherForm].suppliers,
        state.data[otherForm].availableSuppliers
      )
      return {
        ...state,
        data: {
          ...state.data,
          ...{ [formName]: { ...state.data[formName], suppliers, searches, availableSuppliers: suppliers } },
          ...{ [otherForm]: { ...state.data[otherForm], suppliers: otherFormSuppliers } },
        },
      }
    }

    case INDICATOR_SELECTED: {
      return {
        ...state,
        data: { ...state.data, ...{ alertForm: { ...state.data.alertForm, selectedIndicator: data } } },
      }
    }

    case FORM_INVALID:
      return {
        ...state,
        data: {
          ...state.data,
          alertForm: { ...state.data.alertForm, suppliersLoading: false },
          newsForm: { ...state.data.newsForm, suppliersLoading: false },
        },
        valid: false,
        errors: data,
      }

    case FORM_VALID:
      return {
        ...state,
        valid: true,
        errors: {},
      }
    default:
      return state
  }
}
