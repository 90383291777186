import { concat } from 'lodash'
import I18n from 'i18next'

import initialState from './initial_state'
import { INDICATORS_FETCHED } from '@common/actions/fetch_indicators'
import {
  UPDATE_LOCATION_DATA,
  RESET_LOCATIONS,
  APPEND_LOCATION,
  APPEND_LOCATION_FAILED,
  FORM_INVALID,
  FORM_VALID,
  MAP_UPDATED,
  MAP_BOUNDS_FITTED,
  REFRESH_MAP,
  FIND_LOCATION_FINISHED,
  FIND_LOCATION_IN_PROGRESS,
  TOGGLE_IS_POLYGON,
  ENABLE_POLYGON_TOOLS,
} from '@pages/im/actions/location'
import { UPDATE_FEATURES, UPDATE_MAP, BOUNDS_CHANGED } from '@pages/world-map/actions'

import filter from '@pages/world-map/reducers/filter'
import layers from '@pages/world-map/reducers/layers'
import visibility from '@pages/world-map/reducers/visibility'
import loading from '@pages/world-map/reducers/loading'

const updateFeatures = (newState) => {
  return Object.keys(newState.layers)
    .filter((k) => newState.filter.layers[k])
    .map((k) => newState.layers[k])
    .reduce((acc, v) => acc.concat(v), [])
}

export default function location(state = initialState.location, action) {
  const newState = {
    ...state,
    filter: filter(state.filter, action),
    layers: layers(state.layers, action),
    visibility: visibility(state.visibility, action),
    loading: loading(state.loading, action),
  }

  const { type, data, metaData } = action

  switch (type) {
    case UPDATE_LOCATION_DATA:
      return {
        ...newState,
        data: { ...newState.data, ...data },
        externalServiceErrors: null,
        ...(metaData.refreshMap
          ? { updateMap: metaData.refreshMap.data, fitMapBounds: metaData.refreshMap.bounds }
          : {}),
      }

    case RESET_LOCATIONS: {
      return {
        ...newState,
        data: { ...newState.data, selectedLocations: [] },
        updateMap: true,
        fitMapBounds: false,
        externalServiceErrors: null,
      }
    }

    case APPEND_LOCATION: {
      return {
        ...newState,
        data: { ...newState.data, selectedLocations: concat(newState.data.selectedLocations, data) },
        updateMap: true,
        fitMapBounds: false,
        externalServiceErrors: null,
      }
    }

    case APPEND_LOCATION_FAILED: {
      return {
        ...newState,
        externalServiceErrors: [I18n.t('common.messages.unable_to_retrieve_location_name')],
        updateMap: false,
        fitMapBounds: false,
      }
    }

    case TOGGLE_IS_POLYGON: {
      const dataWithRadius = {
        polygonPoints: null,
        polygonArea: null,
        radius: newState.data.backupRadius,
        selectedLocations: transformSelectedLocations(newState.data.selectedLocations),
      }

      const changedData = data ? { radius: null } : dataWithRadius

      return {
        ...newState,
        data: { ...newState.data, ...changedData, isPolygon: data, editable: false, draggable: false },
        updateMap: true,
        fitMapBounds: false,
      }
    }

    case ENABLE_POLYGON_TOOLS: {
      return {
        ...newState,
        polygonToolsEnabled: data,
      }
    }

    case REFRESH_MAP:
      return {
        ...newState,
        fitMapBounds: true,
        updateMap: true,
      }

    case MAP_BOUNDS_FITTED:
      return {
        ...newState,
        fitMapBounds: false,
      }

    case FORM_INVALID:
      return {
        ...newState,
        valid: false,
        errors: data,
      }

    case FORM_VALID:
      return {
        ...newState,
        valid: true,
        errors: {},
      }

    case INDICATORS_FETCHED:
      if (metaData.indicatorType !== 'location') {
        return newState
      }

      return {
        ...newState,
        data: { ...newState.data, ...{ indicators: data, indicatorsLoading: false } },
      }
    case FIND_LOCATION_IN_PROGRESS:
      return {
        ...newState,
        data: {
          ...newState.data,
          findLocation: { loading: true },
        },
      }

    case FIND_LOCATION_FINISHED:
      return {
        ...newState,
        data: {
          ...newState.data,
          findLocation: { loading: false },
        },
      }

    case MAP_UPDATED:
      return {
        ...newState,
        updateMap: false,
      }

    case UPDATE_FEATURES:
      return {
        ...newState,
        features: updateFeatures(newState),
      }

    case UPDATE_MAP:
      return {
        ...newState,
        updateMap: true,
      }

    case BOUNDS_CHANGED:
      return {
        ...newState,
        bounds: action.bounds,
        updateMap: false,
      }

    default:
      return newState
  }
}

const transformSelectedLocations = (selectedLocations) => {
  if (selectedLocations.length === 1 && selectedLocations[0].latitude === 0 && selectedLocations[0].longitude === 0)
    return []
  return selectedLocations
}
