import { isBucketInRange } from './is_bucket_in_range'

export function extractAssigneeRangeByPercentage({ percentage, buckets, assignee }) {
  const amountToSplit = (percentage / 100) * assignee.uniqueHitsCount

  const [range] = assignee.ranges

  const rangeStart = range.start
  const rangeEnd = range.finish

  if (percentage === 0 || percentage === 100) {
    throw new Error('percentage needs to be between 1-99')
  }

  let summedRangeUnique = 0
  let summedRangeTotal = 0
  let extractedRangeUniq = 0
  let extractedRangeTotal = 0
  const firstExtractedRangeStart = rangeStart
  let firstExtractedRangeEnd = rangeStart
  let leftOverRangeStart = null
  const leftOverRangeEnd = rangeEnd

  buckets.every((bucket, i) => {
    const { firstLetters, uniqueCount, count } = bucket
    const isInRange = isBucketInRange({ bucket, range })

    if (isInRange) {
      summedRangeUnique += uniqueCount
      summedRangeTotal += count

      if (summedRangeUnique >= amountToSplit) {
        firstExtractedRangeEnd = firstLetters
        extractedRangeUniq = summedRangeUnique
        extractedRangeTotal = summedRangeTotal
        leftOverRangeStart = buckets[i + 1].firstLetters

        return false
      }
    }

    return true
  })

  const extractedRange = {
    start: firstExtractedRangeStart,
    finish: firstExtractedRangeEnd,
    totalHitsCount: extractedRangeTotal,
    uniqueHitsCount: extractedRangeUniq,
    percentOfHitsReassigned: percentage,
  }

  const leftOverRange = {
    start: leftOverRangeStart,
    finish: leftOverRangeEnd,
    totalHitsCount: assignee.totalHitsCount - extractedRangeTotal,
    uniqueHitsCount: assignee.uniqueHitsCount - extractedRangeUniq,
    percentOfHitsReassigned: -percentage,
  }

  return { extractedRange, leftOverRange }
}
