import { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@mui/material'
import I18n from 'i18next'

import { FIRST_PAGE } from '@common/actions/table'
import { processKeywordsSearch } from '@common/entities/messages/utils'
import { FiltersCont } from '@common/layout'

export default class Filters extends Component {
  static propTypes = {
    changeFilters: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setExpanded: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    filters: PropTypes.object,
    openAddCompanyModal: PropTypes.func.isRequired,
  }

  handleKeywordsSearchChange = ({ target: { value } }) => {
    this.props.changeFilters('ELIST', { keywords: processKeywordsSearch(value) })
  }

  handleSearchClick = (ev) => {
    ev.preventDefault()
    this.props.setExpanded({})
    this.props.setPage('ELIST', FIRST_PAGE)
    this.props.fetchData()
  }

  handleAddItemClick = (ev) => {
    ev.preventDefault()
    this.props.openAddCompanyModal()
  }

  render() {
    return (
      <FiltersCont component="form" sx={{ pt: '1rem', mb: 1 }} justifyContent={'end'}>
        <TextField
          label="Search"
          data-testid="query"
          sx={{ width: 350 }}
          onChange={this.handleKeywordsSearchChange}
          onKeyDown={(e) => e.key === 'Enter' && this.handleSearchClick(e)}
        />
        <Button type="submit" color="secondary" data-testid="search" onClick={this.handleSearchClick}>
          {I18n.t('history.filter.buttons.search')}
        </Button>
        <Button data-testid="add_company" color="primary" onClick={this.handleAddItemClick}>
          {I18n.t('exclusion_list.buttons.add_company')}
        </Button>
      </FiltersCont>
    )
  }
}
