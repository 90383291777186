/* eslint-disable no-nested-ternary */
import moment from 'moment/moment'
import { MOMENT_DATE_FORMAT } from '@common/components/date_picker'

const compareAscending = (dateA, dateB) => {
  return moment(dateA, MOMENT_DATE_FORMAT).isAfter(moment(dateB, MOMENT_DATE_FORMAT)) ? 1 : -1
}

const compareDescending = (dateA, dateB) => {
  return moment(dateA, MOMENT_DATE_FORMAT).isBefore(moment(dateB, MOMENT_DATE_FORMAT)) ? 1 : -1
}

export default (dateA, dateB, order) =>
  order === 'asc' ? compareAscending(dateA, dateB) : compareDescending(dateA, dateB)
