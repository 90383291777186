import { showMoreFormatter } from './show_more_formatter/show_more_formatter'
import { handleEmptyFormatter } from './handle_empty_formatter'

export const industriesFormatter = (cell, _, rowIndex, formatExtraData) => {
  const { industryMap } = formatExtraData

  if (cell?.length > 0) {
    const mappedIndustries = cell.map((industry) => industryMap[industry])

    return showMoreFormatter(mappedIndustries, rowIndex, formatExtraData)
  } else {
    return handleEmptyFormatter()
  }
}
