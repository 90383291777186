// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location--gdiVD{height:calc(100vh - 50px)}.map--_8Mjl{height:100%}`, "",{"version":3,"sources":["webpack://./src/client/pages/im/components/im-type-specific-form/components/location-im-form/location-im-form.scss"],"names":[],"mappings":"AAAA,iBACE,yBAAA,CAGF,YACE,WAAA","sourceRoot":""}]);
// Exports
export var location = `location--gdiVD`;
export var map = `map--_8Mjl`;
export default ___CSS_LOADER_EXPORT___;
