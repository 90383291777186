import PropTypes from 'prop-types'
import { confirmable, createConfirmation } from 'react-confirm'
import { Button, DialogContentText } from '@mui/material'
import I18n from 'i18next'

import ThemeProvider from '@common/theme'

import Modal from './modal'

const Alert = (props) => {
  const {
    title,
    body,
    proceedLabel,
    cancelLabel,
    show,
    proceed,
    dismiss,
    cancel,
    isDeleteDialog,
    modalProps,
    isFullWidth,
  } = props

  return (
    <ThemeProvider>
      <Modal
        isOpen={show}
        onClose={dismiss}
        title={title}
        footer={
          <>
            <Button
              color={isDeleteDialog ? 'error' : 'primary'}
              onClick={proceed}
              data-testid="modal_apply"
              sx={{ color: isDeleteDialog ? 'black' : undefined }}
            >
              {proceedLabel || I18n.t('common.yes')}
            </Button>
            <Button color="secondary" onClick={cancel} data-testid="modal_cancel">
              {cancelLabel || I18n.t('common.no')}
            </Button>
          </>
        }
        isDeleteDialog={isDeleteDialog}
        modalProps={modalProps}
        isFullWidth={isFullWidth}
      >
        <DialogContentText>{body}</DialogContentText>
      </Modal>
    </ThemeProvider>
  )
}

Alert.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  modalProps: PropTypes.object,
  // Props below come from confirmable HOC
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  isDeleteDialog: PropTypes.bool,
}

export const confirm = (options) => createConfirmation(confirmable(Alert))(options)

export default confirmable(Alert)
