import { useDispatch } from 'react-redux'
import { UseFormReturn } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import qs from 'qs'

import {
  ArrowCircleLeftOutlined as ArrowLeftIcon,
  ArrowCircleRightOutlined as ArrowRightIcon,
} from '@mui/icons-material'

import { buildPreloadedState } from '@pages/im/utils/build_preloaded_state'
import { setImInitState } from '@pages/im/actions'

import { IM_FORM_DEFAULT_VALUES } from '@components/form/im-form-fields'
import { useSavedHitsContext } from '@hooks'

type Props = {
  articleFormContext: UseFormReturn
}

const ArticleNav = ({ articleFormContext }: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { getActiveArticleHitIndex, getArticleNavHitIds, navigateToArticle } = useSavedHitsContext()

  const index = getActiveArticleHitIndex()
  const ids = getArticleNavHitIds()

  const disablePrev = index === 0
  const disabledNext = index === ids.length - 1

  const onNavigate = (id: number) => {
    articleFormContext.reset(IM_FORM_DEFAULT_VALUES)
    const state = buildPreloadedState({})
    dispatch(setImInitState(state))
    const savedById = (qs.parse(location.search, { ignoreQueryPrefix: true }).saved_by_id || 'any') as string
    navigateToArticle(id, savedById)
  }

  const onNavigatePrev = () => {
    onNavigate(ids[index - 1])
  }

  const onNavigateNext = () => {
    onNavigate(ids[index + 1])
  }

  return (
    <Grid container justifyContent="space-between" p={1}>
      <Grid item container alignItems="center" xs="auto">
        <Button
          variant="text"
          startIcon={<ArrowLeftIcon />}
          onClick={onNavigatePrev}
          disabled={disablePrev}
          disableRipple
        >
          Previous hit
        </Button>
      </Grid>
      <Grid item container alignItems="center" xs="auto">
        <Typography variant="caption">
          {index + 1} / {ids.length}
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs="auto">
        <Button
          variant="text"
          endIcon={<ArrowRightIcon />}
          onClick={onNavigateNext}
          disabled={disabledNext}
          disableRipple
        >
          Next hit
        </Button>
      </Grid>
    </Grid>
  )
}

export default ArticleNav
