import { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { Stack, TextField, MenuItem, Typography } from '@mui/material'
import { map, snakeCase } from 'lodash'
import PropTypes from 'prop-types'
import I18n from 'i18next'

import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import * as styles from './table.scss'

const defaultRowStyle = () => {
  return {}
}

export default class Table extends Component {
  static propTypes = {
    keyField: PropTypes.string,
    data: PropTypes.array,
    loading: PropTypes.bool,
    columns: PropTypes.array,
    errors: PropTypes.arrayOf(PropTypes.string),
    totalSize: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    sort: PropTypes.shape(),
    emptyLabel: PropTypes.string,
    pageChanged: PropTypes.func,
    sortChanged: PropTypes.func,
    rowStyle: PropTypes.func,
    paginationTotalRendererOff: PropTypes.bool,
    showSizePerPage: PropTypes.bool,
    striped: PropTypes.bool,
    hidePagination: PropTypes.bool,
    customClasses: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    keyField: 'id',
    data: [],
    errors: [],
    pageChanged: () => null,
    sortChanged: () => null,
    rowStyle: defaultRowStyle,
    striped: true,
  }

  customTotal = (from = '', to, size) => {
    const paginationObject = { from: size === 0 ? 0 : from, to, size }

    return (
      <span
        className="react-bootstrap-table-pagination-total"
        data-testid="pagination_info"
        data-test-total={size}
        data-test-from={from}
        data-test-to={to}
      >
        {I18n.t('common.table.pagination', paginationObject)}
      </span>
    )
  }

  sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => {
    return (
      <TextField
        select
        label={'Per Page'}
        sx={{ minWidth: '8rem' }}
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(e.target.value)}
      >
        {options.map((option) => (
          <MenuItem key={option.text} value={option.page}>
            {option.text}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  tablePagination = () => {
    const { perPage, page, totalSize, paginationTotalRendererOff, showSizePerPage } = this.props
    const from = page * perPage - (perPage - 1)
    const to = page * perPage < totalSize ? page * perPage : totalSize
    return paginationFactory({
      custom: true,
      hideSizePerPage: !showSizePerPage,
      hidePageListOnlyOnePage: true,
      sizePerPage: perPage,
      page,
      totalSize,
      showTotal: paginationTotalRendererOff ? false : true,
      paginationTotalRenderer: () => this.customTotal(from, to, totalSize),
      onPageChange: () => {
        window.scrollTo(0, 0)
      },
      sizePerPageRenderer: this.sizePerPageRenderer,
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '500',
          value: 500,
        },
      ],
    })
  }

  formattedErrors = (errors) => <Typography color="error">{errors.join(' - ')}</Typography>

  noDataIndication = () => {
    const { errors, loading, emptyLabel } = this.props

    if (errors.length > 0) {
      return this.formattedErrors(errors)
    }
    return loading ? I18n.t('common.loading') : emptyLabel
  }

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    const { pageChanged, sortChanged } = this.props
    switch (type) {
      case 'pagination':
        return pageChanged(page, sizePerPage)
      case 'sort':
        return sortChanged(sortField, sortOrder)
      default:
        break
    }
  }

  addDataTestAttibutes = (columns) =>
    map(columns, (column) => ({
      ...column,
      headerAttrs: { ...column.headerAttrs, 'data-test-column-name': snakeCase(column.dataField) },
    }))

  render() {
    const { data, loading, columns, keyField, rowStyle, striped, hidePagination, customClasses = [], sort } = this.props

    return (
      <PaginationProvider pagination={this.tablePagination()}>
        {({ paginationProps, paginationTableProps }) => (
          <Stack
            className={styles.table}
            direction={'column'}
            data-testid="table"
            data-test-loading={loading}
            sx={{
              th: {
                borderColor: 'divider',
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1c1c1c' : undefined),
              },
              td: { borderColor: 'divider' },
              color: (theme) => (theme.palette.mode === 'dark' ? 'text.secondary' : 'text.primary'),
            }}
          >
            <div className={styles.tableCont}>
              <BootstrapTable
                classes={data.length > 0 ? ['table-responsive', ...customClasses].join(' ') : null}
                keyField={keyField}
                data={data}
                loading={loading}
                columns={this.addDataTestAttibutes(columns)}
                striped={striped}
                bordered={false}
                noDataIndication={this.noDataIndication}
                onTableChange={this.handleTableChange}
                remote={{ sort: true, pagination: true }}
                rowStyle={rowStyle}
                sort={sort}
                {...paginationTableProps}
              />
            </div>
            {!hidePagination && (
              <Stack
                direction={'row'}
                className={styles.paginationCont}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexWrap={'nowrap'}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'background.default',
                  '.page-link': {
                    color: 'text.secondary',
                    backgroundColor: 'transparent',
                    borderColor: 'primary.dark',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                  '.active .page-link': {
                    color: 'primary.contrastText',
                    backgroundColor: 'primary.dark',
                    borderColor: 'primary.dark',
                  },
                }}
              >
                <PaginationTotalStandalone {...paginationProps} />
                <Stack direction={'row'} gap={2}>
                  <SizePerPageDropdownStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
      </PaginationProvider>
    )
  }
}
