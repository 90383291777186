import isEmpty from 'lodash/isEmpty'

import type { HitData } from '@types'

export const isGreat = (hit: HitData): boolean => hit.quality === 'great'
export const isIrrelevant = (hit: HitData): boolean => hit.quality === 'irrelevant'
export const isNotRated = (hit: HitData): boolean => hit.quality === null

export const someGreat = (hits: HitData[]): boolean => hits.some(isGreat)
export const someIrrelevant = (hits: HitData[]): boolean => hits.some(isIrrelevant)

export const allGreat = (hits: HitData[]): boolean => !isEmpty(hits) && hits.every(isGreat)
export const allIrrelevant = (hits: HitData[]): boolean => !isEmpty(hits) && hits.every(isIrrelevant)
export const allRated = (hits: HitData[]): boolean => hits.every((hit) => isGreat(hit) || isIrrelevant(hit))
export const hasChildren = (hit: HitData): boolean => !!(hit.children && hit.children.length)
