export function isBucketInRange({ bucket, range }) {
  const { firstLetters } = bucket

  const isCorrectRange = !!range.start && !!range.finish

  if (!isCorrectRange) {
    return false
  }

  return firstLetters >= range.start && firstLetters <= range.finish
}
