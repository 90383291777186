import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { Tab } from '@mui/material'
import I18n from 'i18next'

import Icon from '@common/components/icon'

import * as styles from './item.scss'

const t = (name) => I18n.t(`navbar.${name}`)

const Item = ({ path, labelKey, iconKey, isActive, isDistributionItem, ...rest }) => {
  const [isHover, setIsHover] = useState(false)

  const { pathname } = useLocation()

  return (
    <>
      <Tab
        key={path}
        component={Link}
        to={path}
        {...rest}
        value={path}
        label={t(labelKey)}
        icon={<Icon icon={iconKey} filled={isActive || isHover} />}
        iconPosition="start"
        className={classnames(styles.tabLink, { [styles.tabLinkActive]: isActive })}
        sx={{
          ml: isDistributionItem ? 'auto' : 2,
          color: 'text.secondary',
          '&:hover, &.Mui-selected': {
            color: 'text.secondary',
          },
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={(e) => {
          if (path === pathname) {
            e.preventDefault()
          }
        }}
      />
    </>
  )
}
export default Item
