import { useMutation } from '@tanstack/react-query'
import { decamelizeKeys } from 'humps'

import { fetch } from '@services'
import type { IndicatorMessageFormValues } from '@types'

import { getMsgBundleReqBody } from '../helpers'

type TVariables = {
  state: object
  imFormValues: IndicatorMessageFormValues
  bundleId: number
}

const useReviewIndicatorMessage = () => {
  const mutationFn = async ({ state, imFormValues, bundleId }: TVariables): Promise<boolean> => {
    const body = JSON.stringify(decamelizeKeys(getMsgBundleReqBody(state, imFormValues)))

    const res = await fetch(`/api/message_bundles/${bundleId}`, {
      method: 'PATCH',
      body,
    })

    if (!res.ok) {
      return Promise.reject()
    }

    return true
  }

  return useMutation<boolean, boolean, TVariables>({
    mutationKey: ['REVIEW_INDICATOR_MESSAGE'],
    mutationFn,
  })
}

export default useReviewIndicatorMessage
