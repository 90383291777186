import { getRiskLevelIcon } from '@riskmethods/world-map'

const RISK_OBJECT_MARKER_SIZE = [32, 44]
const SCORE_NA = null

export const debitorIcon = () =>
  buildIcon({
    url: getRiskLevelIcon({ type: 'Debitor', SCORE_NA }),
    scaledSize: RISK_OBJECT_MARKER_SIZE,
  })

export const eventIcon = (type) =>
  buildIcon({
    url: getRiskLevelIcon({ type: 'Event', eventType: type }),
    scaledSize: [34, 30],
    anchor: [17, 15],
  })

export const locationIcon = () =>
  buildIcon({
    url: getRiskLevelIcon({ type: 'Location', SCORE_NA }),
    scaledSize: RISK_OBJECT_MARKER_SIZE,
  })

export const manufactureIcon = () =>
  buildIcon({
    url: getRiskLevelIcon({ type: 'Manufacture', SCORE_NA }),
    scaledSize: RISK_OBJECT_MARKER_SIZE,
  })

export const supplierIcon = () =>
  buildIcon({
    url: getRiskLevelIcon({ type: 'Supplier', SCORE_NA }),
    scaledSize: RISK_OBJECT_MARKER_SIZE,
  })

const buildIcon = ({ url, scaledSize, anchor = null }) => {
  return {
    anchor,
    scaledSize,
    url,
  }
}
