import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import { setFilters, setPage } from '@common/actions/table'
import AlertMessage from '@common/components/alert_message'
import { exclusionItem } from '@common/utils/shapes'
import { PageCont, TopCont, BottomCont } from '@common/layout'

import {
  fetchItems,
  fetchData,
  openAddCompanyModal,
  closeAddCompanyModal,
  createExclusionItem,
  updateForm,
  setExpanded,
} from './actions'

import { AddCompanyModal } from './components/modals/add_company_modal'
import Table from './components/table'
import Filters from './components/filters'

import * as styles from './e-list-page.scss'

class ExclusionListPage extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(exclusionItem),
    fetchData: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    openAddCompanyModal: PropTypes.func.isRequired,
    closeAddCompanyModal: PropTypes.func.isRequired,
    createExclusionItem: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    formControls: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string),
    setFilters: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setExpanded: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.fetchData()
    this.props.fetchItems()
  }

  render() {
    const { errors, fetchData, opened, setFilters, filters, setPage } = this.props
    return (
      <>
        <PageCont className={styles.exclusionList}>
          <TopCont>
            {map(errors, (error) => (
              <AlertMessage data-testid="error_message" key={error} message={error} />
            ))}
            <Filters
              changeFilters={setFilters}
              setPage={setPage}
              fetchData={fetchData}
              filters={filters}
              setExpanded={this.props.setExpanded}
              openAddCompanyModal={this.props.openAddCompanyModal}
            />
          </TopCont>
          <BottomCont>
            <Table fetchData={fetchData} />
          </BottomCont>
        </PageCont>
        <AddCompanyModal
          data-testid="add_company_modal"
          isOpen={opened}
          onClose={this.props.closeAddCompanyModal}
          create={this.props.createExclusionItem}
          formControls={this.props.formControls}
          formErrors={this.props.formErrors}
          updateForm={this.props.updateForm}
        />
      </>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    data: { items },
    filters,
    modals: {
      addCompany: { opened },
    },
    form: { formErrors, formControls },
    errors,
  } = rootState.elist

  return {
    items,
    filters,
    errors,
    opened,
    formErrors,
    formControls,
  }
}

const mapDispatchToProps = {
  fetchItems,
  fetchData,
  openAddCompanyModal,
  closeAddCompanyModal,
  createExclusionItem,
  updateForm,
  setFilters,
  setPage,
  setExpanded,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExclusionListPage)
