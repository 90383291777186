import PropTypes from 'prop-types'
import { Chip, Stack } from '@mui/material'
import I18n from 'i18next'

const unitsFactor = {
  km2: 1000000,
}

const AffectedPolygonArea = ({ area }) => (
  <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ mb: 1 }}>
    {I18n.t('indicator_message.location.affected_polygon_area')}
    <Chip size="small" color="secondary" label={Math.round(area / unitsFactor.km2).toLocaleString()} />
    <span>
      km<sup>2</sup>
    </span>
  </Stack>
)

AffectedPolygonArea.propTypes = {
  area: PropTypes.number.isRequired,
}

export default AffectedPolygonArea
