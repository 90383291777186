import { createContext, useState, useEffect, useMemo, useContext } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import { lightTheme, darkTheme } from './themes'
import { getCookie, setPermanentCookie } from '@common/utils/cookie'

const defaultThemeMode = 'light'
const cookieName = 'paletteMode'
const defaultContext = {
  themeMode: defaultThemeMode,
  theme: lightTheme,
}

const isThemeMode = (value) => typeof value === 'string' && ['light', 'dark'].includes(value)

const getMetaThemeColor = (mode) => {
  const themeColor = {
    light: '#f8f8f8',
    dark: '#212121',
  }

  return themeColor[mode]
}

export const ThemeContext = createContext(defaultContext)

const getInitialThemeMode = () => {
  const storedPaletteMode = getCookie(cookieName)
  if (isThemeMode(storedPaletteMode)) {
    return storedPaletteMode
  }

  const { matches } = window.matchMedia('(prefers-color-scheme: dark)') || {}

  return matches ? 'dark' : 'light'
}

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(getInitialThemeMode)

  useEffect(() => {
    const metas = document.querySelectorAll('meta[name="theme-color"]')
    metas.forEach((meta) => {
      meta.setAttribute('content', getMetaThemeColor(themeMode))
    })

    if (themeMode === 'dark') {
      document.body.classList.remove('mode-light')
      document.body.classList.add('mode-dark')
    } else {
      document.body.classList.remove('mode-dark')
      document.body.classList.add('mode-light')
    }
  }, [themeMode])

  const appTheme = useMemo(() => {
    return themeMode === 'dark' ? darkTheme : lightTheme
  }, [themeMode])

  const contextValue = useMemo(
    () => ({
      changeTheme: (newThemeMode) => {
        setPermanentCookie(cookieName, newThemeMode)
        setThemeMode(newThemeMode)
      },
      themeMode,
      theme: appTheme,
    }),
    [themeMode, appTheme]
  )

  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />
      <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
    </MuiThemeProvider>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}

export default ThemeProvider
