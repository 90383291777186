// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--MZV96{flex:1;position:relative}.table--MZV96 th.sortable{overflow:hidden}.table--MZV96 th.sortable .order,.table--MZV96 th.sortable .react-bootstrap-table-sort-order{position:relative;display:inline-block;min-width:10px}.table--MZV96 th.sortable .caret{position:relative;display:inline-block;margin:0 !important}.table--MZV96 th.sortable .caret::after,.table--MZV96 th.sortable .dropdown .caret::after,.table--MZV96 th.sortable .dropup .caret::after{content:"";left:3px;bottom:-2px;position:absolute;border-style:solid;border-width:5px;border-color:currentColor rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)}.table--MZV96 th.sortable .dropdown+.dropup .caret::after{margin-left:9px}.table--MZV96 th.sortable .dropup .caret::after{transform:rotate(180deg);transform-origin:50% 25%}.table--MZV96 td{position:relative}.table--MZV96 table{overflow:initial;color:inherit;display:table;table-layout:auto}.table--MZV96 th{font-weight:600;border-bottom:0px !important}.table-cont--sKyEW{flex:1;padding-bottom:55px}.pagination-cont--cfiVR{min-height:55px;position:fixed;inset:auto 0 0 0}.pagination-cont--cfiVR ul{margin-bottom:0}.table-striped tbody tr:nth-of-type(odd){background-color:rgba(0,0,0,.05)}.mode-dark .table-striped tbody tr:nth-of-type(odd){background-color:rgba(255,255,255,.05)}`, "",{"version":3,"sources":["webpack://./src/client/common/components/table.scss"],"names":[],"mappings":"AAAA,cACE,MAAA,CACA,iBAAA,CAEA,0BAEE,eAAA,CAEA,6FAEE,iBAAA,CAGA,oBAAA,CAEA,cAAA,CAGF,iCACE,iBAAA,CAEA,oBAAA,CAEA,mBAAA,CAMA,0IACE,UAAA,CACA,QAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CACA,mEAAA,CAGJ,0DACE,eAAA,CAGF,gDACE,wBAAA,CACA,wBAAA,CAIJ,iBACE,iBAAA,CAGF,oBACE,gBAAA,CACA,aAAA,CAEA,aAAA,CACA,iBAAA,CAGF,iBACE,eAAA,CACA,4BAAA,CAIJ,mBACE,MAAA,CAGA,mBAAA,CAGF,wBACE,eAAA,CAEA,cAAA,CACA,gBAAA,CAEA,2BACE,eAAA,CAIJ,yCACE,gCAAA,CAGF,oDACE,sCAAA","sourceRoot":""}]);
// Exports
export var table = `table--MZV96`;
var _1 = `table-cont--sKyEW`;
export { _1 as "table-cont" };
export var tableCont = `table-cont--sKyEW`;
var _2 = `pagination-cont--cfiVR`;
export { _2 as "pagination-cont" };
export var paginationCont = `pagination-cont--cfiVR`;
export default ___CSS_LOADER_EXPORT___;
