import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { TopCont, BottomCont } from '@common/layout'
import { useGetIndicators, useUpdateIndicator } from '@pages/shift-management/components/delay_hits/api'

import PageTitle from '../common/page_title/page_title'
import Table from './table/table'

const DelayHits = () => {
  const { data: indicatorsData, refetch: fetchIndicators, isFetching: isFetchingIndicators } = useGetIndicators()
  const { mutateAsync: updateIndicator } = useUpdateIndicator()

  const handleRefreshIndicators = () => {
    if (!isFetchingIndicators) {
      fetchIndicators()
    }
  }

  const handleUpdateIndicator = (args) => {
    updateIndicator(args)
  }

  return (
    <>
      <TopCont>
        <PageTitle title="Delay hits for selected indicators" />
        <Box mb={5}>
          <LoadingButton
            data-testid="delay-hits-refresh"
            color="primary"
            variant="contained"
            loading={isFetchingIndicators}
            onClick={handleRefreshIndicators}
          >
            Refresh
          </LoadingButton>
        </Box>
      </TopCont>
      <BottomCont>
        <Table
          data={indicatorsData || null}
          isLoading={isFetchingIndicators}
          handleUpdateIndicator={handleUpdateIndicator}
        />
      </BottomCont>
    </>
  )
}

export default DelayHits
