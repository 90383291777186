import { getJSON, postJSON, patchJSON, deleteJSON } from '@common/http'
import { tableParams } from '@common/utils/table_params'

export const DATA_RECEIVED = 'EXCLUSION_ITEMS_DATA_RECEIVED'
export const INDEX_ERROR_RECEIVED = 'EXCLUSION_ITEMS_INDEX_ERROR_RECEIVED'
export const SUBMIT_ERROR_RECEIVED = 'EXCLUSION_ITEMS_SUBMIT_ERROR_RECEIVED'
export const LOAD_DATA = 'EXCLUSION_ITEMS_LOAD_DATA'
export const CREATE_EXCLUSION_ITEM = 'CREATE_EXCLUSION_ITEM'
export const CLEAR_SUBMIT_ERRORS = 'EXCLUSION_ITEMS_CLEAR_SUBMIT_ERRORS'
export const EXCLUSION_ITEMS_FETCHED = 'EXCLUSION_ITEMS_FETCHED'
export const OPEN_ADD_COMPANY_MODAL = 'OPEN_ADD_COMPANY_MODAL'
export const CLOSE_ADD_COMPANY_MODAL = 'CLOSE_ADD_COMPANY_MODAL'
export const UPDATE_FORM = 'UPDATE_FORM'
export const CLEAR_FORM = 'CLEAR_ELIST_FORM'
export const SET_EXPANDED = 'SET_EXPANDED'

export const itemsFetched = (data) => ({
  type: EXCLUSION_ITEMS_FETCHED,
  data,
})

const apiPath = '/api/shit_items'

export const fetchItems = () => (dispatch) => {
  return getJSON(apiPath).then(({ data: { shitItems } }) => {
    dispatch(itemsFetched(shitItems))
  })
}

export const loadData = () => ({ type: LOAD_DATA })

export const dataReceived = (json) => ({
  type: DATA_RECEIVED,
  data: json,
})

export const indexErrorReceived = (json) => ({
  type: INDEX_ERROR_RECEIVED,
  data: json,
})

export const submitErrorReceived = (json) => ({
  type: SUBMIT_ERROR_RECEIVED,
  data: json,
})

export const clearSubmitErrors = () => ({
  type: CLEAR_SUBMIT_ERRORS,
})

export const updateForm = (data) => ({
  type: UPDATE_FORM,
  data,
})

export const clearForm = () => ({
  type: CLEAR_FORM,
})

export const fetchData = () => (dispatch, getState) => {
  const {
    table: { page, perPage, sort },
    filters,
  } = getState().elist

  dispatch(loadData())

  return getJSON(apiPath, tableParams({ page, sort, filters, perPage }))
    .then(({ data }) => {
      dispatch(dataReceived(data))
    })
    .catch((resp) => {
      dispatch(indexErrorReceived({ errors: resp?.data?.errors }))
    })
}

export const createExclusionItem = (data) => (dispatch) => {
  return postJSON(apiPath, { shitItem: data }).then(() => {
    dispatch(fetchData())
    dispatch(clearForm('modalForm'))
  })
}

export const updateExclusionItem = () => (dispatch, getState) => {
  const {
    form: {
      formControls: { id, name, alternatives, description, isNotString, isNotName },
    },
  } = getState().elist

  return patchJSON(`${apiPath}/${id.value}`, {
    shitItem: {
      name: name.value,
      alternatives: alternatives.value,
      description: description.value,
      isNotString: isNotString.value,
      isNotName: isNotName.value,
    },
  }).then(() => {
    dispatch(fetchData())
  })
}

export const deleteExclusionItem = ({ id }) => {
  return (dispatch) => {
    return deleteJSON(`${apiPath}/${id}`)
      .then(() => {
        dispatch(fetchData())
        dispatch(clearSubmitErrors())
      })
      .catch(({ data: { errors } }) => {
        dispatch(submitErrorReceived({ errors }))
      })
  }
}

export const openAddCompanyModal = () => ({
  type: OPEN_ADD_COMPANY_MODAL,
})

export const closeAddCompanyModal = () => ({
  type: CLOSE_ADD_COMPANY_MODAL,
})

export const setExpanded = (data) => ({
  type: SET_EXPANDED,
  data,
})
