import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import I18n from 'i18next'

import { IconButton, Divider, Menu, MenuItem, ListItem, ListItemIcon, Switch, styled } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import UserDataIcon from '@mui/icons-material/FolderShared'
import LightIcon from '@mui/icons-material/LightMode'
import DarkIcon from '@mui/icons-material/NightsStay'

import { useTheme } from '@common/theme'
import { ROUTES } from '@common/utils/const'
import { useAuthenticator } from '@components/app/authenticator/authenticator'

type Props = {
  udmBaseUrl: string
}

const t = (name) => I18n.t(`navbar.${name}`)

const UserMenu = ({ udmBaseUrl }: Props) => {
  const history = useHistory()
  const { themeMode, changeTheme } = useTheme()
  const { logout } = useAuthenticator()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleSettingsClose = () => {
    setAnchorEl(null)
  }

  const handleThemeChange = () => {
    changeTheme(themeMode === 'dark' ? 'light' : 'dark')
  }

  const onLogout = () => {
    handleSettingsClose()
    logout()
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="open settings"
        sx={{ ml: 2, color: 'text.secondary' }}
        edge="end"
        onClick={handleSettingsClick}
      >
        <PersonIcon />
      </IconButton>
      <Menu
        data-testid="settings_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleSettingsClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock={true}
      >
        <ListItem sx={{ color: 'text.disabled', svg: { cursor: 'pointer' } }}>
          <LightIcon
            fontSize="small"
            color={themeMode === 'light' ? 'primary' : 'inherit'}
            onClick={() => changeTheme('light')}
          />
          <Switch
            size="small"
            checked={themeMode === 'dark'}
            onChange={handleThemeChange}
            sx={{
              mx: 1,
              '.MuiSwitch-thumb': {
                backgroundColor: 'primary.main',
              },
              '.MuiSwitch-track': {
                backgroundColor: 'primary.main',
                opacity: 0.5,
              },
            }}
          />
          <DarkIcon
            fontSize="small"
            color={themeMode === 'dark' ? 'primary' : 'inherit'}
            onClick={() => changeTheme('dark')}
          />
        </ListItem>
        <Divider sx={{ mb: '4px' }} />
        <StyledMenuItem
          onClick={() => {
            handleSettingsClose()
            history.push(ROUTES.INDIVIDUAL_SETTINGS)
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          {t('individual_settings')}
        </StyledMenuItem>
        <StyledMenuItem onClick={() => window.open(udmBaseUrl + ROUTES.USER_DATA, '_blank')}>
          <ListItemIcon>
            <UserDataIcon fontSize="small" />
          </ListItemIcon>
          {t('user_data')}
        </StyledMenuItem>
        <StyledMenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </StyledMenuItem>
      </Menu>
    </>
  )
}

const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': {
    color: 'inherit',
  },
}))

export default UserMenu
