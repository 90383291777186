import { map, omit, flatMap } from 'lodash'
import I18n from 'i18next'

import {
  LOAD_DATA,
  DATA_RECEIVED,
  INDEX_ERROR_RECEIVED,
  FREEZE_BUNDLE,
  SUBMIT_ERROR_RECEIVED,
  CLEAR_SUBMIT_ERRORS,
  INDICATORS_FETCHED,
  RESET_DATA,
} from '../actions'
import { SET_PAGE, SET_SORT, SET_FILTERS } from '@common/actions/table'

export const initialState = {
  data: { indicators: [] },
  filters: {},
  table: {
    loading: false,
    page: 1,
    totalResults: 0,
    sort: {
      field: 'createdAt',
      order: 'desc',
    },
    data: {
      bundles: [],
    },
  },
  errors: [],
}

export function reducer(state = initialState, { type, data }) {
  switch (type) {
    case INDICATORS_FETCHED: {
      return { ...state, data: { ...state.data, ...{ indicators: data } } }
    }
    case LOAD_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      }
    }
    case DATA_RECEIVED: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: data.totalResults,
          data: {
            bundles: data.bundles,
          },
        },
        errors: [],
      }
    }
    case RESET_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            bundles: [],
          },
        },
      }
    }
    case INDEX_ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: 0,
          data: {
            bundles: [],
          },
          errors: [I18n.t('common.messages.unable_to_retrieve_data')],
        },
      }

    case SUBMIT_ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            bundles: map(state.table.data.bundles, (bundle) => omit(bundle, ['freezed'])),
          },
        },
        errors: flatMap(data.errors, (errors) => errors),
      }

    case FREEZE_BUNDLE:
      return {
        ...state,
        table: {
          ...state.table,
          data: {
            bundles: map(state.table.data.bundles, (bundle) =>
              bundle.id === data.id ? { ...bundle, freezed: true } : bundle
            ),
          },
        },
      }
    case CLEAR_SUBMIT_ERRORS:
      return {
        ...state,
        errors: [],
      }

    case `${SET_PAGE}_REVIEW`:
      return {
        ...state,
        table: {
          ...state.table,
          page: data.page,
        },
      }
    case `${SET_SORT}_REVIEW`:
      return {
        ...state,
        table: {
          ...state.table,
          sort: { field: data.sortField, order: data.sortOrder },
        },
      }
    case `${SET_FILTERS}_REVIEW`:
      return {
        ...state,
        filters: { ...state.filters, ...data },
      }
    default:
      return state
  }
}
