import { useQueryClient } from '@tanstack/react-query'
import { Box, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { Field, Input, Select } from '@riskmethods/rm-front-end'

import { showRemoveHitsWarnModal } from '@pages/saved-hits/helpers'
import { useSavedHitsContext } from '@hooks'
import type { SavedHitsData } from '@types'

const SavedHitsForm = () => {
  const queryClient = useQueryClient()

  const {
    selectedHitIds,
    setSelectedHitIds,
    getSavedHitsQuery,
    getSavedByUsersQuery,
    unsaveHitsQuery,
    savedBySelectOptions,
  } = useSavedHitsContext()

  const handleRemove = () => {
    showRemoveHitsWarnModal().then(
      () => {
        const ids: string[] = selectedHitIds.map((id) => id.toString())

        unsaveHitsQuery.mutateAsync(ids).then((unsavedHits) => {
          const unsavedHitIds = unsavedHits.map((hit) => hit.id)

          queryClient.setQueryData(['GET_SAVED_HITS'], (savedHitsData: SavedHitsData) => {
            return {
              ...savedHitsData,
              hits: savedHitsData.hits.filter((hit) => !unsavedHitIds.includes(hit.id)),
            }
          })

          setSelectedHitIds(selectedHitIds.filter((id) => !unsavedHitIds.includes(id)))
        })
      },
      () => null
    )
  }

  const isFetchingSavedHits = getSavedHitsQuery.isLoading || getSavedHitsQuery.isFetching

  const isFetchingSavedByUsers = getSavedByUsersQuery.isLoading || getSavedByUsersQuery.isFetching

  const disableRemoveBtn = selectedHitIds.length === 0 || isFetchingSavedHits || unsaveHitsQuery.isPending

  let searchBtnLabel = 'Search'

  if (getSavedHitsQuery.data) {
    searchBtnLabel += ` (${getSavedHitsQuery.data.hits.length})`
  }

  return (
    <Box position="sticky" top={0} zIndex={2} mt={1} px={2} py={1.25} sx={{ backgroundColor: 'background.default' }}>
      <Stack direction="row" flexWrap="wrap" alignItems="flex-end" gap={1.25}>
        <Box width="225px">
          <Field
            name="search_for"
            label="Search in Saved Hits"
            Component={(props) => (
              <Input testId="query" enableClearBtn textFieldProps={{ placeholder: '...' }} {...props} />
            )}
          />
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={isFetchingSavedHits}
            disabled={unsaveHitsQuery.isPending}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {searchBtnLabel}
          </LoadingButton>
        </Box>
        <Box width="225px">
          <Field
            name="saved_by_id"
            label="Saved by"
            disabled={isFetchingSavedByUsers}
            Component={(props) => (
              <Select
                testId="users_dropdown"
                options={savedBySelectOptions}
                loading={isFetchingSavedByUsers}
                {...props}
              />
            )}
          />
        </Box>
        <Box flex="1" display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={disableRemoveBtn}
            loading={unsaveHitsQuery.isPending}
            onClick={() => {
              handleRemove()
            }}
          >
            {`Remove selected (${selectedHitIds.length})`}
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  )
}

export default SavedHitsForm
