import { deburr, isEmpty } from 'lodash'

const keywordSign = String.fromCharCode(30)
const separator = String.fromCharCode(31)

const regexEscape = (string: string) => string.replace(/[-[\]/{}()*+?.^$|\\]/g, (match) => `\\${match}`)
const keywordRegex = (keywords: string[]) => new RegExp(`(^|.)(${keywords.join('|')})(?=\\W|\\d|$)`, 'gi')

const separate = (keywords: string[], text: string) => {
  return text
    .replace(keywordRegex(keywords), (_match, pre, keyword) =>
      isEmpty(pre) || /\d|\W/.test(deburr(pre))
        ? `${pre}${separator}${keywordSign}${keyword}${separator}`
        : `${pre}${keyword}`
    )
    .replace(new RegExp(`(^${separator})|(${separator}$)`, 'g'), '')
}

const findHitKeywords = (text: string, keywords: string[]) => {
  const preparedKeywords = (keywords || []).sort((a, b) => b.length - a.length).map(regexEscape)
  const chunks = separate(preparedKeywords, text).split(separator)

  return chunks.map((chunk) => ({
    keyword: chunk[0] === keywordSign,
    content: chunk.replace(keywordSign, ''),
  }))
}

export default findHitKeywords
