import PropTypes from 'prop-types'

import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

import * as styles from './modal.scss'

const Modal = (props) => {
  const {
    title,
    children,
    footer,
    isOpen,
    onClose,
    modalProps,
    isEditingDialog,
    isDeleteDialog,
    bodyStyle,
    isFullWidth = true,
  } = props
  void modalProps
  const shouldShowEditing = !isDeleteDialog && isEditingDialog

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth="md" fullWidth={isFullWidth}>
      <DialogTitle className={styles.title}>
        {isDeleteDialog && <ErrorIcon color={'error'} fontSize={'large'} sx={{ ml: -1, mr: 1 }} />}
        {shouldShowEditing && <AutoAwesomeIcon color={'primary'} fontSize={'large'} sx={{ ml: -1, mr: 1 }} />}
        {title}
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            className={styles.close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={styles.content} sx={bodyStyle}>
        {children}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  )
}

Modal.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
  modalProps: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bodyStyle: PropTypes.object,
  isEditingDialog: PropTypes.bool,
  isDeleteDialog: PropTypes.bool,
}

export default Modal
