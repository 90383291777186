import { stringify } from 'qs'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { fetch } from '@services'

export const getJSON = (path, body) => jsonFetch(path, 'GET', body)
export const putJSON = (path, body) => jsonFetch(path, 'PUT', body)
export const patchJSON = (path, body) => jsonFetch(path, 'PATCH', body)
export const postJSON = (path, body) => jsonFetch(path, 'POST', body)
export const deleteJSON = (path, body) => jsonFetch(path, 'DELETE', body)

const CODES_WITHOUT_BODY = [204, 201]

const handleErrors = (response) => {
  const handleErrorData = (errorData) => {
    const error = new Error(response.statusText)
    error.status = response.status
    error.data = errorData
    throw error
  }

  return response.status >= 200 && response.status < 300 ? response : response.json().then(handleErrorData)
}

const jsonFetch = (path, method, body) => {
  const formattedData = decamelizeKeys(body)
  const queryParams = method === 'GET' ? `?${stringify(formattedData, { arrayFormat: 'brackets', sort: false })}` : ''
  const formattedBody = method === 'GET' ? null : JSON.stringify(formattedData)

  const { accessToken = '' } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  return fetch(`${path}.json${queryParams}`, {
    credentials: 'same-origin',
    method,
    body: formattedBody,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleErrors)
    .then((response) => !CODES_WITHOUT_BODY.includes(response.status) && response.json())
    .then((data) => ({ data: camelizeKeys(data || {}) }))
}
