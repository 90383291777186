// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exclusionList--WZ0cD textarea{border-radius:3px}.exclusionList--WZ0cD .toggle--kB003{font-size:12px}.exclusionList--WZ0cD .showMore--teqKm{margin-left:3px}.exclusionList--WZ0cD .name--iuTxH{width:172px;hyphens:auto;word-break:break-word}.exclusionList--WZ0cD .alternatives--z8QLp{max-width:161px;hyphens:auto;word-break:break-word}.exclusionList--WZ0cD .alternatives--z8QLp ul{margin-left:-22px}.exclusionList--WZ0cD .description--quKVX{max-width:276px;hyphens:auto;word-break:break-word}.exclusionList--WZ0cD .isNotString--Catmo{max-width:348px;hyphens:auto;word-break:break-word}@media(min-width: 1450px){.exclusionList--WZ0cD .alternatives--z8QLp{width:280px;max-width:280px}.exclusionList--WZ0cD .description--quKVX{max-width:100%}.exclusionList--WZ0cD .isNotString--Catmo{max-width:100%}}.exclusionList--WZ0cD .isNotName--UtFcN{width:261px;hyphens:auto;word-break:break-word}.exclusionList--WZ0cD .isNotName--UtFcN ul{margin-left:-21px}`, "",{"version":3,"sources":["webpack://./src/client/pages/e-list/e-list-page.scss"],"names":[],"mappings":"AACE,+BACE,iBAAA,CAGF,qCACE,cAAA,CAGF,uCACE,eAAA,CAGF,mCACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,2CACE,eAAA,CAIA,YAAA,CACA,qBAAA,CAJA,8CACE,iBAAA,CAMJ,0CACE,eAAA,CACA,YAAA,CACA,qBAAA,CAGF,0CACE,eAAA,CACA,YAAA,CACA,qBAAA,CAGF,0BACE,2CACE,WAAA,CACA,eAAA,CAGF,0CACE,cAAA,CAGF,0CACE,cAAA,CAAA,CAIJ,wCACE,WAAA,CAIA,YAAA,CACA,qBAAA,CAJA,2CACE,iBAAA","sourceRoot":""}]);
// Exports
export var exclusionList = `exclusionList--WZ0cD`;
export var toggle = `toggle--kB003`;
export var showMore = `showMore--teqKm`;
export var name = `name--iuTxH`;
export var alternatives = `alternatives--z8QLp`;
export var description = `description--quKVX`;
export var isNotString = `isNotString--Catmo`;
export var isNotName = `isNotName--UtFcN`;
export default ___CSS_LOADER_EXPORT___;
