import { useState } from 'react'
import { Box, TableCell, TableRow } from '@mui/material'
import moment from 'moment'

import { useTheme } from '@common/theme'
import { DATE_TIME_FORMAT } from '@common/utils/const'
import { HitContent, HitCheckBox } from '@components'
import type { HitData, HitQuality, InitPageData } from '@types'
import { useAppContext } from '@hooks'

import SavedHitIcon from './components/saved-hit-icon/saved-hit-icon'

type Props = {
  data: HitData
  initialData: InitPageData
  noTranslationLanguages: string[]
  handleQualityChange: (quality: HitQuality, hitId: number) => Promise<unknown>
  onQualityCheckboxClick: (quality: HitQuality, hitId?: number) => void
  quality: HitQuality
  disableAllRowsUpdateQuality: boolean
  setDisableHeaderUpdateQuality: (disabled: boolean) => void
}

const Row = ({
  data,
  initialData,
  noTranslationLanguages,
  handleQualityChange,
  onQualityCheckboxClick,
  quality,
  disableAllRowsUpdateQuality,
  setDisableHeaderUpdateQuality,
}: Props) => {
  const { themeMode, theme } = useTheme()
  const {
    env: { REMOTE_ENV },
  } = useAppContext()

  const isLocalEnv = !REMOTE_ENV || REMOTE_ENV === 'false'

  const isInboxLinksDisabled = initialData?.currentUser?.inboxLinksDisabled || false
  const showHitLink = isLocalEnv || !isInboxLinksDisabled

  const isArticleAnalyzerUser = initialData?.currentUser?.articleAnalyzerUser || false
  const showSavedTableCell = isLocalEnv || isArticleAnalyzerUser
  const [disableRowActionIcons, setDisableRowActionIcons] = useState<boolean>(false)

  const setDisabled = (disabled: boolean) => {
    setDisableRowActionIcons(disabled)
    setDisableHeaderUpdateQuality(disabled)
  }

  const handleCheckboxChange = (quality: HitQuality) => (e) => {
    return handleQualityChange(e.target.checked ? quality : null, id)
  }

  const evenRowBgColor = themeMode === 'light' ? '#EEF3EB' : 'rgba(255, 255, 255, 0.05)'
  const backgroundColor = data.index % 2 === 0 ? evenRowBgColor : theme.palette.background.default

  const fontSize = '1rem'

  const sx = {
    backgroundColor,
    fontSize,
    verticalAlign: 'top',
    borderBottom: themeMode === 'dark' ? '1px solid rgba(81, 81, 81, 0.5)' : '1px solid rgba(0, 0, 0, 0.12)',
  }

  const { parentId, id, searchNames, newKeywords, createdAt, hitCount, language } = data

  return (
    <TableRow>
      <TableCell key="searchNames" sx={{ ...sx, wordBreak: 'break-word' }}>
        {!parentId ? searchNames.join(', ') : null}
      </TableCell>
      <TableCell key="newKeywords" sx={{ ...sx, wordBreak: 'break-word' }}>
        {!parentId ? newKeywords.join(', ') : null}
      </TableCell>
      <TableCell key="createdAt" sx={sx}>
        {moment.parseZone(createdAt).format(DATE_TIME_FORMAT)}
      </TableCell>
      <TableCell key="text" sx={sx}>
        <HitContent
          hitData={data}
          userSettings={initialData.userSettings}
          noTranslationLanguages={noTranslationLanguages}
          showHitLink={showHitLink}
        />
      </TableCell>
      {showSavedTableCell && (
        <TableCell key="saved" sx={sx} align="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            <SavedHitIcon hit={data} />
          </Box>
        </TableCell>
      )}
      <TableCell key="great" sx={sx} align="center">
        <HitCheckBox
          dataTestId="hit_mark_great"
          type="tick"
          checked={quality === 'great'}
          disabled={disableAllRowsUpdateQuality || disableRowActionIcons}
          setDisabled={setDisabled}
          insideRow
          handleClick={() => onQualityCheckboxClick('great', data.id)}
          handleRequest={handleCheckboxChange('great')}
        />
      </TableCell>
      <TableCell key="irrelevant" sx={sx} align="center">
        <HitCheckBox
          dataTestId="hit_mark_irrelevant"
          type="dash"
          checked={quality === 'irrelevant'}
          disabled={disableAllRowsUpdateQuality || disableRowActionIcons}
          setDisabled={setDisabled}
          insideRow
          handleClick={() => onQualityCheckboxClick('irrelevant', data.id)}
          handleRequest={handleCheckboxChange('irrelevant')}
        />
      </TableCell>
      <TableCell key="hitCount" sx={{ ...sx, paddingLeft: 5 }}>
        {hitCount}
      </TableCell>
      <TableCell key="language" sx={sx}>
        {language.toUpperCase()}
      </TableCell>
    </TableRow>
  )
}

export default Row
