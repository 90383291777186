import { isEmpty } from 'lodash'
import I18n from 'i18next'

const VALID_FORM = { valid: true, errors: {} }
const REQUIRED_FIELDS = ['includeNames', 'cities', 'countries']

const isNotEmpty = (value) => {
  return value && !isEmpty(value) ? true : false
}

export const validatorSupplierSearchForm = (form) => {
  if (REQUIRED_FIELDS.some((key) => isNotEmpty(form[key]))) return VALID_FORM
  return { valid: false, errors: { searchFormInvalid: I18n.t('common.messages.supplier_search_form_invalid') } }
}
