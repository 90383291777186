import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch } from '@services'
import type { GetInboxPageStatsSchema, InboxPageStats } from '@types'

const DEFAULT_DATA = {
  topKeywordCounts: [],
  statistics: {
    new: 0,
  },
} as InboxPageStats

const transformData = (data: GetInboxPageStatsSchema | null): InboxPageStats => {
  if (!data) {
    return DEFAULT_DATA
  }
  return camelizeKeys<GetInboxPageStatsSchema>(data)
}

const queryFn = async (hitIds: number[]): Promise<InboxPageStats> => {
  let data: GetInboxPageStatsSchema | null = null

  const res = await fetch('/api/hits/overview', {
    method: 'POST',
    body: JSON.stringify({ ids: hitIds }),
  })

  if (res.ok) {
    data = await res.json()
  }
  return transformData(data)
}

const useGetStats = (hitIds: number[]) => {
  return useQuery<InboxPageStats>({
    queryKey: ['GET_INBOX_PAGE_STATS'],
    queryFn: () => queryFn(hitIds),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetStats
