import qs from 'qs'
import I18n from 'i18next'

import { SET_PAGE, SET_SORT } from '@common/actions/table'

import {
  LOAD_DATA,
  DATA_RECEIVED,
  INDEX_ERROR_RECEIVED,
  BUSINESS_PARTNERS_FETCHED,
  UPDATE_NAME_SEARCH_QUERY,
  UPDATE_LOCATION_SEARCH_QUERY,
  UPDATE_SEARCH_QUERY_FIELDS,
  URL_CHANGED,
} from '../actions'

export const initialState = {
  data: {
    items: [],
  },
  filters: {
    nameSearchQuery: '',
    searchQueryFields: ['city,city_search_name', 'street,zipcode', 'country_name'],
    locationSearchQuery: '',
  },
  table: {
    loading: false,
    page: 1,
    perPage: 100,
    totalResults: 0,
    sort: {
      field: 'name',
      order: 'asc',
    },
    data: {
      items: [],
    },
  },
  errors: [],
}

const clearSearch = (value) => {
  return value.replace(/\([^()]*\)/gi, '').trim()
}

export function reducer(state = initialState, { type, data }) {
  switch (type) {
    case BUSINESS_PARTNERS_FETCHED: {
      return { ...state, data: { ...state.data, ...{ items: data } } }
    }
    case LOAD_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      }
    }
    case DATA_RECEIVED: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: data.meta.totalEntries,
          data: {
            items: data.data,
          },
          error: '',
        },
        errors: [],
      }
    }
    case INDEX_ERROR_RECEIVED:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          totalResults: 0,
          data: {
            items: [],
          },
          error: data?.error || I18n.t('common.messages.unable_to_retrieve_data'),
        },
      }
    case `${SET_PAGE}_BP`:
      return {
        ...state,
        table: {
          ...state.table,
          page: data.page,
          perPage: data.perPage,
        },
      }
    case `${SET_SORT}_BP`:
      return {
        ...state,
        table: {
          ...state.table,
          sort: { field: data.sortField, order: data.sortOrder },
        },
      }
    case UPDATE_NAME_SEARCH_QUERY:
      return {
        ...state,
        filters: {
          ...state.filters,
          nameSearchQuery: data,
          nameSearchQueryCleared: clearSearch(data),
        },
      }
    case UPDATE_LOCATION_SEARCH_QUERY:
      return {
        ...state,
        filters: {
          ...state.filters,
          locationSearchQuery: data,
          locationSearchQueryCleared: clearSearch(data),
        },
      }
    case UPDATE_SEARCH_QUERY_FIELDS:
      return {
        ...state,
        filters: {
          ...state.filters,
          searchQueryFields: data,
        },
      }
    case URL_CHANGED: {
      const search = data || '?'
      const { filters, table } = qs.parse(search.slice(1, search.length))

      return {
        ...state,
        filters: filters || state.filters,
        table: {
          ...state.table,
          ...table,
        },
      }
    }
    default:
      return state
  }
}
