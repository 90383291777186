import { Field, Input } from '@riskmethods/rm-front-end'

type Props = {
  disabled?: boolean
}

export const SubjectInput = ({ disabled = false }: Props) => {
  return (
    <Field
      name="subject"
      label="Subject"
      disabled={disabled}
      Component={(props) => (
        <Input
          testId="subject-field"
          enableClearBtn
          enableTextCaseConverter
          textFieldProps={{ inputProps: { spellCheck: true } }}
          {...props}
        />
      )}
      validationRules={{
        required: true,
      }}
    />
  )
}
