import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonGroup, Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import I18n from 'i18next'

import {
  Delete as DeleteIcon,
  Visibility as EyeIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'

import { useTheme } from '@common/theme'
import { confirm } from '@common/components/alert'
import { useAppContext, useIMContext } from '@hooks'

type Props = {
  selectedHitsCount: number
  disableDeleteBtn: boolean
  disableDeleteAllBtn: boolean
  isDeleting: boolean
  handleDeleteMarkedHits: () => void
  handleDeleteAllHits: () => void
}

const ActionBtns = ({
  selectedHitsCount,
  disableDeleteBtn,
  disableDeleteAllBtn,
  isDeleting,
  handleDeleteAllHits,
  handleDeleteMarkedHits,
}: Props) => {
  const history = useHistory()
  const { themeMode } = useTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { showInboxStats, toggleInboxStats } = useAppContext()
  const { setIMFormValues } = useIMContext()

  const onStatsBtnClick = () => {
    toggleInboxStats(!showInboxStats)
  }

  const onDeleteBtnClick = () => {
    if (!selectedHitsCount) {
      return
    }
    confirm({
      title: I18n.t('inbox.alerts.delete_marked_hits.title'),
      body: I18n.t('inbox.alerts.delete_marked_hits.body'),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(handleDeleteMarkedHits, () => null)
  }

  const onDeleteAllBtnClick = () => {
    confirm({
      title: I18n.t('inbox.alerts.delete_all_hits.title'),
      body: I18n.t('inbox.alerts.delete_all_hits.body'),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(handleDeleteAllHits, () => null)
  }

  const onCreateClick = (e) => {
    e.preventDefault()
    setIMFormValues(null)
    history.push('/im/create')
  }

  const handleDeleteMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDeleteMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <ButtonGroup variant="contained" disabled={disableDeleteBtn}>
        <LoadingButton
          data-testid="delete_marked"
          color="secondary"
          loading={isDeleting}
          disabled={disableDeleteBtn}
          onClick={onDeleteBtnClick}
          sx={{
            width: '125px',
            borderRight: themeMode === 'dark' ? '1px black solid !important' : '1px solid white !important',
          }}
        >
          {`${I18n.t('inbox.filter.buttons.delete')} (${selectedHitsCount})`}
        </LoadingButton>
        <Button color="secondary" onClick={handleDeleteMenuClick} sx={{ p: 0 }}>
          <KeyboardArrowDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDeleteMenuClose}
        onClick={handleDeleteMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem data-testid="delete_all" disabled={disableDeleteAllBtn || isDeleting} onClick={onDeleteAllBtnClick}>
          <DeleteIcon color="error" fontSize="small" sx={{ mr: 1 }} />
          {I18n.t('inbox.filter.buttons.all_delete')}
        </MenuItem>
      </Menu>
      <Tooltip title="Statistics" placement="bottom">
        <Button data-testid="overview" color="secondary" onClick={onStatsBtnClick}>
          <EyeIcon fontSize="small" />
        </Button>
      </Tooltip>
      <Button
        data-testid="create"
        color="primary"
        href="/im/create"
        onClick={onCreateClick}
        sx={{ color: 'white !important' }}
      >
        {I18n.t('inbox.filter.buttons.create')}
      </Button>
    </Stack>
  )
}

export default ActionBtns
