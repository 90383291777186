import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Stack, Divider, Typography, Chip } from '@mui/material'
import { assign, isEmpty, filter, map, pick } from 'lodash'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import { useGetIndicators, useGetCountries } from '@api'
import { businessPartner, indicator } from '@common/utils/shapes'
import { validateData } from '@pages/im/actions/common'

import { FORM_MODE_CREATE, FORM_MODE_EDIT, FORM_MODE_REVIEW, FORM_MODE_COPY } from '@pages/im/utils/const'

import {
  fetchSuppliers,
  clearForm,
  removeForm,
  removeSupplier,
  selectIndicator,
  formChanged,
  removeSearch,
} from '@pages/im/actions/supplier'

import SupplierAlertForm from './components/supplier-alert-form/supplier-alert-form'
import SupplierNewsForm from './components/supplier-news-form/supplier-news-form'
import Buttons from '../shared/buttons/buttons'

import * as styles from './bp-im-form.scss'

const BPIMForm = (props) => {
  const [state, setState] = useState<
    Record<string, { cities?: []; countries?: []; includeNames?: []; excludeNames?: [] }>
  >({
    alert: { cities: undefined, countries: undefined },
    news: { cities: undefined, countries: undefined },
  })

  const {
    refetch: fetchIndicators,
    data: indicators,
    isFetching: isFetchingIndicators,
  } = useGetIndicators('business_partner')

  const { refetch: fetchCountries, data: countries } = useGetCountries()

  useEffect(() => {
    fetchIndicators()
    fetchCountries()

    if (props.imFormValues.onlyNews) {
      props.removeForm('alert')
    }
  }, [])

  const formChanged = (name) => (fieldName, fieldValue) => {
    const form = state[name]
    setState({ ...state, [name]: assign(form, { [fieldName]: fieldValue }) })
    props.formChanged(name)
  }

  const buildSearchLabel = (includeNames = [], excludeNames = [], countries, cities) => {
    const includeNamesLabel = includeNames.map(({ label }) => `"${label}"`).join(' AND ')
    const excludeNamesLabel = excludeNames.map(({ label }) => `"${label}"`).join(' OR ')
    const countriesLabel = countries.join(' OR ')
    const citiesLabel = cities.join(' OR ')

    return filter(
      [
        includeNamesLabel ? `NAME IS ${includeNamesLabel}` : '',
        excludeNamesLabel ? `NAME IS NOT ${excludeNamesLabel}` : '',
        countriesLabel ? `COUNTRY IS ${countriesLabel}` : '',
        citiesLabel ? `CITY IS ${citiesLabel}` : '',
      ],
      (x) => !isEmpty(x)
    )
      .map((x) => `(${x})`)
      .join(' AND ')
  }

  const clearSearch = (value) => {
    return value.replace(/\([^()]*\)/gi, '').trim()
  }

  const formSearch = (name) => () => {
    const formState = state[name]
    const includeNames = (formState.includeNames || []).map(({ value, phraseSearch }) => ({
      name: clearSearch(value),
      phraseSearch,
    }))
    const excludeNames = (formState.excludeNames || []).map(({ value, phraseSearch }) => ({
      name: clearSearch(value),
      phraseSearch,
    }))
    const cities = (formState.cities || []).map(({ name, fullName }) => name || fullName)
    const countries = (formState.countries || []).map(({ code }) => code)
    const filters = { includeNames, excludeNames, cities, countries }
    const searchLabel = buildSearchLabel(formState.includeNames, formState.excludeNames, countries, cities)

    setState({ ...state, [name]: {} })
    return props.fetchSuppliers(filters, name, searchLabel)
  }

  const clearForm = (name: string) => () => {
    setState({ ...state, [name]: {} })
    props.clearForm(name)
  }

  const removeSupplier = (name) => (businessPartner) => {
    props.removeSupplier(businessPartner, name)
  }

  const disableSubmit = () => {
    const { inProgress } = props
    return inProgress
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { validateData, saveMessage, imFormValues } = props
    const { valid } = validateData('SUPPLIER', !isEditing(), imFormValues.onlyNews, imFormValues.noValidity)
    if (valid) {
      saveMessage()
    }
  }

  const isSearchFormError = () => {
    const { errors } = props
    return errors.searchRequestTimeout || errors.searchFormInvalid ? true : false
  }

  const badgeErrors = () => {
    const formConfig = config()

    const errorPrefix = isSearchFormError() ? 'Error' : formConfig?.labels.error
    const errors = map(
      pick(props.errors, [
        'searchRequestTimeout',
        'noAlertSuppliers',
        'noNewsSuppliers',
        'sameSupplierExists',
        'searchFormInvalid',
        'bankruptcyWithValidityDate',
      ]),
      (message) => message
    )
    return isEmpty(errors) ? formConfig?.labels.error : `${errorPrefix}: ${errors.join(', ')}`
  }

  const removeSearch = (searchFormName) => (searchId) => {
    props.removeSearch(searchId, searchFormName)
  }

  const config = (): { labels: { header: string; submit: string; error: string } } | null => {
    const { imFormValues, formMode } = props
    const { onlyNews } = imFormValues
    switch (formMode) {
      case FORM_MODE_CREATE: {
        return {
          labels: {
            header: I18n.t(`indicator_message.headers.supplier${onlyNews ? '_news' : ''}`),
            submit: I18n.t(`indicator_message.buttons.create_${onlyNews ? 'news' : 'im'}`),
            error: I18n.t(`common.messages.unable_to_create${onlyNews ? '_news' : ''}`),
          },
        }
      }
      case FORM_MODE_EDIT: {
        return {
          labels: {
            header: I18n.t('indicator_message.headers.supplier_edit_news'),
            submit: I18n.t('indicator_message.buttons.update_news'),
            error: I18n.t('common.messages.unable_to_update_news'),
          },
        }
      }
      case FORM_MODE_REVIEW: {
        return {
          labels: {
            header: I18n.t(`indicator_message.headers.supplier_edit${onlyNews ? '_news' : ''}`),
            submit: I18n.t(`indicator_message.buttons.update_${onlyNews ? 'news' : 'im_news'}`),
            error: I18n.t(`common.messages.unable_to_update${onlyNews ? '_news' : ''}`),
          },
        }
      }
      case FORM_MODE_COPY: {
        return {
          labels: {
            header: I18n.t(`indicator_message.headers.supplier_copy${onlyNews ? '_news' : ''}`),
            submit: I18n.t(`indicator_message.buttons.create_${onlyNews ? 'news' : 'im'}`),
            error: I18n.t(`common.messages.unable_to_create${onlyNews ? '_news' : ''}`),
          },
        }
      }
      default: {
        return null
      }
    }
  }

  const isEditing = () => props.formMode === FORM_MODE_EDIT

  const showNotice = () => {
    return !props.valid || props.hasErrors
  }

  const {
    alertForm: {
      suppliers: alertSuppliers,
      suppliersLoading: alertSuppliersLoading,
      selectedIndicator,
      searches: alertSearches,
    },
    newsForm: { suppliers: newsSuppliers, suppliersLoading: newsSuppliersLoading, searches: newsSearches },
    selectIndicator,
    errors,
    imFormValues,
  } = props

  const { onlyNews } = imFormValues

  const formConfig = config()

  return (
    <Stack sx={{ pt: 2, px: 3 }} className={styles.supplier}>
      <Typography variant="h4" component="h1" gutterBottom>
        {formConfig?.labels.header}
        {showNotice() && (
          <Chip color="error" label={badgeErrors()} sx={{ borderRadius: '4px', ml: 2, fontSize: '1.1rem' }} />
        )}
      </Typography>
      <Divider />
      <Stack direction={'row'} sx={{ py: 3 }} flex={1} overflow={'auto'} justifyContent={'space-around'} gap={8}>
        {!onlyNews && (
          <SupplierAlertForm
            indicatorSelected={selectIndicator}
            selectedIndicator={selectedIndicator}
            indicators={indicators}
            indicatorsLoading={isFetchingIndicators}
            countries={countries}
            cities={state.alert.cities}
            selectedCountries={state.alert.countries}
            suppliers={alertSuppliers}
            searches={alertSearches}
            removeSearch={removeSearch('alert')}
            suppliersLoading={alertSuppliersLoading}
            formChanged={formChanged('alert')}
            formSearch={formSearch('alert')}
            clearForm={clearForm('alert')}
            formData={state.alert}
            removeSupplier={removeSupplier('alert')}
            errors={errors}
          />
        )}
        <SupplierNewsForm
          suppliers={newsSuppliers}
          suppliersLoading={newsSuppliersLoading}
          searches={newsSearches}
          countries={countries}
          cities={state.news.cities}
          selectedCountries={state.news.countries}
          formChanged={formChanged('news')}
          formSearch={formSearch('news')}
          clearForm={clearForm('news')}
          removeSupplier={removeSupplier('news')}
          formData={state.news}
          removeSearch={removeSearch('news')}
        />
      </Stack>
      <Divider />
      <Buttons
        submit={{
          onClick: handleSubmit,
          disabled: disableSubmit(),
          loading: props.isLoading,
          label: formConfig?.labels.submit || '',
        }}
        back={{ onClick: props.onBack }}
        cancel={{ onClick: props.onCancel }}
      />
    </Stack>
  )
}

const mapStateToProps = (rootState) => {
  const {
    formMode,
    supplier: {
      valid,
      errors,
      data: { alertForm, newsForm },
    },
    save,
    update,
  } = rootState.indicatorMessage

  return {
    formMode,
    alertForm,
    newsForm,
    valid,
    errors,
    inProgress: save.inProgress || update.inProgress,
    hasErrors: save.hasErrors || update.hasErrors,
  }
}

const mapDispatchToProps = {
  fetchSuppliers,
  clearForm,
  removeForm,
  removeSupplier,
  selectIndicator,
  validateData,
  formChanged,
  removeSearch,
}

BPIMForm.propTypes = {
  fetchSuppliers: PropTypes.func.isRequired,
  removeSupplier: PropTypes.func.isRequired,
  removeSearch: PropTypes.func.isRequired,
  selectIndicator: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  removeForm: PropTypes.func.isRequired,
  alertForm: PropTypes.shape({
    selectedIndicator: indicator,
    suppliers: PropTypes.arrayOf(businessPartner),
    searches: PropTypes.arrayOf(PropTypes.object),
    suppliersLoading: PropTypes.bool.isRequired,
  }).isRequired,
  newsForm: PropTypes.shape({
    suppliers: PropTypes.arrayOf(businessPartner),
    searches: PropTypes.arrayOf(PropTypes.object),
    suppliersLoading: PropTypes.bool,
  }).isRequired,
  valid: PropTypes.bool,
  hasErrors: PropTypes.bool,
  errors: PropTypes.object,
  inProgress: PropTypes.bool.isRequired,
  validateData: PropTypes.func.isRequired,
  saveMessage: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  formChanged: PropTypes.func.isRequired,
  formMode: PropTypes.string,
  imFormValues: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(BPIMForm)
